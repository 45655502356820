<script >
import OurServices from "@/components/OurServices.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";

export default{
  name:"HealthSupportPage",
  components: {SideContactComponent, OurServices},
  data() {
    return {
      show1:false,
      show2:false,
      show3:false,
      show4:false,
      show5:false,
      show6:false,
      show7:false,
    };
  },
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-dbd53695-b92c-e573-fd4b-af802db67edf-45499aa2" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="dbd53695-b92c-e573-fd4b-af802db67ee2" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('service-support.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('service-support.title')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('service-support.subtitle')}}</div>
            </div>
            <div id="w-node-dbd53695-b92c-e573-fd4b-af802db67eec-45499aa2" class="uui-blogpost05_image-wrapper-7"><img srcset="images/planes-de-apoyo-en-salud-pas-hospital-angel-del-mar-1-p-500.jpg 500w, images/planes-de-apoyo-en-salud-pas-hospital-angel-del-mar-1-p-800.jpg 800w, images/planes-de-apoyo-en-salud-pas-hospital-angel-del-mar-1-p-1080.jpg 1080w, images/planes-de-apoyo-en-salud-pas-hospital-angel-del-mar-1.jpg 1209w" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 46vw, 768px" src="images/planes-de-apoyo-en-salud-pas-hospital-angel-del-mar-1.jpg" alt="" class="uui-blogpost03_image-2 pas"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-4">
              <div class="uui-blogpost05_content-left-2">
                <our-services></our-services>
                <side-contact-component></side-contact-component>
              </div>
            </div>
            <div id="w-node-dbd53695-b92c-e573-fd4b-af802db67ef0-45499aa2" class="uui-blogpost05_content-7">
              <div class="uui-blogpost05_content-2">
                <div class="uui-text-rich-text-3 w-richtext">
                  <h4>{{$t('service-support.article')}}</h4>
                  <p>{{$t('service-support.article-1-content')}}</p>
                </div>
                <section class="equipos-mujer">
                  <div class="rl-padding-global-20">
                    <div class="rl-container-large-15"></div>
                  </div>
                </section>
                <section class="uui-section_faq01-8">
                  <div class="uui-page-padding-52">
                    <div class="uui-container-large-42">
                      <div class="uui-padding-vertical-xhuge">
                        <div class="uui-faq01_component-9">
                          <div class="uui-faq01_list-10">
                            <div class="uui-faq01_accordion-8">
                              <div @click="show1 = !show1" class="uui-faq01_question-8">
                                <div class="uui-faq01_heading-8">{{$t('service-support.question1')}}</div>
                                <div class="uui-faq01_icon-wrapper-8">
                                  <div class="accordion-icon_component-9">
                                    <div class="accordion-icon_horizontal-line-9"></div>
                                    <div  v-show="!show1" class="accordion-icon_vertical-line-9"></div>
                                  </div>
                                </div>
                              </div>
                              <div v-show="show1" class="uui-faq01_answer-8 animate__animated animate__fadeIn" >
                                <div class="uui-text-size-medium">{{$t('service-support.question1-p1')}}</div>
                                <div class="uui-space-medium"></div>
                              </div>
                            </div>
                            <div class="uui-faq01_accordion-8">
                              <div @click="show2 = !show2" class="uui-faq01_question-8">
                                <div class="uui-faq01_heading-8">{{$t('service-support.question2')}}</div>
                                <div class="uui-faq01_icon-wrapper-8">
                                  <div class="accordion-icon_component-9">
                                    <div class="accordion-icon_horizontal-line-9"></div>
                                    <div v-show="!show2" class="accordion-icon_vertical-line-9"></div>
                                  </div>
                                </div>
                              </div>
                              <div v-show="show2" class="uui-faq01_answer-8 animate__animated animate__fadeIn" >
                                <div class="uui-text-size-medium">{{$t('service-support.question2-p1')}}</div>
                                <ul role="list">
                                  <li>{{$t('service-support.question2-li1')}}</li>
                                  <li>{{$t('service-support.question2-li2')}}</li>
                                  <li>{{$t('service-support.question2-li3')}}</li>
                                  <li>{{$t('service-support.question2-li4')}}</li>
                                  <li>{{$t('service-support.question2-li5')}}</li>
                                </ul>
                                <div class="uui-space-medium"></div>
                              </div>
                            </div>
                            <div class="uui-faq01_accordion-8">
                              <div @click="show3 = !show3" class="uui-faq01_question-8">
                                <div class="uui-faq01_heading-8">{{$t('service-support.question3')}}</div>
                                <div class="uui-faq01_icon-wrapper-8">
                                  <div class="accordion-icon_component-9">
                                    <div class="accordion-icon_horizontal-line-9"></div>
                                    <div v-show="!show3" class="accordion-icon_vertical-line-9"></div>
                                  </div>
                                </div>
                              </div>
                              <div v-show="show3" class="uui-faq01_answer-8 animate__animated animate__fadeIn">
                                <div class="uui-text-size-medium">{{$t('service-support.question3-p1')}}</div>
                                <div class="uui-space-medium"></div>
                              </div>
                            </div>
                            <div class="uui-faq01_accordion-8">
                              <div  @click="show4 = !show4" class="uui-faq01_question-8">
                                <div class="uui-faq01_heading-8">{{$t('service-support.question4')}}</div>
                                <div class="uui-faq01_icon-wrapper-8">
                                  <div class="accordion-icon_component-9">
                                    <div class="accordion-icon_horizontal-line-9"></div>
                                    <div v-show="!show4" class="accordion-icon_vertical-line-9"></div>
                                  </div>
                                </div>
                              </div>
                              <div v-show="show4" class="uui-faq01_answer-8 animate__animated animate__fadeIn">
                                <ul role="list">
                                  <li>{{$t('service-support.question4-li1')}}</li>
                                  <li>{{$t('service-support.question4-li2')}}</li>
                                  <li>{{$t('service-support.question4-li3')}}</li>
                                  <li>{{$t('service-support.question4-li4')}}</li>
                                  <li>{{$t('service-support.question4-li5')}}</li>
                                </ul>
                                <div class="uui-space-medium"></div>
                              </div>
                            </div>
                            <div class="uui-faq01_accordion-8 last-item">
                              <div @click="show5 = !show5" data-w-id="392e9648-c27c-8e68-64cb-44973d84cb4a" class="uui-faq01_question-8">
                                <div class="uui-faq01_heading-8">{{$t('service-support.question5')}}</div>
                                <div class="uui-faq01_icon-wrapper-8">
                                  <div class="accordion-icon_component-9">
                                    <div class="accordion-icon_horizontal-line-9"></div>
                                    <div v-show="!show5" class="accordion-icon_vertical-line-9"></div>
                                  </div>
                                </div>
                              </div>
                              <div v-show="show5"  class="uui-faq01_answer-8 animate__animated animate__fadeIn">
                                <div class="uui-text-size-medium">{{$t('service-support.question5-p1')}}</div>
                                <div class="uui-space-medium"></div>
                              </div>
                            </div>
                            <div class="uui-faq01_accordion-8 last-item">
                              <div @click="show6 = !show6" class="uui-faq01_question-8">
                                <div class="uui-faq01_heading-8">{{$t('service-support.question6')}}</div>
                                <div class="uui-faq01_icon-wrapper-8">
                                  <div class="accordion-icon_component-9">
                                    <div class="accordion-icon_horizontal-line-9"></div>
                                    <div v-show="!show6" class="accordion-icon_vertical-line-9"></div>
                                  </div>
                                </div>
                              </div>
                              <div v-show="show6" class="uui-faq01_answer-8 animate__animated animate__fadeIn">
                                <div class="uui-text-size-medium">{{$t('service-support.question6-p1')}}</div>
                                <div class="uui-space-medium"></div>
                              </div>
                            </div>
                            <div class="uui-faq01_accordion-8 last-item">
                              <div @click="show7 = !show7" class="uui-faq01_question-8">
                                <div class="uui-faq01_heading-8">{{$t('service-support.question7')}}</div>
                                <div class="uui-faq01_icon-wrapper-8">
                                  <div class="accordion-icon_component-9">
                                    <div class="accordion-icon_horizontal-line-9"></div>
                                    <div v-show="!show7" class="accordion-icon_vertical-line-9"></div>
                                  </div>
                                </div>
                              </div>
                              <div v-show="show7" class="uui-faq01_answer-8 animate__animated animate__fadeIn">
                                <div class="uui-text-size-medium">{{$t('service-support.question7-p1')}}</div>
                                <div class="uui-space-medium"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>

</style>