<script >

export default {
  name: "SideContactComponent",
}
</script>

<template>
  <div class="uui-blogpost05_divider-2"></div>
  <div class="uui-blogpost05_contributers-2">
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <a href="https://maps.app.goo.gl/6XsoQHej9DWHjzuR6" target="_blank" class="link-block-10 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">Sexta Norte, Esquina Primera Oriente S/N, Sector Reforma. Puerto Escondido, Oax, C.P. 71980</div>
        </a>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <a href="tel:+1039541770692" class="link-block-11 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">954 104 2270</div>
        </a>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <a href="mailto:contacto@hospitalangeldelmar.com.mx" class="link-block-12 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">contacto@hospitalangeldelmar.com.mx</div>
        </a>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <div class="uui-blogpost05_author-heading-2">Servicio las 24 horas</div>
      </div>
    </div>
  </div>
  <div class="uui-blogpost05_divider-2"></div>
  <div class="uui-blogpost05_share-wrapper-2">
    <div class="w-layout-grid uui-blogpost05_share-2">
      <a id="w-node-f3b58f88-2378-0476-571d-5427d4de0530-bc1472c6" href="https://www.facebook.com/Hospitalangeldelmar/?locale=es_LA" target="_blank" class="uui-button-secondary-gray-9 is-button-small icon-only w-inline-block">
        <div class="uui-button-icon-11 text-color-gray400 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 14.9912 3.65684 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3084 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.95 6.5625 11.5625 7.3334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3432 19.1283 20 14.9912 20 10Z" fill="currentColor"></path>
          <path d="M13.8926 12.8906L14.3359 10H11.5625V8.125C11.5625 7.33418 11.95 6.5625 13.1922 6.5625H14.4531V4.10156C14.4531 4.10156 13.3088 3.90625 12.2146 3.90625C9.93047 3.90625 8.4375 5.29063 8.4375 7.79688V10H5.89844V12.8906H8.4375V19.8785C9.47287 20.0405 10.5271 20.0405 11.5625 19.8785V12.8906H13.8926Z" fill="white"></path>
        </svg></div>
      </a>
      <a id="w-node-f3b58f88-2378-0476-571d-5427d4de0532-bc1472c6" href="https://mx.linkedin.com/company/hospital-%C3%A1ngel-del-mar" target="_blank" class="uui-button-secondary-gray-9 is-button-small icon-only w-inline-block">
        <div class="uui-button-icon-11 text-color-gray400 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3774_70975)">
            <path d="M18.5236 0H1.47639C1.08483 0 0.709301 0.155548 0.432425 0.432425C0.155548 0.709301 0 1.08483 0 1.47639V18.5236C0 18.9152 0.155548 19.2907 0.432425 19.5676C0.709301 19.8445 1.08483 20 1.47639 20H18.5236C18.9152 20 19.2907 19.8445 19.5676 19.5676C19.8445 19.2907 20 18.9152 20 18.5236V1.47639C20 1.08483 19.8445 0.709301 19.5676 0.432425C19.2907 0.155548 18.9152 0 18.5236 0ZM5.96111 17.0375H2.95417V7.48611H5.96111V17.0375ZM4.45556 6.1625C4.11447 6.16058 3.7816 6.05766 3.49895 5.86674C3.21629 5.67582 2.99653 5.40544 2.8674 5.08974C2.73826 4.77404 2.70554 4.42716 2.77336 4.09288C2.84118 3.7586 3.0065 3.4519 3.24846 3.21148C3.49042 2.97107 3.79818 2.80772 4.13289 2.74205C4.4676 2.67638 4.81426 2.71133 5.12913 2.84249C5.44399 2.97365 5.71295 3.19514 5.90205 3.47901C6.09116 3.76288 6.19194 4.09641 6.19167 4.4375C6.19488 4.66586 6.15209 4.89253 6.06584 5.104C5.97959 5.31547 5.85165 5.50742 5.68964 5.66839C5.52763 5.82936 5.33487 5.95607 5.12285 6.04096C4.91083 6.12585 4.68389 6.16718 4.45556 6.1625ZM17.0444 17.0458H14.0389V11.8278C14.0389 10.2889 13.3847 9.81389 12.5403 9.81389C11.6486 9.81389 10.7736 10.4861 10.7736 11.8667V17.0458H7.76667V7.49306H10.6583V8.81667H10.6972C10.9875 8.22917 12.0042 7.225 13.5556 7.225C15.2333 7.225 17.0458 8.22083 17.0458 11.1375L17.0444 17.0458Z" fill="currentColor"></path>
          </g>
          <defs>
            <clippath id="clip0_3774_70975">
              <rect width="20" height="20" fill="white"></rect>
            </clippath>
          </defs>
        </svg></div>
      </a>
      <a id="w-node-dbeeab2d-1e72-ddea-3afb-ca92dfd10363-39eb23ab" href="https://www.instagram.com/hospitalangeldelmar/?hl=es" target="_blank" class="uui-button-secondary-gray-9 is-button-small icon-only w-inline-block">
        <div class="uui-button-icon-11 text-color-gray400 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
          <path d="M12.0027 5.81016C8.59743 5.81016 5.85075 8.54029 5.85075 11.925C5.85075 15.3097 8.59743 18.0398 12.0027 18.0398C15.4079 18.0398 18.1546 15.3097 18.1546 11.925C18.1546 8.54029 15.4079 5.81016 12.0027 5.81016ZM12.0027 15.9004C9.80212 15.9004 8.00312 14.1176 8.00312 11.925C8.00312 9.73239 9.79676 7.94956 12.0027 7.94956C14.2086 7.94956 16.0022 9.73239 16.0022 11.925C16.0022 14.1176 14.2032 15.9004 12.0027 15.9004ZM19.8412 5.56003C19.8412 6.35299 19.1987 6.9863 18.4062 6.9863C17.6085 6.9863 16.9713 6.34767 16.9713 5.56003C16.9713 4.7724 17.6138 4.13377 18.4062 4.13377C19.1987 4.13377 19.8412 4.7724 19.8412 5.56003ZM23.9157 7.00758C23.8247 5.09703 23.3856 3.40467 21.9775 2.01034C20.5747 0.616008 18.8721 0.179613 16.9499 0.0838196C14.9689 -0.0279399 9.03112 -0.0279399 7.05008 0.0838196C5.1333 0.174292 3.43067 0.610686 2.02253 2.00502C0.614389 3.39935 0.180703 5.09171 0.0843279 7.00226C-0.0281093 8.97136 -0.0281093 14.8733 0.0843279 16.8424C0.175349 18.753 0.614389 20.4453 2.02253 21.8397C3.43067 23.234 5.12794 23.6704 7.05008 23.7662C9.03112 23.8779 14.9689 23.8779 16.9499 23.7662C18.8721 23.6757 20.5747 23.2393 21.9775 21.8397C23.3803 20.4453 23.8193 18.753 23.9157 16.8424C24.0281 14.8733 24.0281 8.97668 23.9157 7.00758ZM21.3564 18.9552C20.9388 19.9983 20.1303 20.8019 19.0755 21.2223C17.496 21.845 13.7481 21.7013 12.0027 21.7013C10.2572 21.7013 6.50396 21.8397 4.92984 21.2223C3.88042 20.8072 3.07195 20.0036 2.64897 18.9552C2.02253 17.3853 2.16709 13.6599 2.16709 11.925C2.16709 10.1901 2.02789 6.45943 2.64897 4.8948C3.06659 3.85171 3.87507 3.04811 4.92984 2.62768C6.50931 2.00502 10.2572 2.14871 12.0027 2.14871C13.7481 2.14871 17.5014 2.01034 19.0755 2.62768C20.1249 3.04278 20.9334 3.84639 21.3564 4.8948C21.9828 6.46475 21.8383 10.1901 21.8383 11.925C21.8383 13.6599 21.9828 17.3906 21.3564 18.9552Z" fill="#B2B2B2"></path>
        </svg></div>
      </a>
    </div>
  </div>
</template>

<style scoped>

</style>
