<script >
import OurServices from "@/components/OurServices.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import AddressComponent from "@/components/AddressComponent.vue";
export default{
  name:"PharmacyPage",
  components: {AddressComponent, SideContactComponent, OurServices}
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_744c27dc-aeb3-7512-6f4e-9195ba36d79b-3b03f6e2" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="744c27dc-aeb3-7512-6f4e-9195ba36d79e" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('PharmacyPage.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('PharmacyPage.title')}}<br> {{$t('PharmacyPage.title2')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('PharmacyPage.subtitle')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_744c27dc-aeb3-7512-6f4e-9195ba36d7a8-3b03f6e2" class="uui-blogpost05_image-wrapper-7">
              <img srcset="../../../public/images/farmacias-p-500.png 500w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, (max-width: 1919px) 46vw, 768px" src="../../../public/images/farmacias-p-500.png" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-4">
              <div class="uui-blogpost05_content-left-2">
                <our-services></our-services><side-contact-component></side-contact-component>
              </div>
            </div>
            <div id="w-node-_744c27dc-aeb3-7512-6f4e-9195ba36d7ac-3b03f6e2" class="uui-blogpost05_content-7">
              <div class="uui-blogpost05_content-2">
                <div class="uui-text-rich-text-3 w-richtext" style="text-align: justify;">
                  <h4>{{$t('PharmacyPage.article-1-title')}}</h4>
                  <p>{{$t('PharmacyPage.article-1-p1')}}</p>
                  <p>{{$t('PharmacyPage.article-1-p2')}}</p><br>
                  <figure style="max-width:1680pxpx" class="w-richtext-align-fullwidth w-richtext-figure-type-image">
                    <div><img src="../../../public/images/FARMCIA-3.jpg" loading="lazy" alt=""></div>
                  </figure>
                  <h4>{{$t('PharmacyPage.benefits-title')}}</h4>
                  <h5>{{$t('PharmacyPage.dealers')}}</h5>
                </div>
                <ul role="list">
                  <li>{{$t('PharmacyPage.dealers-li1')}}</li><li>{{$t('PharmacyPage.dealers-li2')}}</li>
                  <li>{{$t('PharmacyPage.dealers-li3')}}</li><li>{{$t('PharmacyPage.dealers-li4')}}</li>
                </ul>
                <div class="uui-text-rich-text-3 w-richtext"><h5>{{$t('PharmacyPage.laboratories')}}</h5>
                </div>
                <ul role="list">
                  <li>{{$t('PharmacyPage.laboratories-li1')}}</li><li>{{$t('PharmacyPage.laboratories-li2')}}</li>
                  <li>{{$t('PharmacyPage.laboratories-li3')}}</li><li>{{$t('PharmacyPage.laboratories-li4')}}</li>
                  <li>{{$t('PharmacyPage.laboratories-li5')}}</li><li>{{$t('PharmacyPage.laboratories-li6')}}</li>
                </ul>
                <div class="f-section-large-2">
                  <div class="f-container-regular-4">
                    <div class="w-layout-grid f-gallery-two-column">
                      <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec0-3b03f6e2" class="f-gallery-lightbox w-inline-block w-lightbox">
                        <div class="f-gallery-image-tall"><img src="../../../public/images/farmacias.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/farmacias-p-500.png 500w, ../../../public/images/farmacias.png 648w" alt="" class="f-image-cover"></div>
                        <!--                        <script type="application/json" class="w-json">{-->
                        <!--                          "items": [],-->
                        <!--                          "group": ""-->
                        <!--                        }</script>-->
                      </a>
                      <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec3-3b03f6e2" class="f-gallery-lightbox w-inline-block w-lightbox">
                        <div class="f-gallery-image-s"><img src="../../../public/images/FARMACIA-2.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/FARMACIA-2-p-500.jpg 500w, ../../../public/images/FARMACIA-2-p-800.jpg 800w, ../../../public/images/FARMACIA-2-p-1080.jpg 1080w, ../../../public/images/FARMACIA-2-p-1600.jpg 1600w, ../../../public/images/FARMACIA-2-p-2000.jpg 2000w, ../../../public/images/FARMACIA-2-p-2600.jpg 2600w, ../../../public/images/FARMACIA-2.jpg 3456w" alt="" class="f-image-cover"></div>
                        <!--                        <script type="application/json" class="w-json">{-->
                        <!--                          "items": [],-->
                        <!--                          "group": ""-->
                        <!--                        }</script>-->
                      </a>
                      <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec6-3b03f6e2" class="f-gallery-lightbox w-inline-block w-lightbox">
                        <div class="f-gallery-image-s"><img src="../../../public/images/FARMACIA.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/FARMACIA-p-500.jpg 500w, ../../../public/images/FARMACIA-p-800.jpg 800w, ../../../public/images/FARMACIA-p-1080.jpg 1080w, ../../../public/images/FARMACIA-p-1600.jpg 1600w, ../../../public/images/FARMACIA-p-2000.jpg 2000w, ../../../public/images/FARMACIA-p-2600.jpg 2600w, ../../../public/images/FARMACIA.jpg 3456w" alt="" class="f-image-cover"></div>
                        <!--                        <script type="application/json" class="w-json">{-->
                        <!--                          "items": [],-->
                        <!--                          "group": ""-->
                        <!--                        }</script>-->
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 class="uui-heading-medium-11" style="margin-top: 2rem;margin-bottom: 2rem; text-align: center;color: var(--hc--colors--green--600);">{{$t('PharmacyPage.service24')}}</h2>
                  <p style="text-align: center;"><strong>{{$t('PharmacyPage.offers')}} </strong><br> {{$t('PharmacyPage.ofertas-content')}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>

</style>
