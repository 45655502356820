import { createApp } from 'vue';
import App from './App.vue';
import AppHeader from './components/AppHeader.vue';
import HomePage from './pages/HomePage.vue';
import PromotionsPage from './pages/PromotionsPage.vue';
import AboutPage from './pages/AboutPage.vue';
import { createRouter, createWebHistory } from 'vue-router';
import ContactPage from "@/pages/ContactUsPage.vue";
import DirectoryPage from "@/pages/DirectoryPage.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import {createI18n} from "vue-i18n";
import mitt from "mitt";
import GalleryPage from "@/pages/GalleryPage.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import TestimonialsPage from "@/pages/TestimonialsPage.vue";
import VisitorsPage from "@/pages/VisitorsPage.vue";
import PrivacyPage from "@/pages/PrivacyPage.vue";
import InsurersPage from "@/pages/InsurersPage.vue";
import FaqsPage from "@/pages/FaqsPage.vue";
import EmergenciesPage from "@/pages/EmergenciesPage.vue";
import ObstetricalPage from "@/pages/ObstetricalPage.vue";
import PediatricPage from "@/pages/emergencies/PediatricPage.vue";
import AdultsPage from "@/pages/emergencies/AdultsPage.vue";

import DigestiveDiseasesPage from "@/pages/clinics/DigestiveDiseasesClinicPage.vue";
import WomensPage from "@/pages/clinics/WomensClinicPage.vue";
import DiabeticPage from "@/pages/clinics/DiabeticClinicPage.vue";

import PediatricClinicPage from "@/pages/clinics/PediatricClinicPage.vue";
import LaboratoryPage from "@/pages/services/LaboratoryPage.vue";
import HemodialysisPage from "@/pages/services/HemodialysisPage.vue";
import HospitalizationPage from "@/pages/services/HospitalizationPage.vue";
import RadiologyImagingPage from "@/pages/services/RadiologyImagingPage.vue";
import FamilyEmergencyMedicinePage from "@/pages/specialties/FamilyEmergencyMedicinePage.vue";
import UrologyPage from "@/pages/specialties/UrologyPage.vue";
import OphthalmologyPage from "@/pages/specialties/OphthalmologyPage.vue";
import InternalMedicinePage from "@/pages/specialties/InternalMedicinePage.vue";
import DermatologyPage from "@/pages/specialties/DermatologyPage.vue";
import AdultClinicPage from "@/pages/specialties/AdultClinicPage.vue";
import HealthSupportPage from "@/pages/services/HealthSupportPage.vue";
import TraumatologyOrthopedicsPage from "@/pages/specialties/TraumatologyOrthopedicsPage.vue";
import {LOCALE_COOKIE_NAME, SITE_DOMAIN} from "@/config";
import PharmacyPage from "@/pages/services/PharmacyPage.vue";
import OphthalmologyClinicPage from "@/pages/clinics/OphthalmologyClinicPage.vue";
import ShockTraumatologyClinicPage from "@/pages/clinics/ShockTraumatologyClinicPage.vue";
import InternalMedicineClinicPage from "@/pages/clinics/InternalMedicineClinicPage.vue";
import HospitalTransfusionPage from "@/pages/services/HospitalTransfusionPage.vue";


export const getCookie = function (cookieName) {
    let cookiesArray = document.cookie.split(";");
    for (let i = 0; i < cookiesArray.length; i++) {
        let cookie = cookiesArray[i].trim();
        if (cookie.indexOf(cookieName + "=") === 0) {
            return decodeURIComponent(cookie.substring(cookieName.length + 1));
        }
    }
    return null;
};

//const preferredLanguage = localStorage.getItem('preferredLanguage');
const preferredLanguage = getCookie(LOCALE_COOKIE_NAME);


const userLanguage = navigator.language || navigator.userLanguage;
const detectedLanguage = userLanguage.split('-')[0];

//if(preferredLanguage==null)localStorage.setItem('preferredLanguage',detectedLanguage);
//if(preferredLanguage==null)document.cookie = `${LOCALE_COOKIE_NAME}= ${detectedLanguage};domain=${SITE_DOMAIN}; path=/; Secure`;
if(preferredLanguage==null)document.cookie = LOCALE_COOKIE_NAME+"="+detectedLanguage+"; domain="+SITE_DOMAIN+"; path=/; Secure";
// "localeCookie=es; domain=.hospitalangeldelmar.com.mx; path=/; Secure";

const i18n = createI18n({
    locale: preferredLanguage || detectedLanguage,
    messages: {
        es: require('./locales/es.json'),
        en: require('./locales/en.json'),
    },
});

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: HomePage },
        { path: '/promotions', component: PromotionsPage },
        { path: '/about', component: AboutPage },
        { path: '/contact', component: ContactPage },
        { path: '/testimonials', component: TestimonialsPage },
        { path: '/directory', component: DirectoryPage },
        { path: '/visitors', component: VisitorsPage },
        { path: '/gallery', component: GalleryPage },
        { path: '/privacy', component: PrivacyPage },
        { path: '/insurers', component: InsurersPage },
        { path: '/faqs', component: FaqsPage },
        { path: '/emergencies', component: EmergenciesPage },
        { path: '/obstetrical', component: ObstetricalPage },
        { path: '/emergencies-pediatric', component: PediatricPage },
        { path: '/emergencies-adults', component: AdultsPage },
        { path: '/clinics-digestive-diseases', component: DigestiveDiseasesPage },
        { path: '/clinics-womens', component: WomensPage },
        { path: '/clinics-diabetic', component: DiabeticPage },
        { path: '/clinics-pediatric', component: PediatricClinicPage },
        { path: '/clinics-internal-medicine', component: InternalMedicineClinicPage},
        { path: '/clinics-ophthalmology', component: OphthalmologyClinicPage},
        { path: '/clinics-shock-and-trauma', component: ShockTraumatologyClinicPage},
        { path: '/services-laboratory', component: LaboratoryPage },
        { path: '/services-transfusion', component: HospitalTransfusionPage},
        { path: '/services-hemodialysis', component: HemodialysisPage },
        { path: '/services-hospitalization', component: HospitalizationPage },
        { path: '/services-radiology-imaging', component: RadiologyImagingPage },
        { path: '/services-health-support', component: HealthSupportPage },
        { path: '/services-pharmacy', component: PharmacyPage },
        { path: '/specialties-family-emergency-medicine', component: FamilyEmergencyMedicinePage },
        { path: '/specialties-urology', component: UrologyPage },
        { path: '/specialties-ophthalmology', component: OphthalmologyPage },
        { path: '/specialties-internal-medicine', component: InternalMedicinePage },
        { path: '/specialties-dermatology', component: DermatologyPage },
        { path: '/specialties-adult-clinic', component: AdultClinicPage },
        { path: '/specialties-traumatology-orthopedics', component: TraumatologyOrthopedicsPage },



        { path: '/search/:query',name:'search', component: SearchComponent,props: true, },
    ],
    scrollBehavior() {
        return { top:0};
    },
});


export const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.$emitter = emitter;

app.component('AppHeader', AppHeader);
app.component('FooterComponent', FooterComponent);
app.use(router);
app.use(i18n);
app.mount('#app');
