<script >

import AddressLaboratory from "@/components/AddressLaboratory.vue";
import AddressInsurers from "@/components/AddressInsurers.vue";
import AddressFromDeskInsurers from "@/components/AddressFromDeskInsurers.vue";
import AddressPediatric from "@/components/AddressPediatric.vue";
import PediatricWhatsApp from "@/components/PediatricWhatsApp.vue";

export default {
  name: "AddressComponent",
  components: {PediatricWhatsApp, AddressPediatric, AddressFromDeskInsurers, AddressInsurers, AddressLaboratory}
}
</script>

<template>
  <section class="uui-section_contact12">
    <div class="uui-space-large-7"></div>
    <div class="uui-page-padding-48">
      <div class="uui-container-large-38">
        <div class="uui-padding-vertical-xhuge-50">
          <div class="w-layout-grid uui-contact12_component">
            <div class="uui-contact12_item"><img src="../../public/images/Location.svg" loading="lazy" alt="" class="image-22">
              <div class="uui-space-small-23"></div>
              <div class="uui-space-xxsmall-20"></div>
              <div class="uui-text-size-medium-36">{{$t('home.located-at')}}</div>
              <div class="uui-space-small-23"></div>
              <a href="#" class="uui-text-style-link-02-5">{{$t('home.ham-location')}}</a>
            </div>
            <div class="uui-contact12_item"><img src="../../public/images/Calendar-Waiting.svg" loading="lazy" alt="" class="image-20">
              <div class="uui-space-small-23"></div>
              <div class="uui-space-xxsmall-20"></div>
              <div class="uui-text-size-medium-36">{{$t('home.service')}}</div>
              <div class="uui-space-xxsmall-20"></div>
              <a href="#" class="uui-text-style-link-02-5">{{$t('home.24-hours')}}</a>
            </div>
            <div class="uui-contact12_item">
              <div id="w-node-_9d38636a-0354-23ba-88fb-3f0ab24df8d9-585146fb" class="div-block-26"><img src="../../public/images/whats.svg" loading="lazy" alt="" class="image-23 image-green"><img src="../../public/images/Logo-Bg-Removed-HAM.png" loading="lazy" alt="" class="image-21"></div>
              <div class="uui-space-small-23"></div>
              <div class="uui-space-small-23"></div>
              <div class="div-block-27">
                <template v-if="$route.path === '/insurers'">
                  <address-from-desk-insurers></address-from-desk-insurers>
                </template>
                <template v-if="$route.path === '/clinics-pediatric'">
                  <address-pediatric></address-pediatric>
                </template>
                <template v-else>
                  <div class="uui-text-size-medium-36">{{$t('home.general-from-desk')}}</div>
                  <div class="uui-space-small-23"></div>
                  <a href="tel:9541042270" class="uui-text-style-link-02-5 spacing">954 104 2270</a>
                  <div class="uui-space-small-23"></div>
                </template>
                <template v-if="$route.path === '/services-laboratory'">
                  <address-laboratory></address-laboratory>
                </template>
                <template v-else-if="$route.path === '/insurers'">
                  <address-insurers></address-insurers>
                </template>
                <template v-if="$route.path === '/clinics-pediatric'">
                  <pediatric-whats-app></pediatric-whats-app>
                </template>
                <template v-else>
                  <div class="uui-text-size-medium-36">{{$t('home.emergencies')}}</div>
                  <div class="uui-space-small-23"></div>
                  <a href="tel:9541034110" class="uui-text-style-link-02-5 spacing">954 103 4110</a>
                  <a href="tel:9541034110" class="uui-text-style-link-02-5 spacing">954 177 0692</a>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uui-space-large-7"></div>
  </section>
</template>

<style scoped>

</style>
