<script >
// import EmergenciesComponent from "@/components/EmergenciesComponent.vue";
// import AddressComponent from "@/components/AddressComponent.vue";

// import EmergenciesServiceComponent from "@/components/EmergenciesServiceComponent.vue";

export default {
  name: "EmergenciesPage",
  // components: {EmergenciesServiceComponent},
  // components: {AddressComponent, EmergenciesComponent}
}
</script>

<template>
  <header id="blog-header-5" class="uui-section_blog03">
    <div class="uui-page-padding-51">
      <div class="uui-container-large-41">
        <div class="uui-padding-vertical-xhuge-52">
          <div class="uui-blog03_component">
            <div class="uui-blog03_featured-list-wrapper">
              <div class="uui-blog03_featured-list">
                <div class="uui-blog03_featured-item">
                  <a data-w-id="d1297df1-88fc-f198-6dda-cf225c62c904" href="#" class="uui-blog03_image-link is-featured w-inline-block">
                    <div class="uui-blog03_image-wrapper is-featured">
                      <img srcset="images/MEDICOS-EN-URGENCIAS-p-500.jpg 500w, images/MEDICOS-EN-URGENCIAS-p-800.jpg 800w, images/MEDICOS-EN-URGENCIAS-p-1080.jpg 1080w, images/MEDICOS-EN-URGENCIAS-p-1600.jpg 1600w, images/MEDICOS-EN-URGENCIAS-p-2000.jpg 2000w, images/MEDICOS-EN-URGENCIAS-p-2600.jpg 2600w, images/MEDICOS-EN-URGENCIAS-p-3200.jpg 3200w, images/MEDICOS-EN-URGENCIAS.jpg 6960w" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1279px) 47vw, (max-width: 1439px) 48vw, (max-width: 1919px) 640px, 800px" src="images/MEDICOS-EN-URGENCIAS.jpg" alt="" class="uui-blog03_image zoom"></div>
                  </a>
                  <div class="uui-blog03_featured-item-content">
                    <a href="#" class="uui-blog03_title-link is-featured w-inline-block">
                      <h3 class="uui-heading-small-2">Urgencias</h3>
                    </a>
                    <div class="text-block-33">{{$t('urgencies.urgencies-description')}}</div>
                    <div class="w-layout-grid uui-layout04_item-list-2">
                      <div class="uui-layout04_item-2">
                        <div class="uui-space-xsmall-27"></div>
                        <h3 class="uui-heading-xxsmall-27">{{$t('urgencies.urgencies-p1')}}</h3>
                        <div class="uui-space-xxsmall-21"></div>
                        <h3 class="uui-heading-xxsmall-27">954 104 2270 Ext. 103</h3>
                        <div class="uui-space-xxsmall-21"></div>
                        <div class="uui-space-xxsmall-21"></div>
                        <div class="uui-text-size-medium-38">{{$t('urgencies.urgencies-p2')}}</div>
                      </div>
                      <div class="uui-layout04_item-2">
                        <div class="uui-space-xsmall-27"></div>
                        <h3 class="uui-heading-xxsmall-27">{{$t('urgencies.urgencies-p3')}}</h3>
                        <div class="uui-space-xxsmall-21"></div>
                        <h3 class="uui-heading-xxsmall-27">954 104 2270 Ext. 104</h3>
                        <div class="uui-space-xxsmall-21"></div>
                        <div class="uui-space-xxsmall-21"></div>
                        <div class="uui-text-size-medium-38">{{$t('urgencies.urgencies-p4')}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
<!--            <emergencies-service-component></emergencies-service-component>-->
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.zoom {
  transition: transform .1s; /* Animation */

}

.zoom:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
</style>
