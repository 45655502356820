<script >
export default {
  name: "GalleryPage"
}
</script>

<template>
  <section class="rl_section_gallery10">
    <div class="rl-padding-global-23">
      <div class="rl-container-large-18">
        <div class="rl-padding-section-large-22">
          <div class="rl_gallery10_component">
            <div class="rl_gallery10_heading-wrapper">
              <h2 class="rl-heading-style-h2-18">{{$t('home.photographic-gallery')}}</h2>
              <div class="rl_gallery10_spacing-block-1"></div>
              <p class="rl-text-style-medium-18">{{$t('gallery.photographic-gallery-description')}}</p>
            </div>
            <div class="rl_gallery10_spacing-block-2"></div>
            <div class="rl_gallery10_masonry-grid">
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/FOTO_DE_HOSPITAL_Logo-nuevo.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/FOTO_DE_HOSPITAL_Logo-nuevo-p-500.jpg 500w, ../../public/images/FOTO_DE_HOSPITAL_Logo-nuevo-p-800.jpg 800w, ../../public/images/FOTO_DE_HOSPITAL_Logo-nuevo-p-1080.jpg 1080w, ../../public/images/FOTO_DE_HOSPITAL_Logo-nuevo-p-1600.jpg 1600w, ../../public/images/FOTO_DE_HOSPITAL_Logo-nuevo-p-2000.jpg 2000w, ../../public/images/FOTO_DE_HOSPITAL_Logo-nuevo-p-2600.jpg 2600w, ../../public/images/FOTO_DE_HOSPITAL_Logo-nuevo-p-3200.jpg 3200w, ../../public/images/FOTO_DE_HOSPITAL_Logo-nuevo.jpg 7111w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/ec1f6afe-71f9-4cab-8666-18c7d0ffad71.jpg" loading="lazy" srcset="../../public/images/ec1f6afe-71f9-4cab-8666-18c7d0ffad71-p-500.jpg 500w, ../../public/images/ec1f6afe-71f9-4cab-8666-18c7d0ffad71-p-800.jpg 800w, ../../public/images/ec1f6afe-71f9-4cab-8666-18c7d0ffad71.jpg 960w" alt="" sizes="100vw" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/AREA-DE-URGENCIAS-ENTRADA.jpg" loading="lazy" srcset="../../public/images/AREA-DE-URGENCIAS-ENTRADA-p-500.jpg 500w, ../../public/images/AREA-DE-URGENCIAS-ENTRADA-p-800.jpg 800w, ../../public/images/AREA-DE-URGENCIAS-ENTRADA-p-1080.jpg 1080w, ../../public/images/AREA-DE-URGENCIAS-ENTRADA-p-1600.jpg 1600w, ../../public/images/AREA-DE-URGENCIAS-ENTRADA-p-2000.jpg 2000w, ../../public/images/AREA-DE-URGENCIAS-ENTRADA-p-2600.jpg 2600w, ../../public/images/AREA-DE-URGENCIAS-ENTRADA.jpg 3456w" alt="" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/HOSPI-VI.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/HOSPI-VI-p-500.jpg 500w, ../../public/images/HOSPI-VI-p-800.jpg 800w, ../../public/images/HOSPI-VI.jpg 1024w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/Captura-de-pantalla-2023-12-24-a-las-11.42.14-p.m..png" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/Captura-de-pantalla-2023-12-24-a-las-11.42.14-p.m.-p-500.png 500w, ../../public/images/Captura-de-pantalla-2023-12-24-a-las-11.42.14-p.m.-p-800.png 800w, ../../public/images/Captura-de-pantalla-2023-12-24-a-las-11.42.14-p.m.-p-1080.png 1080w, ../../public/images/Captura-de-pantalla-2023-12-24-a-las-11.42.14-p.m.-p-1600.png 1600w, ../../public/images/Captura-de-pantalla-2023-12-24-a-las-11.42.14-p.m.-p-2000.png 2000w, ../../public/images/Captura-de-pantalla-2023-12-24-a-las-11.42.14-p.m..png 2326w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/DRA.-MOEMA-JERONIMO.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/DRA.-MOEMA-JERONIMO-p-500.jpg 500w, ../../public/images/DRA.-MOEMA-JERONIMO-p-800.jpg 800w, ../../public/images/DRA.-MOEMA-JERONIMO-p-1080.jpg 1080w, ../../public/images/DRA.-MOEMA-JERONIMO-p-1600.jpg 1600w, ../../public/images/DRA.-MOEMA-JERONIMO-p-2000.jpg 2000w, ../../public/images/DRA.-MOEMA-JERONIMO-p-2600.jpg 2600w, ../../public/images/DRA.-MOEMA-JERONIMO-p-3200.jpg 3200w, ../../public/images/DRA.-MOEMA-JERONIMO.jpg 6960w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/clinica-de-enfem-digestivas.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/clinica-de-enfem-digestivas-p-500.jpg 500w, ../../public/images/clinica-de-enfem-digestivas-p-800.jpg 800w, ../../public/images/clinica-de-enfem-digestivas-p-1080.jpg 1080w, ../../public/images/clinica-de-enfem-digestivas-p-1600.jpg 1600w, ../../public/images/clinica-de-enfem-digestivas.jpg 1920w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/quirofano-pagina-web.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/quirofano-pagina-web-p-500.jpg 500w, ../../public/images/quirofano-pagina-web.jpg 835w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/capilla.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/capilla-p-500.jpg 500w, ../../public/images/capilla-p-800.jpg 800w, ../../public/images/capilla-p-1080.jpg 1080w, ../../public/images/capilla-p-1600.jpg 1600w, ../../public/images/capilla-p-2000.jpg 2000w, ../../public/images/capilla-p-2600.jpg 2600w, ../../public/images/capilla-p-3200.jpg 3200w, ../../public/images/capilla.jpg 3456w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/GINECOLOGOS-HAM.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/GINECOLOGOS-HAM-p-500.jpg 500w, ../../public/images/GINECOLOGOS-HAM-p-800.jpg 800w, ../../public/images/GINECOLOGOS-HAM-p-1080.jpg 1080w, ../../public/images/GINECOLOGOS-HAM-p-1600.jpg 1600w, ../../public/images/GINECOLOGOS-HAM-p-2000.jpg 2000w, ../../public/images/GINECOLOGOS-HAM-p-2600.jpg 2600w, ../../public/images/GINECOLOGOS-HAM-p-3200.jpg 3200w, ../../public/images/GINECOLOGOS-HAM.jpg 6960w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/MUESTRA-LABORATORIO.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/MUESTRA-LABORATORIO-p-500.jpg 500w, ../../public/images/MUESTRA-LABORATORIO-p-800.jpg 800w, ../../public/images/MUESTRA-LABORATORIO-p-1080.jpg 1080w, ../../public/images/MUESTRA-LABORATORIO-p-1600.jpg 1600w, ../../public/images/MUESTRA-LABORATORIO-p-2000.jpg 2000w, ../../public/images/MUESTRA-LABORATORIO-p-2600.jpg 2600w, ../../public/images/MUESTRA-LABORATORIO-p-3200.jpg 3200w, ../../public/images/MUESTRA-LABORATORIO.jpg 6000w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/quirofano-imagen-pagina-web.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/quirofano-imagen-pagina-web-p-500.jpg 500w, ../../public/images/quirofano-imagen-pagina-web.jpg 767w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/CAFTERIA-HAM1.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/CAFTERIA-HAM1-p-500.jpg 500w, ../../public/images/CAFTERIA-HAM1-p-800.jpg 800w, ../../public/images/CAFTERIA-HAM1-p-1080.jpg 1080w, ../../public/images/CAFTERIA-HAM1-p-1600.jpg 1600w, ../../public/images/CAFTERIA-HAM1-p-2000.jpg 2000w, ../../public/images/CAFTERIA-HAM1-p-2600.jpg 2600w, ../../public/images/CAFTERIA-HAM1-p-3200.jpg 3200w, ../../public/images/CAFTERIA-HAM1.jpg 3456w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/enfermerasHAM.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/enfermerasHAM-p-500.jpg 500w, ../../public/images/enfermerasHAM-p-800.jpg 800w, ../../public/images/enfermerasHAM-p-1080.jpg 1080w, ../../public/images/enfermerasHAM-p-1600.jpg 1600w, ../../public/images/enfermerasHAM.jpg 1654w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/MASTOGRAFO.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/MASTOGRAFO-p-500.jpg 500w, ../../public/images/MASTOGRAFO-p-800.jpg 800w, ../../public/images/MASTOGRAFO-p-1080.jpg 1080w, ../../public/images/MASTOGRAFO-p-1600.jpg 1600w, ../../public/images/MASTOGRAFO-p-2000.jpg 2000w, ../../public/images/MASTOGRAFO-p-2600.jpg 2600w, ../../public/images/MASTOGRAFO-p-3200.jpg 3200w, ../../public/images/MASTOGRAFO.jpg 6960w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/.Card-Media.png" loading="lazy" srcset="../../public/images/.Card-Media-p-500.png 500w, ../../public/images/.Card-Media.png 624w" alt="" sizes="(max-width: 693px) 90vw, (max-width: 767px) 624px, (max-width: 1439px) 28vw, 405.328125px" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/IMG-2.jpeg" loading="lazy" sizes="100vw" srcset="../../public/images/IMG-2-p-500.jpeg 500w, ../../public/images/IMG-2-p-800.jpeg 800w, ../../public/images/IMG-2.jpeg 1024w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/DRA.-YURIDIA-RUIZ-SILVA-MATERNO-FETAL-3.jpeg" loading="lazy" sizes="100vw" srcset="../../public/images/dra.-yuridia-ruiz-silva-materno-fetal-p-500.jpeg 500w, ../../public/images/DRA.-YURIDIA-RUIZ-SILVA-MATERNO-FETAL-3.jpeg 619w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-1.jpeg" loading="lazy" sizes="100vw" srcset="../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-1-p-500.jpeg 500w, ../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-1-p-800.jpeg 800w, ../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-1-p-1080.jpeg 1080w, ../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-1.jpeg 1600w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-3.jpeg" loading="lazy" srcset="../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-3-p-500.jpeg 500w, ../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-3-p-800.jpeg 800w, ../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-3-p-1080.jpeg 1080w, ../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-3.jpeg 1600w" alt="" sizes="100vw" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/FARMACIA-1.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/FARMACIA-1-p-500.jpg 500w, ../../public/images/FARMACIA-1-p-800.jpg 800w, ../../public/images/FARMACIA-1-p-1080.jpg 1080w, ../../public/images/FARMACIA-1-p-1600.jpg 1600w, ../../public/images/FARMACIA-1-p-2000.jpg 2000w, ../../public/images/FARMACIA-1-p-2600.jpg 2600w, ../../public/images/FARMACIA-1.jpg 3456w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/clinica-de-enfermedades-digestivas.jpeg" loading="lazy" sizes="100vw" srcset="../../public/images/clinica-de-enfermedades-digestivas-p-500.jpeg 500w, ../../public/images/clinica-de-enfermedades-digestivas-p-800.jpeg 800w, ../../public/images/clinica-de-enfermedades-digestivas-p-1080.jpeg 1080w, ../../public/images/clinica-de-enfermedades-digestivas.jpeg 1280w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/Captura-de-pantalla-2023-12-25-a-las-9.48.40-a.m..png" loading="lazy" sizes="100vw" srcset="../../public/images/Captura-de-pantalla-2023-12-25-a-las-9.48.40-a.m.-p-500.png 500w, ../../public/images/Captura-de-pantalla-2023-12-25-a-las-9.48.40-a.m.-p-800.png 800w, ../../public/images/Captura-de-pantalla-2023-12-25-a-las-9.48.40-a.m.-p-1080.png 1080w, ../../public/images/Captura-de-pantalla-2023-12-25-a-las-9.48.40-a.m.-p-1600.png 1600w, ../../public/images/Captura-de-pantalla-2023-12-25-a-las-9.48.40-a.m.-p-2000.png 2000w, ../../public/images/Captura-de-pantalla-2023-12-25-a-las-9.48.40-a.m..png 2586w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/ESPECIALISTAS.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/ESPECIALISTAS-p-500.jpg 500w, ../../public/images/ESPECIALISTAS-p-800.jpg 800w, ../../public/images/ESPECIALISTAS-p-1080.jpg 1080w, ../../public/images/ESPECIALISTAS-p-1600.jpg 1600w, ../../public/images/ESPECIALISTAS-p-2000.jpg 2000w, ../../public/images/ESPECIALISTAS-p-2600.jpg 2600w, ../../public/images/ESPECIALISTAS-p-3200.jpg 3200w, ../../public/images/ESPECIALISTAS.jpg 6000w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/IMG_2846-scaled-1110x500.jpg" loading="lazy" sizes="100vw" srcset="../../public/images/IMG_2846-scaled-1110x500-p-500.jpeg 500w, ../../public/images/IMG_2846-scaled-1110x500-p-800.jpeg 800w, ../../public/images/IMG_2846-scaled-1110x500-p-1080.jpeg 1080w, ../../public/images/IMG_2846-scaled-1110x500.jpg 1110w" alt="" class="rl_gallery10_image">
               
              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/IMG_6299.jpg" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 1439px) 28vw, 405.328125px" srcset="../../public/images/IMG_6299-p-500.jpg 500w, ../../public/images/IMG_6299-p-800.jpg 800w, ../../public/images/IMG_6299-p-1080.jpg 1080w, ../../public/images/IMG_6299-p-1600.jpg 1600w, ../../public/images/IMG_6299-p-2000.jpg 2000w, ../../public/images/IMG_6299-p-2600.jpg 2600w, ../../public/images/IMG_6299-p-3200.jpg 3200w, ../../public/images/IMG_6299.jpg 6960w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-4.jpeg" loading="lazy" sizes="100vw" srcset="../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-4-p-500.jpeg 500w, ../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-4-p-800.jpeg 800w, ../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-4-p-1080.jpeg 1080w, ../../public/images/WhatsApp-Image-2023-10-23-at-08.38.08-4.jpeg 1600w" alt="" class="rl_gallery10_image">

              </a>
              <a href="#" class="rl_gallery10_lightbox-link w-inline-block w-lightbox"><img src="../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg" loading="lazy" sizes="100vw" srcset="../../public/images/Pediatria-Hospital-Angel-del-Mar-p-500.jpg 500w, ../../public/images/Pediatria-Hospital-Angel-del-Mar-p-800.jpg 800w, ../../public/images/Pediatria-Hospital-Angel-del-Mar-p-1080.jpg 1080w, ../../public/images/Pediatria-Hospital-Angel-del-Mar-p-1600.jpg 1600w, ../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg 1800w" alt="" class="rl_gallery10_image">

              </a>
            </div>
            <div class="rl_gallery10_masonry-grid"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
