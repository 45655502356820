<script >
import {app} from "@/main";

export default {
  name: "TestimonialsPage",
  data() {
    return {
      comments: [],
    }
  },
  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });
  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods:{
    loadData(locale){
      this.comments =  require(`../data/comments.json`);
      this.comments = this.comments.map(comment => {
        const translatedContent = comment.content[locale];
        const translatedUserPosition = comment.user.position[locale];
        return {
          ...comment,
          content: translatedContent,
          user: {
            ...comment.user,
            position: translatedUserPosition,
          },
        };
      });
    },
  }
}
</script>

<template>
  <section class="uui-section_testimonial13">
    <div class="uui-page-padding-35">
      <div class="uui-container-large-25">
        <div class="uui-padding-vertical-xhuge-35">
          <div class="uui-text-align-center-11">
            <div class="uui-max-width-large-16 align-center">
              <div class="uui-space-xsmall-17">
                <router-link data-w-id="2e19526a-65f3-1e33-fdd1-0d832af16c72" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('home.home')}}</div>
                </router-link>
              </div>
              <h2 class="uui-heading-medium-21">{{$t('home.testimonials')}}</h2>
              <div class="uui-text-size-large-24">{{$t('home.testimonials-description')}}</div>
            </div>
          </div>
          <div class="uui-testimonial13_component">
            <div v-for="(comment, index) in comments" :key="index" class="uui-testimonial13_content">
              <div class="uui-heading-xxsmall-17 text-weight-medium">&quot;{{comment.content}}&quot;</div>
              <div class="uui-testimonial13_client">
                <div class="uui-testimonial13_client-image-wrapper"><img v-bind:src="comment.user.profile_picture"  loading="lazy" alt="" class="uui-testimonial13_customer-image"></div>
                <div class="uui-testimonial13_client-info">
                  <div class="uui-testimonial13_client-heading">{{comment.user.name}}</div>
                  <div class="uui-text-size-small-13">{{comment.user.position}}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
