<script>
import {API_CONTACT} from "@/config";

export default {
  name: "ContactUsComponent",
  data() {
    return {
      searchQuery: '',
      name: '',
      phoneNumber: '',
      email: '',
      message: '',
      success:false,
      error:false,
      form:true,
      submitButtonValue: "Enviar",
    };
  },
  methods: {
    validateForm() {
      return !!(this.name && this.email && this.message);
    },
    contact() {
      if (this.validateForm()) {
        this.submitButtonValue = "Espere...";

        // const data = {
        //   name: this.name,
        //   email: this.email,
        //   message:this.message
        // };



        const formData = new URLSearchParams();
        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('message', this.message);
        if(this.phoneNumber!=='')formData.append('phone', this.phoneNumber);

        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData,
        };




        fetch(API_CONTACT, options)
            .then(response => response.json())
            .then(data => {
              //console.log('Respuesta de la API:', data);
              if(data.ok){
                this.success=true;
                this.form=false;
                this.error=false;
              }else{
                this.error=true;
                this.submitButtonValue = "Enviar";
              }
            })
            .catch(error => console.error('Error al realizar la petición:', error));

      } else {
        this.error=true;
      }
    }
  }
}
</script>

<template>



  <div class="rl_contact6_form-block w-form">
    <div v-if="form" id="wf-form-Contact-6-Form"  class="rl_contact6_form" >
      <div class="rl_contact6_form-field-wrapper">
        <div class="rl_contact6_form-field-wrapper"><label for="Contact-6-First-Name-3" class="rl-field-label">{{$t('contact.form-full-name')}}</label>
          <input v-model="name" type="text" class="rl-form-input-2 w-input" maxlength="256" name="Contact-6-First-Name-3" data-name="Contact 6 First Name 3" :placeholder="$t('contact.form-full-name')" id="Contact-6-First-Name-3" required=""></div>
      </div>
      <div class="rl_contact6_form-field-2col">
        <div class="rl_contact6_form-field-wrapper"><label for="Contact-6-Email-3" class="rl-field-label">{{$t('contact.contact-email')}}</label><input v-model="email" type="email" class="rl-form-input-2 w-input" maxlength="256" name="Contact-6-Email" data-name="Contact 6 Email" placeholder="email@example.com" id="Contact-6-Email-3" required=""></div>
        <div class="rl_contact6_form-field-wrapper"><label for="Contact-6-Phone-3" class="rl-field-label">{{$t('contact.form-phone')}}</label><input  v-model="phoneNumber" type="tel" class="rl-form-input-2 w-input" maxlength="256" name="Contact-6-Phone" data-name="Contact 6 Phone" placeholder="+00 (000) 000-0000" id="Contact-6-Phone-3" required=""></div>
      </div>
      <div class="rl_contact6_form-field-wrapper"><label for="Contact-6-Message-3" class="rl-field-label">{{$t('contact.form-message')}}</label><textarea v-model="message" :placeholder="$t('contact.form-message-placeholder')" maxlength="5000" id="Contact-6-Message-3" name="Contact-6-Message" data-name="Contact 6 Message" required="" class="rl-form-text-area w-input"></textarea></div>
      <div id="w-node-_9a760025-93a6-1598-0cf3-6a8af1266453-a2d16809" class="rl_contact6_button-wrapper">
        <div class="rl_contact6_spacing-block-7"></div><input  @click="contact" type="button" :value="submitButtonValue" data-wait="Please wait..." id="w-node-_9a760025-93a6-1598-0cf3-6a8af1266455-a2d16809" class="rl-button w-button">
      </div>
    </div>
    <div v-if="success" class="rl-success-message-2">
      <div class="rl-success-text">Correo de contacto enviado!</div>
    </div>
    <div v-if="error" class="rl-error-message-2">
      <div class="rl-error-text">Ocurrio un error al enviar el correo de contacto.</div>
    </div>
  </div>
</template>

<style >

</style>
