<script >
import AddressComponent from "@/components/AddressComponent.vue";
import OurServices from "@/components/OurServices.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
export default{
  name:"HemodialysisPage",
  components: {SideContactComponent, OurServices, AddressComponent},
  data() {
    return {
      show1:false,
      show2:false,
      show3:false,
      show4:false,
    };
  },
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_3b370d4e-1e8f-16fb-bdb7-d88e590aa908-24e86033" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="3b370d4e-1e8f-16fb-bdb7-d88e590aa90b" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('HemodialysisPage.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('HemodialysisPage.title')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('HemodialysisPage.subtitle')}}</div>
              <address-component></address-component>
            </div>
            <div class="uui-blogpost05_image-wrapper-2"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, (max-width: 1919px) 46vw, 768px" loading="lazy" src="images/hemodialisis-head.jpg" alt="" srcset="images/hemodialisis-head.jpg 500w, images/hemodialisis-head.jpg 800w, images/hemodialisis-head.jpg 1080w, images/hemodialisis-head.jpg 1600w, images/hemodialisis-head.jpg 2000w, images/hemodialisis-head.jpg 2592w" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-4">
              <div class="uui-blogpost05_content-left-2">
                <our-services></our-services>
                <side-contact-component></side-contact-component>
              </div>
            </div>
            <div id="w-node-_9a312395-9a72-5927-77f9-6de52c4ebc2f-24e86033" class="uui-blogpost05_content-4">
              <h4 class="heading-22">{{$t('HemodialysisPage.article-1-title')}}</h4>
              <div class="uui-text-rich-text-7 w-richtext">
                <p class="paragraph">{{$t('HemodialysisPage.article-1-content')}}</p>
              </div>
              <div class="w-layout-grid grid">
                <div id="w-node-d97ab19c-184a-f49b-fdeb-6c9629b580b6-24e86033" class="div-block-30"><img src="images/hemodialisis-section.jpg" loading="lazy" id="w-node-e8348b32-29e2-9cac-89de-d9fbfd5f0c24-24e86033" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1024px" alt="" srcset="images/hemodialisis-section.jpg 500w, images/hemodialisis-section.jpg 800w, images/hemodialisis-section.jpg 1080w, images/hemodialisis-section.jpg 1600w, images/hemodialisis-section.jpg 2000w, images/hemodialisis-section.jpg 2592w"></div>
                <div id="w-node-_4589b1cf-e513-5ee1-7503-031daad3ee5e-24e86033" class="div-block-31"><img src="images/hemodialisis-p-.jpg" loading="lazy" id="w-node-b4b48702-0c3b-b408-5672-33af7b046ffd-24e86033" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1024px" alt="" srcset="images/hemodialisis-p-500.jpg 500w, images/hemodialisis-p-800.jpg 800w, images/hemodialisis-p-1080.jpg 1080w, images/hemodialisis-p-1600.jpg 1600w, images/hemodialisis-p-2000.jpg 2000w, images/hemodialisis-p-2600.jpg 2600w"></div>
                <div id="w-node-_0c7bcf3d-baa4-e3b4-0853-cd460bb34461-24e86033" class="div-block-32"><img src="images/hemodialisis-spec.jpg" loading="lazy" id="w-node-_674e2056-8798-3c0d-97a1-a34f36f981de-24e86033" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1024px" alt="" srcset="images/hemodialisis-spec.jpg 500w, images/hemodialisis-spec.jpg 800w, images/hemodialisis-spec.jpg 1080w, images/hemodialisis-spec.jpg 1600w, images/hemodialisis-spec.jpg 2000w, images/hemodialisis-spec.jpg 2592w"></div>
                <div id="w-node-_0497cd82-6153-e182-c512-22ba882e0652-24e86033" class="div-block-33"><img src="images/hemodialisis-atention.jpg" loading="lazy" id="w-node-_62bc4554-e1ff-5873-983e-353c90ad1c16-24e86033" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1024px" alt="" srcset="images/hemodialisis-atention.jpg 500w, images/hemodialisis-atention.jpg 800w, images/hemodialisis-atention.jpg 1080w, images/hemodialisis-atention.jpg 1600w, images/hemodialisis-atention.jpg 2000w, images/hemodialisis-atention.jpg 2592w"></div>
              </div>
              <h4 class="heading-22">{{$t('HemodialysisPage.we-have')}}</h4>
              <ul role="list">
                <li>{{$t('HemodialysisPage.we-have-li1')}}</li>
                <li>{{$t('HemodialysisPage.we-have-li2')}}</li>
                <li>{{$t('HemodialysisPage.we-have-li3')}}</li>
                <li>{{$t('HemodialysisPage.we-have-li4')}}</li>
                <li>{{$t('HemodialysisPage.we-have-li5')}}</li>
              </ul>
              <section class="uui-section_layout04">
                <div class="uui-page-padding-33">
                  <div class="uui-container-large-25">
                    <div class="uui-padding-vertical-xhuge-33">
                      <h4 class="heading-22">{{$t('HemodialysisPage.our-services')}}</h4>
                      <div class="w-layout-grid uui-layout04_component">
                        <div class="uui-layout04_content">
                          <div class="uui-space-xsmall-17"></div>
                          <div class="w-layout-grid uui-layout04_item-list">
                            <div class="uui-layout04_item">
                              <div class="uui-space-xsmall-17"></div>
                              <h3 class="uui-heading-xxsmall-15">{{$t('HemodialysisPage.peritoneal-dialysis')}}</h3>
                              <div class="uui-space-xxsmall-13"></div>
                              <div class="uui-text-size-medium-25">{{$t('HemodialysisPage.peritoneal-dialysis-content')}}</div>
                            </div>
                            <div class="uui-layout04_item">
                              <div class="uui-space-xsmall-17"></div>
                              <h3 class="uui-heading-xxsmall-15">{{$t('HemodialysisPage.hemodialysis')}}</h3>
                              <div class="uui-space-xxsmall-13"></div>
                              <div class="uui-text-size-medium-25">{{$t('HemodialysisPage.hemodialysis-content')}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div class="uui-text-rich-text-7 w-richtext">
                <h4>{{$t('HemodialysisPage.who-are-we')}}</h4>
                <p>{{$t('HemodialysisPage.who-are-we-p1')}}</p>
                <p>{{$t('HemodialysisPage.who-are-we-p2')}}</p>
              </div>
              <section class="uui-section_layout04">
                <div class="uui-page-padding-33">
                  <div class="uui-container-large-25">
                    <div class="uui-padding-vertical-xhuge-33">
                      <div class="w-layout-grid uui-layout04_component">
                        <div class="uui-layout04_content">
                          <div class="uui-space-xsmall-17"></div>
                          <div class="w-layout-grid uui-layout04_item-list">
                            <div class="uui-layout04_item">
                              <div class="uui-space-xsmall-17"></div>
                              <h3 class="uui-heading-xxsmall-15">{{$t('HemodialysisPage.feel-safe')}}</h3>
                              <div class="uui-space-xxsmall-13"></div>
                              <div class="uui-text-size-medium-25">{{$t('HemodialysisPage.feel-safe-content')}}</div>
                            </div>
                            <div class="uui-layout04_item">
                              <div class="uui-space-xsmall-17"></div>
                              <h3 class="uui-heading-xxsmall-15">{{$t('HemodialysisPage.feel-confident')}}</h3>
                              <div class="uui-space-xxsmall-13"></div>
                              <div class="uui-text-size-medium-25">{{$t('HemodialysisPage.feel-confident-content')}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 class="heading-22">{{$t('HemodialysisPage.we-have-service')}}</h4>
                <ul role="list">
                  <li>{{$t('HemodialysisPage.we-have-service-li1')}}</li>
                  <li>{{$t('HemodialysisPage.we-have-service-li2')}}</li>
                  <li>{{$t('HemodialysisPage.we-have-service-li3')}}</li>
                </ul>
              </section>
              <div class="w-layout-grid uui-cta03_component">
                <div class="uui-cta03_content">
                  <h2 class="uui-heading-large-3">{{$t('HemodialysisPage.enjoy-puerto-escondido')}}</h2>
                  <div class="uui-space-small-8"></div>
                  <div class="uui-text-size-large-9">{{$t('HemodialysisPage.enjoy-puerto-escondido-content')}}<br><strong> {{$t('HemodialysisPage.enjoy-puerto-escondido-black-content')}} <br></strong>‍<strong> {{$t('HemodialysisPage.enjoy-puerto-escondido-black-content2')}} </strong></div>
                </div><img src="../../../public/images/frame.png" loading="lazy" id="w-node-_9a312395-9a72-5927-77f9-6de52c4ebc85-24e86033" alt="" class="image-8">
              </div>
              <div data-w-id="9a312395-9a72-5927-77f9-6de52c4ebc86"  data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="brix---tabs-wrapper-v2 w-tabs">
                <div class="brix---tabs-content w-tab-content">
                  <div data-w-tab="Tab 1" class="tab-pane-tab-1 w-tab-pane w--tab-active">
                    <div class="w-layout-grid brix---grid-1-column-gap-row-0" style="width: 100%">
                      <div @click="show1 = !show1" data-w-id="9a312395-9a72-5927-77f9-6de52c4ebc8a" class="brix---accordion-item-wrapper-v3-first">
                        <div class="brix---accordion-content-wrapper-v2">
                          <div    class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">{{$t('HemodialysisPage.question-1')}}</h3>
                            </div>
                          </div>
                          <div v-show="show1" class="brix---acordion-body animate__animated animate__fadeInUp">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">{{$t('HemodialysisPage.question-1-p1')}}<br></p>
                              <ul role="list" class="list-5">
                                <li class="list-item-6"><strong>{{$t('HemodialysisPage.question-1-li1')}}</strong></li>
                                <li><strong>{{$t('HemodialysisPage.question-1-li2')}}</strong></li>
                                <li><strong>{{$t('HemodialysisPage.question-1-li3')}}</strong></li>
                              </ul>
                              <p class="brix---paragraph-default">{{$t('HemodialysisPage.question-1-p2')}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block animate__animated animate__fadeInUp">
                            <img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon " :class="{ 'rotate': show1 }">
                          </a>
                        </div>
                      </div>
                      <div @click="show2 = !show2" class="brix---accordion-item-wrapper-v3">
                        <div class="brix---accordion-content-wrapper-v2">
                          <div class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">{{$t('HemodialysisPage.question-2')}}</h3>
                            </div>
                          </div>
                          <div v-show="show2" class="brix---acordion-body animate__animated animate__fadeInUp">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">{{$t('HemodialysisPage.question-2-p1')}}<br>{{$t('HemodialysisPage.question-2-p2')}}<br>{{$t('HemodialysisPage.question-2-p3')}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block">
                            <img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon" :class="{ 'rotate': show2 }"></a>
                        </div>
                      </div>
                      <div @click="show3 = !show3" class="brix---accordion-item-wrapper-v3">
                        <div class="brix---accordion-content-wrapper-v2">
                          <div class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">{{$t('HemodialysisPage.question-3')}}</h3>
                            </div>
                          </div>
                          <div v-show="show3" class="brix---acordion-body animate__animated animate__fadeInUp">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">{{$t('HemodialysisPage.question-3-p1')}}<br>{{$t('HemodialysisPage.question-3-p2')}}<br>{{$t('HemodialysisPage.question-3-p3')}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block">
                            <img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon" :class="{ 'rotate': show3 }"></a>
                        </div>
                      </div>
                      <div @click="show4 = !show4" class="brix---accordion-item-wrapper-v3" >
                        <div class="brix---accordion-content-wrapper-v2">
                          <div class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">{{$t('HemodialysisPage.question-4')}}</h3>
                            </div>
                          </div>
                          <div v-show="show4"  class="brix---acordion-body animate__animated animate__fadeInUp">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">{{$t('HemodialysisPage.question-4-p1')}}<br>‍<br>
                                ‍<strong>{{$t('HemodialysisPage.question-4-subtitle')}}<br>
                                </strong>{{$t('HemodialysisPage.question-4-p2')}}
                                <br>{{$t('HemodialysisPage.question-4-p3')}}
                                <br>{{$t('HemodialysisPage.question-4-p4')}}</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block">
                            <img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon" :class="{ 'rotate': show4 }"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--<div data-w-tab="Tab 2" class="w-tab-pane">
                    <div class="w-layout-grid brix---grid-1-column-gap-row-0">
                      <div data-w-id="9a312395-9a72-5927-77f9-6de52c4ebce1" class="brix---accordion-item-wrapper-v3-first">
                        <div class="brix---accordion-content-wrapper-v2">
                          <div class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">What is Webflow and why is it the best website builder?</h3>
                            </div>
                          </div>
                          <div style="height:0px;-webkit-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="brix---acordion-body">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block"><img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon"></a>
                        </div>
                      </div>
                      <div data-w-id="9a312395-9a72-5927-77f9-6de52c4ebcef" class="brix---accordion-item-wrapper-v3">
                        <div class="brix---accordion-content-wrapper-v2">
                          <div class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">What is your favorite template from BRIX Templates?</h3>
                            </div>
                          </div>
                          <div style="height:0px;-webkit-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="brix---acordion-body">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block"><img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon"></a>
                        </div>
                      </div>
                      <div data-w-id="9a312395-9a72-5927-77f9-6de52c4ebcfd" class="brix---accordion-item-wrapper-v3">
                        <div class="brix---accordion-content-wrapper-v2">
                          <div class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">How do you clone a Webflow Template?</h3>
                            </div>
                          </div>
                          <div style="height:0px;-webkit-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="brix---acordion-body">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block"><img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon"></a>
                        </div>
                      </div>
                      <div data-w-id="9a312395-9a72-5927-77f9-6de52c4ebd0b" class="brix---accordion-item-wrapper-v3">
                        <div class="brix---accordion-content-wrapper-v2">
                          <div class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">Why is BRIX Templates the best Webflow agency?</h3>
                            </div>
                          </div>
                          <div style="height:0px;-webkit-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="brix---acordion-body">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block"><img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon"></a>
                        </div>
                      </div>
                    </div>
                  </div>-->
                  <!--<div data-w-tab="Tab 3" class="w-tab-pane">
                    <div class="w-layout-grid brix---grid-1-column-gap-row-0">
                      <div data-w-id="9a312395-9a72-5927-77f9-6de52c4ebd1b" class="brix---accordion-item-wrapper-v3-first">
                        <div class="brix---accordion-content-wrapper-v2">
                          <div class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">What is Webflow and why is it the best website builder?</h3>
                            </div>
                          </div>
                          <div style="height:0px;-webkit-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="brix---acordion-body">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block"><img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon"></a>
                        </div>
                      </div>
                      <div data-w-id="9a312395-9a72-5927-77f9-6de52c4ebd29" class="brix---accordion-item-wrapper-v3">
                        <div class="brix---accordion-content-wrapper-v2">
                          <div class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">What is your favorite template from BRIX Templates?</h3>
                            </div>
                          </div>
                          <div style="height:0px;-webkit-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="brix---acordion-body">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block"><img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon"></a>
                        </div>
                      </div>
                      <div data-w-id="9a312395-9a72-5927-77f9-6de52c4ebd37" class="brix---accordion-item-wrapper-v3">
                        <div class="brix---accordion-content-wrapper-v2">
                          <div class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">How do you clone a Webflow Template?</h3>
                            </div>
                          </div>
                          <div style="height:0px;-webkit-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="brix---acordion-body">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block"><img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon"></a>
                        </div>
                      </div>
                      <div data-w-id="9a312395-9a72-5927-77f9-6de52c4ebd45" class="brix---accordion-item-wrapper-v3">
                        <div class="brix---accordion-content-wrapper-v2">
                          <div class="brix---accordion-header">
                            <div class="brix---color-neutral-802">
                              <h3 class="brix---accordion-title">Why is BRIX Templates the best Webflow agency?</h3>
                            </div>
                          </div>
                          <div style="height:0px;-webkit-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(null, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="brix---acordion-body">
                            <div class="brix---accordion-spacer"></div>
                            <div class="brix---color-neutral-600">
                              <p class="brix---paragraph-default">Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes.</p>
                            </div>
                          </div>
                        </div>
                        <div class="brix---accordion-right-side">
                          <a href="#" class="brix---btn-circle-accordion-btn w-inline-block"><img src="../../../public/images/chevron-right-icon-elements-brix-templates.svg" alt="Icon - Elements Webflow Library - BRIX Templates" class="brix---accordion-arrow-icon"></a>
                        </div>
                      </div>
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.rotate {
  transform: rotate(90deg);
  transition: transform 0.5s ease; /* Agrega una transición suave para un efecto más agradable */
}
.brix---tabs-wrapper-v2{
  display: block;
}
</style>
