<script >
import AddressComponent from "@/components/AddressComponent.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import MeetHamComponent from "@/components/MeetHamComponent.vue";

export default {
  name: "VisitorsPage",
  components: {MeetHamComponent, SideContactComponent, AddressComponent},
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_8e272eeb-aaf8-62f6-10db-0a8492d21da8-bc1472c6" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-button-row-12">
                <router-link data-w-id="8e272eeb-aaf8-62f6-10db-0a8492d21daa" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('home.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('home.visitors-regulations')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('visitors.visitors-regulations-description')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_8e272eeb-aaf8-62f6-10db-0a8492d21db4-bc1472c6" class="uui-blogpost05_image-wrapper-7"><img srcset="../../public/images/OFICINA-DE-CALIDAD-p-500.jpg 500w, ../../public/images/OFICINA-DE-CALIDAD-p-800.jpg 800w, ../../public/images/OFICINA-DE-CALIDAD-p-1080.jpg 1080w, ../../public/images/OFICINA-DE-CALIDAD-p-1600.jpg 1600w, ../../public/images/OFICINA-DE-CALIDAD-p-2000.jpg 2000w, ../../public/images/OFICINA-DE-CALIDAD-p-2600.jpg 2600w, ../../public/images/OFICINA-DE-CALIDAD-p-3200.jpg 3200w, ../../public/images/oficina-de-calidad.jpeg 6960w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 46vw, 768px" src="../../public/images/oficina-de-calidad.jpeg" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-4">
              <div class="uui-blogpost05_content-left-2">
                <meet-ham-component></meet-ham-component>
                <side-contact-component></side-contact-component>
              </div>
            </div>
            <div class="uui-blogpost01_content">
              <h3 class="ds-md-bold">{{$t('visitors.visitors-regulations-p1')}}</h3>
              <div class="uui-text-rich-text-12 w-richtext">
                <h4>{{$t('visitors.visitors-regulations-p2')}}</h4>
                <p><strong><em>{{$t('visitors.visitors-regulations-p3')}}</em></strong></p>
                <h4>{{$t('visitors.visitors-regulations-p4')}}</h4>
                <p>{{$t('visitors.visitors-regulations-p5')}}</p>
                <p>{{$t('visitors.visitors-regulations-p6')}}</p>
                <p>{{$t('visitors.visitors-regulations-p7')}}</p>
                <h4>{{$t('visitors.visitors-regulations-p8')}}</h4>
                <p>{{$t('visitors.visitors-regulations-p9')}}</p>
                <p>{{$t('visitors.visitors-regulations-p10')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>

</style>
