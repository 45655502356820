<script >

export default{
  name:"EmergenciesComponent",
}
</script>

<template>
  <div class="uui-blogpost05_contributers-2">
    <div class="uui-blogpost05_sidebar-heading-2">{{$t('home.emergencies')}}</div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/emergencies" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.emergencies')}} HAM</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="obstetrical" class="link-block-7 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('obstetrical.obstetrical-title')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/emergencies-pediatric" aria-current="page" class="link-block-8 w-inline-block w--current">
          <div class="uui-blogpost05_author-heading-2">{{$t('pediatric-emergencies.pediatric-emergencies-title')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/emergencies-adults" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('adult.adult-emergency-title')}}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
