<script>
import SearchService from "@/Services/SearchService";

export default {
  name:"SearchComponent",
  data() {
    return {
      searchQuery: '',
      data:[]
    };
  },
  methods:{
    async searchTerm(){
      //console.log("search " + this.searchQuery);
      const dataFromApi = await  SearchService.search(this.searchQuery);
      this.data =dataFromApi.data;
    },
    buildRoute(link) {
      return `/${this.linkToRoute(link)}`;
    },
    linkToRoute(link){
      link = link.includes("index") ? '':link;
      return link.replace(/.html/i,'');//.replace(/-/i,'/');
    }
  },
  async mounted() {
    this.searchQuery = this.$route.params.query;
    //console.log("termino " + this.searchQuery);
    await this.searchTerm();
  },
  async beforeRouteUpdate(to, from, next) {
    //console.log("termino " + this.searchQuery);

    this.searchQuery =to.params.query;
    await this.searchTerm();
    next();
  },
};
</script>

<template>
  <div class="uui-page-padding-20">
    <div class="uui-container-large-16">
      <div class="w-container">
        <div class="uui-heading-subheading-14">"{{this.searchQuery}}" Search Results</div>
          <h1 v-if="this.data.length>0" class="uui-heading-large-6">Encuentra todo lo que ocupes aqui...</h1>
          <h1 v-if="this.data.length===0" class="uui-heading-large-6">No se encontraron resultados</h1>
        <div>
          <div class="search-result-items">
            <div v-for="(item, index) in this.data" :key="index">
<!--              <a href="/sample-result">You're viewing sample results.</a>-->
              <router-link :to="buildRoute(item.link)">
                <h4 v-html="item.title"></h4>
              </router-link>
              <p v-html="item.content" style="text-align: justify"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
