<script >

export default{
  name:"OurSpecialties",
}
</script>

<template>
  <div class="uui-blogpost05_contributers-2">
    <div class="uui-blogpost05_sidebar-heading-2"> {{$t('our-specialties.title')}} </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/specialties-family-emergency-medicine"  :class="{ 'w--current': $route.path === '/specialties-family-emergency-medicine' }" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('our-specialties.spe1')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/specialties-dermatology"  :class="{ 'w--current': $route.path === '/specialties-dermatology' }" class="link-block-8 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('our-specialties.spe2')}}</div>
        </router-link>

      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/specialties-urology"  :class="{ 'w--current': $route.path === '/specialties-urology' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('our-specialties.spe3')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/specialties-ophthalmology"  :class="{ 'w--current': $route.path === '/specialties-ophthalmology' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('our-specialties.spe4')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/specialties-internal-medicine"  :class="{ 'w--current': $route.path === '/specialties-internal-medicine' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('our-specialties.spe5')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/services-radiology-imaging"  :class="{ 'w--current': $route.path === '/services-radiology-imaging' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('our-specialties.spe6')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/specialties-adult-clinic"  :class="{ 'w--current': $route.path === '/specialties-adult-clinic' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('our-specialties.spe7')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/specialties-traumatology-orthopedics" :class="{ 'w--current': $route.path === '/specialties-traumatology-orthopedics' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('our-specialties.spe8')}}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
