<script >

export default{
  name:"AppointmentComponent",
}
</script>

<template>
  <div class="uui-team07_cta-3">
    <div class="uui-team07_cta-content-2">
      <div class="uui-max-width-large-14">
        <h4 class="uui-heading-xxsmall-13">{{$t('home.doubts')}}</h4>
        <div class="uui-space-xxsmall-8"></div>
        <div class="uui-text-size-large-22">{{$t('home.contact-us-2')}}</div>
      </div>
    </div>
    <div class="uui-team07_cta-button-row">
      <router-link to="/contact" class="uui-button-12 w-inline-block">
        <div>{{$t('home.contact-us')}}</div>
      </router-link>
    </div>
  </div>


</template>

<style scoped>

</style>
