<script >
import {app} from "@/main";
import 'animate.css';
import OurClinicsComponent from "@/components/OurClinicsComponent.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import AddressComponent from "@/components/AddressComponent.vue";
import AppointmentComponent from "@/components/AppointmentComponent.vue";
import ClinicsMision from "@/components/ClinicsMision.vue";
import ClinicsVision from "@/components/ClinicsVision.vue";

export default{
  name:"WomensPage",
  components: {
    ClinicsVision,
    ClinicsMision, AppointmentComponent, AddressComponent, SideContactComponent, OurClinicsComponent},
  // components: {AppointmentComponent, AddressComponent, OurClinicsComponent, ContactComponent},
  data() {
    return {
      services:[],
      showDysplasiaClinic:false,
      showBreastClinic:false,
      showMaternalFetalClinic:false,
      showReproductiveBiology:false,
      show1:false,
      show2:false,
      show3:false,
      locale:'',
      specialties:[1],
      specialists:[],
      // showAnswer: false,
    };
  },

  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });

  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods:{
    loadData(locale){
      this.specialists = require('../../data/specialists.json');
      this.specialists = this.specialists.map(specialist => {
        const translateInformation= specialist.information[locale];


        let specialties = specialist.specialties.map(specialty=>{
          const name= specialty.name[locale];
            return {
              ...specialty,
              name:name
            }
        });

        return {
          ...specialist,
          information: translateInformation,
          specialties: specialties
        };
      });
      this.specialists = this.specialists.filter(s =>
          s.specialties.some(specialty => this.specialties.includes(specialty.id))
      );
    },
    stringArray(string) {
      return string.split(',');
    },
  }
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938c14-6b5c1cae" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="7cc4bcd4-67e6-ca4f-d404-7c4e49938c17" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('home.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('home.women-clinic-title')}} 24/7</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('women.women-clinic-description')}}</div>
             <address-component></address-component>
            </div>
            <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938c21-6b5c1cae" class="uui-blogpost05_image-wrapper-7"><img srcset="../../../public/images/CLINICAS-02.jpg 500w, ../../../public/images/CLINICAS-02.jpg 800w, ../../../public/images/CLINICAS-02.jpg 1080w, ../../../public/images/CLINICAS-02.jpg 1600w, ../../../public/images/CLINICAS-02.jpg 2000w, ../../../public/images/CLINICAS-02.jpg 2600w, ../../../public/images/CLINICAS-02.jpg 3200w, ../../../public/images/CLINICAS-02.jpg 6960w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 46vw, 768px" src="../../../public/images/CLINICAS-02.jpg" alt="" class="uui-blogpost03_image-2" style="object-fit: contain;"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">
              <our-clinics-component></our-clinics-component>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938c25-6b5c1cae" class="uui-blogpost05_content-2">
              <div class="uui-text-rich-text-9 w-richtext">
                <h4>{{$t('home.women-clinic-title')}} 24/7</h4>
              </div>
              <div class="div-block-20">
                <clinics-mision mision="Otorgar una atención médica integral de mejor calidad y costo a nuestra población, enfocada a la prevención, diagnóstico y manejo oportuno."></clinics-mision>
                <clinics-vision vision="Que la mujer de nuestra región piense en el Hospital Ángel del Mar para la detección oportuna, tratamiento y vigilancia de su salud.Toda futura mamá querrá atender su cesárea o parto en nuestras instalaciones."></clinics-vision>
              </div>
<!--              <div class="uui-text-rich-text-9 w-richtext">-->
<!--                <figure style="max-width:1680pxpx" class="w-richtext-align-fullwidth w-richtext-figure-type-image">-->
<!--                  <div><img src="../../../public/images/IMG-1.jpeg" loading="lazy" alt=""></div>-->
<!--                </figure>-->
<!--              </div>-->
              <div class="f-section-large-2">
                <div class="f-container-regular-4">
                  <div class="w-layout-grid f-gallery-two-column">
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec0-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-tall"><img src="../../../public/images/clinic-women-image-1.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/clinic-women-image-1.jpg 500w, ../../../public/images/clinic-women-image-1.jpg 800w, ../../../public/images/clinic-women-image-1.jpg 1080w, ../../../public/images/clinic-women-image-1.jpg 1600w, ../../../public/images/clinic-women-image-1.jpg 2000w, ../../../public/images/clinic-women-image-1.jpg 2600w, ../../../public/images/clinic-women-image-1.jpg 3200w, ../../../public/images/clinic-women-image-1.jpg 6000w" alt="" class="f-image-cover"></div>
                    </a>
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec3-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-s"><img src="../../../public/images/clinic-women-image-2.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/clinic-women-image-2.jpg 500w, ../../../public/images/clinic-women-image-2.jpg 800w, ../../../public/images/clinic-women-image-2.jpg 1080w, ../../../public/images/clinic-women-image-2.jpg 1600w, ../../../public/images/clinic-women-image-2.jpg 2000w, ../../../public/images/clinic-women-image-2.jpg 2600w, ../../../public/images/clinic-women-image-2.jpg 3200w, ../../../public/images/clinic-women-image-2.jpg 6000w" alt="" class="f-image-cover"></div>
                    </a>
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec6-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-s"><img src="../../../public/images/clinic-women-image-3.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/clinic-women-image-3.jpg 500w, ../../../public/images/clinic-women-image-3.jpg 800w, ../../../public/images/clinic-women-image-3.jpg 1080w, ../../../public/images/clinic-women-image-3.jpg 1600w, ../../../public/images/clinic-women-image-3.jpg 2000w, ../../../public/images/clinic-women-image-3.jpg 2600w, ../../../public/images/clinic-women-image-3.jpg 3200w, ../../../public/images/clinic-women-image-3.jpg 6960w" alt="" class="f-image-cover"></div>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <section class="uui-section_career03">
                  <div class="uui-page-padding-53">
                    <div class="uui-container-small-7">
                      <div class="uui-padding-vertical-xhuge-54">
                        <div class="uui-text-align-center-19">
                          <div class="uui-space-xsmall"></div>
                          <div class="uui-text-size-large">{{$t('women.women-clinic-p1')}}</div>
                        </div>
                        <div class="uui-career03_component">
                          <div class="uui-career03_accordion">
                            <div @click="showBreastClinic = !showBreastClinic" class="uui-career03_job-description">
                              <h3 class="uui-heading-xsmall">{{$t('women.women-clinic-p2')}}</h3>
                              <div class="uui-career03_icon-wrapper">
                                <div class="accordion-icon_component-9">
                                  <div class="accordion-icon_horizontal-line-9"></div>
                                  <div v-show="!showBreastClinic" class="accordion-icon_vertical-line-9 animate__animated  animate__rotateIn"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="showBreastClinic"  class="uui-career03_list-wrapper">
                              <div class="uui-career03_list">
                                <div class="uui-career03_item">
                                  <div class="uui-career03_title-wrapper">
                                    <div class="uui-career03_title-heading">
                                      <div class="uui-career03_heading">&quot;{{$t('women.women-clinic-p3')}}&quot;</div>
                                    </div>
                                  </div>
                                  <div class="uui-max-width-large-26">
                                    <div class="uui-faq01_accordion">
                                      <div class="uui-faq01_answer">
                                        <div class="uui-text-size-medium-16">{{$t('women.women-clinic-p4')}}<br>- <strong>{{$t('women.women-clinic-p5')}}</strong> {{$t('women.women-clinic-p6')}}<br>- <strong>{{$t('women.women-clinic-p7')}}</strong> {{$t('women.women-clinic-p8')}}<br>- <strong>{{$t('women.women-clinic-p9')}} </strong>{{$t('women.women-clinic-p10')}}<strong><br></strong><br>{{$t('women.women-clinic-p11')}} <strong>{{$t('women.women-clinic-p12')}}</strong> {{$t('women.women-clinic-p13')}}<br>- <strong>{{$t('women.women-clinic-p14')}} </strong><strong>{{$t('women.women-clinic-p15')}}</strong> {{$t('women.women-clinic-p16')}}<br>- <strong>{{$t('women.women-clinic-p17')}}</strong> <strong>{{$t('women.women-clinic-p18')}}</strong> {{$t('women.women-clinic-p19')}}<br>- <strong>{{$t('women.women-clinic-p20')}}</strong> {{$t('women.women-clinic-p21')}}<br>‍<br>{{$t('women.women-clinic-p22')}}<br>{{$t('women.women-clinic-p23')}}<br></div>
                                        <div class="uui-space-medium-6"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="uui-career03_accordion">
                            <div @click="showDysplasiaClinic = !showDysplasiaClinic" class="uui-career03_job-description">
                              <h3 class="uui-heading-xsmall">{{$t('women.women-clinic-p24')}}</h3>
                              <div class="uui-career03_icon-wrapper">
                                <div class="accordion-icon_component-9">
                                  <div class="accordion-icon_horizontal-line-9"></div>
                                  <div v-show="!showDysplasiaClinic" class="accordion-icon_vertical-line-9 animate__animated  animate__rotateIn"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="showDysplasiaClinic"  class="uui-career03_list-wrapper">
                              <div class="uui-career03_list">
                                <div class="uui-career03_item">
                                  <div class="uui-max-width-large-26">
                                    <div class="uui-faq01_accordion">
                                      <div class="uui-faq01_answer">
                                        <div class="uui-text-size-medium-16">{{$t('women.women-clinic-p25')}}<br>- {{$t('women.women-clinic-p26')}}<br>- {{$t('women.women-clinic-p27')}}<br>- {{$t('women.women-clinic-p28')}}<br>- {{$t('women.women-clinic-p29')}}<br>- {{$t('women.women-clinic-p30')}}</div>
                                        <div class="uui-space-medium-6"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="uui-career03_accordion">
                            <div @click="showMaternalFetalClinic = !showMaternalFetalClinic" class="uui-career03_job-description">
                              <h3 class="uui-heading-xsmall">{{$t('women.women-clinic-p31')}}</h3>
                              <div class="uui-career03_icon-wrapper">
                                <div class="accordion-icon_component-9">
                                  <div class="accordion-icon_horizontal-line-9"></div>
                                  <div v-show="!showMaternalFetalClinic" class="accordion-icon_vertical-line-9"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="showMaternalFetalClinic" class="uui-career03_list-wrapper">
                              <div class="uui-career03_list">
                                <div class="uui-career03_item">
                                  <div class="uui-max-width-large-26">
                                    <div class="uui-faq01_accordion">
                                      <div class="uui-faq01_answer">
                                        <div class="uui-text-size-medium-16">{{$t('women.women-clinic-p32')}}<br>{{$t('women.women-clinic-p33')}}<br>- {{$t('women.women-clinic-p34')}}<br>- {{$t('women.women-clinic-p35')}}<br>- {{$t('women.women-clinic-p36')}}<br>- {{$t('women.women-clinic-p37')}}<br>- {{$t('women.women-clinic-p38')}}<br>‍<br>{{$t('women.women-clinic-p39')}}<br>{{$t('women.women-clinic-p40')}}<br>{{$t('women.women-clinic-p41')}}<br></div>
                                        <div class="uui-space-medium-6"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="uui-career03_accordion">
                            <div @click="showReproductiveBiology = !showReproductiveBiology"  class="uui-career03_job-description">
                              <h3 class="uui-heading-xsmall">{{$t('women.women-clinic-p42')}}</h3>
                              <div class="uui-career03_icon-wrapper">
                                <div class="accordion-icon_component-9">
                                  <div class="accordion-icon_horizontal-line-9"></div>
                                  <div v-show="!showReproductiveBiology" class="accordion-icon_vertical-line-9"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="showReproductiveBiology" class="uui-career03_list-wrapper">
                              <div class="uui-career03_list">
                                <div class="uui-career03_item">
                                  <div class="uui-max-width-large-26">
                                    <div class="uui-text-size-medium-40">{{$t('women.women-clinic-p43')}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="uui-text-rich-text-3 w-richtext">
                <h4>{{$t('women.women-clinic-p44')}}</h4>
                <p>{{$t('women.women-clinic-p45')}}</p>
                <p><strong class="bold-text-20">{{$t('women.women-clinic-p46')}}</strong></p>
                <p>- {{$t('women.women-clinic-p47')}}<br>- {{$t('women.women-clinic-p48')}}<br>- {{$t('women.women-clinic-p49')}}<br>- {{$t('women.women-clinic-p50')}}<br>- {{$t('women.women-clinic-p51')}}<br>- {{$t('women.women-clinic-p52')}}<br>- {{$t('women.women-clinic-p53')}}<br>- {{$t('women.women-clinic-p54')}}</p>
                <p><strong class="bold-text-20">{{$t('women.women-clinic-p55')}}</strong></p>
                <p>- {{$t('women.women-clinic-p56')}}</p>
                <p>{{$t('women.women-clinic-p57')}}<br>- {{$t('women.women-clinic-p58')}}<br>- {{$t('women.women-clinic-p59')}}<br>- {{$t('women.women-clinic-p60')}}<br>- {{$t('women.women-clinic-p61')}}<br>- {{$t('women.women-clinic-p62')}}<br></p>
                <p><strong class="bold-text-20">{{$t('women.women-clinic-p63')}}</strong></p>
                <p>- {{$t('women.women-clinic-p64')}}<br>- {{$t('women.women-clinic-p65')}}<br>- {{$t('women.women-clinic-p66')}}<br></p>
                <h4>{{$t('women.women-clinic-p67')}}</h4>
                <p>{{$t('women.women-clinic-p68')}}</p>
                <p>{{$t('women.women-clinic-p69')}}</p>
                <p>{{$t('women.women-clinic-p70')}}</p>
                <p>{{$t('women.women-clinic-p71')}} </p>
                <p><strong>{{$t('women.women-clinic-p72')}}</strong> {{$t('women.women-clinic-p73')}}</p>
              </div>
              <div>
                <div class="uui-text-align-center-5">
                  <div class="uui-max-width-large-10 align-center">
                    <div class="uui-space-xsmall-11"></div>
                    <div class="uui-text-rich-text-3 w-richtext">
                      <h4>{{$t('women.women-clinic-p74')}}</h4>
                    </div>
                  </div>
                </div>
                <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="uui-pricing08_component w-tabs">
                  <div class="uui-pricing08_tab-content w-tab-content">
                    <div data-w-tab="Tab 1" class="uui-pricing08_tab-pane w-tab-pane w--tab-active">
                      <div class="w-layout-grid uui-pricing08_components">
                        <div data-w-id="7cc4bcd4-67e6-ca4f-d404-7c4e49938cfb" class="uui-pricing08_plan">
                          <div class="uui-pricing08_content">
                            <div class="uui-pricing08_content-top">
                              <div class="uui-text-align-center-5">
                                <h2 class="uui-heading-xxsmall-9">{{$t('women.women-clinic-p75')}}</h2>
                                <div class="uui-pricing08_price">$10,300<span class="uui-pricing08_price-text">MXN</span></div>
                              </div>
                              <div class="uui-pricing08_feature-list">
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d06-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p76')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d0b-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p77')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d10-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p78')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d15-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p79')}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uui-pricing08_popular-plan zoom">
                          <div class="uui-pricing08_popular-label">
                            <div>{{$t('women.women-clinic-p80')}}</div>
                          </div>
                          <div class="uui-pricing08_content">
                            <div class="uui-pricing08_content-top">
                              <div class="uui-text-align-center-5">
                                <h2 class="uui-heading-xxsmall-9">{{$t('women.women-clinic-p81')}}</h2>
                                <div class="uui-pricing08_price">$19,500<span class="uui-pricing08_price-text">MXN</span></div>
                              </div>
                              <div class="uui-pricing08_feature-list">
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d28-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p76')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d2d-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p77')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d32-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p82')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d37-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p83')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d3c-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p84')}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uui-pricing08_plan">
                          <div class="uui-pricing08_content">
                            <div class="uui-pricing08_content-top">
                              <div class="uui-text-align-center-5">
                                <h2 class="uui-heading-xxsmall-9">{{$t('women.women-clinic-p85')}}</h2>
                                <div class="uui-pricing08_price">$14,500<span class="uui-pricing08_price-text">MXN</span></div>
                              </div>
                              <div class="uui-pricing08_feature-list">
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d4c-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p86')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d51-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p77')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d56-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p78')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d5b-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p79')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d60-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p84')}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section class="uui-section_faq01-6">
                <div class="uui-page-padding-46">
                  <div class="uui-container-large-36">
                    <div class="uui-padding-vertical-xhuge-48">
                      <div class="uui-faq02_content">
                        <h2 class="uui-heading-medium">{{$t('women.women-clinic-p87')}}</h2>
                        <div class="uui-space-xsmall"></div>
                        <div class="uui-text-size-large-35">{{$t('women.women-clinic-p88')}}<br>‍<br><strong class="bold-text-20">{{$t('women.women-clinic-p89')}}</strong></div>
                        <div class="uui-space-medium"></div>
                        <div class="div-block-21">
                          <div class="uui-pricing08_feature">
                            <div class="uui-pricing08_icon-wrapper">
                              <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                              </svg></div>
                            </div>
                            <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p90')}}</div>
                          </div>
                          <div class="uui-pricing08_feature">
                            <div class="uui-pricing08_icon-wrapper">
                              <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                              </svg></div>
                            </div>
                            <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p91')}}</div>
                          </div>
                          <div class="uui-pricing08_feature">
                            <div class="uui-pricing08_icon-wrapper">
                              <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                              </svg></div>
                            </div>
                            <div class="uui-text-size-medium-17">{{$t('women.women-clinic-p92')}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="uui-faq01_component-7">
                        <div class="uui-faq01_list-8">
                          <div class="uui-faq01_accordion-6">
                            <div @click="show1 = !show1"  class="uui-faq01_question-6">
                              <div class="uui-faq01_heading-6">{{$t('women.women-clinic-p93')}}<br></div>
                              <div class="uui-faq01_icon-wrapper-6">
                                <div class="accordion-icon_component-7">
                                  <div class="accordion-icon_horizontal-line-7"></div>
                                  <div v-show="!show1" class="accordion-icon_vertical-line-7"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show1" class="uui-faq01_answer-6 animate__animated animate__fadeIn">
                              <div class="uui-text-size-large-35"><strong>{{$t('women.women-clinic-p94')}}</strong></div>
                              <ul role="list">
                                <li>{{$t('women.women-clinic-p95')}}</li>
                                <li>{{$t('women.women-clinic-p96')}}</li>
                                <li>{{$t('women.women-clinic-p97')}}</li>
                                <li>{{$t('women.women-clinic-p98')}}</li>
                                <li>{{$t('women.women-clinic-p99')}}</li>
                                <li>{{$t('women.women-clinic-p100')}}</li>
                                <li>{{$t('women.women-clinic-p101')}}</li>
                                <li>{{$t('women.women-clinic-p102')}}</li>
                                <li>{{$t('women.women-clinic-p103')}}</li>
                                <li>{{$t('women.women-clinic-p104')}}</li>
                                <li>{{$t('women.women-clinic-p105')}}</li>
                                <li>{{$t('women.women-clinic-p106')}}</li>
                                <li>{{$t('women.women-clinic-p107')}}</li>
                                <li>{{$t('women.women-clinic-p108')}}</li>
                              </ul>
                              <div class="uui-text-size-large-35"><strong>{{$t('women.women-clinic-p109')}}</strong></div>
                              <div class="uui-space-medium-14"></div>
                              <ul role="list">
                                <li>{{$t('women.women-clinic-p110')}}</li>
                                <li>{{$t('women.women-clinic-p111')}}</li>
                                <li>{{$t('women.women-clinic-p112')}}</li>
                                <li>{{$t('women.women-clinic-p113')}}</li>
                                <li>{{$t('women.women-clinic-p114')}}</li>
                                <li>{{$t('women.women-clinic-p115')}}</li>
                                <li>{{$t('women.women-clinic-p116')}}</li>
                                <li>{{$t('women.women-clinic-p117')}}</li>
                                <li>{{$t('women.women-clinic-p118')}}</li>
                                <li>{{$t('women.women-clinic-p119')}}</li>
                                <li>{{$t('women.women-clinic-p120')}}</li>
                                <li>{{$t('women.women-clinic-p121')}}</li>
                                <li>{{$t('women.women-clinic-p122')}}</li>
                                <li>{{$t('women.women-clinic-p123')}}</li>
                              </ul>
                            </div>
                          </div>
                          <div class="uui-faq01_accordion-6">
                            <div @click="show2 = !show2" class="uui-faq01_question-6">
                              <div class="uui-faq01_heading-6">{{$t('women.women-clinic-p124')}}</div>
                              <div class="uui-faq01_icon-wrapper-6">
                                <div class="accordion-icon_component-7">
                                  <div class="accordion-icon_horizontal-line-7"></div>
                                  <div   v-show="!show2" class="accordion-icon_vertical-line-7"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show2" class="uui-faq01_answer-6 animate__animated animate__fadeIn">
                              <div class="uui-text-size-medium-34">{{$t('women.women-clinic-p125')}}</div>
                              <ul role="list">
                                <li>{{$t('women.women-clinic-p126')}}</li>
                                <li>{{$t('women.women-clinic-p127')}}</li>
                                <li>{{$t('women.women-clinic-p128')}}</li>
                                <li>{{$t('women.women-clinic-p129')}}</li>
                                <li>{{$t('women.women-clinic-p130')}}</li>
                                <li>{{$t('women.women-clinic-p131')}}</li>
                                <li>{{$t('women.women-clinic-p132')}}</li>
                              </ul>
                              <div class="uui-space-medium-14"></div>
                            </div>
                          </div>
                          <div class="uui-faq01_accordion-6">
                            <div @click="show3 = !show3" class="uui-faq01_question-6">
                              <div class="uui-faq01_heading-6">{{$t('women.women-clinic-p133')}}</div>
                              <div class="uui-faq01_icon-wrapper-6">
                                <div class="accordion-icon_component-7">
                                  <div class="accordion-icon_horizontal-line-7"></div>
                                  <div v-show="!show3" class="accordion-icon_vertical-line-7"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show3" class="uui-faq01_answer-6 animate__animated animate__fadeIn">
                              <ul role="list">
                                <li>{{$t('women.women-clinic-p134')}}</li>
                                <li>{{$t('women.women-clinic-p135')}}</li>
                                <li>{{$t('women.women-clinic-p136')}}</li>
                                <li>{{$t('women.women-clinic-p137')}}</li>
                                <li>{{$t('women.women-clinic-p138')}}</li>
                                <li>{{$t('women.women-clinic-p139')}}</li>
                              </ul>
                              <div class="uui-space-medium-14"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="team_seccion">
                <div class="uui-page-padding-18">
                  <div class="uui-container-large-14">
                    <div class="uui-padding-vertical-xhuge-20">
                      <div class="uui-max-width-large-9">
                        <h2 class="uui-heading-medium-11" style="margin-top: 2rem;margin-bottom: 2rem; text-align: center;color: var(--hc--colors--green--600);">{{$t('home.emergencies-aid')}}</h2>
                        <h2 class="uui-heading-medium-11">{{$t('home.our-team')}}</h2>
                        <div class="uui-space-xsmall-10"></div>
                      </div>
                      <div class="uui-team07_component-2">
                        <div class="uui-team07_list-wrapper-2">
                          <div class="w-layout-grid item_medico">
<!--                            <div class="card_doctor">-->
<!--                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DRA.-FLORENTINA-1.jpg" loading="lazy" srcset="../../../public/images/DRA.-FLORENTINA-1-p-500.jpg 500w, ../../../public/images/DRA.-FLORENTINA-1-p-800.jpg 800w, ../../../public/images/DRA.-FLORENTINA-1-p-1080.jpg 1080w, ../../../public/images/DRA.-FLORENTINA-1-p-1600.jpg 1600w, ../../../public/images/DRA.-FLORENTINA-1-p-2000.jpg 2000w, ../../../public/images/DRA.-FLORENTINA-1-p-2600.jpg 2600w, ../../../public/images/DRA.-FLORENTINA-1-p-3200.jpg 3200w, ../../../public/images/DRA.-FLORENTINA-1.jpg 6960w" alt="" sizes="84px" class="uui-team07_image-2"></div>-->
<!--                              <div class="uui-team07_item-content-2">-->
<!--                                <div class="uui-heading-tiny-4">Dra. Florentina López Toledo</div>-->
<!--                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 3297941</div>-->
<!--                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Esp. 4743489</div>-->
<!--                                <div class="uui-space-xxsmall-8"></div>-->
<!--                                <div class="uui-text-size-medium-15"><strong>Ginecología y Obstetricia<br></strong>Postgraduada en el Hospital de la Mujer CDMX / Universidad Autónoma de México U.N.A.M</div>-->
<!--                              </div>-->
<!--                            </div>-->

                            <div  v-for="(item, index) in specialists" :key="index" class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img v-bind:src="item.profile_picture" loading="lazy" sizes="84px"  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">{{item.name}}</div>
                                <div v-if="item.professional_license.length"  class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. {{item.professional_license}}</div>
                                <div v-if="item.specialty_license.length">
                                  <div  v-for="(spe, i) in stringArray(item.specialty_license)" :key="i"  class="uui-text-size-medium-15 text-color-primary700">Ced. Esp. {{spe}} </div>
                                </div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15">
                                  <strong v-for="(specialty, index) in item.specialties" :key="index">{{specialty.name}} <br></strong>
                                  {{item.information}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uui-space-xxsmall-21"></div>
                        <div class="uui-space-xxsmall-21"></div>
                        <div class="uui-team07_list-wrapper-2">
                          <div class="uui-blogsection12_item-2">
                            <router-link data-w-id="32de169b-7621-95e6-cbfc-914d31519a18" to="/obstetrical" class="uui-blogsection12_image-link-2 w-inline-block">
                              <div class="uui-blogsection12_image-wrapper-2"><img srcset="images/obstetricas-emergency-p-500.jpeg 500w, images/obstetricas-emergency-p-800.jpeg 800w, images/obstetricas-emergency-p-1080.jpeg 1080w, images/obstetricas-emergency-p-1600.jpeg 1600w, images/obstetricas-emergency.jpeg 1800w" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, (max-width: 1919px) 1280px, 1600px" src="images/obstetricas-emergency.jpeg" alt="" class="uui-blogsection12_image-2"></div>
                            </router-link>
                            <router-link data-w-id="32de169b-7621-95e6-cbfc-914d31519a1b" to="/obstetrical" class="uui-blogsection12_title-link-2 w-inline-block">
                              <h3 class="uui-heading-xsmall-5">{{$t('urgencies.urgencies-title-1')}}</h3>
                              <div class="uui-blogsection12_icon-2 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 17L17 7M17 7H7M17 7V17" stroke="CurrentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>
                            <div class="uui-text-size-medium-31">{{$t('urgencies.urgencies-title-1-description')}}<br>{{$t('urgencies.urgencies-title-1-description-2')}}</div>
                          </div>
                        </div>
                        <appointment-component></appointment-component>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.zoom {

  transition: transform .2s; /* Animation */

}

.zoom:hover {
  transform: scale(1.2);
}
</style>
