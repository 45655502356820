<script>
export default {
  name: "ClinicsMision",
  props: {
    mision: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <a href="#" class="sysf-card-2 sysf-card-box sysf-s-p-24 w-inline-block">
    <div>
      <h3 class="sysf-heading-3 sysf-xs">MISIÓN</h3>
      <p class="sysf-body-text-3 sysf-m sysf-s-m-0">{{mision}}</p>
    </div>
  </a>
</template>

<style scoped>

</style>
