<script >

// import OurSpecialties from "@/components/OurSpecialties.vue";
// import AddressComponent from "@/components/AddressComponent.vue";
import {app} from "@/main";
import AddressComponent from "@/components/AddressComponent.vue";
import OurSpecialties from "@/components/OurSpecialties.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import AppointmentComponent from "@/components/AppointmentComponent.vue";

export default{
  name:"OphthalmologyPage",
  components: {AppointmentComponent, SideContactComponent, OurSpecialties, AddressComponent},
  // components: {AddressComponent, OurSpecialties},
  data() {
    return {
      services:[],
      show1:false,
      show2:false,
      show3:false,
      locale:'',
      specialties:[32],
      specialists:[],
    };
  },



  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });

  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods:{
    loadData(locale){
      this.specialists = require('../../data/specialists.json');
      this.specialists = this.specialists.filter(s =>
          s.specialties.some(specialty => this.specialties.includes(specialty.id))
      );
      this.specialists = this.specialists.map(specialist => {
        const translateInformation= specialist.information[locale];
        let specialties = specialist.specialties.map(specialty=>{
          const name= specialty.name[locale];
          return {
            ...specialty,
            name:name
          }
        });

        return {
          ...specialist,
          information: translateInformation,
          specialties:specialties
        };
      });

    },
    stringArray(string) {
      return string.split(',');
    },
  }
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_6990952c-a788-fc1a-c311-dde9ed0d7a16-73cd5331" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-button-row-12">
                <a data-w-id="6990952c-a788-fc1a-c311-dde9ed0d7a18" href="../index.html" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('ophthalmology.home')}}</div>
                </a>
              </div>
              <h1 class="uui-heading-large-15">{{$t('ophthalmology.title')}}</h1>
              <div class="text-block-13">{{$t('ophthalmology.subtitle')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_6990952c-a788-fc1a-c311-dde9ed0d7a21-73cd5331" class="uui-blogpost05_image-wrapper-7"><img srcset="../../../public/images/oftalmologia-p-500.jpeg 500w, ../../../public/images/oftalmologia-p-800.jpeg 800w, ../../../public/images/oftalmologia-p-1080.jpeg 1080w, ../../../public/images/oftalmologia.jpeg 1480w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 44vw, (max-width: 1919px) 46vw, 768px" src="../../../public/images/oftalmologia.jpeg" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">
              <our-specialties></our-specialties>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-_75228e77-5a8c-b5e4-9ff4-d26e6454f41c-73cd5331" class="uui-blogpost05_content-2">
              <div class="uui-text-rich-text-5 w-richtext">
                <h4 class="heading-20 display-md-bold">{{$t('ophthalmology.ophthalmology')}}</h4>
                <p class="paragraph-12">{{$t('ophthalmology.p1')}}</p>
              </div>
              <section class="uui-section_faq01-3">
                <div class="uui-page-padding-30">
                  <div class="uui-container-large-23">
                    <div class="uui-padding-vertical-xhuge-31">
                      <div class="uui-text-align-center-10">
                        <div class="uui-max-width-large-15 align-center"></div>
                      </div>
                      <div class="uui-faq01_component-3">
                        <div class="uui-faq01_list-3">
                          <div class="uui-faq01_accordion-3">
                            <div @click="show1 = !show1" class="uui-faq01_question-3">
                              <div class="uui-faq01_heading-3">{{$t('ophthalmology.consultation')}}</div>
                              <div class="uui-faq01_icon-wrapper-3">
                                <div class="accordion-icon_component-4">
                                  <div class="accordion-icon_horizontal-line-4"></div>
                                  <div v-show="!show1" class="accordion-icon_vertical-line-4"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show1" class="uui-faq01_answer-3 animate__animated animate__fadeIn">
                              <ul role="list">
                                <li>{{$t('ophthalmology.consultation-li1')}}</li>
                                <li class="list-item-10">{{$t('ophthalmology.consultation-li2')}}<br></li>
                                <li>{{$t('ophthalmology.consultation-li3')}}</li>
                              </ul>
                            </div>
                            <div class="uui-space-medium-10"></div>
                          </div>
                          <div class="uui-faq01_accordion-3">
                            <div @click="show2 = !show2" class="uui-faq01_question-3">
                              <div class="uui-faq01_heading-3">{{$t('ophthalmology.surgery')}}</div>
                              <div class="uui-faq01_icon-wrapper-3">
                                <div class="accordion-icon_component-4">
                                  <div class="accordion-icon_horizontal-line-4"></div>
                                  <div v-show="!show2" class="accordion-icon_vertical-line-4"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show2" class="uui-faq01_answer-3 animate__animated animate__fadeIn">
                              <ul role="list" class="list-6">
                                <li>{{$t('ophthalmology.surgery-li1')}}</li>
                                <li>{{$t('ophthalmology.surgery-li2')}}</li>
                                <li>{{$t('ophthalmology.surgery-li3')}}</li>
                                <li>{{$t('ophthalmology.surgery-li4')}}</li>
                                <li>{{$t('ophthalmology.surgery-li5')}}</li>
                                <li>{{$t('ophthalmology.surgery-li6')}}</li>
                                <li>{{$t('ophthalmology.surgery-li7')}}</li>

                              </ul>
                              <div class="uui-space-medium-10"></div>
                            </div>
                          </div>
                          <div class="uui-faq01_accordion-3">
                            <div @click="show3 = !show3" class="uui-faq01_question-3">
                              <div class="uui-faq01_heading-3">{{$t('ophthalmology.equipment')}}</div>
                              <div class="uui-faq01_icon-wrapper-3">
                                <div class="accordion-icon_component-4">
                                  <div class="accordion-icon_horizontal-line-4"></div>
                                  <div v-show="!show3" class="accordion-icon_vertical-line-4"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show3" class="uui-faq01_answer-3 animate__animated animate__fadeIn">
                              <ul role="list" class="list-7">
                                <li>{{$t('ophthalmology.equipment-li1')}}</li>
                                <li>{{$t('ophthalmology.equipment-li2')}}</li>
                              </ul>
                              <div class="uui-space-medium-10"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="team_seccion">
                <div class="uui-page-padding-29">
                  <div class="uui-container-large-22">
                    <div class="uui-padding-vertical-xhuge-30">
                      <div class="uui-max-width-large-14">
                        <h2 class="uui-heading-medium-19">{{$t('ophthalmology.medical-team')}}</h2>
                        <div class="uui-space-xsmall-10"></div>
                      </div>
                      <div class="uui-team07_component-3">
                        <div class="uui-team07_list-wrapper">
                          <div class="w-layout-grid item_medico">
<!--                            <div  v-for="(item, index) in specialists" :key="index" class="card_doctor">-->
<!--                              <div class="uui-team07_image-wrapper-2"><img v-bind:src="item.profile_picture" loading="lazy" sizes="84px"  alt="" class="uui-team07_image-2"></div>-->
<!--                              <div class="uui-team07_item-content-2">-->
<!--                                <div class="uui-heading-tiny-4">{{item.name}}</div>-->
<!--                                <div v-if="item.professional_license.length"  class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. {{item.professional_license}}</div>-->
<!--                                <div v-if="item.specialty_license.length">-->
<!--                                  <div  v-for="(spe, i) in stringArray(item.specialty_license)" :key="i"  class="uui-text-size-medium-15 text-color-primary700">Ced. Esp. {{spe}} </div>-->
<!--                                </div>-->
<!--                                <div class="uui-space-xxsmall-8"></div>-->
<!--                                <div class="uui-text-size-medium-15">-->
<!--                                  <strong v-for="(specialty, index) in item.specialties" :key="index">{{specialty.name}} <br></strong>-->
<!--                                  {{item.information}}-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
                            <div class="uui-team07_item-3">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA.jpg" loading="lazy" sizes="84px" srcset="../../../public/images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-500.jpg 500w, ../../../public/images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-800.jpg 800w, ../../../public/images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-1080.jpg 1080w, ../../../public/images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-1600.jpg 1600w, ../../../public/images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-2000.jpg 2000w, ../../../public/images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-2600.jpg 2600w, ../../../public/images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-3200.jpg 3200w, ../../../public/images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA.jpg 4000w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-3">
                                <div class="uui-heading-tiny-6">Dra. Laura Alejandra Mendoza Ochoa</div>
                                <div class="uui-text-size-medium-22 text-color-primary700">{{$t('ophthalmology.professional-license')}} 6739532</div>
                                <div class="uui-text-size-medium-22 text-color-primary700">{{$t('ophthalmology.specialty-license')}} 9085076</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-22"><strong>{{$t('ophthalmology.ophthalmology')}}<br></strong>{{$t('ophthalmology.dr1-description')}}</div>
                              </div>
                            </div>
                            <div class="uui-team07_item-3">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA.jpg" loading="lazy" srcset="../../../public/images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-500.jpg 500w, ../../../public/images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-800.jpg 800w, ../../../public/images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-1080.jpg 1080w, ../../../public/images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-1600.jpg 1600w, ../../../public/images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-2000.jpg 2000w, ../../../public/images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-2600.jpg 2600w, ../../../public/images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-3200.jpg 3200w, ../../../public/images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA.jpg 4000w" alt="" sizes="84px" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-3">
                                <div class="uui-heading-tiny-6">Dr. Alberto Carlos Abdala Figueroa</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-22"><strong>{{$t('ophthalmology.ophthalmology')}}<br></strong>{{$t('ophthalmology.dr2-description')}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <AppointmentComponent></AppointmentComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>

</style>
