<script >
// import OurSpecialties from "@/components/OurSpecialties.vue";
// import AddressComponent from "@/components/AddressComponent.vue";
// import AppointmentComponent from "@/components/AppointmentComponent.vue";

import AddressComponent from "@/components/AddressComponent.vue";
import OurSpecialties from "@/components/OurSpecialties.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import AppointmentComponent from "@/components/AppointmentComponent.vue";
import {app} from "@/main";

export default{
  name:"AdultClinicSpecialtyPage",
  components: {AppointmentComponent, SideContactComponent, OurSpecialties, AddressComponent},
  data() {
    return {
      locale:'',
      specialties:[19,2,25],
      specialists:[],
    };
  },

  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });

  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods: {
    loadData(locale) {
      this.specialists = require('../../data/specialists.json');
      this.specialists = this.specialists.filter(s =>
          s.specialties.some(specialty => this.specialties.includes(specialty.id))
      );
      this.specialists = this.specialists.map(specialist => {
        const translateInformation = specialist.information[locale];
        let specialties = specialist.specialties.map(specialty => {
          const name = specialty.name[locale];
          return {
            ...specialty,
            name: name
          }
        });

        return {
          ...specialist,
          information: translateInformation,
          specialties: specialties
        };
      });

    },
    stringArray(string) {
      return string.split(',');
    },
  }
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_1c8e95af-47bb-9a06-c63a-b79c1d447ea0-e67a1062" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-button-row-12">
                <router-link data-w-id="1c8e95af-47bb-9a06-c63a-b79c1d447ea2" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('home.home')}}</div>

                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('adult-clinic.title')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('adult-clinic.subtitle')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_1c8e95af-47bb-9a06-c63a-b79c1d447eac-e67a1062" class="uui-blogpost05_image-wrapper-7"><img srcset="../../../public/images/clinica-adulto-p-500.png 500w, ../../../public/images/clinica-adulto-p-800.png 800w, ../../../public/images/clinica-adulto.png 928w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 46vw, 768px" src="../../../public/images/clinica-adulto.png" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">
              <our-specialties></our-specialties>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-_1c8e95af-47bb-9a06-c63a-b79c1d447eb0-e67a1062" class="uui-blogpost05_content-7">
              <div class="uui-blogpost05_content-2">
                <h3 class="ds-md-bold">{{$t('adult-clinic.title')}}</h3>
                <div class="uui-text-rich-text-3 w-richtext">
                  <p>{{$t('adult-clinic.p1')}}</p>
                  <p>{{$t('adult-clinic.p2')}}</p>
                  <p>{{$t('adult-clinic.p3')}}</p>
                  <p>{{$t('adult-clinic.p4')}}</p>
                  <h4>{{$t('adult-clinic.question1')}}</h4>
                  <p>{{$t('adult-clinic.p5')}}</p>
                </div>
                <section class="equipos-mujer">
                  <div class="rl-padding-global-20">
                    <div class="rl-container-large-15"></div>
                  </div>
                </section>
                <section class="team_seccion">
                  <div class="uui-page-padding-18">
                    <div class="uui-container-large-14">
                      <div class="uui-padding-vertical-xhuge-20">
                        <div class="uui-max-width-large-9">
                          <h2 class="uui-heading-medium-11">{{$t('adult-clinic.our-team')}}</h2>
                          <div class="uui-space-xsmall-10"></div>
                        </div>
                        <div class="uui-team07_component-2">
                          <div class="uui-team07_list-wrapper-2">
                            <div class="w-layout-grid item_medico">
<!--                              <div  v-for="(item, index) in specialists" :key="index" class="card_doctor">-->
<!--                                <div class="uui-team07_image-wrapper-2"><img v-bind:src="item.profile_picture" loading="lazy" sizes="84px"  alt="" class="uui-team07_image-2"></div>-->
<!--                                <div class="uui-team07_item-content-2">-->
<!--                                  <div class="uui-heading-tiny-4">{{item.name}}</div>-->
<!--                                  <div v-if="item.professional_license.length"  class="uui-text-size-medium-15 text-color-primary700">{{$t('adult-clinic.professional-license')}} {{item.professional_license}}</div>-->
<!--                                  <div v-if="item.specialty_license.length">-->
<!--                                    <div  v-for="(spe, i) in stringArray(item.specialty_license)" :key="i"  class="uui-text-size-medium-15 text-color-primary700">{{$t('adult-clinic.specialty-license')}} {{spe}} </div>-->
<!--                                  </div>-->
<!--                                  <div class="uui-space-xxsmall-8"></div>-->
<!--                                  <div class="uui-text-size-medium-15">-->
<!--                                    <strong v-for="(specialty, index) in item.specialties" :key="index">{{specialty.name}} <br></strong>-->
<!--                                    {{item.information}}-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->
                              <div class="card_doctor">
                                <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/doc.png" loading="lazy" alt="" class="uui-team07_image-2"></div>
                                <div class="uui-team07_item-content-2">
                                  <div class="uui-heading-tiny-4">Dr. Yuri Alfonso Roldan Aragón</div>
                                  <div class="uui-text-size-medium-15 text-color-primary700">{{$t('adult-clinic.professional-license')}} 2391273</div>
                                  <div class="uui-text-size-medium-15 text-color-primary700">{{$t('adult-clinic.specialty-license')}} 5259026</div>
                                  <div class="uui-space-xxsmall-8"></div>
                                  <div class="uui-text-size-medium-15"><strong>{{$t('adult-clinic.esp1')}}<br></strong>{{$t('adult-clinic.dr1-description')}}</div>
                                </div>
                              </div>
                              <div class="card_doctor">
                                <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/moema.png" loading="lazy" sizes="84px" srcset="../../../public/images/moema-p-500.png 500w, ../../../public/images/moema-p-800.png 800w, ../../../public/images/moema-p-1080.png 1080w, ../../../public/images/moema.png 1196w" alt="" class="uui-team07_image-2"></div>
                                <div class="uui-team07_item-content-2">
                                  <div class="uui-heading-tiny-4">Dra. Moema Jerónimo R.</div>
                                  <div class="uui-text-size-medium-15 text-color-primary700">{{$t('adult-clinic.professional-license')}} 6740669</div>
                                  <div class="uui-text-size-medium-15 text-color-primary700">{{$t('adult-clinic.specialty-license')}} 9289026</div>
                                  <div class="uui-space-xxsmall-8"></div>
                                  <div class="uui-text-size-medium-15"><strong>{{$t('adult-clinic.esp2')}}</strong></div>
                                </div>
                              </div>
                              <div class="card_doctor">
                                <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR.-JESUS-BETANZOS-GOMEZ.jpg" loading="lazy" sizes="84px" srcset="../../../public/images/DR.-JESUS-BETANZOS-GOMEZ-p-500.jpg 500w, ../../../public/images/DR.-JESUS-BETANZOS-GOMEZ-p-800.jpg 800w, ../../../public/images/DR.-JESUS-BETANZOS-GOMEZ-p-1080.jpg 1080w, ../../../public/images/DR.-JESUS-BETANZOS-GOMEZ.jpg 1500w" alt="" class="uui-team07_image-2"></div>
                                <div class="uui-team07_item-content-2">
                                  <div class="uui-heading-tiny-4">Dr. Jesús Betanzos G.</div>
                                  <div class="uui-text-size-medium-15 text-color-primary700">{{$t('adult-clinic.specialty-license')}} 8924726</div>
                                  <div class="uui-text-size-medium-15 text-color-primary700">{{$t('adult-clinic.professional-license')}} 8924726</div>
                                  <div class="uui-space-xxsmall-8"></div>
                                  <div class="uui-text-size-medium-15"><strong>{{$t('adult-clinic.esp2')}}</strong></div>
                                </div>
                              </div>
                              <div class="card_doctor">
                                <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1.jpg" loading="lazy" sizes="84px" srcset="../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-500.jpg 500w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-800.jpg 800w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-1080.jpg 1080w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-1600.jpg 1600w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-2000.jpg 2000w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-2600.jpg 2600w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-3200.jpg 3200w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1.jpg 4000w" alt="" class="uui-team07_image-2"></div>
                                <div class="uui-team07_item-content-2">
                                  <div class="uui-heading-tiny-4">Lic. Valeria Vásquez P.</div>
                                  <div class="uui-space-xxsmall-8"></div>
                                  <div class="uui-text-size-medium-15"><strong>{{$t('adult-clinic.esp3')}} </strong></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <appointment-component></appointment-component>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>

</style>
