<script >
// import EmergenciesComponent from "@/components/EmergenciesComponent.vue";
// import AddressComponent from "@/components/AddressComponent.vue";
// import AppointmentComponent from "@/components/AppointmentComponent.vue";

import EmergenciesServiceComponent from "@/components/EmergenciesServiceComponent.vue";
import AddressComponent from "@/components/AddressComponent.vue";
import EmergenciesComponent from "@/components/EmergenciesComponent.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import AppointmentComponent from "@/components/AppointmentComponent.vue";
import {app} from "@/main";

export default {
  name: "AdultsPage",
  components: {
    AppointmentComponent,
    SideContactComponent, EmergenciesComponent, AddressComponent, EmergenciesServiceComponent},
  data() {
    return {
      locale:'',
      specialties:[6],
      specialists:[],
    };
  },

  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });

  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods:{
    loadData(locale){
      this.specialists = require('../../data/specialists.json');
      this.specialists = this.specialists.filter(s =>
          s.specialties.some(specialty => this.specialties.includes(specialty.id))
      );
      this.specialists = this.specialists.map(specialist => {
        const translateInformation= specialist.information[locale];
        let specialties = specialist.specialties.map(specialty=>{
          const name= specialty.name[locale];
          return {
            ...specialty,
            name:name
          }
        });

        return {
          ...specialist,
          information: translateInformation,
          specialties:specialties
        };
      });

    },
    stringArray(string) {
      return string.split(',');
    },
  }
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_6f71ce2f-83a3-01cb-0ca9-a11eccde886d-0822a821" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="6f71ce2f-83a3-01cb-0ca9-a11eccde8870" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('home.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('adult.adult-emergency-title')}}</h1>
              <div class="uui-space-large-7"></div>
              <address-component></address-component>
            </div>
            <div id="w-node-_6f71ce2f-83a3-01cb-0ca9-a11eccde8878-0822a821" class="uui-blogpost05_image-wrapper-7"><img srcset="../../../public/images/MEDICOS-EN-URGENCIAS-p-500.jpg 500w, ../../../public/images/MEDICOS-EN-URGENCIAS-p-800.jpg 800w, ../../../public/images/MEDICOS-EN-URGENCIAS-p-1080.jpg 1080w, ../../../public/images/MEDICOS-EN-URGENCIAS-p-1600.jpg 1600w, ../../../public/images/MEDICOS-EN-URGENCIAS-p-2000.jpg 2000w, ../../../public/images/MEDICOS-EN-URGENCIAS-p-2600.jpg 2600w, ../../../public/images/MEDICOS-EN-URGENCIAS-p-3200.jpg 3200w, ../../../public/images/MEDICOS-EN-URGENCIAS.jpg 6960w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 46vw, 768px" src="../../../public/images/MEDICOS-EN-URGENCIAS.jpg" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">
              <emergencies-component></emergencies-component>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-baf1a201-57dc-18f8-88d2-494b34f902a8-0822a821" class="uui-blogpost05_content-2 urgency">
              <section class="equipos-mujer">
                <div class="rl-padding-global-20">
                  <div class="rl-container-large-15"></div>
                </div>
              </section>
              <section class="team_seccion">
                <div class="uui-page-padding-18">
                  <div class="uui-container-large-14"></div>
                </div>
              </section>
              <section class="rl_section_layout239-3">
                <div class="rl-padding-global-22">
                  <div class="rl-container-large-17"></div>
                </div>
              </section>
              <header class="uui-section_blogpost06">
                <header class="uui-blogpost06_content-wrapper">
                  <div class="uui-page-padding-24">
                    <div class="uui-container-large-19">
                      <div class="uui-padding-vertical-xhuge-26">
                        <blockquote class="block-quote-3">{{$t('adult.adult-emergency-description')}}</blockquote>
                        <div class="div-block-15">
                          <h4 class="heading-10"><strong>{{$t('adult.adult-emergency-p1')}}</strong></h4>
                          <ul role="list" class="list">
                            <li>{{$t('adult.adult-emergency-p2')}}</li>
                            <li>{{$t('adult.adult-emergency-p3')}}</li>
                            <li>{{$t('adult.adult-emergency-p4')}}</li>
                            <li>{{$t('adult.adult-emergency-p5')}}</li>
                          </ul>
                        </div>
                        <div class="uui-max-width-large-12 align-center">
                          <div class="uui-blogpost06_content">
                            <div class="div-block-14">
                              <h4 class="heading-10">{{$t('adult.adult-emergency-p6')}}</h4>
                              <ul role="list" class="list">
                                <li>{{$t('adult.adult-emergency-p7')}}</li>
                                <li>{{$t('adult.adult-emergency-p8')}}</li>
                                <li>{{$t('adult.adult-emergency-p9')}}</li>
                                <li>{{$t('adult.adult-emergency-p10')}}</li>
                                <li>{{$t('adult.adult-emergency-p11')}}</li>
                                <li>{{$t('adult.adult-emergency-p12')}}</li>
                                <li>{{$t('adult.adult-emergency-p13')}}</li>
                                <li>{{$t('adult.adult-emergency-p14')}}</li>
                                <li>{{$t('adult.adult-emergency-p15')}}</li>
                              </ul>
                            </div>
                            <div class="div-block-13">
                              <h4 class="heading-10">{{$t('adult.adult-emergency-p16')}}</h4>
                              <ul role="list" class="list">
                                <li>{{$t('adult.adult-emergency-p17')}}</li>
                                <li>{{$t('adult.adult-emergency-p18')}}</li>
                                <li>{{$t('adult.adult-emergency-p19')}}</li>
                                <li>{{$t('adult.adult-emergency-p20')}}</li>
                                <li>{{$t('adult.adult-emergency-p21')}}</li>
                              </ul>
                            </div>
                            <div class="uui-blogpost06_rich-text-divider"></div>
                            <div class="text-block-20"><strong><em class="italic-text">&quot;{{$t('adult.adult-emergency-p22')}}&quot;</em></strong></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </header>
              </header>
              <section class="team_seccion">
                <div class="uui-page-padding-18">
                  <div class="uui-container-large-14">
                    <div class="uui-padding-vertical-xhuge-20">
                      <div class="uui-max-width-large-9">
                        <h2 class="uui-heading-medium-11" style="margin-top: 2rem;margin-bottom: 2rem; text-align: center;color: var(--hc--colors--green--600);">{{$t('home.emergencies-aid')}}</h2>
                        <h2 class="uui-heading-medium-11">{{$t('home.our-team')}}</h2>
                        <div class="uui-space-xsmall-10"></div>
                      </div>
                      <div class="uui-team07_component-2">
                        <div class="uui-team07_list-wrapper-2">
                          <div class="w-layout-grid item_medico">
                            <div  v-for="(item, index) in specialists" :key="index" class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img v-bind:src="item.profile_picture" loading="lazy" sizes="84px"  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">{{item.name}}</div>
                                <div v-if="item.professional_license.length"  class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. {{item.professional_license}}</div>
                                <div v-if="item.specialty_license.length">
                                  <div  v-for="(spe, i) in stringArray(item.specialty_license)" :key="i"  class="uui-text-size-medium-15 text-color-primary700">Ced. Esp. {{spe}} </div>
                                </div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15">
                                  <strong v-for="(specialty, index) in item.specialties" :key="index">{{specialty.name}} <br></strong>
                                  {{item.information}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <appointment-component></appointment-component>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <section class="uui-section_blogsection02-2">
            <div class="uui-page-padding-43">
              <div class="uui-container-large-33">
                <div class="uui-padding-vertical-xhuge-44">
                  <div class="uui-text-align-center-15">
                    <div class="uui-max-width-large-20 align-center">
                      <h2 class="uui-heading-medium-28">Servicio de Urgencias</h2>
                      <div class="uui-space-xsmall-22"></div>
                      <div class="uui-text-size-large-31">El Servicio de Urgencias del Hospital Ángel del Mar te ofrece atención médica oportuna, profesional y ética las 24 horas del día, los 365 días del año, con médicos urgenciologos y con respaldo de más de 65 especialistas , con estudios de imágen y gabinete; 2 áreas de choque equipadas y acceso de costado rápido.</div>
                    </div>
                  </div>
                  <emergencies-service-component></emergencies-service-component>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>

</style>
