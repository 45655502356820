<script >

// import OurSpecialties from "@/components/OurSpecialties.vue";
// import AddressComponent from "@/components/AddressComponent.vue";
// import AppointmentComponent from "@/components/AppointmentComponent.vue";

import AddressComponent from "@/components/AddressComponent.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import OurSpecialties from "@/components/OurSpecialties.vue";
import 'animate.css';
import AppointmentComponent from "@/components/AppointmentComponent.vue";

export default {
  name: "UrologyPage",
  components: { AppointmentComponent, OurSpecialties, SideContactComponent, AddressComponent },
  // components: {AppointmentComponent, AddressComponent, OurSpecialties}
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-b8bed620-ee83-db9f-67f9-12cdad4101bb-dae17072" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="b8bed620-ee83-db9f-67f9-12cdad4101be" to="/"
                  class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699"
                        stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div>{{$t('urology.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('urology.title')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('urology.subtitle')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-b8bed620-ee83-db9f-67f9-12cdad4101c8-dae17072" class="uui-blogpost05_image-wrapper-7"><img
                srcset="../../../public/images/Urologia-p-500.jpeg 500w, ../../../public/images/Urologia-p-800.jpeg 800w, ../../../public/images/Urologia-p-1080.jpeg 1080w, ../../../public/images/Urologia.jpeg 1480w"
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 44vw, (max-width: 1919px) 46vw, 768px"
                src="../../../public/images/Urologia.jpeg" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">
              <our-specialties></our-specialties>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-d3011c76-3a42-0255-daac-ecb301989a23-dae17072" class="uui-blogpost05_content-2">
              <div class="uui-text-rich-text-3 w-richtext">
                <h3>{{$t('urology.article-1')}}</h3>
                <p>{{$t('urology.article-p1')}}</p>
              </div>
              <div class="uui-text-rich-text-3 w-richtext">
                <h4>{{$t('urology.general')}}</h4>
              </div>
              <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                    height="24" viewbox="0 0 24 24" fill="none">
                    <path
                      d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                      stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                <div class="rl_footer1_link-text-2">{{$t('urology.general-li1')}}</div>
              </a>
              <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                    height="24" viewbox="0 0 24 24" fill="none">
                    <path
                      d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                      stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                <div class="rl_footer1_link-text-2">{{$t('urology.general-li2')}}</div>
              </a>
              <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                    height="24" viewbox="0 0 24 24" fill="none">
                    <path
                      d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                      stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                <div class="rl_footer1_link-text-2">{{$t('urology.general-li3')}}</div>
              </a>
              <section class="team_seccion">
                <div class="uui-page-padding-18">
                  <div class="uui-container-large-14">
                    <div class="uui-padding-vertical-xhuge-20">
                      <div class="uui-max-width-large-9">
                        <h3>{{$t('urology.medical-team')}}</h3>
                        <div class="uui-space-xsmall-10"></div>
                      </div>
                      <div class="uui-team07_component-2">
                        <div class="uui-team07_list-wrapper-2">
                          <div class="w-layout-grid item_medico">
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/Dr.VictorManuel.webp"
                                  loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Víctor Manuel Flores Carrillox</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('urology.professional-license')}} 5757226</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('urology.specialty-license')}} 8475888</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>{{$t('urology.urology')}}<br></strong>{{$t('urology.dr1-description')}}
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <appointment-component></appointment-component>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</header></template>

<style scoped></style>
