<script >

export default{
  name:"OurServices"
}
</script>

<template>
<!--  <div class="uui-blogpost05_contributers-2">-->
<!--    <div class="uui-blogpost05_sidebar-heading-2">Conoce Nuestros Servicios</div>-->
<!--    <div class="uui-blogpost05_author-wrapper-2">-->
<!--      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/Farmacia-Icon-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>-->
<!--      <div>-->
<!--        <router-link to="/services-laboratory"  :class="{ 'w&#45;&#45;current': $route.path === '/services-laboratory' }" class="link-block-6 w-inline-block">-->
<!--          <div class="uui-blogpost05_author-heading-2">Laboratorio / Servicio de Transfusión Hospitalaria</div>-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="uui-blogpost05_author-wrapper-2">-->
<!--      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/Hemodialisis-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>-->
<!--      <div>-->
<!--        <router-link to="/services-hemodialysis"  :class="{ 'w&#45;&#45;current': $route.path === '/services-hemodialysis' }" class="link-block-8 w-inline-block">-->
<!--          <div class="uui-blogpost05_author-heading-2">Hemodiálisis</div>-->
<!--        </router-link>-->

<!--      </div>-->
<!--    </div>-->
<!--    <div class="uui-blogpost05_author-wrapper-2">-->
<!--      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/Hospitalización-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>-->
<!--      <div>-->
<!--        <router-link to="/services-hospitalization"  :class="{ 'w&#45;&#45;current': $route.path === '/services-hospitalization' }" class="link-block-8 w-inline-block">-->
<!--          <div class="uui-blogpost05_author-heading-2">Hospitalización</div>-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="uui-blogpost05_author-wrapper-2">-->
<!--      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/Radiología-e-Imagen-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>-->
<!--      <div>-->
<!--        <router-link to="/services-radiology-imaging"  :class="{ 'w&#45;&#45;current': $route.path === '/services-radiology-imaging' }" class="link-block-9 w-inline-block">-->
<!--          <div class="uui-blogpost05_author-heading-2">Radiología e Imagen</div>-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="uui-blogpost05_author-wrapper-2">-->
<!--      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/aseguradoras-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>-->
<!--      <div>-->
<!--        <router-link to="/insurers"  :class="{ 'w&#45;&#45;current': $route.path === '/insurers' }" class="link-block-9 w-inline-block">-->
<!--          <div class="uui-blogpost05_author-heading-2">Aseguradoras</div>-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="uui-blogpost05_author-wrapper-2">-->
<!--      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1.jpg" loading="lazy" sizes="(max-width: 1439px) 48px, (max-width: 1919px) 3vw, 48px" srcset="../../public/images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1-p-500.jpg 500w, ../../public/images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1-p-800.jpg 800w, ../../public/images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1-p-1080.jpg 1080w, ../../public/images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1.jpg 1604w" alt="" class="uui-blogpost05_author-image-2"></div>-->
<!--      <div>-->
<!--        <router-link to="/services-health-support" :class="{ 'w&#45;&#45;current': $route.path === '/services-health-support' }" class="link-block-9 w-inline-block">-->
<!--          <div class="uui-blogpost05_author-heading-2">Planes de Apoyo en Salud(PAS)</div>-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="uui-blogpost05_contributers-2">
    <div class="uui-blogpost05_sidebar-heading-2">{{$t('home.meet-our-services')}}</div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1.jpg" loading="lazy" sizes="(max-width: 1439px) 48px, (max-width: 1919px) 3vw, 48px" srcset="../../public/images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1-p-500.jpg 500w, ../../public/images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1-p-800.jpg 800w, ../../public/images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1-p-1080.jpg 1080w, ../../public/images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1.jpg 1604w" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/services-health-support" :class="{ 'w--current': $route.path === '/services-health-support' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.pas')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/Farmacia-Icon-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link  to="/services-pharmacy" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.pharmacy-title')}} {{$t('home.24-hours')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/ICONO-LABORATORIO.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/services-laboratory"  :class="{ 'w--current': $route.path === '/services-transfusion' }" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.laboratory-and-hospital-transfusion-title')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/transfusion-hospitalaria-icon3.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/services-transfusion"  :class="{ 'w--current': $route.path === '/services-laboratory' }" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('HospitalTransfusionPage.title')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/Hemodialisis-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/services-hemodialysis"  :class="{ 'w--current': $route.path === '/services-hemodialysis' }" class="link-block-8 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.hemodialysis-title')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/Hospitalizacion-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/services-hospitalization"  :class="{ 'w--current': $route.path === '/services-hospitalization' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.hospitalization-title')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/Radiologia-e-Imagen-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/services-radiology-imaging"  :class="{ 'w--current': $route.path === '/services-radiology-imaging' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.radiology-and-imaging-title')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/aseguradoras-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/insurers"  :class="{ 'w--current': $route.path === '/insurers' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.insurers-title')}}</div>
        </router-link>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>
