<script >
import AddressComponent from "@/components/AddressComponent.vue";
import OurServices from "@/components/OurServices.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import AppointmentComponent from "@/components/AppointmentComponent.vue";

export default {
  name: "RadiologyImagingPage",
  components: { AppointmentComponent, SideContactComponent, OurServices, AddressComponent },
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
    };
  },
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_6be369f5-ab6d-a41d-1b86-2f82480c48d4-224c4582" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="6be369f5-ab6d-a41d-1b86-2f82480c48d7" to="/"
                             class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699"
                                                                                                                                                               stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </div>
                  <div>{{$t('RadiologyImagingPage.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('RadiologyImagingPage.title')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('RadiologyImagingPage.subtitle')}}</div>
              <address-component></address-component>
            </div>
            <div class="uui-blogpost05_image-wrapper-2">
              <img srcset="../../../public/images/radiologia-e-imagen-p-500.jpg 500w, ../../../public/images/radiologia-e-imagen-p-800.jpg 800w, ../../../public/images/radiologia-e-imagen-p-1080.jpg 1080w, ../../../public/images/radiologia-e-imagen-p-1600.jpg 1600w"  loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 620px, 40vw"
                   src="../../../public/images/radiologia-e-imagen-p.jpg" alt="" class="uui-blogpost03_image-2">
            </div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-4">
              <div class="uui-blogpost05_content-left-2">
                <our-services></our-services>
                <side-contact-component></side-contact-component>
              </div>
            </div>
            <div id="w-node-_724bdfc4-1f49-a8b7-d32e-922c168f3216-9186f2f7" class="uui-blogpost05_content-4">
              <div class="uui-text-rich-text-7 w-richtext">
                <h4>{{$t('RadiologyImagingPage.article-1')}}</h4>
                <p class="paragraph">{{$t('RadiologyImagingPage.article-1-content')}}
                  <br>{{$t('RadiologyImagingPage.article-1-content2')}}</p>
                <figure style="max-width:1680pxpx" class="w-richtext-align-fullwidth w-richtext-figure-type-image"><div><img src="../../../public/images/MASTOGRAFO-p-1080.jpg" loading="lazy" alt=""></div>
                </figure>
              </div>
              <section class="uui-section_faq01-9">
                <div class="uui-page-padding-56">
                  <div class="uui-container-large-43">
                    <div class="uui-padding-vertical-xhuge-57">
                      <div class="uui-faq01_component-10">
                        <div class="uui-faq01_list-11">

                          <div class="uui-faq01_accordion-9">
                            <div @click="show1 = !show1" class="uui-faq01_question-9">
                              <div class="uui-faq01_heading-10">{{$t('RadiologyImagingPage.image')}}</div>
                              <div class="uui-faq01_icon-wrapper-9">
                                <div class="accordion-icon_component-10">
                                  <div class="accordion-icon_horizontal-line-10"></div>
                                  <div v-show="!show1" class="accordion-icon_vertical-line-10"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show1" class="uui-faq01_answer-9 animate__animated animate__fadeIn">
                              <div class="uui-text-size-medium-41">{{$t('RadiologyImagingPage.image-p1')}}</div>
                              <div class="uui-space-medium-16"></div>
                              <ul role="list">
                                <li>{{$t('RadiologyImagingPage.image-li1')}}</li> <li>{{$t('RadiologyImagingPage.image-li2')}}</li>
                                <li>{{$t('RadiologyImagingPage.image-li3')}}</li> <li>{{$t('RadiologyImagingPage.image-li4')}}</li>
                                <li>{{$t('RadiologyImagingPage.image-li5')}}</li> <li>{{$t('RadiologyImagingPage.image-li6')}}</li>
                                <li>{{$t('RadiologyImagingPage.image-li7')}}</li> <li>{{$t('RadiologyImagingPage.image-li8')}}</li>
                                <li>{{$t('RadiologyImagingPage.image-li9')}}</li> <li>{{$t('RadiologyImagingPage.image-li10')}}</li>
                                <li>{{$t('RadiologyImagingPage.image-li11')}}</li><li>{{$t('RadiologyImagingPage.image-li12')}}</li>
                              </ul>
                            </div>
                          </div>

                          <div class="uui-faq01_accordion-9">
                            <div @click="show2 = !show2" class="uui-faq01_question-9">
                              <div class="uui-faq01_heading-10">{{$t('RadiologyImagingPage.mastography')}}</div>
                              <div class="uui-faq01_icon-wrapper-9">
                                <div class="accordion-icon_component-10">
                                  <div class="accordion-icon_horizontal-line-10"></div>
                                  <div v-show="!show2" class="accordion-icon_vertical-line-10"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show2" class="uui-faq01_answer-9 animate__animated animate__fadeIn">
                              <div class="uui-text-size-medium-41">{{$t('RadiologyImagingPage.mastography-p1')}}</div>
                              <div class="uui-space-medium-16"></div>
                              <ul role="list">
                                <li>{{$t('RadiologyImagingPage.mastography-li1')}}</li>
                                <li>{{$t('RadiologyImagingPage.mastography-li2')}}</li>
                              </ul>
                              <p style="text-align: justify;">{{$t('RadiologyImagingPage.mastography-p2')}}</p>
                            </div>
                          </div>

                          <div class="uui-faq01_accordion-9">
                            <div @click="show3 = !show3" class="uui-faq01_question-9">
                              <div class="uui-faq01_heading-10"><strong class="bold-text-21">{{$t('RadiologyImagingPage.bone-scan')}}</strong></div>
                              <div class="uui-faq01_icon-wrapper-9">
                                <div class="accordion-icon_component-10">
                                  <div class="accordion-icon_horizontal-line-10"></div>
                                  <div v-show="!show3" class="accordion-icon_vertical-line-10"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show3" class="uui-faq01_answer-9 animate__animated animate__fadeIn">
                              <div class="uui-text-size-medium-41" style="text-align: justify;"><p>{{$t('RadiologyImagingPage.bone-scan-p1')}}</p></div>
                              <div class="uui-space-medium-16"></div>
                            </div>
                          </div>

                          <div class="uui-faq01_accordion-9">
                            <div @click="show4 = !show4" class="uui-faq01_question-9">
                              <div class="uui-faq01_heading-10"><strong class="bold-text-21">{{$t('RadiologyImagingPage.tomography')}}</strong></div>
                              <div class="uui-faq01_icon-wrapper-9">
                                <div class="accordion-icon_component-10">
                                  <div class="accordion-icon_horizontal-line-10"></div>
                                  <div v-show="!show4" class="accordion-icon_vertical-line-10"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show4" class="uui-faq01_answer-9 animate__animated animate__fadeIn">
                              <div class="uui-text-size-medium-41" style="text-align: justify;"><p>{{$t('RadiologyImagingPage.tomography-p1')}}</p></div>
                              <div class="uui-space-medium-16"></div>
                            </div>
                          </div>
                          <div class="uui-faq01_accordion-9">
                            <div @click="show5 = !show5" class="uui-faq01_question-9">
                              <div class="uui-faq01_heading-10"><strong class="bold-text-21">{{$t('RadiologyImagingPage.ultrasound')}}</strong></div>
                              <div class="uui-faq01_icon-wrapper-9">
                                <div class="accordion-icon_component-10">
                                  <div class="accordion-icon_horizontal-line-10"></div>
                                  <div v-show="!show5" class="accordion-icon_vertical-line-10"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show5" class="uui-faq01_answer-9 animate__animated animate__fadeIn">
                              <div class="uui-text-size-medium-41" style="text-align: justify;"><p>{{$t('RadiologyImagingPage.ultrasound-p1')}}</p>
                                <div class="uui-space-medium-16"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div class="f-section-large-2">
                <div class="f-container-regular-4">
                  <div class="w-layout-grid f-gallery-two-column">
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec0-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-tall"><img src="../../../public/images/radiologia-e-imagen-rayos-x.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/radiologia-e-imagen-rayos-x-500.jpg 500w, ../../../public/images/radiologia-e-imagen-rayos-x-800.jpg 800w, ../../../public/images/radiologia-e-imagen-rayos-x-1080.jpg 1080w, ../../../public/images/radiologia-e-imagen-rayos-x-1600.jpg 1600w, ../../../public/images/radiologia-e-imagen-rayos-x-2000.jpg 2000w, ../../../public/images/radiologia-e-imagen-rayos-x-2600.jpg 2600w, ../../../public/images/radiologia-e-imagen-rayos-x-3200.jpg 3200w" alt="" class="f-image-cover"></div>
                    </a>
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec3-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-s"><img src="../../../public/images/TOMOGRAFIA-4.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/TOMOGRAFIA-4-p-500.jpg 500w, ../../../public/images/TOMOGRAFIA-4-p-800.jpg 800w, ../../../public/images/TOMOGRAFIA-4-p-1080.jpg 1080w, ../../../public/images/TOMOGRAFIA-4-p-1600.jpg 1600w, ../../../public/images/TOMOGRAFIA-4-p-2000.jpg 2000w, ../../../public/images/TOMOGRAFIA-4-p-2600.jpg 2600w, ../../../public/images/TOMOGRAFIA-4-p-3200.jpg 3200w" alt="" class="f-image-cover"></div>
                    </a>
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec6-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-s"><img src="../../../public/images/RAYOS-X-1.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/RAYOS-X-1-p-500.jpg 500w, ../../../public/images/RAYOS-X-1-p-800.jpg 800w, ../../../public/images/RAYOS-X-1-p-1080.jpg 1080w, ../../../public/images/RAYOS-X-1-p-1600.jpg 1600w, ../../../public/images/RAYOS-X-1-p-2000.jpg 2000w, ../../../public/images/RAYOS-X-1-p-2600.jpg 2600w, ../../../public/images/RAYOS-X-1-p-3200.jpg 3200w" alt="" class="f-image-cover"></div>
                    </a>
                  </div>
                </div>
              </div>
              <appointment-component></appointment-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<style scoped></style>
