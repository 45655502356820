<script >
import {app} from "@/main";
import 'animate.css';
import OurClinicsComponent from "@/components/OurClinicsComponent.vue";
import AddressComponent from "@/components/AddressComponent.vue";
import AppointmentComponent from "@/components/AppointmentComponent.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import ClinicsMision from "@/components/ClinicsMision.vue";
import ClinicsVision from "@/components/ClinicsVision.vue";

export default{
  name:"OphthalmologyClinicPage",
  components: {
    ClinicsVision,
    ClinicsMision,
    SideContactComponent,
    AppointmentComponent,
    AddressComponent,
    OurClinicsComponent
  },
  // components: {AppointmentComponent, AddressComponent, SideContactComponent, OurClinicsComponent},
  data() {
    return {
      services:[],
      showDysplasiaClinic:false,
      showBreastClinic:false,
      showMaternalFetalClinic:false,
      showReproductiveBiology:false,
      show1:false,
      show2:false,
      show3:false,
      locale:'',
      specialties:[1],
      specialists:[],
      // showAnswer: false,
    };
  },

  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });

  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods:{
    loadData(locale){
      this.specialists = require('../../data/specialists.json');
      this.specialists = this.specialists.map(specialist => {
        const translateInformation= specialist.information[locale];


        let specialties = specialist.specialties.map(specialty=>{
          const name= specialty.name[locale];
          return {
            ...specialty,
            name:name
          }
        });

        return {
          ...specialist,
          information: translateInformation,
          specialties: specialties
        };
      });
      this.specialists = this.specialists.filter(s =>
          s.specialties.some(specialty => this.specialties.includes(specialty.id))
      );
    },
    stringArray(string) {
      return string.split(',');
    },
  }
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938c14-6b5c1cae" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="7cc4bcd4-67e6-ca4f-d404-7c4e49938c17" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('home.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('home.ophthalmology-clinic')}} 24/7</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('ophthalmology-clinic.ophthalmology-clinic-description')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_2e6aefb2-b0a4-f6c6-506b-ee032e38dc14-6df8db5e" class="uui-blogpost05_image-wrapper-7">
              <img src="../../../public/images/CLINICAS-06.jpg" loading="lazy" alt="" class="uui-blogpost03_image-2 diabe" style="object-fit: contain;">
            </div>
            <!--<div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938c21-6b5c1cae" class="uui-blogpost05_image-wrapper-7"><img srcset="../../../public/images/Copia-de-GINECOLOGOS-HAM2-p-500.jpeg 500w, ../../../public/images/Copia-de-GINECOLOGOS-HAM2-p-800.jpeg 800w, ../../../public/images/Copia-de-GINECOLOGOS-HAM2-p-1080.jpeg 1080w, ../../../public/images/Copia-de-GINECOLOGOS-HAM2-p-1600.jpeg 1600w, ../../../public/images/Copia-de-GINECOLOGOS-HAM2-p-2000.jpeg 2000w, ../../../public/images/Copia-de-GINECOLOGOS-HAM2-p-2600.jpeg 2600w, ../../../public/images/Copia-de-GINECOLOGOS-HAM2-p-3200.jpeg 3200w, ../../../public/images/GINECOLOGOS-HAM2.jpg 6960w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 46vw, 768px" src="../../../public/images/GINECOLOGOS-HAM2.jpg" alt="" class="uui-blogpost03_image-2"></div>-->
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">
              <our-clinics-component></our-clinics-component>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938c25-6b5c1cae" class="uui-blogpost05_content-2">
              <div class="uui-text-rich-text-9 w-richtext">
                <h4>{{$t('home.ophthalmology-clinic')}} 24/7</h4><p> {{$t('ophthalmology-clinic.ophthalmology-clinic-p1')}}<strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p2')}}</strong>, {{$t('ophthalmology-clinic.ophthalmology-clinic-p3')}} <strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p4')}}</strong></p>
              </div>
              <div class="uui-text-rich-text-9 w-richtext">                <figure style="max-width:1680pxpx" class="w-richtext-align-fullwidth w-richtext-figure-type-image">                  <div><img src="../../../public/images/oftalmologia.jpeg" loading="lazy" alt=""></div>                </figure>
              </div>
              <div class="div-block-20">
                <clinics-mision mision="Proporcionar atención oftalmológica excepcional en un ambiente amable y acogedor. Nos esforzamos por ofrecer a cada paciente una atención personalizada, utilizando la última tecnología y los tratamientos más efectivos para mejorar la calidad de vida de nuestros pacientes, protegiendo y mejorando su visión."></clinics-mision>
                <clinics-vision vision="Ser reconocidos como un líder en la atención oftalmológica, donde cada paciente se siente valorado y cuidado. Aspiramos a establecer nuevos estándares en la atención al paciente, a través de nuestro compromiso con la excelencia clínica, la innovación y la educación del paciente."></clinics-vision>
              </div>
              <h3>{{$t('ophthalmology-clinic.ophthalmology-clinic-p5')}} <br>{{$t('ophthalmology-clinic.ophthalmology-clinic-p6')}}</h3>
              <div class="animate__animated  animate__fadeInLeft">                
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">                  
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p7')}}</strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p8')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">                  
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>                  
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p9')}} </strong>: {{$t('ophthalmology-clinic.ophthalmology-clinic-p10')}}<br></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>                  
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p11')}}</strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p12')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p13')}}</strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p14')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p15')}}</strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p16')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p17')}}</strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p18')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p19')}}</strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p20')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p21')}}</strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p22')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p23')}}</strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p24')}}</div>
                </a>                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p25')}} </strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p26')}}</div>
                </a>                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p27')}}</strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p28')}}</div>
                </a>                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p29')}}</strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p30')}}</div>
                </a>
                <h4>{{$t('ophthalmology-clinic.ophthalmology-clinic-p31')}}</h4>
              </div>  

                      <div class="uui-faq01_component-7">
                        <div class="uui-faq01_list-8">
                          <div class="uui-faq01_accordion-6">
                            <div @click="show1 = !show1"  class="uui-faq01_question-6">
                              <div class="uui-faq01_heading-6">{{$t('ophthalmology-clinic.ophthalmology-clinic-p32')}}<br></div>
                              <div class="uui-faq01_icon-wrapper-6">
                                <div class="accordion-icon_component-7">
                                  <div class="accordion-icon_horizontal-line-7"></div>
                                  <div v-show="!show1" class="accordion-icon_vertical-line-7"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show1" class="uui-faq01_answer-6 animate__animated animate__fadeIn">
                              <div class="uui-text-size-large-35"><strong></strong></div>
                              <p>{{$t('ophthalmology-clinic.ophthalmology-clinic-p33')}}</p>
                            </div>
                          </div>
                          <div class="uui-faq01_accordion-6">
                            <div @click="show2 = !show2" class="uui-faq01_question-6">
                              <div class="uui-faq01_heading-6">{{$t('ophthalmology-clinic.ophthalmology-clinic-p34')}}</div>
                              <div class="uui-faq01_icon-wrapper-6">
                                <div class="accordion-icon_component-7">
                                  <div class="accordion-icon_horizontal-line-7"></div>
                                  <div   v-show="!show2" class="accordion-icon_vertical-line-7"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show2" class="uui-faq01_answer-6 animate__animated animate__fadeIn">
                              <div class="uui-text-size-medium-34">{{$t('ophthalmology-clinic.ophthalmology-clinic-p35')}}</div>
                              <ul role="list">
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p36')}}</strong></li>
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p37')}}</strong></li>
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p38')}}</strong></li>
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p39')}}</strong></li>
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p40')}}</strong></li>
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p41')}}</strong></li>
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p42')}}</strong></li>
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p43')}}</strong></li>
                              </ul>
                              <p>{{$t('ophthalmology-clinic.ophthalmology-clinic-p44')}}</p>
                              <div class="uui-space-medium-14"></div>
                            </div>
                          </div>    
                          <div class="uui-faq01_accordion-6">
                            <div @click="show3 = !show3" class="uui-faq01_question-6">
                              <div class="uui-faq01_heading-6"> {{$t('ophthalmology-clinic.ophthalmology-clinic-p45')}}</div>
                              <div class="uui-faq01_icon-wrapper-6">
                                <div class="accordion-icon_component-7">
                                  <div class="accordion-icon_horizontal-line-7"></div>
                                  <div   v-show="!show3" class="accordion-icon_vertical-line-7"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show3" class="uui-faq01_answer-6 animate__animated animate__fadeIn">
                              <div class="uui-text-size-medium-34">{{$t('ophthalmology-clinic.ophthalmology-clinic-p46')}}</div>
                              <ul role="list">
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p47')}}</strong> {{$t('ophthalmology-clinic.ophthalmology-clinic-p48')}}</li>
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p49')}}</strong> {{$t('ophthalmology-clinic.ophthalmology-clinic-p50')}}</li>
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p51')}}</strong> {{$t('ophthalmology-clinic.ophthalmology-clinic-p52')}}</li>
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p53')}}</strong> {{$t('ophthalmology-clinic.ophthalmology-clinic-p54')}}</li>
                                <li><strong>{{$t('ophthalmology-clinic.ophthalmology-clinic-p55')}}</strong> {{$t('ophthalmology-clinic.ophthalmology-clinic-p56')}}</li>
                              </ul>
                              <p>{{$t('ophthalmology-clinic.ophthalmology-clinic-p57')}}</p>
                              <div class="uui-space-medium-14"></div>
                            </div>
                          </div>                       
                        </div>
                      </div>

              <section class="team_seccion">
                <div class="uui-page-padding-18">
                  <div class="uui-container-large-14">
                    <div class="uui-padding-vertical-xhuge-20">
                      <div class="uui-max-width-large-9">
                        <h2 class="uui-heading-medium-11" style="margin-top: 2rem;margin-bottom: 2rem; text-align: center;color: var(--hc--colors--green--600);">{{$t('home.emergencies-aid')}}</h2>
                        <h2 class="uui-heading-medium-11">{{$t('home.our-team')}}</h2>
                        <div class="uui-space-xsmall-10"></div>
                      </div>
                      <div class="uui-team07_component-2">
                        <div class="uui-team07_list-wrapper-2">
                          <div class="w-layout-grid item_medico">
                            <div class="rich-text-block w-richtext">
                              <h4 class="heading-18">{{$t('ophthalmology-clinic.ophthalmologists')}}</h4>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA.jpg"                                  loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Alejandra Mendoza Ochoa</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. 6739532</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. 9085076</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Oftalmóloga.<br></strong>Egresada del Instituto Conde de Valenciana en Cd. de México<br></div>
                                <div class="uui-text-size-medium-15"><strong>Subespecialidad en cirugía de catarata <br></strong>Egresada del Instituto Nacional de Rehabilitación en Ciudad de México.<br></div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA.jpg"                                  loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Alberto Abdala Figueroa</div>
<!--                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. </div>-->
<!--                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. </div>-->
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Oftalmólogo.<br></strong>Egresada del Instituto Conde de Valenciana en Cd. de México<br></div>
                                <div class="uui-text-size-medium-15"><strong>Subespecialidad en Oculoplastica <br></strong>Egresado del instituto Fellow de Orbita y vía Lagrimal egresado de la Universidad de Bristish Columbia Canadá.<br></div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/dr.javier-abacuc-heras-bautista.jpg"                                  loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Javier Abacuc Heras Bautista</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. 6464007</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. 8226811</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Oftalmólogo.<br></strong>Egresada del Instituto Conde de Valenciana en Cd. de México<br></div>
                                <div class="uui-text-size-medium-15"><strong>Subespecialidad en cirugía de catarata, cornea, cirugía refractiva, retina y vitreo<br></strong>Del hospital Militar en la Ciudad de México.<br></div>
                              </div>
                            </div>
                            <div class="rich-text-block w-richtext">
                              <h4 class="heading-18">{{$t('ophthalmology-clinic.optometrist')}}</h4>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/doc.png"                                  loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Lic. Alan Elizondo Ojeda</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. </div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. </div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Optometrista.<br></strong>Egresado de la Facultad de estudios superiores de la UNAM<br></div>
                                
                              </div>
                            </div>

                          </div>
                        </div>
                        <appointment-component></appointment-component>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<style scoped>
</style>
