<script>

//import ContactUsComponent from "@/components/ContactUsComponent.vue";


import ContactUsComponent from "@/components/ContactUsComponent.vue";

export default {
  name: "ContactPage",
  components: {ContactUsComponent},
  //components: {ContactUsComponent},
  data() {
    return {
      nombre: '',
      email: ''
    };
  },
  methods: {
    enviarFormulario(event) {
      event.preventDefault();
      // Aquí puedes realizar la lógica necesaria con los datos del formulario
      console.log("enviar fomulario..");
    }
  }
}
</script>

<template>
  <section class="rl_section_contact6">
    <div class="rl-padding-global">
      <div class="rl-container-large">
        <div class="rl-padding-section-large">
          <div class="w-layout-grid rl_content6_component">
            <div class="rl_contact6_content">
              <div class="rl_contact6_spacing-block-1"></div>
              <h2 class="rl-heading-style-h2">{{$t('home.contact-us')}}</h2>
              <div class="rl_contact6_spacing-block-2"></div>
              <p class="rl-text-style-medium">{{$t('contact.contact-us-description')}}</p>
              <div class="rl_contact6_spacing-block-2"></div>
              <div class="rl-text-style-subheading">{{$t('contact.contact-us-question')}}</div>
              <div class="rl_contact6_spacing-block-3"></div>
              <div class="rl_contact6_contact-list">
                <div id="w-node-_9a760025-93a6-1598-0cf3-6a8af1266403-a2d16809" class="rl_contact6_item">
                  <div class="rl_contact6_icon-wrapper">
                    <div class="rl_contact6_icon w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6M22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6M22 6L12 13L2 6" stroke="#20816F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  </div>
                  <div class="rl-text-style-regular">contacto@hospitalangeldelmar.com.mx</div>
                </div>
                <div id="w-node-_9a760025-93a6-1598-0cf3-6a8af1266408-a2d16809" class="rl_contact6_item">
                  <div class="rl_contact6_icon-wrapper">
                    <div class="rl_contact6_icon w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z" stroke="#20816F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="#20816F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  </div>
                  <div class="rl-text-style-regular">Sexta Norte esquina Primera Oriente S/N, Sector Reforma, Puerto Escondido, Oax, C.P. 71980</div>
                </div>
                <div id="w-node-_9a760025-93a6-1598-0cf3-6a8af126640d-a2d16809" class="rl_contact6_item">
                  <div class="rl_contact6_icon-wrapper">
                    <div class="rl_contact6_icon w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 16.92V19.92C22.0011 20.1985 21.9441 20.4741 21.8325 20.7293C21.7209 20.9845 21.5573 21.2136 21.3521 21.4018C21.1468 21.5901 20.9046 21.7335 20.6407 21.8227C20.3769 21.9119 20.0974 21.945 19.82 21.92C16.7428 21.5856 13.787 20.5341 11.19 18.85C8.77382 17.3146 6.72533 15.2661 5.18999 12.85C3.49997 10.2412 2.44824 7.27097 2.11999 4.17997C2.095 3.90344 2.12787 3.62474 2.21649 3.3616C2.30512 3.09846 2.44756 2.85666 2.63476 2.6516C2.82196 2.44653 3.0498 2.28268 3.30379 2.1705C3.55777 2.05831 3.83233 2.00024 4.10999 1.99997H7.10999C7.5953 1.9952 8.06579 2.16705 8.43376 2.48351C8.80173 2.79996 9.04207 3.23942 9.10999 3.71997C9.23662 4.68004 9.47144 5.6227 9.80999 6.52997C9.94454 6.8879 9.97366 7.27689 9.8939 7.65086C9.81415 8.02482 9.62886 8.36809 9.35999 8.63998L8.08999 9.90997C9.51355 12.4135 11.5864 14.4864 14.09 15.91L15.36 14.64C15.6319 14.3711 15.9751 14.1858 16.3491 14.1061C16.7231 14.0263 17.1121 14.0554 17.47 14.19C18.3773 14.5285 19.3199 14.7634 20.28 14.89C20.7658 14.9585 21.2094 15.2032 21.5265 15.5775C21.8437 15.9518 22.0122 16.4296 22 16.92Z" stroke="#20816F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  </div>
                  <div class="rl-text-style-regular">954 104 2270</div>
                </div>
              </div>
            </div>

            <contact-us-component></contact-us-component>
          </div>
          <div class="container-5">
            <h2 class="centered-heading">{{$t('home.schedule-appointment')}}</h2>
            <p class="pricing-description">{{$t('contact.contact-description-1')}} <strong>{{$t('contact.emergencies-service')}}</strong> {{$t('contact.contact-description-2')}} <strong>{{$t('home.24-hours')}}</strong>{{$t('contact.contact-description-3')}}  <strong>{{$t('contact.contact-description-4')}}</strong> {{$t('contact.contact-description-5')}} <strong>{{$t('contact.contact-description-6')}}</strong><br></p>
            <div class="div-block-29">
              <a href="https://angeldelmar.zonasana.clinic/login?v=2.26.0" target="_blank" class="rl_navbar3_button w-button">{{$t('home.schedule-appointment')}}</a>
            </div>
            <div class="pricing-grid">
              <div id="w-node-bd80bb90-b913-2731-5697-d85d261a6413-a2d16809" class="pricing-card-three"><img src="images/email-2.png" loading="lazy" alt="" class="pricing-image">
                <h3 class="heading-12">{{$t('contact.contact-email')}}</h3>
                <p class="pricing-card-text">
                  <a href="mailto:contacto@hospitalangeldelmar.com.mx" class="link-9">contacto@hospitalangeldelmar.com.mx</a>
                </p>
              </div>
              <div id="w-node-bd80bb90-b913-2731-5697-d85d261a641a-a2d16809" class="pricing-card-three"><img src="images/phone-2.png" loading="lazy" alt="" class="pricing-image">
                <div class="div-block-25">
                  <div class="uui-contact12_item"><img src="images/Vector.svg" loading="lazy" alt="" class="image-23">
                    <div class="uui-space-small-23"></div>
                    <div class="uui-space-xxsmall-20"></div>
                    <div class="uui-text-size-medium-36"><strong class="bold-text-15">{{$t('home.general-from-desk')}}</strong></div>
                    <div class="uui-space-small-23"></div>
                    <a href="#" class="uui-text-style-link-02-5"><strong>954 177 0692</strong></a>
                  </div>
                  <div class="uui-contact12_item"><img src="images/Logo-Bg-Removed-HAM.png" loading="lazy" alt="" class="image-21">
                    <div class="uui-space-small-23"></div>
                    <div class="uui-space-xxsmall-20"></div>
                    <div class="uui-text-size-medium-36">{{$t('home.emergencies')}}</div>
                    <div class="uui-space-small-23"></div>
                    <a href="#" class="uui-text-style-link-02-5">954 103 4110</a>
                  </div>
                </div>
              </div>
              <div id="w-node-bd80bb90-b913-2731-5697-d85d261a6433-a2d16809" class="pricing-card-three"><img src="images/location-1.png" loading="lazy" alt="" class="pricing-image">
                <h3 class="heading-14">{{$t('home.location')}}</h3>
                <p class="pricing-card-text">
                  <a href="https://maps.app.goo.gl/6XsoQHej9DWHjzuR6" target="_blank" class="link-7">Sexta Norte esquina Primera Oriente S/N, Sector Reforma, Puerto Escondido, Oax, C.P. 71980</a>
                </p>
              </div>
            </div>
          </div>
          <section class="features-table">
            <div class="container-7">
              <h2 class="centered-heading-2">{{$t('home.directory')}}</h2>
              <div class="comparison-table">
                <div class="comparison-row-main">
                  <h3 class="comparison-title">{{$t('contact.contact-main-phone')}}</h3>
                  <h3 class="comparison-title">{{$t('contact.contact-area')}}</h3>
                  <h3 class="comparison-title">{{$t('contact.contact-extension')}}</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('contact.main-from-desk')}} ({{$t('home.gynecology')}})</h3>
                  <h3 class="comparison-title">100</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('contact.medic-appointment')}} ({{$t('home.specialists')}})</h3>
                  <h3 class="comparison-title">101</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('contact.main-from-desk')}} (Aux.)</h3>
                  <h3 class="comparison-title">102</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('home.emergencies')}} - {{$t('contact.admission')}}</h3>
                  <h3 class="comparison-title">103</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('home.emergencies')}} - {{$t('contact.admission')}} (Aux.)</h3>
                  <h3 class="comparison-title">104</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('contact.imagenology')}} ({{$t('contact.ultra')}})</h3>
                  <h3 class="comparison-title">105</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('home.laboratory')}}</h3>
                  <h3 class="comparison-title">106</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('home.pharmacy-title')}}</h3>
                  <h3 class="comparison-title">107</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('contact.administration-accountability')}}</h3>
                  <h3 class="comparison-title">108</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('contact.quality-administration')}}</h3>
                  <h3 class="comparison-title">113</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('contact.desk-odontology')}}</h3>
                  <h3 class="comparison-title">200</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('contact.desk-pediatric')}}</h3>
                  <h3 class="comparison-title">203</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('contact.desk-digestive-disease')}}</h3>
                  <h3 class="comparison-title">206</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('contact.desk-ophthalmology')}}</h3>
                  <h3 class="comparison-title">207</h3>
                </div>
                <div class="comparison-row-main">
                  <h3 class="comparison-title">954 104 2270</h3>
                  <h3 class="comparison-title">{{$t('home.hemodialysis-title')}}</h3>
                  <h3 class="comparison-title">302</h3>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
