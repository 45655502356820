<script >

// import ContactComponent from "@/components/ContactComponent.vue";
// import OurClinicsComponent from "@/components/OurClinicsComponent.vue";
// import AddressComponent from "@/components/AddressComponent.vue";
// import AppointmentComponent from "@/components/AppointmentComponent.vue";

import OurClinicsComponent from "@/components/OurClinicsComponent.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import AddressComponent from "@/components/AddressComponent.vue";
import 'animate.css';
import AppointmentComponent from "@/components/AppointmentComponent.vue";
import {app} from "@/main";
import ClinicsMision from "@/components/ClinicsMision.vue";
import ClinicsVision from "@/components/ClinicsVision.vue";

export default{
  name:"PediatricClinicPage",
  components: {
    ClinicsVision,
    ClinicsMision,
    AppointmentComponent, AddressComponent, SideContactComponent, OurClinicsComponent},
  data() {
    return {
      locale:'',
      specialties:[4,36,11,37,38,35,39],
      specialists:[],
      data:[]
    }
  },
  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });

  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods:{
    loadData(locale){
      let specialties = require('../../data/specialties.json');

      specialties = specialties.filter(s =>
          this.specialties.includes(s.value)
      );

      specialties= specialties.map(specialty => {
        const translateText = specialty.text[locale];
        return {
          ...specialty,
          text: translateText,
        };
      });

      this.specialists = require('../../data/specialists.json');
      this.specialists = this.specialists.filter(s =>
          s.specialties.some(specialty => this.specialties.includes(specialty.id))
      );
      this.specialists = this.specialists.map(specialist => {
        const translateInformation= specialist.information[locale];
        let specialties = specialist.specialties.map(specialty=>{
          const name= specialty.name[locale];
          return {
            ...specialty,
            name:name
          }
        });

        return {
          ...specialist,
          information: translateInformation,
          specialties:specialties
        };
      });


      this.data=[];
      specialties.forEach(spec=>{
        const specialists = this.specialists.filter(s => {
          return s.specialties.some(specialty => specialty.id === spec.value);
        });
        let item ={}
        item.id =spec.value;
        item.name =spec.text;
        item.specialists = specialists;
        this.data.push(item);
      });
    },
    stringArray(string) {
      return string.split(',');
    },
  }
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_6f37c005-259f-eb43-cf75-e68aa67e4a2a-cc92b027" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="6f37c005-259f-eb43-cf75-e68aa67e4a2d" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('home.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('home.pediatric-clinic-title')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('pediatric.pediatric-clinic-description')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_6f37c005-259f-eb43-cf75-e68aa67e4a37-cc92b027" class="uui-blogpost05_image-wrapper-7"><img srcset="../../../public/images/CLINICAS-04.jpg 500w, ../../../public/images/CLINICAS-04.jpg 800w, ../../../public/images/CLINICAS-04.jpg 1080w, ../../../public/images/CLINICAS-04.jpg 1600w, ../../../public/images/CLINICAS-04.jpg 2000w, ../../../public/images/CLINICAS-04.jpg 2600w, ../../../public/images/CLINICAS-04.jpg 3200w, ../../../public/images/CLINICAS-04.jpg 6000w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 46vw, 768px" src="../../../public/images/CLINICAS-04.jpg" alt="" class="uui-blogpost03_image-2" style="object-fit: contain;"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">
              <our-clinics-component></our-clinics-component>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-b1390d5b-927e-e66d-acc0-5a3882c399b6-cc92b027" class="uui-blogpost05_content-6">
              <div class=" w-richtext">
                <h4>{{$t('home.pediatric-clinic-title')}}</h4>

<!--                <blockquote>Clínica pediátrica al interior del Hospital Ángel del Mar, diseñada especialmente para nuestros pacientes pediátricos con pediatras certificados, cubrimos urgencias las 24 horas.</blockquote>-->
<!--                <p>Constituye el primer nivel de atención médica, por parte de un profesional de la salud capacitado para diagnosticar y manejar diferentes patologías comunes y derivar al paciente al especialista indicado.</p>-->
<!--                <p>Contamos con servicios diseñados especialmente para nuestros pacientes pediátricos; atendidos por pediatras certificados atendiendo urgencias las 24hrs. del día.</p>-->
                <p>{{$t('pediatric.pediatric-clinic-p1')}}</p>
<!--                <figure style="max-width:1680px" class="w-richtext-align-fullwidth w-richtext-figure-type-image">-->
<!--                  <div><img src="../../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg" loading="lazy" alt=""></div>-->
<!--                </figure>-->
                <div class="f-section-large-2">
                  <div class="f-container-regular-4">
                    <div class="w-layout-grid f-gallery-two-column">
                      <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec0-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                        <div class="f-gallery-image-tall"><img src="../../../public/images/clinic-pediatric-image-2.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/clinic-pediatric-image-2.jpg 500w, ../../../public/images/clinic-pediatric-image-2.jpg 800w, ../../../public/images/clinic-pediatric-image-2.jpg 1080w, ../../../public/images/clinic-pediatric-image-2.jpg 1600w, ../../../public/images/clinic-pediatric-image-2.jpg 2000w, ../../../public/images/clinic-pediatric-image-2.jpg 2600w, ../../../public/images/clinic-pediatric-image-2.jpg 3200w, ../../../public/images/clinic-pediatric-image-2.jpg 6000w" alt="" class="f-image-cover"></div>
                      </a>
                      <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec3-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                        <div class="f-gallery-image-s"><img src="../../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg 500w, ../../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg 800w, ../../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg 1080w, ../../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg 1600w, ../../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg 2000w, ../../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg 2600w, ../../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg 3200w, ../../../public/images/Pediatria-Hospital-Angel-del-Mar.jpg 6000w" alt="" class="f-image-cover"></div>
                      </a>
                      <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec6-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                        <div class="f-gallery-image-s"><img src="../../../public/images/clinic-pediatric-image-3.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/clinic-pediatric-image-3.jpg 500w, ../../../public/images/clinic-pediatric-image-3.jpg 800w, ../../../public/images/clinic-pediatric-image-3.jpg 1080w, ../../../public/images/clinic-pediatric-image-3.jpg 1600w, ../../../public/images/clinic-pediatric-image-3.jpg 2000w, ../../../public/images/clinic-pediatric-image-3.jpg 2600w, ../../../public/images/clinic-pediatric-image-3.jpg 3200w, ../../../public/images/clinic-pediatric-image-3.jpg 6960w" alt="" class="f-image-cover"></div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="div-block-20">
                  <clinics-mision mision="Fomentar y proteger la salud integral y el bienestar de los niños, en nuestra región, a través de la asistencia médica otorgada por personal especializado en pediatría."></clinics-mision>
                  <clinics-vision vision="Ser la referencia asistencial de calidad en salud infantil para la región."></clinics-vision>
                </div>
                <h4>{{$t('pediatric.pediatric-clinic-p2')}}</h4>
                <p>{{$t('pediatric.pediatric-clinic-p3')}}</p>
                <h4>{{$t('pediatric.pediatric-clinic-p4')}}</h4>
                <ul role="list">
                  <li>{{$t('pediatric.pediatric-clinic-p5')}}</li>
                  <li>{{$t('pediatric.pediatric-clinic-p6')}}</li>
                </ul>
                <p>{{$t('pediatric.pediatric-clinic-p7')}} </p>
                <ul role="list">
                  <li><strong>{{$t('pediatric.pediatric-clinic-p8')}}</strong> {{$t('pediatric.pediatric-clinic-p9')}}</li>
                  <li><strong>{{$t('pediatric.pediatric-clinic-p10')}} </strong>{{$t('pediatric.pediatric-clinic-p11')}}</li>
                  <li><strong>{{$t('pediatric.pediatric-clinic-p12')}} </strong>{{$t('pediatric.pediatric-clinic-p13')}}</li>
                  <li><strong>{{$t('pediatric.pediatric-clinic-p14')}} </strong>{{$t('pediatric.pediatric-clinic-p15')}}</li>
                  <li><strong>{{$t('pediatric.pediatric-clinic-p16')}} </strong>{{$t('pediatric.pediatric-clinic-p17')}}</li>
                  <li><strong>{{$t('pediatric.pediatric-clinic-p18')}} </strong>{{$t('pediatric.pediatric-clinic-p19')}}</li>
                  <li><strong>{{$t('pediatric.pediatric-clinic-p20')}} </strong>{{$t('pediatric.pediatric-clinic-p21')}}</li>
                  <li><strong>{{$t('pediatric.pediatric-clinic-p22')}} </strong>{{$t('pediatric.pediatric-clinic-p23')}}</li>
                </ul>
              </div>
              <div class="animate__animated  animate__fadeInLeft">
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong class="bold-text-14">{{$t('pediatric.pediatric-clinic-p24')}} </strong>{{$t('pediatric.pediatric-clinic-p25')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p26')}}<br></strong>- {{$t('pediatric.pediatric-clinic-p27')}}<br>- {{$t('pediatric.pediatric-clinic-p28')}}<br>- {{$t('pediatric.pediatric-clinic-p29')}}<br>- {{$t('pediatric.pediatric-clinic-p30')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p31')}}<br>- </strong>{{$t('pediatric.pediatric-clinic-p32')}}<br>- {{$t('pediatric.pediatric-clinic-p33')}}<br>- {{$t('pediatric.pediatric-clinic-p34')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p35')}}</strong></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p36')}}<br>- </strong>{{$t('pediatric.pediatric-clinic-p37')}}<br>- {{$t('pediatric.pediatric-clinic-p38')}}<br>- {{$t('pediatric.pediatric-clinic-p39')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p40')}}</strong></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p41')}}</strong></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p42')}}</strong></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p43')}}
                  </strong></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p44')}}</strong></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p45')}}</strong></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p46')}}</strong></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p47')}}</strong></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p48')}}</strong></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong class="bold-text-14">{{$t('pediatric.pediatric-clinic-p49')}}</strong></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('pediatric.pediatric-clinic-p50')}}<br>- </strong>{{$t('pediatric.pediatric-clinic-p51')}}<br>- {{$t('pediatric.pediatric-clinic-p52')}}<br>- {{$t('pediatric.pediatric-clinic-p52')}}</div>
                </a>
              </div>

              <section class="team_seccion">
                <div class="uui-page-padding-18">
                  <div class="uui-container-large-14">
                    <div class="uui-max-width-large-9">
                      <h2 class="uui-heading-medium-11" style="margin-top: 2rem;margin-bottom: 2rem; text-align: center;color: var(--hc--colors--green--600);">{{$t('home.emergencies-aid')}}</h2>
                      <h2 class="uui-heading-medium-11">{{$t('home.our-team')}}</h2>
                      <div class="uui-space-xsmall-10"></div>
                    </div>

                    <div  class="uui-padding-vertical-xhuge-20">
                      <div  class="uui-team07_component-2">
<!--                        <div v-for="(item, index) in data" :key="index" class="uui-team07_list-wrapper-2">-->

<!--                          <div class="uui-max-width-large-9">-->
<!--                            <h4 >{{item.name}}</h4>-->
<!--                            <div class="uui-space-xsmall-10"></div>-->
<!--                          </div>-->
<!--                          <div class="w-layout-grid item_medico">-->


<!--                            <div  v-for="(item, index) in item.specialists" :key="index" class="card_doctor">-->
<!--                              <div class="uui-team07_image-wrapper-2"><img v-bind:src="item.profile_picture" loading="lazy" sizes="84px"  alt="" class="uui-team07_image-2"></div>-->
<!--                              <div class="uui-team07_item-content-2">-->
<!--                                <div class="uui-heading-tiny-4">{{item.name}}</div>-->
<!--                                <div v-if="item.professional_license.length"  class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. {{item.professional_license}}</div>-->
<!--                                <div v-if="item.specialty_license.length">-->
<!--                                  <div  v-for="(spe, i) in stringArray(item.specialty_license)" :key="i"  class="uui-text-size-medium-15 text-color-primary700">Ced. Esp. {{spe}} </div>-->
<!--                                </div>-->
<!--                                <div class="uui-space-xxsmall-8"></div>-->
<!--                                <div class="uui-text-size-medium-15">-->
<!--                                  <strong v-for="(specialty, index) in item.specialties" :key="index">{{specialty.name}} <br></strong>-->
<!--                                  {{item.information}}-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->

<!--                          </div>-->
<!--                        </div>-->

                        <div class="uui-team07_list-wrapper-2">
                          <div class="w-layout-grid item_medico">
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/luis-alberto-manriquez.png" loading="lazy" sizes="84px" srcset="../../../public/images/luis-alberto-manriquez-p-500.png 500w, ../../../public/images/luis-alberto-manriquez-p-800.png 800w, ../../../public/images/luis-alberto-manriquez-p-1080.png 1080w, ../../../public/images/luis-alberto-manriquez.png 1084w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Luis Alberto Manríquez Santos</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 4644596</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. De Esp. 6323680</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Pediatría.</strong><br>Posgrado en la Universidad Nacional Autónoma de México H.G. Darío Fernández Fierro ISSSTE Certificado por el Consejo de Certificación en Pediatría No 15498.</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/maythe-narda.png" loading="lazy" sizes="84px" srcset="../../../public/images/maythe-narda-p-500.png 500w, ../../../public/images/maythe-narda-p-800.png 800w, ../../../public/images/maythe-narda-p-1080.png 1080w, ../../../public/images/maythe-narda.png 1196w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Maythe Narda Hernández García</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 6792224</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. De Esp. 9714459</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Pediatría.<br></strong>Certificada por el Colegio Mexicano de Pediatría No 18962.</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ.jpg" loading="lazy" sizes="84px" srcset="../../../public/images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-500.jpg 500w, ../../../public/images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-800.jpg 800w, ../../../public/images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-1080.jpg 1080w, ../../../public/images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-1600.jpg 1600w, ../../../public/images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-2000.jpg 2000w, ../../../public/images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-2600.jpg 2600w, ../../../public/images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-3200.jpg 3200w, ../../../public/images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ.jpg 6000w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Armando Domínguez González</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 9456145</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 9456145</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Pediatría.<br></strong>Egresado de la U.A.B.J.O, Hospital Del Niño, DIF Hidalgo – UAEH, Certificado por el Colegio Mexicano de Pediatría No. 18797, Miembro del Colegio de Pediatría De Oaxaca.</div>
                              </div>
                            </div>
                            <div class="rich-text-block w-richtext">
                              <h4 class="heading-18">Cardiólogo Pediatra</h4>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/dr.cesar-augusto-zarate-morales.jpeg" loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. César Augusto Zarate Morales</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof 1872220</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Eso 3392103</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Cardiología Pediátrica.</strong>‍<br>Hospital Infantil De México Federico Gómez, UNAM.</div>
                              </div>
                            </div>
                            <div id="w-node-c486f659-5c98-6867-7274-227d1153112c-cc92b027" class="rich-text-block w-richtext">
                              <h4 class="heading-18">Cirujano Oncólogo Pediatra</h4>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR.-ALEX-TONY-REYES-SANTIAGO-CIRUJANO-ONCOLOGO-PEDIATRA-2.jpeg" loading="lazy" sizes="84px" srcset="../../../public/images/DR.-ALEX-TONY-REYES-SANTIAGO-CIRUJANO-ONCOLOGO-PEDIATRA-2-p-500.jpeg 500w, ../../../public/images/DR.-ALEX-TONY-REYES-SANTIAGO-CIRUJANO-ONCOLOGO-PEDIATRA-2-p-800.jpeg 800w, ../../../public/images/DR.-ALEX-TONY-REYES-SANTIAGO-CIRUJANO-ONCOLOGO-PEDIATRA-2.jpeg 1200w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Alex Tony Reyes Santiago</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. 5399716</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. 9177508</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Cirujano Oncólogo Pediatra.</strong><br>Hospital Infantil de México Federico Gómez. Cirugía Oncológica Pediátrica, UNAM, Médico Cirujano UABJO, Cirujano Pediatra UNAM.</div>
                              </div>
                            </div>
                            <div id="w-node-e515d574-5760-dadb-4949-66cb089c1cef-cc92b027" class="rich-text-block w-richtext">
                              <h4 class="heading-18">Gastroenterólogo Pediatra</h4>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/dr.alberto-molina-peto.jpg" loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Alberto Molina Peto</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. 10002907</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. 10531813</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Gastroenterólogo Pediatra.<br>‍</strong>Médico Cirujano por la Universidad Autónoma Benito Juárez de Oaxaca, Especialidad en Gastroenterología y Nutrición Pediátrica por la Universidad Nacional Autónoma de México, Formación en la UMAE Hospital de Pediatría del Centro Médico Nacional Siglo XXI en la Ciudad de México y por la UNAM.</div>
                              </div>
                            </div>
                            <div id="w-node-_3db727d3-a283-a67a-a1d3-325e834edfd9-cc92b027" class="rich-text-block w-richtext">
                              <h4 class="heading-18">Neuróloga Pediatra</h4>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/dra.Kenia-cruz-calvo-neurologa-pediatra.png" loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Kenia Cruz Calvo</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. 6230372</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. 10996304</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Neuróloga Pediatra.</strong><br>UPAEP UNAM, Certificada por el Consejo Mexicano de Pediatría, Certificada por el Consejo Mexicano de Neurología.</div>
                              </div>
                            </div>
                            <div id="w-node-afe3ac3b-79a7-2076-c496-600d1d678b9f-cc92b027" class="rich-text-block w-richtext">
                              <h4 class="heading-18">Neumóloga Pediatra</h4>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DRA.MONSERRATH-PACHECO-ARVIZU-NEUMOLOGA-PEDIATRA.jpeg" loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4"><strong>Dra. Monserrath Pacheco Arvizú</strong></div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 12309973</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Esp.13413202</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Neumóloga pediatra.<br></strong>Instituto Nacional de Pediatría / UNAM, Hospital General “Dr. Aurelio Valdivieso“ / UABJO</div>
                              </div>
                            </div>
                            <div id="w-node-_8ff23fff-1133-d169-9b96-a2e446e45491-cc92b027" class="rich-text-block w-richtext">
                              <h4 class="heading-18">Traumatología y Ortopedia Pediátrica</h4>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/dr.francisco-javier-martinez.jpg" loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4"><strong>Dr. Francisco Javier Martínez</strong></div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. 8043480</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. 11482794</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Traumatólogo Pediátrica.<br></strong>UNAM / Hospital Shriners para Niños México.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uui-space-xxsmall-21"></div>
                        <div class="uui-space-xxsmall-21"></div>
                        <div class="uui-blogsection12_item-2">
                          <router-link data-w-id="32de169b-7621-95e6-cbfc-914d31519a24" to="/emergencies-pediatric" class="uui-blogsection12_image-link-2 w-inline-block">
                            <div class="uui-blogsection12_image-wrapper-2"><img srcset="images/cardiologia-pediatrica-p-500.jpeg 500w, images/cardiologia-pediatrica-p-800.jpeg 800w, images/cardiologia-pediatrica-p-1080.jpeg 1080w, images/cardiologia-pediatrica.jpeg 1480w" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, (max-width: 1919px) 1280px, 1600px" src="images/cardiologia-pediatrica.jpeg" alt="" class="uui-blogsection12_image-2"></div>
                          </router-link>
                          <router-link data-w-id="32de169b-7621-95e6-cbfc-914d31519a27"  to="/emergencies-pediatric" class="uui-blogsection12_title-link-2 w-inline-block">
                            <h3 class="uui-heading-xsmall-5">{{$t('urgencies.urgencies-title-2')}}</h3>
                            <div class="uui-blogsection12_icon-2 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7 17L17 7M17 7H7M17 7V17" stroke="CurrentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg></div>
                          </router-link>
                          <div class="uui-text-size-medium-31">{{$t('urgencies.urgencies-title-2-description')}}<br>{{$t('urgencies.urgencies-title-2-description-2')}}</div>
                        </div>
                        <appointment-component></appointment-component>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>

</style>
