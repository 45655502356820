<script >
// import EmergenciesComponent from "@/components/EmergenciesComponent.vue";
// import AddressComponent from "@/components/AddressComponent.vue";
// import AppointmentComponent from "@/components/AppointmentComponent.vue";
import {app} from "@/main";
import EmergenciesServiceComponent from "@/components/EmergenciesServiceComponent.vue";
import AddressComponent from "@/components/AddressComponent.vue";
import EmergenciesComponent from "@/components/EmergenciesComponent.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import AppointmentComponent from "@/components/AppointmentComponent.vue";

export default {
  name: "ObstetricalPage",
  components: {
    AppointmentComponent,
    SideContactComponent, EmergenciesComponent, AddressComponent, EmergenciesServiceComponent},
  // components: {AppointmentComponent, AddressComponent, EmergenciesComponent},
  data() {
    return {
      causesBleeding:[],
      molarPregnancy:[],
      show1:false,
      show2:false,
      show3:false,
      show4:false,
      show5:false,
      show6:false,
      show7:false,
      show8:false,
      show9:false,
      show10:false,
      show11:false,
      locale:'',
      specialties:[1],
      specialists:[],
    };
  },

  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });

  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods: {
    loadData(locale) {
      this.specialists = require('../data/specialists.json');
      this.specialists = this.specialists.filter(s =>
          s.specialties.some(specialty => this.specialties.includes(specialty.id))
      );
      this.specialists = this.specialists.map(specialist => {
        const translateInformation = specialist.information[locale];
        let specialties = specialist.specialties.map(specialty => {
          const name = specialty.name[locale];
          return {
            ...specialty,
            name: name
          }
        });

        return {
          ...specialist,
          information: translateInformation,
          specialties: specialties
        };
      });

    },
    stringArray(string) {
      return string.split(',');
    },
  }
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-c81df231-3e74-6ad0-5007-ec17d2de8eed-c62ff672" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="c81df231-3e74-6ad0-5007-ec17d2de8ef0" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('home.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('obstetrical.obstetrical-title')}}</h1>
              <div class="uui-space-large-7"></div>
              <address-component></address-component>
            </div>
            <div id="w-node-c81df231-3e74-6ad0-5007-ec17d2de8ef8-c62ff672" class="uui-blogpost05_image-wrapper-7"><img srcset="images/MEDICOS-EN-URGENCIAS-p-500.jpg 500w, images/MEDICOS-EN-URGENCIAS-p-800.jpg 800w, images/MEDICOS-EN-URGENCIAS-p-1080.jpg 1080w, images/MEDICOS-EN-URGENCIAS-p-1600.jpg 1600w, images/MEDICOS-EN-URGENCIAS-p-2000.jpg 2000w, images/MEDICOS-EN-URGENCIAS-p-2600.jpg 2600w, images/MEDICOS-EN-URGENCIAS-p-3200.jpg 3200w, images/MEDICOS-EN-URGENCIAS.jpg 6960w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 46vw, 768px" src="images/MEDICOS-EN-URGENCIAS.jpg" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8 urgencia">
            <div class="uui-blogpost05_content-left-2">
             <emergencies-component></emergencies-component>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-a49f15ab-381a-2f75-11cc-239946b84c74-c62ff672" class="uui-blogpost05_content-2">
              <header class="uui-section_blogpost06">
                <div class="w-layout-grid uui-blogpost06_header">
                  <div id="w-node-a49f15ab-381a-2f75-11cc-239946b84c84-c62ff672" class="uui-blogpost06_header-content card-urgencias"><img src="images/image-100.png" loading="lazy" alt="" class="image-17">
                    <h1 class="uui-heading-large-8">{{$t('obstetrical.obstetrical-emergency-title')}}</h1>
                    <div class="w-layout-hflex flex-block-20">
                      <div class="w-layout-vflex flex-block-22">
                        <h1 class="heading-8">{{$t('obstetrical.obstetrical-emergency-title-2')}}<br></h1>
                        <div class="w-layout-hflex flex-block-19">
                          <h1 class="heading-15">954 104 2270</h1>
                          <h1 class="heading-16">Ext. 103</h1>
                        </div>
                        <h1 class="heading-17">{{$t('obstetrical.obstetrical-emergency-title-3')}}<br></h1>
                      </div>
                      <div class="w-layout-vflex flex-block-21">
                        <h1 class="heading-8">{{$t('obstetrical.obstetrical-emergency-title-4')}} (AUX)<br></h1>
                        <div class="w-layout-hflex flex-block-19">
                          <h1 class="heading-15">954 104 2270</h1>
                          <h1 class="heading-16">Ext. 104</h1>
                        </div>
                        <h1 class="heading-17">{{$t('obstetrical.obstetrical-emergency-title-3')}}<br></h1>
                      </div>
                    </div>
                  </div>
                </div>
                <header class="uui-blogpost06_content-wrapper">
                  <div class="uui-page-padding-24">
                    <div class="uui-container-large-19">
                      <div class="uui-padding-vertical-xhuge-26">
                        <div>
                          <h4 class="heading-10">{{$t('obstetrical.obstetrical-emergency-p1')}}</h4>
                          <div class="uui-text-rich-text-4 w-richtext">
                            <p><strong class="bold-text-18">{{$t('obstetrical.obstetrical-emergency-p2')}}</strong></p>
                          </div>
                          <ul role="list" class="list">
                            <li class="list-item-11">{{$t('obstetrical.obstetrical-emergency-p3')}}</li>
                            <li class="list-item-12">{{$t('obstetrical.obstetrical-emergency-p4')}}</li>
                          </ul>
                          <div class="uui-text-rich-text-4 w-richtext">
                            <p><strong class="bold-text-19">{{$t('obstetrical.obstetrical-emergency-p5')}}</strong></p>
                          </div>
                          <ul role="list" class="list">
                            <li>{{$t('obstetrical.obstetrical-emergency-p6')}}</li>
                            <li>{{$t('obstetrical.obstetrical-emergency-p7')}}</li>
                            <li>{{$t('obstetrical.obstetrical-emergency-p8')}}</li>
                            <li>{{$t('obstetrical.obstetrical-emergency-p9')}}</li>
                            <li>{{$t('obstetrical.obstetrical-emergency-p10')}}</li>
                            <li>{{$t('obstetrical.obstetrical-emergency-p11')}}</li>
                          </ul>
                          <div class="uui-blogpost06_rich-text-divider"></div>
                          <div class="uui-text-rich-text-4 w-richtext">
                            <h5>{{$t('obstetrical.obstetrical-emergency-p12')}}</h5>
                            <p>{{$t('obstetrical.obstetrical-emergency-p13')}}</p>
                          </div>
                          <div class="uui-max-width-large-12 align-center">
                            <div class="uui-space-xsmall-13"></div>
                            <div class="text-block-25"><strong><em class="italic-text-2">{{$t('obstetrical.obstetrical-emergency-p14')}}</em></strong></div>
                          </div>
                        </div>
                        <div class="uui-padding-vertical-xhuge-42">
                          <div class="uui-text-rich-text-4 w-richtext">
                            <h4>{{$t('obstetrical.obstetrical-emergency-p15')}}</h4>
                            <p>{{$t('obstetrical.obstetrical-emergency-p16')}}</p>
                          </div>
                          <div class="uui-faq01_component-5">
                            <div class="uui-faq01_list-5">
                              <div class="uui-faq01_accordion-5">
                                <div @click="show1 = !show1" data-w-id="a49f15ab-381a-2f75-11cc-239946b84cd8" class="uui-faq01_question-5">
                                  <div class="uui-faq01_heading-5">Sintomas</div>
                                  <div class="uui-faq01_icon-wrapper-5">
                                    <div class="accordion-icon_component-6">
                                      <div class="accordion-icon_horizontal-line-6"></div>
                                      <div v-show="!show1" class="accordion-icon_vertical-line-6"></div>
                                    </div>
                                  </div>
                                </div>
                                <div v-show="show1"  class="uui-faq01_answer-5 animate__animated animate__fadeIn">
                                  <div class="uui-text-size-medium-19">{{$t('obstetrical.obstetrical-emergency-p17')}}<br></div>
                                  <ul role="list" class="list-2">
                                    <li>{{$t('obstetrical.obstetrical-emergency-p18')}}</li>
                                    <li>{{$t('obstetrical.obstetrical-emergency-p19')}}</li>
                                    <li>{{$t('obstetrical.obstetrical-emergency-p20')}}<a href="https://hospitalangeldelmar.com.mx/?stm_services=alta-especialidad#1657859517374-483655e1-3569"><br></a>
                                    </li>
                                  </ul>
                                  <div class="uui-space-medium-13"></div>
                                </div>
                              </div>
                              <div class="uui-faq01_accordion-5">
                                <div @click="show2 = !show2" data-w-id="a49f15ab-381a-2f75-11cc-239946b84cef" class="uui-faq01_question-5">
                                  <div class="uui-faq01_heading-5">{{$t('obstetrical.obstetrical-emergency-p21')}}</div>
                                  <div class="uui-faq01_icon-wrapper-5">
                                    <div class="accordion-icon_component-6">
                                      <div class="accordion-icon_horizontal-line-6"></div>
                                      <div v-show="!show2"  class="accordion-icon_vertical-line-6"></div>
                                    </div>
                                  </div>
                                </div>
                                <div v-show="show2" class="uui-faq01_answer-5 animate__animated animate__fadeIn">
                                  <div class="uui-text-size-medium-31">{{$t('obstetrical.obstetrical-emergency-p22')}}</div>
                                  <ul role="list" class="list-2">
                                    <li>{{$t('obstetrical.obstetrical-emergency-p23')}}</li>
                                    <li>{{$t('obstetrical.obstetrical-emergency-p24')}}</li>
                                    <li>{{$t('obstetrical.obstetrical-emergency-p25')}}<a href="https://hospitalangeldelmar.com.mx/?stm_services=alta-especialidad#1657859517374-483655e1-3569"><br></a>
                                    </li>
                                    <li>{{$t('obstetrical.obstetrical-emergency-p26')}}<a href="https://hospitalangeldelmar.com.mx/?stm_services=alta-especialidad#1657859517374-483655e1-3569"><br></a>
                                    </li>
                                  </ul>
                                  <div class="uui-space-medium-13"></div>
                                </div>
                              </div>
                              <div class="uui-faq01_accordion-5">
                                <div @click="show3 = !show3" data-w-id="a49f15ab-381a-2f75-11cc-239946b84d08" class="uui-faq01_question-5">
                                  <div class="uui-faq01_heading-5">{{$t('obstetrical.obstetrical-emergency-p27')}}</div>
                                  <div class="uui-faq01_icon-wrapper-5">
                                    <div class="accordion-icon_component-6">
                                      <div class="accordion-icon_horizontal-line-6"></div>
                                      <div  v-show="!show3" class="accordion-icon_vertical-line-6"></div>
                                    </div>
                                  </div>
                                </div>
                                <div v-show="show3" class="uui-faq01_answer-5 animate__animated animate__fadeIn">
                                  <div class="uui-text-size-medium-31">{{$t('obstetrical.obstetrical-emergency-p28')}}</div>
                                  <div class="uui-space-medium-13"></div>
                                  <ul role="list" class="list-3">
                                    <li>{{$t('obstetrical.obstetrical-emergency-p29')}}</li>
                                    <li>{{$t('obstetrical.obstetrical-emergency-p30')}}</li>
                                    <li>{{$t('obstetrical.obstetrical-emergency-p31')}}<br></li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="uui-text-rich-text-4 w-richtext">
                            <h4><strong>{{$t('obstetrical.obstetrical-emergency-p32')}}</strong></h4>
                            <p>{{$t('obstetrical.obstetrical-emergency-p33')}}</p>
                            <p>{{$t('obstetrical.obstetrical-emergency-p34')}}</p>
                            <p>{{$t('obstetrical.obstetrical-emergency-p35')}}</p>
                          </div>
                          <section class="uui-section_faq01-5">
                            <div class="uui-page-padding-41">
                              <div class="uui-container-large-31">
                                <div class="uui-padding-vertical-xhuge-42">
                                  <div class="uui-faq01_component-5">
                                    <div class="uui-faq01_list-6">
                                      <div class="uui-faq01_accordion-5">
                                        <div @click="show4 = !show4" data-w-id="a49f15ab-381a-2f75-11cc-239946b84d2e" class="uui-faq01_question-5">
                                          <div class="uui-faq01_heading-5">{{$t('obstetrical.obstetrical-emergency-p36')}}</div>
                                          <div class="uui-faq01_icon-wrapper-5">
                                            <div class="accordion-icon_component-6">
                                              <div class="accordion-icon_horizontal-line-6"></div>
                                              <div v-show="!show4" class="accordion-icon_vertical-line-6"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-show="show4" class="uui-faq01_answer-5 animate__animated animate__fadeIn">
                                          <div class="uui-text-size-medium-31">{{$t('obstetrical.obstetrical-emergency-p37')}}</div>
                                          <div class="uui-space-medium-13"></div>
                                          <ul role="list" class="list-2">
                                            <li>{{$t('obstetrical.obstetrical-emergency-p38')}}</li>
                                            <li>{{$t('obstetrical.obstetrical-emergency-p39')}}</li>
                                            <li>{{$t('obstetrical.obstetrical-emergency-p40')}}</li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div class="uui-faq01_accordion-5">
                                        <div @click="show5 = !show5" data-w-id="a49f15ab-381a-2f75-11cc-239946b84d41" class="uui-faq01_question-5">
                                          <div class="uui-faq01_heading-5">{{$t('obstetrical.obstetrical-emergency-p41')}}</div>
                                          <div class="uui-faq01_icon-wrapper-5">
                                            <div class="accordion-icon_component-6">
                                              <div class="accordion-icon_horizontal-line-6"></div>
                                              <div v-show="!show5" class="accordion-icon_vertical-line-6"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-show="show5" class="uui-faq01_answer-5 animate__animated animate__fadeIn">
                                          <div class="uui-text-size-medium-31">{{$t('obstetrical.obstetrical-emergency-p42')}}</div>
                                          <div class="uui-space-medium-13"></div>
                                        </div>
                                      </div>
                                      <div class="uui-faq01_accordion-5">
                                        <div @click="show6 = !show6" data-w-id="a49f15ab-381a-2f75-11cc-239946b84d4d" class="uui-faq01_question-5">
                                          <div class="uui-faq01_heading-5">{{$t('obstetrical.obstetrical-emergency-p43')}}</div>
                                          <div class="uui-faq01_icon-wrapper-5">
                                            <div class="accordion-icon_component-6">
                                              <div class="accordion-icon_horizontal-line-6"></div>
                                              <div v-show="!show6" class="accordion-icon_vertical-line-6"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-show="show6" class="uui-faq01_answer-5 animate__animated animate__fadeIn">
                                          <div class="uui-text-size-medium-31">{{$t('obstetrical.obstetrical-emergency-p44')}}</div>
                                          <div class="uui-space-medium-13"></div>
                                          <ul role="list" class="list-3">
                                            <li>{{$t('obstetrical.obstetrical-emergency-p45')}}</li>
                                            <li>{{$t('obstetrical.obstetrical-emergency-p46')}}</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                        <div>
                          <div class="uui-text-rich-text-4 w-richtext">
                            <h4><strong>{{$t('obstetrical.obstetrical-emergency-p47')}}</strong></h4>
                            <p>{{$t('obstetrical.obstetrical-emergency-p48')}} <strong>{{$t('obstetrical.obstetrical-emergency-p49')}}</strong>, {{$t('obstetrical.obstetrical-emergency-p50')}}</p>
                            <p>{{$t('obstetrical.obstetrical-emergency-p51')}} <strong>{{$t('obstetrical.obstetrical-emergency-p52')}}</strong> <strong>{{$t('obstetrical.obstetrical-emergency-p53')}}</strong> {{$t('obstetrical.obstetrical-emergency-p54')}} <strong>{{$t('obstetrical.obstetrical-emergency-p55')}}</strong>.</p>
                            <p><strong>{{$t('obstetrical.obstetrical-emergency-p56')}} </strong>{{$t('obstetrical.obstetrical-emergency-p57')}}</p>
                            <p><strong>{{$t('obstetrical.obstetrical-emergency-p58')}}</strong> {{$t('obstetrical.obstetrical-emergency-p59')}}</p>
                          </div>
                          <section class="uui-section_faq01-5">
                            <div class="uui-page-padding-42">
                              <div class="uui-container-large-32">
                                <div class="uui-padding-vertical-xhuge-43">
                                  <div class="uui-faq01_component-6">
                                    <div class="uui-faq01_list-7">
                                      <div class="uui-faq01_accordion-5">
                                        <div @click="show7 = !show7" data-w-id="a49f15ab-381a-2f75-11cc-239946b84d73" class="uui-faq01_question-5">
                                          <div class="uui-faq01_heading-5">{{$t('obstetrical.obstetrical-emergency-p60')}}</div>
                                          <div class="uui-faq01_icon-wrapper-5">
                                            <div class="accordion-icon_component-6">
                                              <div class="accordion-icon_horizontal-line-6"></div>
                                              <div v-show="!show7" class="accordion-icon_vertical-line-6"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-show="show7" class="uui-faq01_answer-5 animate__animated animate__fadeIn">
                                          <div class="uui-text-size-medium-31">{{$t('obstetrical.obstetrical-emergency-p61')}}</div>
                                          <div class="uui-space-medium-13"></div>
                                        </div>
                                      </div>
                                      <div class="uui-faq01_accordion-5">
                                        <div @click="show8 = !show8" data-w-id="a49f15ab-381a-2f75-11cc-239946b84d7f" class="uui-faq01_question-5">
                                          <div class="uui-faq01_heading-5">{{$t('obstetrical.obstetrical-emergency-p62')}}</div>
                                          <div class="uui-faq01_icon-wrapper-5">
                                            <div class="accordion-icon_component-6">
                                              <div class="accordion-icon_horizontal-line-6"></div>
                                              <div v-show="!show8" class="accordion-icon_vertical-line-6"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-show="show8" class="uui-faq01_answer-5 animate__animated animate__fadeIn">
                                          <div class="uui-text-size-medium-31">{{$t('obstetrical.obstetrical-emergency-p63')}}</div>
                                          <ul role="list" class="list-4">
                                            <li>{{$t('obstetrical.obstetrical-emergency-p64')}}</li>
                                            <li>{{$t('obstetrical.obstetrical-emergency-p65')}}</li>
                                            <li>{{$t('obstetrical.obstetrical-emergency-p66')}}</li>
                                            <li>{{$t('obstetrical.obstetrical-emergency-p67')}}</li>
                                            <li>{{$t('obstetrical.obstetrical-emergency-p68')}}</li>
                                            <li>{{$t('obstetrical.obstetrical-emergency-p69')}}</li>
                                            <li>{{$t('obstetrical.obstetrical-emergency-p70')}}</li>
                                            <li>{{$t('obstetrical.obstetrical-emergency-p71')}}</li>
                                            <li>{{$t('obstetrical.obstetrical-emergency-p72')}}</li>
                                          </ul>
                                          <div class="uui-space-medium-13"></div>
                                        </div>
                                      </div>
                                      <div class="uui-faq01_accordion-5">
                                        <div @click="show9 = !show9" data-w-id="a49f15ab-381a-2f75-11cc-239946b84d9e" class="uui-faq01_question-5">
                                          <div class="uui-faq01_heading-5">{{$t('obstetrical.obstetrical-emergency-p73')}}</div>
                                          <div class="uui-faq01_icon-wrapper-5">
                                            <div class="accordion-icon_component-6">
                                              <div class="accordion-icon_horizontal-line-6"></div>
                                              <div v-show="!show9" class="accordion-icon_vertical-line-6"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-show="show9" class="uui-faq01_answer-5 animate__animated animate__fadeIn">
                                          <div class="uui-text-size-medium-31">{{$t('obstetrical.obstetrical-emergency-p74')}}</div>
                                          <div class="uui-space-medium-13"></div>
                                          <ul role="list" class="list-3">
                                            <li class="list-item-2">{{$t('obstetrical.obstetrical-emergency-p75')}}</li>
                                            <li class="list-item">{{$t('obstetrical.obstetrical-emergency-p76')}}</li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div class="uui-faq01_accordion-5">
                                        <div @click="show10 = !show10" data-w-id="a49f15ab-381a-2f75-11cc-239946b84daf" class="uui-faq01_question-5">
                                          <div class="uui-faq01_heading-5">{{$t('obstetrical.obstetrical-emergency-p77')}}</div>
                                          <div class="uui-faq01_icon-wrapper-5">
                                            <div class="accordion-icon_component-6">
                                              <div class="accordion-icon_horizontal-line-6"></div>
                                              <div v-show="!show10" class="accordion-icon_vertical-line-6"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-show="show10" class="uui-faq01_answer-5 animate__animated animate__fadeIn"  >
                                          <div class="uui-text-size-medium-31">{{$t('obstetrical.obstetrical-emergency-p78')}}<br>{{$t('obstetrical.obstetrical-emergency-p79')}}<br>{{$t('obstetrical.obstetrical-emergency-p80')}}</div>
                                          <div class="uui-space-medium-13"></div>
                                        </div>
                                      </div>
                                      <div class="uui-faq01_accordion-5 last-item">
                                        <div @click="show11 = !show11" data-w-id="a49f15ab-381a-2f75-11cc-239946b84dbf" class="uui-faq01_question-5">
                                          <div class="uui-faq01_heading-5">{{$t('obstetrical.obstetrical-emergency-p81')}}</div>
                                          <div class="uui-faq01_icon-wrapper-5">
                                            <div class="accordion-icon_component-6">
                                              <div class="accordion-icon_horizontal-line-6"></div>
                                              <div v-show="!show11" class="accordion-icon_vertical-line-6"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-show="show11"  class="uui-faq01_answer-5 animate__animated animate__fadeIn">
                                          <div class="uui-text-size-medium-31">{{$t('obstetrical.obstetrical-emergency-p82')}}</div>
                                          <div class="uui-space-medium-13"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                        <div class="uui-max-width-large-12 align-center"></div>
                      </div>
                    </div>
                    <section class="uui-section_faq01-5">
                      <div class="uui-page-padding-41">
                        <div class="uui-container-large-31"></div>
                      </div>
                    </section>
                  </div>
                </header>
              </header>
              <section class="team_seccion">
                <div class="uui-page-padding-18">
                  <div class="uui-container-large-14">
                    <div class="uui-padding-vertical-xhuge-20">
                      <div class="uui-max-width-large-9">
                        <h2 class="uui-heading-medium-11" style="margin-top: 2rem;margin-bottom: 2rem; text-align: center;color: var(--hc--colors--green--600);">{{$t('home.emergencies-aid')}}</h2>
                        <h2 class="uui-heading-medium-11">{{$t('home.our-team')}}</h2>
                        <div class="uui-space-xsmall-10"></div>
                      </div>
                      <div class="uui-team07_component-2">
                        <div class="uui-team07_list-wrapper-2">
                          <div class="w-layout-grid item_medico">
<!--                            <div  v-for="(item, index) in specialists" :key="index" class="card_doctor">-->
<!--                              <div class="uui-team07_image-wrapper-2"><img v-bind:src="item.profile_picture" loading="lazy" sizes="84px"  alt="" class="uui-team07_image-2"></div>-->
<!--                              <div class="uui-team07_item-content-2">-->
<!--                                <div class="uui-heading-tiny-4">{{item.name}}</div>-->
<!--                                <div v-if="item.professional_license.length"  class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. {{item.professional_license}}</div>-->
<!--                                <div v-if="item.specialty_license.length">-->
<!--                                  <div  v-for="(spe, i) in stringArray(item.specialty_license)" :key="i"  class="uui-text-size-medium-15 text-color-primary700">Ced. Esp. {{spe}} </div>-->
<!--                                </div>-->
<!--                                <div class="uui-space-xxsmall-8"></div>-->
<!--                                <div class="uui-text-size-medium-15">-->
<!--                                  <strong v-for="(specialty, index) in item.specialties" :key="index">{{specialty.name}} <br></strong>-->
<!--                                  {{item.information}}-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
                            <div class="card_doctor">
                              <div id="w-node-a49f15ab-381a-2f75-11cc-239946b84dee-c62ff672" class="uui-team07_image-wrapper-2"><img src="../../public/images/DRA-FLORENTINA-TOLEDO.jpg" loading="lazy" sizes="84px" srcset="../../public/images/DRA-FLORENTINA-TOLEDO-p-500.jpg 500w, ../../public/images/DRA-FLORENTINA-TOLEDO-p-800.jpg 800w, ../../public/images/DRA-FLORENTINA-TOLEDO-p-1080.jpg 1080w, ../../public/images/DRA-FLORENTINA-TOLEDO-p-1600.jpg 1600w, ../../public/images/DRA-FLORENTINA-TOLEDO-p-2000.jpg 2000w, ../../public/images/DRA-FLORENTINA-TOLEDO-p-2600.jpg 2600w, ../../public/images/DRA-FLORENTINA-TOLEDO-p-3200.jpg 3200w, ../../public/images/DRA-FLORENTINA-TOLEDO.jpg 6000w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Florentina López Toledo</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 3297941<br>Ced. Esp. 4743489</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Ginecología y Obstetricia<br></strong>Postgraduada en el Hospital de la Mujer CDMX / Universidad Autónoma de México U.N.A.M</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div id="w-node-a49f15ab-381a-2f75-11cc-239946b84dfe-c62ff672" class="uui-team07_image-wrapper-2"><img src="../../public/images/Captura-de-pantalla-2023-12-22-a-las-1.33.11-p.m..png" loading="lazy" sizes="84px" srcset="../../public/images/Captura-de-pantalla-2023-12-22-a-las-1.33.11-p.m.-p-500.png 500w, ../../public/images/Captura-de-pantalla-2023-12-22-a-las-1.33.11-p.m.-p-800.png 800w, ../../public/images/Captura-de-pantalla-2023-12-22-a-las-1.33.11-p.m.-p-1080.png 1080w, ../../public/images/Captura-de-pantalla-2023-12-22-a-las-1.33.11-p.m..png 1364w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Andrea Elianet Bornios Riviela</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Esp 11829131<br></div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Ginecología y Obstetricia.<br></strong>Alta Especialidad en Colposcopia y Patología de Tracto Vaginal Inferior. Posgrado Hospital General de Puebla / BUAP.</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div id="w-node-a49f15ab-381a-2f75-11cc-239946b84e0e-c62ff672" class="uui-team07_image-wrapper-2"><img src="../../public/images/DR.-MIGUEL-ANGEL-CARDENAS.jpg" loading="lazy" sizes="84px" srcset="../../public/images/DR.-MIGUEL-ANGEL-CARDENAS-p-500.jpg 500w, ../../public/images/DR.-MIGUEL-ANGEL-CARDENAS-p-800.jpg 800w, ../../public/images/DR.-MIGUEL-ANGEL-CARDENAS-p-1080.jpg 1080w, ../../public/images/DR.-MIGUEL-ANGEL-CARDENAS-p-1600.jpg 1600w, ../../public/images/DR.-MIGUEL-ANGEL-CARDENAS-p-2000.jpg 2000w, ../../public/images/DR.-MIGUEL-ANGEL-CARDENAS-p-2600.jpg 2600w, ../../public/images/DR.-MIGUEL-ANGEL-CARDENAS-p-3200.jpg 3200w, ../../public/images/DR.-MIGUEL-ANGEL-CARDENAS.jpg 6960w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Miguel Ángel Cárdenas Aderete</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 3982449<br></div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Ginecología y Obstetricia<br></strong>Postgrado UABJO HGDAV / Universidad Autónoma Benito Juárez de Oaxaca</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div id="w-node-a49f15ab-381a-2f75-11cc-239946b84e1d-c62ff672" class="uui-team07_image-wrapper-2"><img src="../../public/images/DR.-FLAVIO-ROJAS-CASTILLEJOS-HEMATOLOGIA-1.jpeg" loading="lazy" sizes="84px" srcset="../../public/images/DR.-FLAVIO-ROJAS-CASTILLEJOS-HEMATOLOGIA-1-p-500.jpeg 500w, ../../public/images/DR.-FLAVIO-ROJAS-CASTILLEJOS-HEMATOLOGIA-1-p-800.jpeg 800w, ../../public/images/DR.-FLAVIO-ROJAS-CASTILLEJOS-HEMATOLOGIA-1-p-1080.jpeg 1080w, ../../public/images/DR.-FLAVIO-ROJAS-CASTILLEJOS-HEMATOLOGIA-1.jpeg 1280w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Juan Carlos Castillo Ortega</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 10421065<br></div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 13573112<br></div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Ginecología y Obstetricia.<br></strong>Postgrado UABJO HGDAV / Universidad Autónoma Benito Juárez de Oaxaca.</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div id="w-node-_717ce958-8aa5-6ca8-2e34-874db569d5e6-c62ff672" class="uui-team07_image-wrapper-2"><img src="../../public/images/DRA-BINELE-SARAIM-CRISTOBAL-MONTOYA.jpg" loading="lazy" sizes="84px" srcset="../../public/images/DRA-BINELE-SARAIM-CRISTOBAL-MONTOYA-p-500.jpg 500w, ../../public/images/DRA-BINELE-SARAIM-CRISTOBAL-MONTOYA-p-800.jpg 800w, ../../public/images/DRA-BINELE-SARAIM-CRISTOBAL-MONTOYA-p-1080.jpg 1080w, ../../public/images/DRA-BINELE-SARAIM-CRISTOBAL-MONTOYA-p-1600.jpg 1600w, ../../public/images/DRA-BINELE-SARAIM-CRISTOBAL-MONTOYA-p-2000.jpg 2000w, ../../public/images/DRA-BINELE-SARAIM-CRISTOBAL-MONTOYA-p-2600.jpg 2600w, ../../public/images/DRA-BINELE-SARAIM-CRISTOBAL-MONTOYA-p-3200.jpg 3200w, ../../public/images/DRA-BINELE-SARAIM-CRISTOBAL-MONTOYA.jpg 6000w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Binele Cristóbal Montoya </div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 4988586<br></div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 5602083<br></div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Ginecología y Obstetricia.<br></strong>Postgrado Hospital General de México / Universidad Autonóma de México (U.N.A.M)</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div id="w-node-a49f15ab-381a-2f75-11cc-239946b84e2c-c62ff672" class="uui-team07_image-wrapper-2"><img src="../../public/images/dra.-Yuridia-Ruiz-Silva-Materno-Fetal-3_1.jpeg" loading="lazy" sizes="84px" srcset="../../public/images/dra.-Yuridia-Ruiz-Silva-Materno-Fetal-3_1-p-500.jpeg 500w, ../../public/images/dra.-Yuridia-Ruiz-Silva-Materno-Fetal-3_1-p-800.jpeg 800w, ../../public/images/dra.-Yuridia-Ruiz-Silva-Materno-Fetal-3_1.jpeg 1057w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Yuridia Ruiz Silva</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 10509846<br>Ced. Prof. 112893<br></div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Ginecología y Obstetricia / Médico Materno Fetal.</strong><br>El Instituto Nacional de Perinatología / Certificada por el Consejo Mexicano de Ginecología y Obstetricia.</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div id="w-node-_246c8d44-207a-d6c8-1a4f-cf4d5e7f50a5-c62ff672" class="uui-team07_image-wrapper-2"><img src="../../public/images/doca.png" loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Karla Osiris Peña</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 4248594<br>Ced. Prof. 7288215<br></div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Ginecóloga con Subespecialidad en Biología de la Reproducción Humana.</strong></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <appointment-component></appointment-component>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <section class="uui-section_blogsection02-2">
            <div class="uui-page-padding-43">
              <div class="uui-container-large-33">
                <div class="uui-padding-vertical-xhuge-44">
                  <div class="uui-text-align-center-15">
                    <div class="uui-max-width-large-20 align-center">
                      <h2 class="uui-heading-medium-28">Servicio de Urgencias</h2>
                      <div class="uui-space-xsmall-22"></div>
                      <div class="uui-text-size-large-31">El Servicio de Urgencias del Hospital Ángel del Mar te ofrece atención médica oportuna, profesional y ética las 24 horas del día, los 365 días del año, con médicos urgenciologos y con respaldo de más de 65 especialistas, con estudios de imagen y gabinete; 2 áreas de choque equipadas y acceso rápido a un costado.</div>
                    </div>
                  </div>
                  <emergencies-service-component></emergencies-service-component>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>

</style>
