<script >
import AddressComponent from "@/components/AddressComponent.vue";
import OurServices from "@/components/OurServices.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import 'animate.css';

export default{
  name:"HospitalTransfusionPage",
  components: {SideContactComponent, OurServices, AddressComponent},
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_744c27dc-aeb3-7512-6f4e-9195ba36d79b-3b03f6e2" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="744c27dc-aeb3-7512-6f4e-9195ba36d79e" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('HospitalTransfusionPage.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('HospitalTransfusionPage.title')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('HospitalTransfusionPage.subtitle')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_744c27dc-aeb3-7512-6f4e-9195ba36d7a8-3b03f6e2" class="uui-blogpost05_image-wrapper-7">
              <img srcset="../../../public/images/servicio-de-transfusion-500.jpg 500w, ../../../public/images/servicio-de-transfusion-800.jpg 800w, ../../../public/images/servicio-de-transfusion-1080.jpg 1080w, ../../../public/images/servicio-de-transfusion-1600.jpg 1600w, ../../../public/images/servicio-de-transfusion-2000.jpg 2000w, ../../../public/images/servicio-de-transfusion-2600.jpg 2600w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 46vw, 768px" src="../../../public/images/MUESTRA-LABORATORIO.jpg" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-4">
              <div class="uui-blogpost05_content-left-2">
                <our-services></our-services>
                <side-contact-component></side-contact-component>
              </div>
            </div>
            <div id="w-node-_744c27dc-aeb3-7512-6f4e-9195ba36d7ac-3b03f6e2" class="uui-blogpost05_content-7">
              <div class="uui-blogpost05_content-2">
                <div class="uui-text-rich-text-3 w-richtext">
                  <h4>{{$t('HospitalTransfusionPage.article-1')}}</h4>
                  <p>{{$t('HospitalTransfusionPage.article-1-content')}}</p>
                  <p>{{$t('HospitalTransfusionPage.article-1-content2')}}</p>
                  <figure style="max-width:1680pxpx" class="w-richtext-align-fullwidth w-richtext-figure-type-image"><div>
                    <img src="../../../public/images/aman-chaturvedi-0ZZo5o00o80-unsplash.jpg" loading="lazy" alt=""></div>
                  </figure>
                </div>
                <div>                
                  <p>{{$t('HospitalTransfusionPage.article-1-content3')}}</p>
                </div>
                <div>
                </div>
              </div>
              <div>
                <h2 class="uui-heading-medium-11" style="margin-top: 2rem;margin-bottom: 2rem; text-align: center;color: var(--hc--colors--green--600);">{{$t('HospitalTransfusionPage.service24')}}</h2>
                <p style="text-align: center"><strong>{{$t('HospitalTransfusionPage.licencia')}}</strong><br> {{$t('HospitalTransfusionPage.licencia-number')}}</p>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

