<script>
import {app} from '@/main.js';
import data from "@/data/specialties_search.json";
import 'animate.css';
import {all} from "core-js/internals/document-all";
export default {
  name: "HomePage",
  computed: {
    all() {
      return all
    }
  },
  data() {
    return {
      locale:'',
      comments: [],
      specialties: [],
      allSpecialties: [],
      resultsFilterSpecialties:[],
      specialists:[],
      resultsFilterSpecialists:[],
      showModal: false,
      activeTab: 0,
      choice: 'clinicsActive',
      searchTerm:'',
      selectedOption: "0",
      searchQuery: "",
    }
  },

  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });
    document.addEventListener("click.outside", (event) => {
      if (event.target.closest(".modal-container")) {
        return;
      }
      this.showModal = false;
    });
  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods:{
    makeActive: function(val) {
      this.choice = val;
    },
    isActiveTab: function(val) {
      return this.choice === val;
    },
    closeModal() {
      this.showModal = false;
    },

    loadData(locale){
      this.comments =  require(`../data/comments.json`);
      this.comments = this.comments.map(comment => {
        const translatedContent = comment.content[locale];
        const translatedUserPosition = comment.user.position[locale];
        return {
          ...comment,
          content: translatedContent,
          user: {
            ...comment.user,
            position: translatedUserPosition,
          },
        };
      });

      this.specialties = require('../data/specialties_search.json');
      this.specialties = data.map(specialty => {
        const translateText = specialty.text[locale];
        return {
          ...specialty,
          text: translateText,
        };
      });
      this.resultsFilterSpecialties = this.specialties;

      this.allSpecialties = require('../data/specialties.json');
      this.allSpecialties = this.allSpecialties.map(specialty => {
        const translateText = specialty.text[locale];
        return {
          ...specialty,
          text: translateText,
        };
      });

      this.specialists = require('../data/specialists.json');
      this.specialists = this.specialists.map(specialist => {
        const translateInformation= specialist.information[locale];
        return {
          ...specialist,
          information: translateInformation,
        };
      });
      this.resultsFilterSpecialists = this.specialists;
    },


    searchSpecialty: function () {
      this.resultsFilterSpecialties = this.specialties
          .filter(
              item => this.normalizeString(item.text).toUpperCase()
                  .includes(this.normalizeString(this.searchTerm).toUpperCase()));
    },
    filterSpecialty(){
      if(this.selectedOption==="0"){
       // this.specialtyName = 'Todas las especialidades';
        this.resultsFilterSpecialists = this.specialists;
        return;
      }
      //let value = this.specialties.find(v=> v.value===this.selectedOption);
      //this.specialtyName = value.text;
      this.resultsFilterSpecialists = this.specialists.filter(specialist => {
        return specialist.specialties.some(specialty => specialty.id === this.selectedOption);
      });
    },
    handleInputChange() {
      if (this.searchTerm === '') {
        this.resultsFilterSpecialties= this.specialties;
      }
    },
    normalizeString(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    search(){
      if (this.searchQuery.trim() !== "") {
        this.$router.push({ name: "search", params: { query: this.searchQuery } });
      }
    },
    getImagePath(translationKey) {
      return this.$t(translationKey);
    }
  }
}
</script>
<!---->
<template class="home">

  <header class="uui-section_heroheader05">
    <div class="uui-page-padding-48">
      <div class="uui-container-large-38">
        <div class="uui-padding-vertical-xhuge-50">
          <div class="w-layout-grid uui-heroheader05_component">
            <div class="uui-heroheader05_content">
              <div class="uui-space-large-7"></div>
              <div class="uui-space-small-23"></div><img :src="getImagePath('principal-image')" loading="lazy" width="423" alt="" class="image-26">
              <div class="uui-space-small-23"></div>
              <div class="uui-space-large-7"></div>
              <section class="uui-section_contact12 section-2">
                <div class="uui-page-padding-48">
                  <div class="uui-container-large-38">
                    <div class="uui-padding-vertical-xhuge-50">
                      <div class="w-layout-grid uui-contact12_component">
                        <div class="uui-contact12_item"><img src="images/Location.svg" loading="lazy" alt="" class="image-22">
                          <div class="uui-space-small-23"></div>
                          <div class="uui-space-xxsmall-20"></div>
                          <div class="uui-text-size-medium-36">{{ $t('home.located-at') }}</div>
                          <div class="uui-space-small-23"></div>
                          <a href="https://maps.app.goo.gl/VXS49U4WeCpr57qX6" target="_blank" class="uui-text-style-link-02-5">{{$t('home.ham-location') }}</a>
                        </div>
                        <div class="uui-contact12_item"><img src="images/Calendar-Waiting.svg" loading="lazy" alt="" class="image-20">
                          <div class="uui-space-small-23"></div>
                          <div class="uui-space-xxsmall-20"></div>
                          <div class="uui-text-size-medium-36">{{ $t('home.service') }}</div>
                          <div class="uui-space-small-23"></div>
                          <a href="#" class="uui-text-style-link-02-5">24 Horas</a>
                        </div>
                        <div id="w-node-f568a0d5-a8a4-b10c-48cd-4bf7b99e7080-e7bcd1ab" class="uui-contact12_item">
                          <div id="w-node-e7cc03cb-a8ce-4027-91e0-6cb08b5e32b1-e7bcd1ab" class="div-block-26"><img src="images/whats.svg" loading="lazy" alt="" class="image-23 image-green"><img src="images/Logo-Bg-Removed-HAM.png" loading="lazy" alt="" class="image-21"></div>
                          <div class="uui-space-small-23"></div>
                          <div class="uui-space-xxsmall-20"></div>
                          <div class="div-block-27">
                            <div class="uui-text-size-medium-36">{{ $t('home.general-from-desk') }}</div>
                            <div class="uui-space-small-23"></div>
                            <a href="tel:9541042270" class="uui-text-style-link-02-5 spacing">954 104 2270</a>
                            <div class="uui-space-xxsmall-20"></div>
                            <div class="uui-text-size-medium-36">{{ $t('home.emergencies') }}</div>
                            <div class="uui-space-small-23"></div>
                            <a href="tel:9541034110" class="uui-text-style-link-02-5 spacing">954 103 4110</a>
                            <a href="tel:9541770692" class="uui-text-style-link-02-5 spacing">954 177 0692</a>
                          </div>
                        </div>
                      </div>
                      <div class="uui-button-row-31">
                        <a href="https://angeldelmar.zonasana.clinic/login?v=2.26.0" target="_blank" class="rl_navbar3_button w-button">{{$t('home.schedule-appointment')}}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uui-space-large-7"></div>
              </section>
              <div class="uui-space-large-7"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div v-if="showModal" class="custom-modal" >
    <div  class="modal-bg"></div>
    <div class="modal-container">
      <button  @click="closeModal" class="close-text w-inline-block" style="background-color: white">
        <div class="text-block-24">x</div>
      </button>
      <h1 class="heading-11">{{$t('home.cmh-title')}}</h1>
      <p class="paragraph-6">{{$t('home.cmh-paragraph-1')}}<br>{{$t('home.cmh-paragraph-2')}}</p>
      <ul role="list">
        <li class="list-item-3">{{$t('home.cmh-list-1')}}</li>
        <li class="list-item-5">{{$t('home.cmh-list-2')}}</li>
        <li class="list-item-4">{{$t('home.cmh-list-3')}}</li>
      </ul>
      <p class="paragraph-5">{{$t('home.cmh-ham')}}</p>
      <div class="div-block-10"><img src="../../public/images/Consorcio.png" loading="lazy" sizes="100vw" srcset="../../public/images/Consorcio-p-500.png 500w, ../../public/images/Consorcio-p-800.png 800w, ../../public/images/Consorcio-p-1080.png 1080w, ../../public/images/Consorcio.png 1280w" alt="" class="image-18">
        <!--<img src="../../public/images/sumar-salud.png" loading="lazy" alt="" class="image-19">--></div>
      <router-link class="button w-button" to="/about">
        {{$t('home.more-about-ham')}}
      </router-link>
    </div>
  </div>
  <div class="rl-container-large-3">
    <header class="uui-section_heroheader21">
      <header class="uui-section_heroheader20">
        <div class="uui-page-padding-5">
          <div class="uui-container-large-5">
            <div class="uui-padding-vertical-xhuge-6">
              <div class="uui-heroheader20_component">
                <div class="uui-text-align-center">
                  <div class="uui-max-width-xlarge">
                    <div class="uui-space-small-4"></div>
                    <h1 class="uui-heading-xlarge text-color-primary900">{{$t('home.cmh-first')}}</h1>
                    <div class="uui-space-small-4"></div>
                    <div class="uui-max-width-large-4 align-center"></div>
                    <div class="uui-button-row-4 button-row-center is-reverse-mobile-landscape">
                      <a data-w-id="08fcb188-40a3-f99f-37e0-290c12cbb434" @click="showModal = true" class="button-primary w-button">{{$t('home.learn-more')}}</a>
                    </div>
                  </div>
                </div>
                <div class="uui-heroheader20_lightbox-wrapper">
                  <a href="#" data-w-id="11e7b416-267e-10a3-0634-b6f84dd7066e" class="uui-heroheader20_lightbox shadow-xxlarge w-inline-block w-lightbox"><img src="images/Consorcio.png" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, 481.25px" srcset="images/Consorcio-p-500.png 500w, images/Consorcio-p-800.png 800w, images/Consorcio-p-1080.png 1080w, images/Consorcio.png 1280w" alt="" class="uui-heroheader20_lightbox-image">

                  </a>
                  <div class="uui-heroheader20_background">
                    <div class="uui-heroheader20_background-grid">
                      <div id="w-node-_11e7b416-267e-10a3-0634-b6f84dd70673-e7bcd1ab" class="uui-heroheader20_background-grid-section"></div>
                      <div id="w-node-_11e7b416-267e-10a3-0634-b6f84dd70674-e7bcd1ab" class="uui-heroheader20_background-grid-section background-color-primary300"></div>
                      <div id="w-node-_11e7b416-267e-10a3-0634-b6f84dd70675-e7bcd1ab" class="uui-heroheader20_background-grid-section"></div>
                      <div id="w-node-_11e7b416-267e-10a3-0634-b6f84dd70676-e7bcd1ab" class="uui-heroheader20_background-grid-section"></div>
                      <div id="w-node-_11e7b416-267e-10a3-0634-b6f84dd70677-e7bcd1ab" class="uui-heroheader20_background-grid-section background-color-primary100"></div>
                      <div id="w-node-_11e7b416-267e-10a3-0634-b6f84dd70678-e7bcd1ab" class="uui-heroheader20_background-grid-section"></div>
                      <div id="w-node-_11e7b416-267e-10a3-0634-b6f84dd70679-e7bcd1ab" class="uui-heroheader20_background-grid-section background-color-primary200"></div>
                      <div id="w-node-_11e7b416-267e-10a3-0634-b6f84dd7067a-e7bcd1ab" class="uui-heroheader20_background-grid-section"></div>
                      <div id="w-node-_11e7b416-267e-10a3-0634-b6f84dd7067b-e7bcd1ab" class="uui-heroheader20_background-grid-section background-color-primary300"></div>
                      <div id="w-node-_11e7b416-267e-10a3-0634-b6f84dd7067c-e7bcd1ab" class="uui-heroheader20_background-grid-section background-color-white"></div>
                      <div id="w-node-_11e7b416-267e-10a3-0634-b6f84dd7067d-e7bcd1ab" class="uui-heroheader20_background-grid-section background-color-primary100"></div>
                    </div>
                    <div class="uui-heroheader20_background-grid-mask"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </header>
    <div class="rl-padding-section-large-3"></div>
  </div>
  <section class="uui-section_cta11-2">
    <div class="uui-page-padding-38">
      <div class="uui-container-large-28">
        <div class="uui-padding-vertical-xhuge-38">
          <div class="uui-cta11_component-2">
            <div class="uui-text-align-center-13">
              <h3 class="display-md-semibold">{{$t('home.found-specialists')}}</h3>
              <div class="uui-space-xsmall-19"></div>
              <div class="uui-text-size-large-27">{{$t('home.found-specialists-description')}}</div>
              <div class="uui-cta11_form-2 w-form">
                <div  class="uui-form_component-7" data-wf-page-id="6539b684ac5a3259e7bcd1ab" data-wf-element-id="a876e638-804d-ab8f-e239-47c3f0446657">
                  <div class="uui-signup-form_wrapper-7">
                    <input type="text" v-model="searchQuery"  class="uui-form_input-16 w-input" maxlength="256" name="Email" data-name="Email" :placeholder="$t('home.search-what-you-need')" id="Email" required="" autocomplete="off">
                    <input type="submit" @click="search" :value="$t('home.search')" data-wait="Please wait..." class="uui-button-28 w-button">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <header class="rl_section_header1">
    <div class="rl-padding-global-3">
      <div class="rl-padding-section-large-4"></div>
      <section class="rl_section_layout28">
        <div class="rl-padding-global-4">
          <div class="rl-container-large-3"></div>
        </div>
      </section>
      <section class="uui-section_layout69">
        <div class="uui-page-padding">
          <div class="uui-container-large">
            <div class="uui-padding-vertical-xhuge-7">
              <h2 class="uui-heading-medium-3">{{$t('home.family-phrase')}}</h2>
              <div class="uui-text-align-center">
                <div class="uui-space-xsmall-3"></div>
                <div class="uui-text-size-large-4">{{$t('home.family-phrase-description')}}</div>
              </div>
              <section class="uui-section_blogsection02">
                <div class="uui-page-padding-8">
                  <div class="uui-container-large-5"></div>
                </div>
              </section>
              <div  data-easing="ease" data-duration-in="300" data-duration-out="100" class="uui-career09_component w-tabs">
                <div id="nav" class="uui-career09_tabs-menu w-tab-menu">
                  <a href='#' :class="{ 'w--current': choice === 'clinicsActive' }" class="uui-career09_tab-link w-inline-block w-tab-link" v-on:click="makeActive('clinicsActive')">{{$t('home.clinics')}}</a>
                  <a href='#' :class="{ 'w--current': choice === 'servicesActive' }"  class="uui-career09_tab-link w-inline-block w-tab-link " v-on:click="makeActive('servicesActive')">{{$t('home.services')}}</a>
                  <a href='#'  :class="{ 'w--current': choice === 'specialtiesActive' }" class="uui-career09_tab-link w-inline-block w-tab-link " v-on:click="makeActive('specialtiesActive')">{{$t('home.specialties')}}</a>
                </div>

                <div class="uui-career09_tabs-content w-tab-content" style=" transition: color 0.3s ease-in-out;">
                  <div class="uui-career09_tab-pane w-tab-pane boxes" :class="{ 'w--tab-active': choice === 'clinicsActive' }" id="home" v-show="isActiveTab('clinicsActive')">
                    <div class="uui-career09_list-wrapper">
                      <div class="uui-career09_list2323">
                        <div id="w-node-_38890d1e-4776-daf0-34ec-4660e13e9119-e7bcd1ab" class="uui-layout88_item">

                            <div class="uui-layout88_item-content">
                              <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="images/CLINICA-DIGESTIVA48x48.svg" class="image-7"></div>
                              <h3 class="uui-heading-xxsmall-5">{{$t('home.digestive-disease-clinic-title')}}<br>{{$t('home.24-7')}}</h3>
                              <div class="uui-space-xxsmall-5"></div>
                              <div class="uui-text-size-medium-9">{{$t('home.digestive-disease-card-description')}}</div>
                            </div>
                            <div class="uui-space-small-7"></div>
                            <div class="uui-button-row-7">
                              <router-link class="uui-button-link-6 w-inline-block" to="/clinics-digestive-diseases">
                                <div>{{$t('home.learn-more')}}</div>
                                <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg></div>
                              </router-link>

                            </div>

                        </div>
                        <div id="w-node-a760ac49-5b05-17ec-50b2-95de37b6472f-e7bcd1ab" class="uui-layout88_item">
                            <div class="uui-layout88_item-content">
                              <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="images/Clinica-Mujer-icon.svg" class="image-7"></div>
                              <h3 class="uui-heading-xxsmall-5">{{$t('home.women-clinic-title')}}<br>{{$t('home.24-7')}}</h3>
                              <div class="uui-space-xxsmall-5"></div>
                              <div class="uui-text-size-medium-9">{{$t('home.women-clinic-card-description')}}</div>
                            </div>
                            <div class="uui-space-small-7"></div>
                            <div class="uui-button-row-7">
                              <router-link class="uui-button-link-6 w-inline-block" to="/clinics-womens">
                                <div>{{$t('home.learn-more')}}</div>
                                <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg></div>
                              </router-link>
                            </div>

                        </div>
                        <div id="w-node-_9ee222dc-9146-3867-0681-f2683773c435-e7bcd1ab" class="uui-layout88_item">
                            <div class="uui-layout88_item-content">
                              <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="images/diabetico-icon.svg" class="image-7"></div>
                              <h3 class="uui-heading-xxsmall-5">{{$t('home.diabetic-clinic-title')}} <br>{{$t('home.24-7')}}</h3>
                              <div class="uui-space-xxsmall-5"></div>
                              <div class="uui-text-size-medium-9">{{$t('home.diabetic-clinic-card-description')}}</div>
                            </div>
                            <div class="uui-space-small-7"></div>
                            <div class="uui-button-row-7">
                              <router-link class="uui-button-link-6 w-inline-block" to="/clinics-diabetic">
                                <div>{{$t('home.learn-more')}}</div>
                                <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg></div>
                              </router-link>

                            </div>
                        </div>
                        <div id="w-node-_67312e13-fde2-4bef-4857-39f7cbb58f1a-e7bcd1ab" class="uui-layout88_item">
                          <div class="uui-layout88_item-content">
                            <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="images/Pediatria-icon.svg" class="image-7"></div>
                            <h3 class="uui-heading-xxsmall-5">{{$t('home.pediatric-clinic-title')}} <br>{{$t('home.24-7')}}</h3>
                            <div class="uui-space-xxsmall-5"></div>
                            <div class="uui-text-size-medium-9">{{$t('home.pediatric-clinic-card-description')}}</div>
                          </div>
                          <div class="uui-space-small-7"></div>
                          <div class="uui-button-row-7">
                            <router-link class="uui-button-link-6 w-inline-block" to="/clinics-pediatric">
                              <div>{{$t('home.learn-more')}}</div>
                              <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>

                          </div>
                        </div>
                        <div id="w-node-_67312e13-fde2-4bef-4857-39f7cbb58f1a-e7bcd1ab" class="uui-layout88_item">
                          <div class="uui-layout88_item-content">
                            <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="images/clinic-internal-medicine.svg" class="image-7"></div>
                            <h3 class="uui-heading-xxsmall-5">{{$t('home.internal-medicine-clinic')}}<br>{{$t('home.24-7')}}</h3>
                            <div class="uui-space-xxsmall-5"></div>
<!--                            Descripcion de la clinica-->
                            <div class="uui-text-size-medium-9"></div>
                          </div>
                          <div class="uui-space-small-7"></div>
                          <div class="uui-button-row-7">
                            <router-link class="uui-button-link-6 w-inline-block" to="/clinics-internal-medicine">
                              <div>{{$t('home.learn-more')}}</div>
                              <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>

                          </div>
                        </div>
                        <div id="w-node-_67312e13-fde2-4bef-4857-39f7cbb58f1a-e7bcd1ab" class="uui-layout88_item">
                          <div class="uui-layout88_item-content">
                            <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="images/clinic-ophthalmology.svg" class="image-7"></div>
                            <h3 class="uui-heading-xxsmall-5">{{$t('home.ophthalmology-clinic')}}<br>{{$t('home.24-7')}}</h3>
                            <div class="uui-space-xxsmall-5"></div>
                            <!--                            Descripcion de la clinica-->
                            <div class="uui-text-size-medium-9"></div>
                          </div>
                          <div class="uui-space-small-7"></div>
                          <div class="uui-button-row-7">
                            <router-link class="uui-button-link-6 w-inline-block" to="/clinics-ophthalmology">
                              <div>{{$t('home.learn-more')}}</div>
                              <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>

                          </div>
                        </div>
                        <div id="w-node-_67312e13-fde2-4bef-4857-39f7cbb58f1a-e7bcd1ab" class="uui-layout88_item">
                          <div class="uui-layout88_item-content">
                            <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="images/clinic-trauma.svg" class="image-7"></div>
                            <h3 class="uui-heading-xxsmall-5">{{$t('home.trauma-and-shock-clinic')}}<br>{{$t('home.24-7')}}</h3>
                            <div class="uui-space-xxsmall-5"></div>
                            <!--                            Descripcion de la clinica-->
                            <div class="uui-text-size-medium-9"></div>
                          </div>
                          <div class="uui-space-small-7"></div>
                          <div class="uui-button-row-7">
                            <router-link class="uui-button-link-6 w-inline-block" to="/clinics-shock-and-trauma">
                              <div>{{$t('home.learn-more')}}</div>
                              <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uui-career08_tab-pane w-tab-pane boxes" :class="{ 'w--tab-active': choice === 'servicesActive' }" id="info" v-show="isActiveTab('servicesActive')">
                    <div class="uui-career09_list-wrapper">
                      <div class="uui-career09_list2323">
                        <div id="w-node-_1e9764c6-d9be-0f02-3e86-08251de4ca12-e7bcd1ab" class="uui-layout88_item">
                          <div class="uui-layout88_item-content">
                            <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="../../public/images/ICONO-LABORATORIO.svg" class="image-7"></div>
                            <h3 class="uui-heading-xxsmall-5">{{$t('home.laboratory-and-hospital-transfusion-title')}}</h3>
                            <div class="uui-space-xxsmall-5"></div>
                            <div class="uui-text-size-medium-9">{{$t('home.laboratory-and-hospital-transfusion-card-description')}}</div>
                          </div>
                          <div class="uui-space-small-7"></div>
                          <div class="uui-button-row-7">
                            <router-link class="uui-button-link-6 w-inline-block" to="/services-laboratory">
                              <div>{{$t('home.learn-more')}}</div>
                              <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>

                          </div>
                        </div>


                        <div id="w-node-edd0d72f-aac0-99c5-0cf8-46be48063ab2-e7bcd1ab" class="uui-layout88_item">
                          <div class="uui-layout88_item-content">
                            <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="images/Farmacia-Icon-icon.svg" class="image-7"></div>
                            <h3 class="uui-heading-xxsmall-5">{{$t('home.pharmacy-title')}} <br>{{$t('home.24-hours')}}</h3>
                            <div class="uui-space-xxsmall-5"></div>
                            <div class="uui-text-size-medium-9">{{$t('home.pharmacy-card-description')}}</div>
                          </div>
                          <div class="uui-space-small-7"></div>
                          <div class="uui-button-row-7">
                            <router-link class="uui-button-link-6 w-inline-block" to="/services-laboratory">
                              <div>{{$t('home.learn-more')}}</div>
                              <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>

                          </div>
                        </div>
                        <div id="w-node-_5050c731-3e94-4b38-f59e-d3ee2e33eb8b-e7bcd1ab" class="uui-layout88_item">
                          <div class="uui-layout88_item-content">
                            <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="../../public/images/Hemodialisis-icon.svg" class="image-7"></div>
                            <h3 class="uui-heading-xxsmall-5">{{$t('home.hemodialysis-title')}}</h3>
                            <div class="uui-space-xxsmall-5"></div>
                            <div class="uui-text-size-medium-9">{{$t('home.hemodialysis-card-description')}}</div>
                          </div>
                          <div class="uui-space-small-7"></div>
                          <div class="uui-button-row-7">
                            <router-link class="uui-button-link-6 w-inline-block" to="/services-hemodialysis">
                              <div>{{$t('home.learn-more')}}</div>
                              <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>

                          </div>
                        </div>
                        <div id="w-node-b1ec7fca-a80a-1667-8c0d-f24d1cb45d49-e7bcd1ab" class="uui-layout88_item">
                          <div class="uui-layout88_item-content">
                            <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="../../public/images/Hospitalizacion-icon.svg" class="image-7"></div>
                            <h3 class="uui-heading-xxsmall-5">{{$t('home.hospitalization-title')}}</h3>
                            <div class="uui-space-xxsmall-5"></div>
                            <div class="uui-text-size-medium-9">{{$t('home.hospitalization-card-description')}}</div>
                          </div>
                          <div class="uui-space-small-7"></div>
                          <div class="uui-button-row-7">
                            <router-link  class="uui-button-link-6 w-inline-block" to="/services-hospitalization">
                              <div>{{$t('home.learn-more')}}</div>
                              <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>

                          </div>
                        </div>

                        <div id="w-node-_57b6840a-a3e7-f7fa-1210-815447a6b17c-e7bcd1ab" class="uui-layout88_item">
                          <div class="uui-layout88_item-content">
                            <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="images/Radiologia-e-Imagen-icon.svg" class="image-7"></div>
                            <h3 class="uui-heading-xxsmall-5">{{$t('home.radiology-and-imaging-title')}}</h3>
                            <div class="uui-space-xxsmall-5"></div>
                            <div class="uui-text-size-medium-9">{{$t('home.radiology-and-imaging-card-description')}}</div>
                          </div>
                          <div class="uui-space-small-7"></div>
                          <div class="uui-button-row-7">
                            <router-link class="uui-button-link-6 w-inline-block" to="/services-radiology-imaging">
                              <div>{{$t('home.learn-more')}}</div>
                              <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>

                          </div>
                        </div>



                        <div id="w-node-_6bc6dddb-b729-4b75-9353-59e2c008bf5d-e7bcd1ab" class="uui-layout88_item">
                          <div class="uui-layout88_item-content">
                            <div class="uui-space-xxlarge-2"><img width="179" loading="lazy" alt="" src="images/aseguradoras-icon.svg" class="image-7"></div>
                            <h3 class="uui-heading-xxsmall-5">{{$t('home.insurers-title')}}</h3>
                            <div class="uui-space-xxsmall-5"></div>
                            <div class="uui-text-size-medium-9">{{$t('home.insurers-card-description')}}</div>
                          </div>
                          <div class="uui-space-small-7"></div>
                          <div class="uui-button-row-7">
                            <router-link class="uui-button-link-6 w-inline-block" to="/insurers">
                              <div>{{$t('home.learn-more')}}</div>
                              <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>

                          </div>
                        </div>
                        <div id="w-node-_06f8de63-582b-02a6-52ed-11af8b6e7db3-e7bcd1ab" class="uui-layout88_item">
                          <div class="uui-layout88_item-content">
                            <div class="uui-space-xxlarge-2"><img width="179" sizes="(max-width: 479px) 100vw, 96px" alt="" src="images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1.jpg" loading="lazy" srcset="images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1-p-500.jpg 500w, images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1-p-800.jpg 800w, images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1-p-1080.jpg 1080w, images/logotipo-planes-de-apoyo-en-salud-pas-Hospital-Angel-del-Mar-.1.jpg 1604w" class="image-7"></div>
                            <h3 class="uui-heading-xxsmall-5">{{$t('home.pas')}}</h3>
                            <div class="uui-space-xxsmall-5"></div>
                          </div>
                          <div class="uui-space-small-7"></div>
                          <div class="uui-button-row-7">
                            <router-link class="uui-button-link-6 w-inline-block" to="/services-health-support">
                              <div>{{$t('home.learn-more')}}</div>
                              <div class="uui-button-icon-7 w-embed"><svg width="21" height="20" viewbox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.6665 9.99984H16.3332M16.3332 9.99984L10.4998 4.1665M16.3332 9.99984L10.4998 15.8332" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg></div>
                            </router-link>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uui-career08_tab-pane w-tab-pane boxes" :class="{ 'w--tab-active': choice === 'specialtiesActive' }" id="specialties" v-show="isActiveTab('specialtiesActive')">

                      <div class="uui-career09_list-wrapper">
                        <div class="uui-career09_list2323">
                          <div id="w-node-_5a101aad-ed05-9c94-3d57-06e41f654ea7-e7bcd1ab" class="rl_blog44_item-5">
                            <router-link to="/specialties-family-emergency-medicine"  class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/medicina-familiar-p-500.jpeg 500w, images/medicina-familiar-p-800.jpeg 800w, images/medicina-familiar-p-1080.jpeg 1080w, images/medicina-familiar.jpeg 1480w" alt="" src="images/medicina-familiar.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.family-medicine')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                                <div class="rl-button-link-8">
                                  <div class="rl-button-link-text-8">{{$t('home.learn-more')}}</div>
                                  <div class="rl-button-link-icon-8 w-embed"><svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 3L11 8L6 13" stroke="CurrentColor" stroke-width="1.5"></path>
                                  </svg></div>
                                </div>
                              </div>
                            </router-link>
                          </div>
                          <div id="w-node-_85573014-338b-6e64-1ac2-26d31c44ca7e-e7bcd1ab" class="rl_blog44_item-5-copy">
                            <router-link class="rl_blog44_item-link-5 w-inline-block" to="/specialties-urology">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Urologia-p-500.jpeg 500w, images/Urologia-p-800.jpeg 800w, images/Urologia-p-1080.jpeg 1080w, images/Urologia.jpeg 1480w" alt="" src="images/Urologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.urology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                                <div class="rl-button-link-8">
                                  <div class="rl-button-link-text-8">{{$t('home.learn-more')}}</div>
                                  <div class="rl-button-link-icon-8 w-embed"><svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 3L11 8L6 13" stroke="CurrentColor" stroke-width="1.5"></path>
                                  </svg></div>
                                </div>
                              </div>
                            </router-link>

                          </div>
                          <div id="w-node-f1729aac-c02c-8d5c-3744-8d4a00a1b2ed-e7bcd1ab" class="rl_blog44_item-5">
                            <router-link  to="/specialties-internal-medicine" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/medicina-interna-p-500.jpeg 500w, images/medicina-interna-p-800.jpeg 800w, images/medicina-interna-p-1080.jpeg 1080w, images/medicina-interna.jpeg 1480w" alt="" src="images/medicina-interna.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.internal-medicine')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                                <div class="rl-button-link-8">
                                  <div class="rl-button-link-text-8">{{$t('home.learn-more')}}</div>
                                  <div class="rl-button-link-icon-8 w-embed"><svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 3L11 8L6 13" stroke="CurrentColor" stroke-width="1.5"></path>
                                  </svg></div>
                                </div>
                              </div>
                            </router-link>
                          </div>
                          <div id="w-node-_83595551-846d-5f0d-7add-11e61b472bcc-e7bcd1ab" class="rl_blog44_item-5">
                            <router-link class="rl_blog44_item-link-5 w-inline-block" to="/specialties-traumatology-orthopedics">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/traumatologia-y-ortopedia-p-500.jpeg 500w, images/traumatologia-y-ortopedia-p-800.jpeg 800w, images/traumatologia-y-ortopedia-p-1080.jpeg 1080w, images/traumatologia-y-ortopedia.jpeg 1480w" alt="" src="images/traumatologia-y-ortopedia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.traumatology-and-orthopedic')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                                <div class="rl-button-link-8">
                                  <div class="rl-button-link-text-8">{{$t('home.learn-more')}}</div>
                                  <div class="rl-button-link-icon-8 w-embed"><svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 3L11 8L6 13" stroke="CurrentColor" stroke-width="1.5"></path>
                                  </svg></div>
                                </div>
                              </div>
                            </router-link>

                          </div>
                          <div id="w-node-ed43e99f-a955-b94f-c553-9ca84e6925a4-e7bcd1ab" class="rl_blog44_item-5">
                            <router-link class="rl_blog44_item-link-5 w-inline-block" to="/specialties-ophthalmology">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/oftalmologia-p-500.jpeg 500w, images/oftalmologia-p-800.jpeg 800w, images/oftalmologia-p-1080.jpeg 1080w, images/oftalmologia.jpeg 1480w" alt="" src="images/oftalmologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.ophthalmology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                                <div class="rl-button-link-8">
                                  <div class="rl-button-link-text-8">{{$t('home.learn-more')}}</div>
                                  <div class="rl-button-link-icon-8 w-embed"><svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 3L11 8L6 13" stroke="CurrentColor" stroke-width="1.5"></path>
                                  </svg></div>
                                </div>
                              </div>
                            </router-link>

                          </div>
                          <div id="w-node-_8f1838e1-cca7-bccf-87d3-4007c8485472-e7bcd1ab" class="rl_blog44_item-5">
                            <router-link class="rl_blog44_item-link-5 w-inline-block" to="/specialties-dermatology">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Dermatologia-p-500.jpeg 500w, images/Dermatologia-p-800.jpeg 800w, images/Dermatologia-p-1080.jpeg 1080w, images/Dermatología.jpeg 1480w" alt="" src="images/Dermatología.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.dermatology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                                <div class="rl-button-link-8">
                                  <div class="rl-button-link-text-8">{{$t('home.learn-more')}}</div>
                                  <div class="rl-button-link-icon-8 w-embed"><svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 3L11 8L6 13" stroke="CurrentColor" stroke-width="1.5"></path>
                                  </svg></div>
                                </div>
                              </div>
                            </router-link>

                          </div>
                          <div id="w-node-dd6ddcaa-9558-57d2-73a2-6e12a7294efe-e7bcd1ab" class="rl_blog44_item-5">
                            <router-link to="/clinics-womens" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/ginecologia-p-500.jpeg 500w, images/ginecologia-p-800.jpeg 800w, images/ginecologia-p-1080.jpeg 1080w, images/ginecologia.jpeg 1480w" alt="" src="images/ginecologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.gynecology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </router-link>
                          </div>
                          <div id="w-node-_324510e2-f4ef-b875-ce4c-0903a1e9ffc0-e7bcd1ab" class="rl_blog44_item-5">
                            <router-link to="/clinics-pediatric" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/neuricirugia-pediatrica-p-500.jpeg 500w, images/neuricirugia-pediatrica-p-800.jpeg 800w, images/neuricirugia-pediatrica-p-1080.jpeg 1080w, images/neuricirugia-pediatrica.jpeg 1480w" alt="" src="images/neuricirugia-pediatrica.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.pediatric-neurosurgery')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </router-link>
                          </div>
                          <div id="w-node-_9f60d384-e621-c7f4-31cb-0dd3f644445b-e7bcd1ab" class="rl_blog44_item-5">
                            <router-link to="/clinics-pediatric" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/cardiologia-pediatrica-p-500.jpeg 500w, images/cardiologia-pediatrica-p-800.jpeg 800w, images/cardiologia-pediatrica-p-1080.jpeg 1080w, images/cardiologia-pediatrica.jpeg 1480w" alt="" src="images/cardiologia-pediatrica.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.pediatric-cardiology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </router-link>
                          </div>
                          <div id="w-node-_24ac0206-e6f6-8eba-d8e0-61f1a194d6be-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/audiologia-p-500.jpeg 500w, images/audiologia-p-800.jpeg 800w, images/audiologia-p-1080.jpeg 1080w, images/audiologia.jpeg 1480w" alt="" src="images/audiologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.audiology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-a2056937-63ec-f5ea-1233-644f5884dcdf-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Otorrinolaringologia-p-500.jpeg 500w, images/Otorrinolaringologia-p-800.jpeg 800w, images/Otorrinolaringologia-p-1080.jpeg 1080w, images/Otorrinolaringologia.jpeg 1480w" alt="" src="images/Otorrinolaringologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.otolaryngology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_18521fb4-1e72-ae0f-e989-765b140a1839-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/odontologia-p-500.jpeg 500w, images/odontologia-p-800.jpeg 800w, images/odontologia-p-1080.jpeg 1080w, images/odontologia.jpeg 1480w" alt="" src="images/odontologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.odontology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_0f37aa99-fe89-e5a5-a067-a1f1740f07bb-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/Fisioterapia-y-Rehabilitacion-p-500.jpeg 500w, images/Fisioterapia-y-Rehabilitacion-p-800.jpeg 800w, images/Fisioterapia-y-Rehabilitacion-p-1080.jpeg 1080w, images/Fisioterapia-y-Rehabilitacion.jpeg 1480w" alt="" src="images/Fisioterapia-y-Rehabilitacion.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.physiotherapy-and-rehabilitation')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_447dba97-74c8-1b1d-ee6e-a1ec56df22ca-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Oncologia-Clinica-p-500.jpeg 500w, images/Oncologia-Clinica-p-800.jpeg 800w, images/Oncologia-Clinica-p-1080.jpeg 1080w, images/Oncologia-Clinica.jpeg 1480w" alt="" src="images/Oncologia-Clinica.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.oncology-clinic')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-cb44c2b9-0bd3-0243-7213-e7915cea5056-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Oncologia-Quirurgica-p-500.jpeg 500w, images/Oncologia-Quirurgica-p-800.jpeg 800w, images/Oncologia-Quirurgica-p-1080.jpeg 1080w, images/Oncologia-Quirurgica.jpeg 1480w" alt="" src="images/Oncologia-Quirurgica.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.oncology-surgery')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_6b433549-5670-6509-9414-1fa37b877a60-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/cardiologia-pediatrica-p-500.jpeg 500w, images/cardiologia-pediatrica-p-800.jpeg 800w, images/cardiologia-pediatrica-p-1080.jpeg 1080w, images/cardiologia-pediatrica.jpeg 1480w" alt="" src="images/cardiologia-pediatrica.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.cardiology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_97ea3b53-c8f8-cb0b-0e60-6618e9081bd0-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Reumatologia-p-500.jpeg 500w, images/Reumatologia-p-800.jpeg 800w, images/Reumatologia-p-1080.jpeg 1080w, images/Reumatologia.jpeg 1480w" alt="" src="images/Reumatologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.rheumatology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_6bae8f8c-55ed-b3c4-a8ed-8ddebabc3117-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Cirugia-General-p-500.jpeg 500w, images/Cirugia-General-p-800.jpeg 800w, images/Cirugia-General-p-1080.jpeg 1080w, images/Cirugia-General.jpeg 1480w" alt="" src="images/Cirugia-General.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.general-surgery')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_96543a0a-2617-61d1-19f4-8fb71450a2f9-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/Nefrologia-p-500.jpeg 500w, images/Nefrologia-p-800.jpeg 800w, images/Nefrologia.jpeg 1060w" alt="" src="images/Nefrologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.nephrology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-f8f01988-6053-f6ae-f693-7a23fe75ca2f-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Neurocirugia-p-500.jpeg 500w, images/Neurocirugia-p-800.jpeg 800w, images/Neurocirugia-p-1080.jpeg 1080w, images/Neurocirugia.jpeg 1480w" alt="" src="images/Neurocirugia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.neurosurgery')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_5e68f55d-f181-66a4-0b9e-a44905d34446-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Neurologia-p-500.jpeg 500w, images/Neurologia-p-800.jpeg 800w, images/Neurologia-p-1080.jpeg 1080w, images/Neurologia.jpeg 1480w" alt="" src="images/Neurologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.neurology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_07aa0f57-b13a-761c-18f6-e34997f0b8ef-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/Cirugia-Plastica-p-500.jpeg 500w, images/Cirugia-Plastica-p-800.jpeg 800w, images/Cirugia-Plastica-p-1080.jpeg 1080w, images/Cirugia-Plastica.jpeg 1480w" alt="" src="images/Cirugia-Plastica.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.plastic-surgery')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_1693c233-fc53-9b95-e478-8b0f66af81a4-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Endocrinologia-p-500.jpeg 500w, images/Endocrinologia-p-800.jpeg 800w, images/Endocrinologia-p-1080.jpeg 1080w, images/Endocrinologia.jpeg 1480w" alt="" src="images/Endocrinologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.endocrinology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_58934503-739f-e024-33a3-f0082926f8ee-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Gastroenterologia-p-500.jpeg 500w, images/Gastroenterologia-p-800.jpeg 800w, images/Gastroenterologia-p-1080.jpeg 1080w, images/Gastroenterologia.jpeg 1480w" alt="" src="images/Gastroenterologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.gastroenterology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-c8ebb9e2-c0d1-40e3-873e-8fbebeedf04b-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/Coloproctologia-p-500.jpeg 500w, images/Coloproctologia-p-800.jpeg 800w, images/Coloproctologia.jpeg 1060w" alt="" src="images/Coloproctologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.coloproctology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_79e96d85-f619-da34-ac30-34795d93e7df-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Angiologia-p-500.jpeg 500w, images/Angiologia-p-800.jpeg 800w, images/Angiologia-p-1080.jpeg 1080w, images/Angiologia.jpeg 1480w" alt="" src="images/Angiologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.angiology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_112bcd20-950f-fa89-67f4-b71f4c4c3858-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Psicologia-p-500.jpeg 500w, images/Psicologia-p-800.jpeg 800w, images/Psicologia-p-1080.jpeg 1080w, images/Psicologia.jpeg 1480w" alt="" src="images/Psicologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.psychology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-cb9a884b-31ee-9a1e-a47b-322c802513d7-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/Infectologia-p-500.jpeg 500w, images/Infectologia-p-800.jpeg 800w, images/Infectologia-p-1080.jpeg 1080w, images/Infectologia.jpeg 1380w" alt="" src="images/Infectologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.infectology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_0a2ef3fd-3b0c-fe37-faf0-3f6600d030d6-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Hematologia-p-500.jpeg 500w, images/Hematologia-p-800.jpeg 800w, images/Hematologia-p-1080.jpeg 1080w, images/Hematologia.jpeg 1480w" alt="" src="images/Hematologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.hematology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-ccd91f5e-f09f-66e2-3042-684be90aad15-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Heridas-y-Estomas-p-500.jpeg 500w, images/Heridas-y-Estomas-p-800.jpeg 800w, images/Heridas-y-Estomas-p-1080.jpeg 1080w, images/Heridas-y-Estomas.jpeg 1480w" alt="" src="images/Heridas-y-Estomas.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.wounds-and-stomata')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_75e60ad3-7a8f-e4a5-8620-35f099b82e81-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/Biologia-de-la-Reproduccion-p-500.jpeg 500w, images/Biologia-de-la-Reproduccion-p-800.jpeg 800w, images/Biologia-de-la-Reproduccion-p-1080.jpeg 1080w, images/Biologia-de-la-Reproduccion.jpeg 1480w" alt="" src="images/Biologia-de-la-Reproduccion.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.reproductive-biology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_04df96bd-03fe-4ce9-6c25-13eed6b419bc-e7bcd1ab" class="rl_blog44_item-5">
                            <router-link to="/clinics-pediatric" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Pediatria-p-500.jpeg 500w, images/Pediatria-p-800.jpeg 800w, images/Pediatria-p-1080.jpeg 1080w, images/Pediatria.jpeg 1480w" alt="" src="images/Pediatria.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.pediatric')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </router-link>
                          </div>
                          <div id="w-node-f8de291b-701e-6a51-5829-b4199ddfbf8c-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Urgencias-y-Area-de-Choque-p-500.jpeg 500w, images/Urgencias-y-Area-de-Choque-p-800.jpeg 800w, images/Urgencias-y-Area-de-Choque-p-1080.jpeg 1080w, images/Urgencias-y-Area-de-Choque.jpeg 1480w" alt="" src="images/Urgencias-y-Area-de-Choque.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.emergency-and-shock-area')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-_9e4b7f52-f634-4b3c-d47c-f6a2d62ec277-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.53125px, 423.59375px" srcset="images/Urgenciologos-y-Generales-p-500.jpeg 500w, images/Urgenciologos-y-Generales-p-800.jpeg 800w, images/Urgenciologos-y-Generales-p-1080.jpeg 1080w, images/Urgenciologos-y-Generales.jpeg 1480w" alt="" src="images/Urgenciologos-y-Generales.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.emergency-and-general-practitioners')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                          <div id="w-node-c636765c-2e81-b73c-d12a-fd2213405f40-e7bcd1ab" class="rl_blog44_item-5">
                            <a href="#" class="rl_blog44_item-link-5 w-inline-block">
                              <div class="rl_blog44_image-wrapper-5"><img sizes="(max-width: 479px) 100vw, (max-width: 767px) 88vw, (max-width: 991px) 45vw, (max-width: 1279px) 29vw, (max-width: 1439px) 374.546875px, 423.609375px" srcset="images/Nutriologia-p-500.jpeg 500w, images/Nutriologia-p-800.jpeg 800w, images/Nutriologia-p-1080.jpeg 1080w, images/Nutriologia.jpeg 1480w" alt="" src="images/Nutriologia.jpeg" loading="lazy" class="rl_blog44_image-5"></div>
                              <div class="rl_blog44_item-content-5">
                                <div class="rl_blog44_item-content-top-5">
                                  <div class="rl_blog44_spacing-block-4-5"></div>
                                  <h3 class="rl-heading-style-h5-6">{{$t('home.nutriology')}}</h3>
                                  <div class="rl_blog44_spacing-block-5-5"></div>
                                </div>
                                <div class="rl_blog44_spacing-block-6-5"></div>
                              </div>
                            </a>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <section class="uui-section_layout70">
                <div class="uui-page-padding-12">
                  <div class="uui-container-large-9">
                    <div class="uui-padding-vertical-xhuge-12">
                      <div class="w-layout-grid uui-layout70_component">
                        <div class="uui-layout70_item">
                          <div class="uui-layout70_image-wrapper"><img src="images/ULTIMO.png" loading="lazy" alt="Mockup" class="uui-layout70_image"></div>
                        </div>
                        <div class="uui-layout70_item">
                          <div class="uui-layout70_image-wrapper"><img src="images/1.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 31vw, (max-width: 1279px) 32vw, (max-width: 1439px) 405.328125px, (max-width: 1919px) 426.671875px, 458.671875px" height="529" alt="Mockup" srcset="images/1-p-500.png 500w, images/1.png 529w" class="uui-layout70_image"></div>
                          <div class="uui-button-row-7 button-row-center">
                            <a data-w-id="a7c2b677-759e-55f0-9368-741c6146b1bb" href="#" class="uui-button-link-6 w-inline-block"></a>
                          </div>
                        </div>
                        <div class="uui-layout70_item">
                          <div class="uui-layout70_image-wrapper"><img src="images/2.png" loading="lazy" alt="Mockup" class="uui-layout70_image"></div>
                          <div class="uui-space-xxsmall-5"></div>
                          <div class="uui-button-row-7 button-row-center">
                            <a data-w-id="a7c2b677-759e-55f0-9368-741c6146b1c9" href="#" class="uui-button-link-6 w-inline-block"></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <section class="uui-section_career09">
              <div class="uui-page-padding-6">
                <section class="uui-section_blogsection12">
                  <div class="uui-page-padding-7">
                    <div class="uui-container-large-5"></div>
                  </div>
                </section>
                <div class="uui-container-small"></div>
              </div>
            </section>
          </div>
          <section class="rl_section_testimonial17">
            <div class="rl-padding-global-10">
              <div class="rl-container-large-8">
                <div class="rl-padding-section-large-9">
                  <div class="rl_testimonial17_component">
                    <div class="rl_testimonial17_heading-wrapper">
                      <h2 class="rl-heading-style-h2-8">{{$t('home.real-testimonials')}}</h2>
                      <div class="rl_testimonial17_spacing-block-1"></div>
                    </div>
                    <div class="rl_testimonial17_spacing-block-2"></div>
                    <div class="rl_testimonial17_testimonial-list">
                      <div v-for="(comment, index) in comments" :key="index" class="rl_testimonial17_testimonial">
                        <div class="rl_testimonial17_spacing-block-4"></div>
                        <div class="rl-text-style-medium-10">“{{comment.content}}”</div>
                        <div class="rl_testimonial17_spacing-block-4"></div>
                        <div class="rl_testimonial17_customer">
                          <div class="rl_testimonial17_customer-image-wrapper"><img v-bind:src="comment.user.profile_picture" loading="lazy" alt="" class="rl_testimonial17_customer-image"></div>
                          <div class="rl_testimonial17_customer-info">
                            <p class="rl_testimonial17_name-text">{{comment.user.name}}</p>
                            <p class="rl-text-style-regular-8">{{comment.user.position}}</p>
                          </div>
                        </div>
                      </div>
                      <!--                      <div class="rl_testimonial17_testimonial">-->
                      <!--                        <div class="rl_testimonial17_spacing-block-4"></div>-->
                      <!--                        <div class="rl-text-style-medium-10">“No puedo expresar mas que mis agradecimientos por muy buen ambiente y excelente atención que se me ha brindado. Continuamente están innovando ellos mismos la cual hace que la atención sea de primera.”</div>-->
                      <!--                        <div class="rl_testimonial17_spacing-block-4"></div>-->
                      <!--                        <div class="rl_testimonial17_customer">-->
                      <!--                          <div class="rl_testimonial17_customer-image-wrapper"><img src="../../public/images/Nelson-Tinder.png" loading="lazy" alt="" class="rl_testimonial17_customer-image"></div>-->
                      <!--                          <div class="rl_testimonial17_customer-info">-->
                      <!--                            <p class="rl_testimonial17_name-text">Nelson Tinder</p>-->
                      <!--                          </div>-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                      <!--                      <div class="rl_testimonial17_testimonial">-->
                      <!--                        <div class="rl_testimonial17_spacing-block-4"></div>-->
                      <!--                        <div class="rl-text-style-medium-10">“Los médicos, las enfermeras y los asistentes fueron excelentes. La comida fue excelente Me siento bien, de vuelta a mi antiguo yo y se siente genial. Gracias a Dios por hospitales como esta.”</div>-->
                      <!--                        <div class="rl_testimonial17_spacing-block-4"></div>-->
                      <!--                        <div class="rl_testimonial17_customer">-->
                      <!--                          <div class="rl_testimonial17_customer-image-wrapper"><img src="../../public/images/Maria-Jackson.png" loading="lazy" alt="" class="rl_testimonial17_customer-image"></div>-->
                      <!--                          <div class="rl_testimonial17_customer-info">-->
                      <!--                            <p class="rl_testimonial17_name-text">Maria Jackson</p>-->
                      <!--                            <p class="rl-text-style-regular-8">Profesora</p>-->
                      <!--                          </div>-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  </header>
  <section class="uui-section_contact07">
    <div class="uui-page-padding-35">
      <div class="uui-container-large-25"></div>
    </div>
  </section>
  <section class="uui-section_contact07-2">
    <div class="uui-page-padding-45">
      <div class="uui-container-large-35">
        <div class="uui-padding-vertical-xhuge-46">
          <div class="w-layout-grid uui-contact07_component-2">
            <div class="uui-contact07_content-left-2">
              <div class="uui-max-width-large-21">
                <div class="uui-heading-subheading-24">{{$t('home.health-clic')}}</div>
                <h2 class="uui-heading-medium-30">{{$t('home.we-are-here-to-help')}}</h2>
                <div class="uui-space-xsmall-23"></div>
                <div class="uui-text-size-large-33">{{$t('home.contact-us-phrase')}}</div>
              </div>
            </div>
            <div class="uui-contact07_content-right-2">
              <div class="w-layout-grid uui-contact07_contact-list-2">
                <div id="w-node-_467eb8d5-9509-cdf7-bc56-3e49fb31825f-e7bcd1ab" class="uui-contact07_item-2">
                  <div class="uui-contact07_item-icon-wrapper-2">
                    <div class="icon-featured-large-3">
                      <div class="uui-icon-1x1-xsmall-10 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg></div>
                    </div>
                  </div>
                  <div class="uui-contact07_item-text-wrapper-2">
                    <h3 class="uui-heading-xxsmall-22">{{$t('home.our-email')}}</h3>
                    <div class="uui-space-xxsmall-17"></div>
                    <a href="mailto:contacto@hospitalangeldelmar.com.mx" class="uui-text-style-link-02-4">contacto@hospitalangeldelmar.com.mx</a>
                  </div>
                </div>
                <div id="w-node-_467eb8d5-9509-cdf7-bc56-3e49fb318269-e7bcd1ab" class="uui-contact07_item-2">
                  <div class="uui-contact07_item-icon-wrapper-2">
                    <div class="icon-featured-large-3">
                      <div class="uui-icon-1x1-xsmall-10 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.38028 8.85323C9.07627 10.3028 10.0251 11.6615 11.2266 12.8631C12.4282 14.0646 13.7869 15.0134 15.2365 15.7094C15.3612 15.7693 15.4235 15.7992 15.5024 15.8222C15.7828 15.904 16.127 15.8453 16.3644 15.6752C16.4313 15.6274 16.4884 15.5702 16.6027 15.4559C16.9523 15.1063 17.1271 14.9315 17.3029 14.8172C17.9658 14.3862 18.8204 14.3862 19.4833 14.8172C19.6591 14.9315 19.8339 15.1063 20.1835 15.4559L20.3783 15.6508C20.9098 16.1822 21.1755 16.448 21.3198 16.7333C21.6069 17.3009 21.6069 17.9712 21.3198 18.5387C21.1755 18.8241 20.9098 19.0898 20.3783 19.6213L20.2207 19.7789C19.6911 20.3085 19.4263 20.5733 19.0662 20.7756C18.6667 21 18.0462 21.1614 17.588 21.16C17.1751 21.1588 16.8928 21.0787 16.3284 20.9185C13.295 20.0575 10.4326 18.433 8.04466 16.045C5.65668 13.6571 4.03221 10.7947 3.17124 7.76131C3.01103 7.19687 2.93092 6.91464 2.9297 6.5017C2.92833 6.04347 3.08969 5.42298 3.31411 5.02348C3.51636 4.66345 3.78117 4.39863 4.3108 3.86901L4.46843 3.71138C4.99987 3.17993 5.2656 2.91421 5.55098 2.76987C6.11854 2.4828 6.7888 2.4828 7.35636 2.76987C7.64174 2.91421 7.90747 3.17993 8.43891 3.71138L8.63378 3.90625C8.98338 4.25585 9.15819 4.43065 9.27247 4.60643C9.70347 5.26932 9.70347 6.1239 9.27247 6.78679C9.15819 6.96257 8.98338 7.13738 8.63378 7.48698C8.51947 7.60129 8.46231 7.65845 8.41447 7.72526C8.24446 7.96269 8.18576 8.30695 8.26748 8.5873C8.29048 8.6662 8.32041 8.72854 8.38028 8.85323Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg></div>
                    </div>
                  </div>
                  <div class="uui-contact07_item-text-wrapper-2">
                    <h3 class="uui-heading-xxsmall-22">{{$t('home.call-us')}}</h3>
                    <div class="uui-space-xxsmall-17"></div>
                    <a href="tel:9541042270" class="uui-text-style-link-02-4">954 104 2270</a>
                  </div>
                </div>
                <div id="w-node-_467eb8d5-9509-cdf7-bc56-3e49fb318273-e7bcd1ab" class="uui-contact07_item-2">
                  <div class="uui-contact07_item-icon-wrapper-2">
                    <div class="icon-featured-large-3">
                      <div class="uui-icon-1x1-xsmall-10 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12.5C13.6569 12.5 15 11.1569 15 9.5C15 7.84315 13.6569 6.5 12 6.5C10.3431 6.5 9 7.84315 9 9.5C9 11.1569 10.3431 12.5 12 12.5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M12 22C14 18 20 15.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 15.4183 10 18 12 22Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg></div>
                    </div>
                  </div>
                  <div class="uui-contact07_item-text-wrapper-2">
                    <h3 class="uui-heading-xxsmall-22">{{$t('home.located-at')}}</h3>
                    <div class="uui-space-xxsmall-17"></div>
                    <a href="https://maps.app.goo.gl/VXS49U4WeCpr57qX6" target="_blank" class="uui-text-style-link-02-4">{{$t('home.ham-location')}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="uui-contact07_map-wrapper-2"><img src="images/image-87.png" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, 1280px" srcset="images/image-87-p-500.png 500w, images/image-87-p-800.png 800w, images/image-87-p-1080.png 1080w, images/image-87.png 1440w" alt="Google Maps placeholder image" class="uui-contact07_map-placeholder-2">
            <div class="uui-contact07_map-2 w-widget w-widget-map" data-widget-style="roadmap"  data-enable-scroll="true" role="" title="" data-enable-touch="true" data-widget-zoom="12" data-widget-tooltip=""></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
@keyframes slideInOut {
  0% { opacity: 0; transform: translateX(-20px); }
  50% { opacity: 1; transform: translateX(0); }
}

.boxes {
  animation: slideInOut .5s ease-in-out; /* Ajusta la duración y la función de temporización según tus necesidades */
}

.boxes.active {
  animation: none; /* Detiene la animación cuando está activo */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active en <2.1.8 */ {
  opacity: 0;
}
</style>
