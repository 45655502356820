<script>
import {LOCALE_COOKIE_NAME, SITE_DOMAIN} from "@/config";

export default {
  name: "LocaleComponent",
  data() {
    return {
      locale:'es',
      showLocaleNav:false
    };
  },
  mounted() {


    document.addEventListener('click', this.localeToggle);
  },

  beforeMount(){
    let cookieName = LOCALE_COOKIE_NAME;
    //let localeCookie="";
    let cookiesArray = document.cookie.split(";");
    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();
      // Verificar si la cookie comienza con el nombre deseado
      if (cookie.indexOf(cookieName + "=") === 0) {
        // Decodificar y devolver el valor de la cookie utilizando decodeURIComponent
        this.locale = decodeURIComponent(cookie.substring(cookieName.length + 1));
       return;
      }
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.localeToggle);
  },
  methods: {
    localeToggle(event) {
      const nav = this.$refs.submenu;
      const toggle = this.$refs.toggle;
      if (nav && !nav.contains(event.target) && toggle && !toggle.contains(event.target)) {
        this.showNavLocaleClick()
      }
    },
    showNavLocaleClick(){
      this.showLocaleNav=!this.showLocaleNav;
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      this.locale=locale;
      //localStorage.setItem('preferredLanguage', locale);
     // document.cookie = `${LOCALE_COOKIE_NAME}= ${locale};domain=${SITE_DOMAIN}; path=/; Secure`;
      document.cookie = document.cookie = LOCALE_COOKIE_NAME+"="+locale+"; domain="+SITE_DOMAIN+"; path=/; Secure";
      this.$emitter.emit('languageChanged', locale);
      this.showLocaleNav=!this.showLocaleNav;
    },

  },
};
</script>

<template>


  <div data-hover="false" data-delay="0" class="f-dropdown w-dropdown">
    <div @click="showNavLocaleClick" data-w-id="01e410d4-4a2c-d146-f965-7e35cf6f77d0" class="f-dropdown-toggle w-dropdown-toggle" ref="toggle">
      <div class="f-paragraph-small-3">{{locale}}</div>
      <div class="f-dropdown-icon-l w-icon-dropdown-toggle" :class="{ 'rotate': showLocaleNav }"></div>
    </div>
    <nav v-if="showLocaleNav" class="f-dropdown-list w-dropdown-list animate__animated  animate__fadeIn w--open" ref="submenu">
      <div class="f-dropdown-wrap">
        <a  @click="changeLanguage('es')" class="f-dropdown-link w-dropdown-link">Español</a>
        <a  @click="changeLanguage('en')" class="f-dropdown-link w-dropdown-link">English</a>
      </div>
    </nav>
  </div>


</template>

<style scoped>
.rotate {
  transform: rotate(180deg);
  transition: transform 0.2s ease; /* Agrega una transición suave para un efecto más agradable */
}
</style>
