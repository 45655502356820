<script >
import {app} from "@/main";

export default {
  name: "FaqsPage",
  data() {
    return {
      faqs:[],
      showAnswer: false,
    };
  },
  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });
  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods:{
    toggleAnswer(index) {
      console.log(index)
      this.faqs[index].showAnswer = !this.faqs[index].showAnswer;
    },
    loadData(locale){
      this.faqs =  require(`../data/faqs.json`);
      this.faqs = this.faqs.map(faq => {
        const translatedQuestion = faq.question[locale];
        const translatedAnswer = faq.answer[locale];
        return {
          ...faq,
          question: translatedQuestion,
          answer: translatedAnswer,

        };
      });
    },
  }


}
</script>

<template>
  <section class="uui-section_faq01-4">
    <div class="uui-page-padding-36">
      <div class="uui-container-large-26">
        <div class="uui-padding-vertical-xhuge-36">
          <div class="uui-text-align-center-12">
            <div class="uui-max-width-large-17 align-center">
              <h2 class="uui-heading-medium-23">{{$t('home.faqs')}}</h2>
              <div class="uui-space-xsmall-18"></div>
              <div class="uui-text-size-large-26">{{$t('faqs.faqs-description')}}</div>
            </div>
          </div>
          <div class="uui-faq01_component-4">
            <div class="uui-faq01_list-4">
              <div v-for="(item, index) in faqs" :key="index" class="uui-faq01_accordion-4">
                <div @click="toggleAnswer(index)" data-w-id="3ad72784-a5f9-25b0-4ad2-9edadabc1279" class="uui-faq01_question-4 ">
                  <div class="uui-faq01_heading-4"><strong class="bold-text-9">{{ item.question }}</strong></div>
                  <div class="uui-faq01_icon-wrapper-4">
                      <div class="accordion-icon_component-5">
                        <div  class="accordion-icon_horizontal-line-5"></div>
                        <div v-show="!item.showAnswer" class="accordion-icon_vertical-line-5"></div>
                      </div>
                  </div>
                </div>
                <transition name="fade">
                  <div v-show="item.showAnswer" style="height:70px;display: none" >
                    <div class="uui-text-size-medium-27">{{ item.answer }}</div>
                    <div class="uui-space-medium-11"></div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

.accordion-faq{
  height:70px;display: none
}
</style>
