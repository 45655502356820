<script >
// import OurSpecialties from "@/components/OurSpecialties.vue";
// import AddressComponent from "@/components/AddressComponent.vue";
// import AppointmentComponent from "@/components/AppointmentComponent.vue";

import AddressComponent from "@/components/AddressComponent.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import OurSpecialties from "@/components/OurSpecialties.vue";
import AppointmentComponent from "@/components/AppointmentComponent.vue";

export default {
  name: "FamilyEmergencyMedicinePage",
  components: { AppointmentComponent, OurSpecialties, SideContactComponent, AddressComponent },
  // components: {AppointmentComponent, AddressComponent, OurSpecialties}
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_276ed020-a3a4-d708-a757-fa17016a4599-9c7c6cce" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="276ed020-a3a4-d708-a757-fa17016a459c" to="/"
                  class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699"
                        stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div>{{$t('family-medicine.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('family-medicine.title')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('family-medicine.subtitle')}}
              </div>
              <address-component></address-component>
            </div>
            <div id="w-node-_276ed020-a3a4-d708-a757-fa17016a45a6-9c7c6cce" class="uui-blogpost05_image-wrapper-7"><img
                srcset="../../../public/images/MEDICOS-EN-URGENCIAS-1-p-500.jpg 500w, ../../../public/images/MEDICOS-EN-URGENCIAS-1-p-800.jpg 800w, ../../../public/images/MEDICOS-EN-URGENCIAS-1-p-1080.jpg 1080w, ../../../public/images/MEDICOS-EN-URGENCIAS-1-p-1600.jpg 1600w, ../../../public/images/MEDICOS-EN-URGENCIAS-1-p-2000.jpg 2000w, ../../../public/images/MEDICOS-EN-URGENCIAS-1-p-2600.jpg 2600w, ../../../public/images/MEDICOS-EN-URGENCIAS-1-p-3200.jpg 3200w, ../../../public/images/MEDICOS-EN-URGENCIAS-1.jpg 6960w"
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 44vw, (max-width: 1919px) 46vw, 768px"
                src="../../../public/images/MEDICOS-EN-URGENCIAS-1.jpg" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">

              <our-specialties></our-specialties>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-_032b4b60-8589-706f-57d2-b8d760697eae-9c7c6cce" class="uui-blogpost05_content-2">
              <div>
                <div class="uui-text-rich-text-3 w-richtext">
                  <h3>{{$t('family-medicine.article-1')}}</h3>
                  <p>{{$t('family-medicine.article-1-p1')}}</p>
                  <p>{{$t('family-medicine.article-1-p2')}}</p>
                </div>
                <div class="w-layout-grid item_medico">
                  <div class="card_doctor">
                    <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR.-OMAR.jpg" loading="lazy"
                        sizes="84px"
                        srcset="../../../public/images/DR.-OMAR-p-500.jpg 500w, ../../../public/images/DR.-OMAR-p-800.jpg 800w, ../../../public/images/DR.-OMAR-p-1080.jpg 1080w, ../../../public/images/DR.-OMAR-p-1600.jpg 1600w, ../../../public/images/DR.-OMAR-p-2000.jpg 2000w, ../../../public/images/DR.-OMAR-p-2600.jpg 2600w, ../../../public/images/DR.-OMAR-p-3200.jpg 3200w, ../../../public/images/DR.-OMAR.jpg 6960w"
                        alt="" class="uui-team07_image-2"></div>
                    <div class="uui-team07_item-content-2">
                      <div class="uui-heading-tiny-4">Dr. Omar López Pérez</div>
                      <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 760538</div>
                      <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 3777009</div>
                      <div class="uui-space-xxsmall-8"></div>
                      <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr1-description')}}</div>
                    </div>
                  </div>
                </div>
                <div class="uui-text-rich-text-3 w-richtext">
                  <h4>{{$t('family-medicine.article-2')}}</h4>
                  <p>{{$t('family-medicine.article-2-p1')}}</p>
                </div>
              </div>
              <div class="uui-text-rich-text-3 w-richtext">
                <figure style="max-width:1680px" class="w-richtext-align-fullwidth w-richtext-figure-type-image">
                  <div><img src="../../../public/images/AREA-DE-URGENCIAS-CAMA-.jpg" loading="lazy" alt=""></div>
                </figure>
              </div>
              <div class="div-block-22">
                <a href="#" class="sysf-card sysf-card-box sysf-s-p-24 w-inline-block">
                  <div class="div-block-16">
                    <h3 class="sysf-heading sysf-xs">{{$t('family-medicine.installations')}}</h3>
                    <p class="sysf-body-text sysf-m sysf-s-m-0">{{$t('family-medicine.installations-content')}}</p>
                  </div>
                </a>
                <a href="#" class="sysf-card sysf-card-box sysf-s-p-24 w-inline-block">
                  <div class="div-block-17">
                    <h3 class="sysf-heading sysf-xs">{{$t('family-medicine.health')}}</h3>
                    <p class="sysf-body-text sysf-m sysf-s-m-0">{{$t('family-medicine.health-content')}}</p>
                  </div>
                </a>
              </div>
              <section class="team_seccion">
                <div class="uui-page-padding-18">
                  <div class="uui-container-large-14">
                    <div class="uui-padding-vertical-xhuge-20">
                      <div class="uui-max-width-large-9">
                        <h3>{{$t('family-medicine.medical-team')}}</h3>
                        <div class="uui-space-xsmall-10"></div>
                      </div>
                      <div class="uui-team07_component-2">
                        <div class="uui-team07_list-wrapper-2">
                          <div class="w-layout-grid item_medico">
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR-RAIBEL.jpg"
                                  loading="lazy" sizes="84px"
                                  srcset="../../../public/images/DR-RAIBEL-p-500.jpg 500w, ../../../public/images/DR-RAIBEL-p-800.jpg 800w, ../../../public/images/DR-RAIBEL-p-1080.jpg 1080w, ../../../public/images/DR-RAIBEL-p-1600.jpg 1600w, ../../../public/images/DR-RAIBEL-p-2000.jpg 2000w, ../../../public/images/DR-RAIBEL-p-2600.jpg 2600w, ../../../public/images/DR-RAIBEL-p-3200.jpg 3200w, ../../../public/images/DR-RAIBEL.jpg 4640w"
                                  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Raibel Cortés Flores</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 9822069</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr2-description')}}</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img
                                  src="../../../public/images/DRA.-GENNY-MARTINEZ-LAVARIEGA.jpg" loading="lazy"
                                  sizes="84px"
                                  srcset="../../../public/images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-500.jpg 500w, ../../../public/images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-800.jpg 800w, ../../../public/images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-1080.jpg 1080w, ../../../public/images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-1600.jpg 1600w, ../../../public/images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-2000.jpg 2000w, ../../../public/images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-2600.jpg 2600w, ../../../public/images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-3200.jpg 3200w, ../../../public/images/DRA.-GENNY-MARTINEZ-LAVARIEGA.jpg 6960w"
                                  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Genny Martínez Lavariega</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 9599560</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr3-description')}}</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img
                                  src="../../../public/images/DR-MICHAEL-AGUILAR-VELASQUEZ.jpg" loading="lazy"
                                  sizes="84px"
                                  srcset="../../../public/images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-500.jpg 500w, ../../../public/images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-800.jpg 800w, ../../../public/images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-1080.jpg 1080w, ../../../public/images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-1600.jpg 1600w, ../../../public/images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-2000.jpg 2000w, ../../../public/images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-2600.jpg 2600w, ../../../public/images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-3200.jpg 3200w, ../../../public/images/DR-MICHAEL-AGUILAR-VELASQUEZ.jpg 6960w"
                                  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Michael Aguilar Velásquez</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 13117230</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr4-description')}}</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img
                                  src="../../../public/images/irma-alejandrea.png" loading="lazy" sizes="84px"
                                  srcset="../../../public/images/irma-alejandrea-p-500.png 500w, ../../../public/images/irma-alejandrea-p-800.png 800w, ../../../public/images/irma-alejandrea.png 970w"
                                  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Irma Alejandra Madero Guerrero</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 12080714</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr5-description')}}</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DRA-MARGARITA.jpg"
                                  loading="lazy" sizes="84px"
                                  srcset="../../../public/images/DRA-MARGARITA-p-500.jpg 500w, ../../../public/images/DRA-MARGARITA-p-800.jpg 800w, ../../../public/images/DRA-MARGARITA-p-1080.jpg 1080w, ../../../public/images/DRA-MARGARITA-p-1600.jpg 1600w, ../../../public/images/DRA-MARGARITA-p-2000.jpg 2000w, ../../../public/images/DRA-MARGARITA-p-2600.jpg 2600w, ../../../public/images/DRA-MARGARITA-p-3200.jpg 3200w, ../../../public/images/DRA-MARGARITA.jpg 6960w"
                                  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Margarita Santiago Santiago</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 10212240</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr6-description')}}</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DRA-LAURA.jpg"
                                  loading="lazy" sizes="84px"
                                  srcset="../../../public/images/DRA-LAURA-p-500.jpg 500w, ../../../public/images/DRA-LAURA-p-800.jpg 800w, ../../../public/images/DRA-LAURA-p-1080.jpg 1080w, ../../../public/images/DRA-LAURA-p-1600.jpg 1600w, ../../../public/images/DRA-LAURA-p-2000.jpg 2000w, ../../../public/images/DRA-LAURA-p-2600.jpg 2600w, ../../../public/images/DRA-LAURA-p-3200.jpg 3200w, ../../../public/images/DRA-LAURA.jpg 6960w"
                                  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Laura Monserrat Matías Robles</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 851003</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr7-description')}}</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR-DAVID.jpg"
                                  loading="lazy" sizes="84px"
                                  srcset="../../../public/images/DR-DAVID-p-500.jpg 500w, ../../../public/images/DR-DAVID-p-800.jpg 800w, ../../../public/images/DR-DAVID-p-1080.jpg 1080w, ../../../public/images/DR-DAVID-p-1600.jpg 1600w, ../../../public/images/DR-DAVID-p-2000.jpg 2000w, ../../../public/images/DR-DAVID-p-2600.jpg 2600w, ../../../public/images/DR-DAVID-p-3200.jpg 3200w, ../../../public/images/DR-DAVID.jpg 4640w"
                                  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. David García Vásquez</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 11971281</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr8-description')}}</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR-RICARDO-2.jpg"
                                  loading="lazy" sizes="84px"
                                  srcset="../../../public/images/DR-RICARDO-2-p-500.jpg 500w, ../../../public/images/DR-RICARDO-2-p-800.jpg 800w, ../../../public/images/DR-RICARDO-2-p-1080.jpg 1080w, ../../../public/images/DR-RICARDO-2-p-1600.jpg 1600w, ../../../public/images/DR-RICARDO-2-p-2000.jpg 2000w, ../../../public/images/DR-RICARDO-2-p-2600.jpg 2600w, ../../../public/images/DR-RICARDO-2-p-3200.jpg 3200w, ../../../public/images/DR-RICARDO-2.jpg 4640w"
                                  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Ricardo Santiago Hernández</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 4490465</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr9-description')}}</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR-SERGIO.jpg"
                                  loading="lazy" sizes="84px"
                                  srcset="../../../public/images/DR-SERGIO-p-500.jpg 500w, ../../../public/images/DR-SERGIO-p-800.jpg 800w, ../../../public/images/DR-SERGIO-p-1080.jpg 1080w, ../../../public/images/DR-SERGIO-p-1600.jpg 1600w, ../../../public/images/DR-SERGIO-p-2000.jpg 2000w,../../../public/images/DR-SERGIO-p-2600.jpg 2600w, ../../../public/images/DR-SERGIO-p-3200.jpg 3200w, ../../../public/images/DR-SERGIO.jpg 4640w"
                                alt="" class="uui-team07_image-2"></div>
                            <div class="uui-team07_item-content-2">
                              <div class="uui-heading-tiny-4">Dr. Sergio Rodrigo Balderas Gómez</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 12757895</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr10-description')}}</div>
                            </div>
                          </div>
                          <div class="card_doctor">
                            <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR-EDUARDO.jpg"
                                loading="lazy" sizes="84px"
                                srcset="../../../public/images/DR-EDUARDO-p-500.jpg 500w, ../../../public/images/DR-EDUARDO-p-800.jpg 800w, ../../../public/images/DR-EDUARDO-p-1080.jpg 1080w, ../../../public/images/DR-EDUARDO-p-1600.jpg 1600w, ../../../public/images/DR-EDUARDO-p-2000.jpg 2000w, ../../../public/images/DR-EDUARDO-p-2600.jpg 2600w, ../../../public/images/DR-EDUARDO-p-3200.jpg 3200w, ../../../public/images/DR-EDUARDO.jpg 4640w"
                                alt="" class="uui-team07_image-2"></div>
                            <div class="uui-team07_item-content-2">
                              <div class="uui-heading-tiny-4">Dr. Eduardo Aguilar Valeriano</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('family-medicine.professional-license')}} 12090815</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr11-description')}}</div>
                            </div>
                          </div>
                          <div class="card_doctor">
                            <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/doca.png"
                                loading="lazy" alt="" class="uui-team07_image-2"></div>
                            <div class="uui-team07_item-content-2">
                              <div class="uui-heading-tiny-4">Dra. Yesenia Canseco Cruz</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr12-description')}}</div>
                            </div>
                          </div>
                          <div class="card_doctor">
                            <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/doc.png"
                                loading="lazy" alt="" class="uui-team07_image-2"></div>
                            <div class="uui-team07_item-content-2">
                              <div class="uui-heading-tiny-4">Dr. Luis Enrique Vázquez Ramos</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof 12327862</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('family-medicine.doctor')}}<br></strong>{{$t('family-medicine.dr13-description')}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <appointment-component></appointment-component>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="uui-text-rich-text-9 w-richtext">
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header></template>

<style scoped></style>
