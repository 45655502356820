<script >

export default{
  name:"EmergenciesServiceComponent"
}
</script>

<template>
  <div class="uui-blog03_list-wrapper">
    <div class="w-layout-grid uui-blogsection02_list-2">
      <div class="uui-blogsection12_item-2">
        <router-link data-w-id="32de169b-7621-95e6-cbfc-914d31519a18" to="/obstetrical" class="uui-blogsection12_image-link-2 w-inline-block">
          <div class="uui-blogsection12_image-wrapper-2"><img srcset="images/obstetricas-emergency-p-500.jpeg 500w, images/obstetricas-emergency-p-800.jpeg 800w, images/obstetricas-emergency-p-1080.jpeg 1080w, images/obstetricas-emergency-p-1600.jpeg 1600w, images/obstetricas-emergency.jpeg 1800w" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, (max-width: 1919px) 1280px, 1600px" src="images/obstetricas-emergency.jpeg" alt="" class="uui-blogsection12_image-2"></div>
        </router-link>
        <router-link data-w-id="32de169b-7621-95e6-cbfc-914d31519a1b" to="/obstetrical" class="uui-blogsection12_title-link-2 w-inline-block">
          <h3 class="uui-heading-xsmall-5">{{$t('urgencies.urgencies-title-1')}}</h3>
          <div class="uui-blogsection12_icon-2 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 17L17 7M17 7H7M17 7V17" stroke="CurrentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg></div>
        </router-link>
        <div class="uui-text-size-medium-31">{{$t('urgencies.urgencies-title-1-description')}}<br>{{$t('urgencies.urgencies-title-1-description-2')}}</div>
      </div>
      <div class="uui-blogsection12_item-2">
        <router-link data-w-id="32de169b-7621-95e6-cbfc-914d31519a24" to="/emergencies-pediatric" class="uui-blogsection12_image-link-2 w-inline-block">
          <div class="uui-blogsection12_image-wrapper-2"><img srcset="images/cardiologia-pediatrica-p-500.jpeg 500w, images/cardiologia-pediatrica-p-800.jpeg 800w, images/cardiologia-pediatrica-p-1080.jpeg 1080w, images/cardiologia-pediatrica.jpeg 1480w" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, (max-width: 1919px) 1280px, 1600px" src="images/cardiologia-pediatrica.jpeg" alt="" class="uui-blogsection12_image-2"></div>
        </router-link>
        <router-link data-w-id="32de169b-7621-95e6-cbfc-914d31519a27"  to="/emergencies-pediatric" class="uui-blogsection12_title-link-2 w-inline-block">
          <h3 class="uui-heading-xsmall-5">{{$t('urgencies.urgencies-title-2')}}</h3>
          <div class="uui-blogsection12_icon-2 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 17L17 7M17 7H7M17 7V17" stroke="CurrentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg></div>
        </router-link>
        <div class="uui-text-size-medium-31">{{$t('urgencies.urgencies-title-2-description')}}<br>{{$t('urgencies.urgencies-title-2-description-2')}}</div>
      </div>
      <div class="uui-blogsection12_item-2">
        <router-link data-w-id="32de169b-7621-95e6-cbfc-914d31519a30" to="/emergencies-adults" class="uui-blogsection12_image-link-2 w-inline-block">
          <div class="uui-blogsection12_image-wrapper-2"><img srcset="images/urgencias-adultas-p-500.jpeg 500w, images/urgencias-adultas-p-800.jpeg 800w, images/urgencias-adultas-p-1080.jpeg 1080w, images/urgencias-adultas-p-1600.jpeg 1600w, images/urgencias-adultas.jpeg 1800w" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 95vw, (max-width: 1919px) 1280px, 1600px" src="images/urgencias-adultas.jpeg" alt="" class="uui-blogsection12_image-2"></div>
        </router-link>
        <router-link data-w-id="32de169b-7621-95e6-cbfc-914d31519a33" to="/emergencies-adults" class="uui-blogsection12_title-link-2 w-inline-block">
          <h3 class="uui-heading-xsmall-5">{{$t('urgencies.urgencies-title-3')}}</h3>
          <div class="uui-blogsection12_icon-2 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 17L17 7M17 7H7M17 7V17" stroke="CurrentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg></div>
        </router-link>
        <div class="uui-text-size-medium-31">{{$t('urgencies.urgencies-title-3-description')}}<br>{{$t('urgencies.urgencies-title-3-description-2')}}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
