<script>
export default {
  name: "PediatricWhatsApp"
}
</script>

<template>
  <div class="uui-text-size-medium-36">WhatsApp</div>
  <div class="uui-space-small-23"></div>
  <a href="https://wa.me/9541537429" class="uui-text-style-link-02-5 spacing"> 954 153 7429</a>
</template>

<style scoped>

</style>
