<script>
import {app} from "@/main";

export default {
  name: "DirectoryPage",
  data() {
    return {
      locale:'',
      specialties: [],
      data:[],
      resultsFilterSpecialties:[],
      resultsFilterData:[],
      searchTerm:'',
    }
  },
  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });

  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods:{
    loadData(locale){
      this.specialties = require('../data/specialties.json');
      this.specialties = this.specialties.map(specialty => {
        const translateText = specialty.text[locale];
        return {
          ...specialty,
          text: translateText,
        };
      });

      //this.specialists = require('../data/specialists_.json');
      this.specialists = require(`../data/specialists_${locale}.json`);

      this.specialists = this.specialists.map(specialist => {
      //  const translateInformation= specialist.information[locale];
        const translateInformation= specialist.information.data;
        return {
          ...specialist,
          information: translateInformation,
        };
      });
      this.specialistsBySpecialty();
    },

    specialistsBySpecialty:function(){
      this.data=[];
      this.specialties.forEach(spec=>{
        const specialists = this.specialists.filter(s => {
          return s.specialties.some(specialty => specialty.id === spec.value);
        });
        let item ={}
        item.id =spec.value;
        item.name =spec.text;
        item.specialists = specialists;
        this.data.push(item);
      });
      this.resultsFilterData=this.data;
    },
    handleInputChange() {
      if (this.searchTerm === '') {
        this.resultsFilterData= this.data;
      }
    },
    searchSpecialist: function () {

      this.resultsFilterData = [];

      this.data.forEach(value => {
        let obj ={}
        obj.id =value.id;
        obj.name =value.name;
        obj.specialists =
            value.specialists.filter(
                item => this.normalizeString(item.name).toUpperCase()
                    .includes(this.normalizeString(this.searchTerm).toUpperCase())
            ||
                    this.normalizeString(item.information).toUpperCase()
                        .includes(this.normalizeString(this.searchTerm).toUpperCase())
            );
        if(obj.specialists.length>0)this.resultsFilterData.push(obj);
      });

    },


    normalizeString:function (str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    stringArray(string) {
      // Convierte el string en un array utilizando split(',')
      return string.split(',');
    },
  }
}
</script>

<template>
  <header class="uui-section_header09">
    <div class="uui-page-padding-28">
      <div class="uui-container-small-4">
        <div class="uui-padding-vertical-xhuge-29">
          <div class="uui-text-align-center-9">
            <h1 class="uui-heading-large-9">{{$t('directory.medical-directory')}}</h1>
            <div class="uui-space-small-13"></div>
            <div class="uui-text-size-xlarge-6">{{$t('directory.medical-directory-description')}}</div>
            <div class="uui-header09_form w-form">
              <div id="email-form" name="email-form" data-name="Email Form" method="get" class="uui-form_component-6" data-wf-page-id="654bf198ba23506dd4649e07" data-wf-element-id="aa4d3645-b6e4-65b4-598a-dcbc9742b509">
                <div class="uui-signup-form_wrapper-6">
                  <input type="text" class="uui-form_input-12 w-input" maxlength="256" v-model="searchTerm" @input="handleInputChange"   @keyup.enter="searchSpecialist" :placeholder="$t('directory.search-what-you-need')" id="email-2" required="">
                  <input type="button" :value="$t('home.search')" data-wait="Please wait..." @click="searchSpecialist" class="uui-button-21 w-button">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <section class="uui-section_career09-2">
    <div class="uui-page-padding-15">
      <section class="uui-section_team01">
        <div class="uui-page-padding-16">
          <div class="uui-container-large-12">
            <div v-if="resultsFilterData.length===0" class="uui-padding-vertical-xhuge-17 animate__animated animate__fadeI">
              <h1>{{$t('directory.data-not-found')}}</h1>
            </div>
            <address  v-for="(item, index) in resultsFilterData" :key="index" id="ginecologia" class="uui-padding-vertical-xhuge-17 animate__animated animate__fadeIn">
              <div class="uui-text-align-center-4">
                <div class="uui-max-width-large-7 align-center">
                  <h2 class="uui-heading-medium-9">{{item.name}}</h2>
                </div>
              </div>
              <div class="uui-team01_component">
                <div class="uui-team01_list-wrapper">
                  <div class="w-layout-grid uui-team01_list">
                    <div v-for="(specialist, i) in item.specialists" :key="i" id="w-node-ef7a7fd1-0bf7-4b28-15ea-60c0f9bba68a-d4649e07" class="uui-team01_item">
                      <div class="uui-team01_image-wrapper">
<!--                        <img v-bind:src="specialist.profile_picture" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DRA-FLORENTINA-TOLEDO-p-500.jpg 500w, images/DRA-FLORENTINA-TOLEDO-p-800.jpg 800w, images/DRA-FLORENTINA-TOLEDO-p-1080.jpg 1080w, images/DRA-FLORENTINA-TOLEDO-p-1600.jpg 1600w, images/DRA-FLORENTINA-TOLEDO-p-2000.jpg 2000w, images/DRA-FLORENTINA-TOLEDO-p-2600.jpg 2600w, images/DRA-FLORENTINA-TOLEDO-p-3200.jpg 3200w, images/DRA-FLORENTINA-TOLEDO.jpg 6000w" alt="" class="uui-team01_image">-->
                        <img v-bind:src="specialist.profile_picture" loading="lazy"  alt="" class="uui-team01_image" style="object-position: 50% 10% !important;">
                      </div>
                      <div class="uui-heading-tiny-3">{{specialist.name}}</div>
                      <div v-if="specialist.professional_license.length"  class="uui-text-size-medium-13 text-color-primary700">{{$t('general.professional-license')}} {{specialist.professional_license}}</div>
                      <div v-if="specialist.specialty_license.length">
                        <div  v-for="(item, index) in stringArray(specialist.specialty_license)" :key="index"  class="uui-text-size-medium-13 text-color-primary700">{{$t('general.specialty-license')}} {{item}} </div>
                      </div>

                      <div class="uui-space-xxsmall-7"></div>
                      <div class="uui-text-size-medium-13"><strong>{{item.name}}.<br></strong>{{specialist.information}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </address>
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Medicina Interna</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-d1bc9d1f-5058-e527-86dd-2e48d761ed48-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-JESUS-BETANZOS-GOMEZ.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-JESUS-BETANZOS-GOMEZ-p-500.jpg 500w, images/DR.-JESUS-BETANZOS-GOMEZ-p-800.jpg 800w, images/DR.-JESUS-BETANZOS-GOMEZ-p-1080.jpg 1080w, images/DR.-JESUS-BETANZOS-GOMEZ.jpg 1500w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Jesús Betanzos Gómez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 5701720</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Esp. 8924726</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Medicina Interna.</strong><br>Universidad Autónoma Benito Juárez de Oaxaca en especialidad de Medicina Interna, miembro del Colegio Mexicano de Medicina Interna, miembro del American College Of Physicians. Certificación vigente por el Consejo Mexicano de Medicina Interna.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-de077d91-6fc6-1436-e72d-d3f5ccd3c621-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/moema.png" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/moema-p-500.png 500w, images/moema-p-800.png 800w, images/moema-p-1080.png 1080w, images/moema.png 1196w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Moema Jerónimo Rodríguez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 6740669</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Esp. 09289026</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Medicina Interna.<br></strong> UABJO/ Espc. BUAP.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_708f9658-66f9-c33b-c25f-61bf3a346c14-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/izaidel.png" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/izaidel-p-500.png 500w, images/izaidel-p-800.png 800w, images/izaidel-p-1080.png 1080w, images/izaidel.png 1200w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Izaidel Jiménez Escobar</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 10374436</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 10374436</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Medicina Interna.</strong><br>Universidad Autónoma Metropolitana, Universidad Autónoma de México, Hospital Español de México.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Traumatología y Ortopedia</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_54324e0d-f238-191c-aa79-a28be5df440f-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/juan-jose-diaz.png" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/juan-jose-diaz-p-500.png 500w, images/juan-jose-diaz-p-800.png 800w, images/juan-jose-diaz-p-1080.png 1080w, images/juan-jose-diaz.png 1200w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Juan José Diaz Velásquez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 6616584</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Esp. 9289025 / Reg. 21-248315</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Traumatología.</strong><br>UABJO/ BUAP/ certificado por el Consejo Mexicano de Ortopedia y Traumatología.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_56960c1f-5f41-cabf-969e-88aa035bb18c-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img class="uui-team01_image eduardo" src="images/DR.-EDUARDO-ESLAVA-COLOAPA-TRAUMATOLOIA-Y-ORTOPEDIA.jpeg" alt="" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" loading="lazy" srcset="images/DR.-EDUARDO-ESLAVA-COLOAPA-TRAUMATOLOIA-Y-ORTOPEDIA-p-500.jpeg 500w, images/DR.-EDUARDO-ESLAVA-COLOAPA-TRAUMATOLOIA-Y-ORTOPEDIA.jpeg 599w"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Eduardo Eslava Coloapa</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 2759694</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. De Esp. 3411104</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Ortopedia y traumatología.<br></strong>Médico cirujano por la Fac. Med. Ciudad universitaria UNAM, posgrado UMAE Magdalena de las Salinas IMSS.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Pediatría</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_8672c8d9-71d3-7920-d9a5-28a7911b0242-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/luis-alberto-manriquez.png" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/luis-alberto-manriquez-p-500.png 500w, images/luis-alberto-manriquez-p-800.png 800w, images/luis-alberto-manriquez-p-1080.png 1080w, images/luis-alberto-manriquez.png 1084w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Luis Alberto Manríquez Santos</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 4644596</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. De Esp. 6323680</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Pediatría.<br>‍</strong>Posgrado en la Universidad Nacional Autónoma de México H.G. Darío Fernández Fierro ISSSTE certificado por el Consejo de Certificación en Pediatría No. 15498.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-eb48fe91-7de7-f7ed-993b-04fe8d156fec-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/maythe-narda.png" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/maythe-narda-p-500.png 500w, images/maythe-narda-p-800.png 800w, images/maythe-narda-p-1080.png 1080w, images/maythe-narda.png 1196w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Maythe Narda Hernández García</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 6792224</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. De Esp. 9714459</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Pediatría.</strong><br>Certificada por el Colegio Mexicano de Pediatría No 18962.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-af789aba-e6b3-36d6-e688-c9d456bc2438-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-500.jpg 500w, images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-800.jpg 800w, images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-1080.jpg 1080w, images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-1600.jpg 1600w, images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-2000.jpg 2000w, images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-2600.jpg 2600w, images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ-p-3200.jpg 3200w, images/DR.-ARMANDO-DOMINGUEZ-GONZALEZ.jpg 6000w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Armando Domínguez González</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 9456145</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Céd. Prof. 9456145</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Pediatría.</strong><br>Egresado de la U.A.B.J.O, Hospital Del Niño, DIF Hidalgo – UAEH, certificado por el Colegio Mexicano de Pediatría No. 18797, miembro del Colegio de Pediatría de Oaxaca.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Médico General</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-f74c68ef-f05d-1ec2-bf82-eeac66f9e0fc-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR-RAIBEL.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR-RAIBEL-p-500.jpg 500w, images/DR-RAIBEL-p-800.jpg 800w, images/DR-RAIBEL-p-1080.jpg 1080w, images/DR-RAIBEL-p-1600.jpg 1600w, images/DR-RAIBEL-p-2000.jpg 2000w, images/DR-RAIBEL-p-2600.jpg 2600w, images/DR-RAIBEL-p-3200.jpg 3200w, images/DR-RAIBEL.jpg 4640w" alt="" class="uui-team01_image eduardo"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Raibel Cortés Flores</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 9822069</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Esp. 8924726</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico General.</strong><br>Universidad Popular Autónoma del Estado de Puebla (UPAEP).</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_95909f42-b62d-9438-c87c-078b3e90c98b-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DRA.-GENNY-MARTINEZ-LAVARIEGA.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-500.jpg 500w, images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-800.jpg 800w, images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-1080.jpg 1080w, images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-1600.jpg 1600w, images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-2000.jpg 2000w, images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-2600.jpg 2600w, images/DRA.-GENNY-MARTINEZ-LAVARIEGA-p-3200.jpg 3200w, images/DRA.-GENNY-MARTINEZ-LAVARIEGA.jpg 6960w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Genny Martínez Lavariega</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 9599560</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico General.</strong><br>Universidad Regional del Sureste.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_4edbc485-e520-050b-cd3b-02617197538e-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR-MICHAEL-AGUILAR-VELASQUEZ.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-500.jpg 500w, images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-800.jpg 800w, images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-1080.jpg 1080w, images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-1600.jpg 1600w, images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-2000.jpg 2000w, images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-2600.jpg 2600w, images/DR-MICHAEL-AGUILAR-VELASQUEZ-p-3200.jpg 3200w, images/DR-MICHAEL-AGUILAR-VELASQUEZ.jpg 6960w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Michael Aguilar Velásquez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 13117230</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico General.</strong><br>Universidad Autónoma de México.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_01ddb901-c3f5-aa67-6092-dddfb6275074-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/irma-alejandrea.png" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/irma-alejandrea-p-500.png 500w, images/irma-alejandrea-p-800.png 800w, images/irma-alejandrea.png 970w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Irma Alejandra Madero Guerrero</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 12080714</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico General.</strong><br>Universidad Autónoma de Ciudad Juárez (U.A.C.J).</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_0854a7ba-d1a6-197b-f1d3-ff2b4e17e2c7-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DRA-MARGARITA.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DRA-MARGARITA-p-500.jpg 500w, images/DRA-MARGARITA-p-800.jpg 800w, images/DRA-MARGARITA-p-1080.jpg 1080w, images/DRA-MARGARITA-p-1600.jpg 1600w, images/DRA-MARGARITA-p-2000.jpg 2000w, images/DRA-MARGARITA-p-2600.jpg 2600w, images/DRA-MARGARITA-p-3200.jpg 3200w, images/DRA-MARGARITA.jpg 6960w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Margarita Santiago Santiago</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 10212240</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico General.<br></strong>Universidad Regional del Sureste.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_1a14aa50-afa9-e34a-7495-4daf5ac35028-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DRA-LAURA.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DRA-LAURA-p-500.jpg 500w, images/DRA-LAURA-p-800.jpg 800w, images/DRA-LAURA-p-1080.jpg 1080w, images/DRA-LAURA-p-1600.jpg 1600w, images/DRA-LAURA-p-2000.jpg 2000w, images/DRA-LAURA-p-2600.jpg 2600w, images/DRA-LAURA-p-3200.jpg 3200w, images/DRA-LAURA.jpg 6960w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Laura Monserrat Matías Robles</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 851003</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico General.</strong><br>Universidad Autónoma Benito Juárez de Oaxaca.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_93fff63d-d8e9-3626-072c-3bd2c5cfb8e9-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR-DAVID.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR-DAVID-p-500.jpg 500w, images/DR-DAVID-p-800.jpg 800w, images/DR-DAVID-p-1080.jpg 1080w, images/DR-DAVID-p-1600.jpg 1600w, images/DR-DAVID-p-2000.jpg 2000w, images/DR-DAVID-p-2600.jpg 2600w, images/DR-DAVID-p-3200.jpg 3200w, images/DR-DAVID.jpg 4640w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. David García <br>Vásquez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 11971281</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico General.<br></strong>Universidad Autónoma Benito Juárez de Oaxaca.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-c550b978-9e61-9e5f-ef39-4adfcf5fab21-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR-RICARDO-2.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR-RICARDO-2-p-500.jpg 500w, images/DR-RICARDO-2-p-800.jpg 800w, images/DR-RICARDO-2-p-1080.jpg 1080w, images/DR-RICARDO-2-p-1600.jpg 1600w, images/DR-RICARDO-2-p-2000.jpg 2000w, images/DR-RICARDO-2-p-2600.jpg 2600w, images/DR-RICARDO-2-p-3200.jpg 3200w, images/DR-RICARDO-2.jpg 4640w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Ricardo Santiago <br>Hernández</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 4490465</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico General.<br></strong>Universidad Autónoma Benito Juárez de Oaxaca.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-d3076155-9851-a414-4dab-c3e1777949c7-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR-SERGIO.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR-SERGIO-p-500.jpg 500w, images/DR-SERGIO-p-800.jpg 800w, images/DR-SERGIO-p-1080.jpg 1080w, images/DR-SERGIO-p-1600.jpg 1600w, images/DR-SERGIO-p-2000.jpg 2000w, images/DR-SERGIO-p-2600.jpg 2600w, images/DR-SERGIO-p-3200.jpg 3200w, images/DR-SERGIO.jpg 4640w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Sergio Rodrigo Balderas Gómez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 12757895</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico General.</strong><br>Universidad Autónoma Benito Juárez de Oaxaca.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_515a407f-ca5e-8414-5efc-2ce046fc43ae-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR-EDUARDO.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR-EDUARDO-p-500.jpg 500w, images/DR-EDUARDO-p-800.jpg 800w, images/DR-EDUARDO-p-1080.jpg 1080w, images/DR-EDUARDO-p-1600.jpg 1600w, images/DR-EDUARDO-p-2000.jpg 2000w, images/DR-EDUARDO-p-2600.jpg 2600w, images/DR-EDUARDO-p-3200.jpg 3200w, images/DR-EDUARDO.jpg 4640w" alt="" class="uui-team01_image eduardo"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Eduardo Valeriano</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 12090815</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico General.<br></strong>Postgrado UABJO HGDAV / Universidad Autónoma Benito Juárez de Oaxaca.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Médico Urgenciólogo</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-a59370f2-2230-d1d6-4b1f-8e5d266325f2-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-IRWIN-REY-POBLETE-PINACHO.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-IRWIN-REY-POBLETE-PINACHO-p-500.jpg 500w, images/DR.-IRWIN-REY-POBLETE-PINACHO-p-800.jpg 800w, images/DR.-IRWIN-REY-POBLETE-PINACHO-p-1080.jpg 1080w, images/DR.-IRWIN-REY-POBLETE-PINACHO-p-1600.jpg 1600w, images/DR.-IRWIN-REY-POBLETE-PINACHO-p-2000.jpg 2000w, images/DR.-IRWIN-REY-POBLETE-PINACHO-p-2600.jpg 2600w, images/DR.-IRWIN-REY-POBLETE-PINACHO-p-3200.jpg 3200w, images/DR.-IRWIN-REY-POBLETE-PINACHO.jpg 6000w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Irwin Rey <br>Poblete Pinacho</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 6546931</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. De Esp.12900505</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico Urgenciólogo.</strong><br>Hospital General Regional 196, Fidel Velásquez Sánchez Ecatepec de Morelos, Universidad Instituto Politécnico Nacional.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Cirugía General - Laparoscópica - Coloproctología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_4c332c25-55ce-aa70-c26b-029798bef1cd-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-500.jpg 500w, images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-800.jpg 800w, images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-1080.jpg 1080w, images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-1600.jpg 1600w, images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-2000.jpg 2000w, images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-2600.jpg 2600w, images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-3200.jpg 3200w, images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO.jpg 4000w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Yolanda Citlali Solís Saucedo</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 8667333</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 12522339</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 13478464</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Médico Cirujano, Cirugía General, Coloproctología.</strong><br>Universidad Autónoma de San Luis Potosí, Universidad de Guanajuato, Universidad de Guadalajara.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_70f8cf0d-d53c-506e-8e79-582d9acc70c2-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-RAUL-DAVID-GIJON-SORIANO-&#45;&#45;CIRUGIA-GENERAL-Y-LAPAROSCOPIA.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-RAUL-DAVID-GIJON-SORIANO-&#45;&#45;CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-500.jpg 500w, images/DR.-RAUL-DAVID-GIJON-SORIANO-&#45;&#45;CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-800.jpg 800w, images/DR.-RAUL-DAVID-GIJON-SORIANO-&#45;&#45;CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-1080.jpg 1080w, images/DR.-RAUL-DAVID-GIJON-SORIANO-&#45;&#45;CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-1600.jpg 1600w, images/DR.-RAUL-DAVID-GIJON-SORIANO-&#45;&#45;CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-2000.jpg 2000w, images/DR.-RAUL-DAVID-GIJON-SORIANO-&#45;&#45;CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-2600.jpg 2600w, images/DR.-RAUL-DAVID-GIJON-SORIANO-&#45;&#45;CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-3200.jpg 3200w, images/DR.-RAUL-DAVID-GIJON-SORIANO-&#45;&#45;CIRUGIA-GENERAL-Y-LAPAROSCOPIA.jpg 4000w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Raúl David Gijón Soriano</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 7244681</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 10487460</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Cirugía General y laparoscopia.<br></strong>Medicina Gral. Universidad Regional del Sureste, Cirugía General, Universidad Veracruzana.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-cbbf7ce9-09fe-f394-84ca-9d554b00dfd5-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Jorge Gijón</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 2876351</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 318042</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Coloproctólogo.<br></strong>Sub- especialista en cirujano coloproctólogo, Hospital Regional Lic. Adolfo López Mateos ISSSTE 2000-2002, México DF, rectificado 2023-2028por el Colegio Mexicano de Especialistas en Enfermedades del Colon y del Recto A.C.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Angiología, Cirugía Vascular y Endovascular</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_148b25e3-acef-2fa2-315e-b774ca67baa3-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-OSMAR-GABINO-HERNANDEZ-2-ANGIOLOGÍA-.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-OSMAR-GABINO-HERNANDEZ-2-ANGIOLOGÍA&#45;&#45;p-500.jpeg 500w, images/DR.-OSMAR-GABINO-HERNANDEZ-2-ANGIOLOGÍA&#45;&#45;p-800.jpeg 800w, images/DR.-OSMAR-GABINO-HERNANDEZ-2-ANGIOLOGÍA-.jpeg 1099w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Osmar Gabino Hernández</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 10740260</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 12467140</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Angiología, Cirugía Vascular y Endovascular.</strong><br>Hospital de Especialidades CMN siglo XXI. UNAM.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Cardiología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_7ad87389-0748-2d12-b348-ba27460b69e1-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-JOSE-E.-BAHENA-1-CARDIOLOGIA.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-JOSE-E.-BAHENA-1-CARDIOLOGIA-p-500.jpg 500w, images/DR.-JOSE-E.-BAHENA-1-CARDIOLOGIA.jpg 720w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. José Eduardo Bahena</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 8732268</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11548192</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Cardiología clínica e intervencionista.</strong><br>Jefe de servicio de cardiología y hemodinámica del nuevo hospital ISSSTE Delegación Sur Tláhuac, médico cirujano en UNAM.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_63b45423-0297-77c9-ad71-053be44e877c-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-JORGE-LUIS-RUIZ-TOLEDO-CARDIOLOGIA.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-JORGE-LUIS-RUIZ-TOLEDO-CARDIOLOGIA-p-500.jpeg 500w, images/DR.-JORGE-LUIS-RUIZ-TOLEDO-CARDIOLOGIA-p-800.jpeg 800w, images/DR.-JORGE-LUIS-RUIZ-TOLEDO-CARDIOLOGIA.jpeg 1066w" alt="" class="uui-team01_image eduardo"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Jorge Luis Ruíz Toledo</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 7176733</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 10358119</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Cardiólogo ecocardiografía .</strong><br>UNAM.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_19a4483c-2ec5-4b0d-ec15-63389f8e56ff-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. César Augusto Zarate Morales</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 1872220</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Eso 3392103</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Cardiología pediátrica.</strong><br>Hospital Infantil de México Federico Gómez, UNAM.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Cirugía Plástica, Estética y Reconstructiva</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_1e5721b3-5dac-443f-2bbc-f42419755530-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Jaime Aron García Espinoza</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 9163212</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Consejo de Especialidad CMCPER 2416</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 13545131</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Cirugía plástica, estética y reconstructiva</strong>.<br>Cirugía plástica estética y reconstructiva, Fellowship en cirugía estética (Barcelona clínica planas), Hospital Central Sur de Petróleos Mexicanos (cirugía plástica).</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Cirujano Oncólogo Pediatra</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_1944dc15-94b0-a6a8-830c-e469e40be368-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-ALEX-TONY-REYES-SANTIAGO-CIRUJANO-ONCOLOGO-PEDIATRA-2.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-ALEX-TONY-REYES-SANTIAGO-CIRUJANO-ONCOLOGO-PEDIATRA-2-p-500.jpeg 500w, images/DR.-ALEX-TONY-REYES-SANTIAGO-CIRUJANO-ONCOLOGO-PEDIATRA-2-p-800.jpeg 800w, images/DR.-ALEX-TONY-REYES-SANTIAGO-CIRUJANO-ONCOLOGO-PEDIATRA-2.jpeg 1200w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Alex Tonny Reyes Santiago</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 5399716</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced esp. 9177508</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Cirujano oncólogo pediatra.</strong><br>Hospital Infantil de México Federico Gómez. Cirugía Oncológica Pediatríca, UNAM, médico cirujano UABJO, cirujano pediatra UNAM.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Dermatología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_30231583-71ac-cbe4-95b4-ef7a67e1308d-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doca.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Ana Karla Díaz</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 8761927</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11718999</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Medicina Interna.</strong><br>Licenciatura de Médico Cirujano en Cuernavaca, Centro Dermatológico.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_55eb6303-6490-2b53-9bad-8ac981a4b8c4-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Ladislao de la Pascua</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced prof. 8320656</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced esp. 11543103</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13">Instituto Nacional de Cancerología Servicio de Dermatología y Unidad de Melanoma, Clínica Especializada Condesa, y dermatología pediátrica en el CMN siglo XXI. Especializada condesa, y dermatología pediátrica en el CMN siglo XXI, Diplomado de dermatología cosmética y terapia láser con el Dr. Javier Ruiz Ávila conocido como el &quot;Dermatólogo de las Estrellas&quot; en la CDMX.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_397a66a1-e428-8875-a539-787201e0eca8-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doca.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Dayra Rebeca Jiménez Mendoza</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 8320656</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11543103</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Dermatología.</strong><br>Dermatología, Hospital General de México, UNAM Querétaro.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Endocrinología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-c5ea16a5-116f-c6a9-e3e0-85375d8b9332-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doca.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Yanei taatení Escobedo</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 8732344</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11747214</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Endocrinología.</strong><br>Medicina interna / Endocrinología / alta especialidad, enfermedades tiroideas, Hospital General de México, UNAM.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Endoscopia Gastrointestinal</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-ed4f8859-2db8-7646-39da-bca7ca0bbe5d-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-JORGE-ARTURO-PERALES-OLIVA.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-500.jpg 500w, images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-800.jpg 800w, images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-1080.jpg 1080w, images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-1600.jpg 1600w, images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-2000.jpg 2000w, images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-2600.jpg 2600w, images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-3200.jpg 3200w, images/DR.-JORGE-ARTURO-PERALES-OLIVA.jpg 6000w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Jorge Arturo Perales</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 7543229</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11970808</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Endoscopia Gastrointestinal.</strong><br>Endoscopía digestiva avanzada / ultrasonido endoscópico, Hospital Central Militar / Centro Médico Nacional SXXI - UDEFA/UNAM.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Terapeuta Físico</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_39b0c818-932f-35ac-e7b1-91cefaeada0f-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/LIC.-LUIS-IBAÑEZ-DEL-CARMEN-IBAÑEZ-PACHECO-1-FISIOTERAPEUTA.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/LIC.-LUZ-IBAÑEZ-DEL-CARMEN-IBAÑEZ-PACHECO-1-FISIOTERAPEUTA-p-500.jpeg 500w, images/LIC.-LUZ-IBAÑEZ-DEL-CARMEN-IBAÑEZ-PACHECO-1-FISIOTERAPEUTA-p-800.jpeg 800w, images/LIC.-LUIS-IBAÑEZ-DEL-CARMEN-IBAÑEZ-PACHECO-1-FISIOTERAPEUTA.jpeg 922w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Lic. Luz del Carmen Ibáñez Pacheco</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 10481280</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11970808</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Medicina Interna.</strong><br>Centro Mexicano Universitario de Ciencias y Humanidades (Puebla).</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Gastroenterología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_12a0f6bb-9b3a-f2cf-0788-487f87a87dd3-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Alberto Molina Peto</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 10002907</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 10531813</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Gastroenterólogo pediatra.</strong><br>Médico cirujano por la Universidad Autónoma Benito Juárez de Oaxaca, especialidad en gastroenterología y nutrición pediátrica por la Universidad nacional Autónoma de México, formación en la UMAE Hospital De Pediatría del Centro Médico Nacional Siglo XXI en la ciudad de México y por la UNAM.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_73fe9765-9727-01cb-aa1a-1447ada575ec-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Sebastián Porras</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 8881892</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced esp. 10531813</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Gastroenterología.</strong><br>Licenciatura en medicina por Universidad Popular Autónoma del Estado de Puebla (UPAEP), Gastroenterología (UNAM).</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Hematología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_426ebc52-ee3b-6d13-2d5f-620f148cd0fb-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-FLAVIO-ROJAS-CASTILLEJOS-HEMATOLOGIA-1.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-FLAVIO-ROJAS-CASTILLEJOS-HEMATOLOGIA-1-p-500.jpeg 500w, images/DR.-FLAVIO-ROJAS-CASTILLEJOS-HEMATOLOGIA-1-p-800.jpeg 800w, images/DR.-FLAVIO-ROJAS-CASTILLEJOS-HEMATOLOGIA-1-p-1080.jpeg 1080w, images/DR.-FLAVIO-ROJAS-CASTILLEJOS-HEMATOLOGIA-1.jpeg 1280w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Flavio Rojas Castillejos</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 6867382</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 10005824</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11578 578</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Hematólogo.<br></strong>Licenciatura en medicina por Universidad Popular Autónoma del Estado de Puebla (UPAEP), gastroenterología (UNAM).</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Heridas y Estomas</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_20f5811e-ba82-d626-fda0-d2b1cdd67709-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-JOSE-JUAN-JIMENEZ-RSP.-HERIDAS-Y-ESTOMAS.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-JOSE-JUAN-JIMENEZ-RSP.-HERIDAS-Y-ESTOMAS-p-500.jpg 500w, images/DR.-JOSE-JUAN-JIMENEZ-RSP.-HERIDAS-Y-ESTOMAS-p-800.jpg 800w, images/DR.-JOSE-JUAN-JIMENEZ-RSP.-HERIDAS-Y-ESTOMAS.jpg 1080w" alt="" class="uui-team01_image eduardo"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. José Juan Jiménez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 8482064</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11749870</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Heridas y estomas.</strong><br>Lic. en Enfermería UABJO, Esp. Heridas y Estomas UPAEP, diplomado en Medicina Regenerativa Anáhuac, diplomado en Terapia Neural CAPACITEM.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Infectología Internista</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_6a9fbef3-fdae-df33-fc6a-ab0f1ae999aa-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Yuri Alfonso Roldan Aragón</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 2391273</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 5259026</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Infectóloga internista.<br></strong>Medicina interna UABJO - Hospital Dr. Aurelio Valdivieso, Infectología UNAM-INCMNSZ, Certificación Consejo Mexicano Infectología 823.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Electromiografía y Potenciales Evocados, Medicina de Rehabilitación y Electrodiagnóstico</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_91fa09f2-2984-8945-103a-ddf79aa7ce15-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DRA.-ALMA-LORENA-LOPEZ-VASQUEZ-Electromiografia-y-potenciales-evocados.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DRA.-ALMA-LORENA-LOPEZ-VASQUEZ-Electromiografia-y-potenciales-evocados-p-500.jpeg 500w, images/DRA.-ALMA-LORENA-LOPEZ-VASQUEZ-Electromiografia-y-potenciales-evocados.jpeg 1044w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Alma Lorena López Vázquez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Med. Gral. 6354070</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 09940970</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Electromiografía y potenciales evocados, medicina de rehabilitación y electrodiagnóstico.</strong><br>IMSS Norte Magdalena de las Salinas/ UNAM.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Medicina Interna y Oncología Médica</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_289e0d9d-0270-4320-1764-0bbe9c3a8b3b-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Eder Alexandro Arango Bravo</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 5843377</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11237339</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11237338</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Medicina Interna y Oncología Médica.</strong><br>Médico cirujano general Universidad Autónoma Benito Juárez Oaxaca, médico especialista en medicina interna CDMX, Universidad Autónoma de México, Médico Subespecialista en Oncología.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Nefrología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-ea9f5714-b75d-6cc5-4713-7a7c4e2bc396-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doca.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Karla Berenice Cano Escobar</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 7230659</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 12911925</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Nefrología.</strong><br>Hospital Instituto Nacional de Cardiología, Universidad la UNAM.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Neurocirugía</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_4a90ab37-6575-5351-14b9-d14a3b9c861b-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Jorge Enrique Jiménez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 3999456</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 6442716</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Neurocirujano.</strong><br>Hospital General de México, UNAM.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-a06e851e-28b4-c256-0ef5-cfc92540747a-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-GUADALUPE-NEFTHALI-ÁNGEL-MÁRQUEZ-NEUROCIRUJANO-2.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-GUADALUPE-NEFTHALI-ÁNGEL-MÁRQUEZ-NEUROCIRUJANO-2-p-500.jpeg 500w, images/DR.-GUADALUPE-NEFTHALI-ÁNGEL-MÁRQUEZ-NEUROCIRUJANO-2-p-800.jpeg 800w, images/DR.-GUADALUPE-NEFTHALI-ÁNGEL-MÁRQUEZ-NEUROCIRUJANO-2.jpeg 1024w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Guadalupe Neftalí Ángel Márquez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 10336446</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 6841555</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Neurocirugía.</strong><br>Universidad Autónoma Benito Juárez de Oaxaca, el Consejo Mexicano de Cirugía Neurológica A.C.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Neurología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_5e898b1e-2c35-ba7f-1499-fd7086267a29-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Pastor López Gómez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 892491</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">AESSA-31726</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Neurología.<br></strong>Centro Médico la Raza IMSS, UNAM.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_5e898b1e-2c35-ba7f-1499-fd7086267a38-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doca.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Kenia Cruz Calvo</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 6230372</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 10996304</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Neuróloga pediatra.<br></strong>UPAEP / UNAM, certificada por el Consejo Mexicano de Pediatría, certificada por el Consejo Mexicano de Neurología.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Nutriología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-cf9cb44a-aa64-989c-069b-26d29d6c66bc-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/LIC.&#45;&#45;VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/LIC.&#45;&#45;VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-500.jpg 500w, images/LIC.&#45;&#45;VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-800.jpg 800w, images/LIC.&#45;&#45;VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-1080.jpg 1080w, images/LIC.&#45;&#45;VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-1600.jpg 1600w, images/LIC.&#45;&#45;VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-2000.jpg 2000w, images/LIC.&#45;&#45;VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-2600.jpg 2600w, images/LIC.&#45;&#45;VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-3200.jpg 3200w, images/LIC.&#45;&#45;VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1.jpg 4000w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Lic. Valeria Taniveth Vásquez Pérez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 5701720</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Esp. 8924726</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Nutrióloga.</strong><br>Universidad Regional del Sureste.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_688ae54b-837a-0baf-276c-a04cc2d84544-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/LIC.-MA.-DE-JESUS-SALINAS-ZIGA-3-NUTRIOLOGA.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/LIC.-MA.-DE-JESUS-SALINAS-ZIGA-3-NUTRIOLOGA-p-500.jpeg 500w, images/LIC.-MA.-DE-JESUS-SALINAS-ZIGA-3-NUTRIOLOGA-p-800.jpeg 800w, images/LIC.-MA.-DE-JESUS-SALINAS-ZIGA-3-NUTRIOLOGA-p-1080.jpeg 1080w, images/LIC.-MA.-DE-JESUS-SALINAS-ZIGA-3-NUTRIOLOGA.jpeg 1200w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Lic. María de Jesús Salinas Ziga</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 13372859</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Nutrición y educadora en diabetes Licenciatura.</strong><br>‍<strong>‍</strong>Universidad Popular Autónoma del Estado de Puebla (UPAEP), Diplomado de Educador en Diabetes: Universidad Anáhuac.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Oncología Quirúrgica</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-b6acea78-096d-ae3f-4334-7dc1ddba9a3d-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-ALCARO-AGUERO-SIBAJA-ONCOLOGO.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-ALCARO-AGUERO-SIBAJA-ONCOLOGO-p-500.jpeg 500w, images/DR.-ALCARO-AGUERO-SIBAJA-ONCOLOGO-p-800.jpeg 800w, images/DR.-ALCARO-AGUERO-SIBAJA-ONCOLOGO.jpeg 1080w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Álvaro Agüero Sibaja</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 11544665</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11639535</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Oncología.<br></strong>Universidad Nacional Autónoma de México, Universidad Autónoma Benito Juárez de Oaxaca certificado por el Consejo Mexicano de Oncología.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_3337b50c-1008-991b-dc83-94d7caae2868-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR.-GONZALO-MARQUEZ-ONCOLOGO.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR.-GONZALO-MARQUEZ-ONCOLOGO-p-500.jpeg 500w, images/DR.-GONZALO-MARQUEZ-ONCOLOGO-p-800.jpeg 800w, images/DR.-GONZALO-MARQUEZ-ONCOLOGO.jpeg 1201w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Gonzalo Márquez Acosta</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 1458082</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11639535</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Cirugía Oncológica.<br></strong>Hospital General de México, UNAM.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_39ebbc66-9dbe-7b90-485d-31926995bc9a-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doca.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Carolina Carrillo Hernández</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">DGP 11084282</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">DGPE 12285161</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Otorrinolaringología y cirugía, oncológica de cabeza y cuello.</strong><br>Hospital Civil de Guadalajara Fray Antonio Alcalde.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Ortopedia</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_2b7cd62c-30f4-bafd-f8b0-70ac07b578b9-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Francisco Javier Martínez</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 8043480</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 11482794</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Traumatólogo Pediátrico.</strong><br>UNAM/Hospital SHRINERS para Niños México.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Otorrinolaringología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_9b9c36d3-31e1-c5da-af60-dc3aea3ff8fa-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR-RENE-EMMANUEL-PADILLA-OTORRINO.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR-RENE-EMMANUEL-PADILLA-OTORRINO-p-500.jpeg 500w, images/DR-RENE-EMMANUEL-PADILLA-OTORRINO-p-800.jpeg 800w, images/DR-RENE-EMMANUEL-PADILLA-OTORRINO.jpeg 1066w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Rene Emanuel Padilla</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">D.G.P. 6851045</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 10796988</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Otorrinolaringología</strong>.<br>Universidad de Guadalajara (U. de G) / Universidad Autónoma de México (U.N.A.M.) Alta especialidad en Rinología y Cirugía Facial, certificado por el Consejo Mexicano de Otorrinolaringología y Cirugía de Cabeza y cuello pal-2842/17, miembro Sociedad Mexicana de Rinología y Cirugía Plástica Facial.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Psicología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-f2a54b6e-1d4e-6279-e8f1-87d81e7dfc02-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/LIC.-JULIO-APOLINAR-BONILLA-&#45;&#45;PSICOLOGIA-INFANTIL-.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/LIC.-JULIO-APOLINAR-BONILLA-&#45;&#45;PSICOLOGIA-INFANTIL&#45;&#45;p-500.jpeg 500w, images/LIC.-JULIO-APOLINAR-BONILLA-&#45;&#45;PSICOLOGIA-INFANTIL-.jpeg 720w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Lic. Julio Apolinar Bonilla Mota</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 4482 625</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 10796988</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Psicología infantil.</strong><br>Universidad Veracruzana.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Reumatología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_8a1f52e0-f36b-c76c-2921-f58eb5e81b92-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Gustavo Eduardo Razo</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 12530673</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Esp. 8924726</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Esp. 7160810</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Reumatología.</strong><br>Certificado por el Consejo Mexicano de Reumatología y Medicina Interna.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Urología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_8f8211f3-93df-89a3-05c0-a350414ace2f-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Víctor Manuel Flores</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 5757226</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 8475888</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Urología.</strong><br>Certificado por el Consejo Mexicano de Urología, egresado de la Universidad Nacional Autónoma de México.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Oftalmología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_15e00747-1bea-62ea-ac20-91039f2d1a92-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-500.jpg 500w, images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-800.jpg 800w, images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-1080.jpg 1080w, images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-1600.jpg 1600w, images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-2000.jpg 2000w, images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-2600.jpg 2600w, images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA-p-3200.jpg 3200w, images/DRA-LAURA-ALEJANDRA-MENDOZA-OCHOA.jpg 4000w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Laura Alejandra Mendoza Ochoa</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Med. 6739532</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 9085076</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Oftalmología.</strong><br>Cirujano Oftalmólogo, subespecialista en cirugía de catarata.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_193b202a-ec04-b54e-eb52-6adbcd616002-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-500.jpg 500w, images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-800.jpg 800w, images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-1080.jpg 1080w, images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-1600.jpg 1600w, images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-2000.jpg 2000w, images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-2600.jpg 2600w, images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA-p-3200.jpg 3200w, images/DR-ALBERTO-CARLOS-ABDALA-FIGUEROLA.jpg 4000w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Alberto Carlos Abdala Figueroa</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 9085076</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 9085076</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Oftalmología.<br></strong>Cirugía de párpados, órbita y vías lagrimales.<br>‍<strong><br></strong></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Radiólogo</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_965a03bf-0a3b-da3b-d4b1-56ce72193f5f-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DRA-EDGAR-LOGAN-LAZARO-RUIZ.jpg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DRA-EDGAR-LOGAN-LAZARO-RUIZ-p-500.jpg 500w, images/DRA-EDGAR-LOGAN-LAZARO-RUIZ-p-800.jpg 800w, images/DRA-EDGAR-LOGAN-LAZARO-RUIZ-p-1080.jpg 1080w, images/DRA-EDGAR-LOGAN-LAZARO-RUIZ-p-1600.jpg 1600w, images/DRA-EDGAR-LOGAN-LAZARO-RUIZ-p-2000.jpg 2000w, images/DRA-EDGAR-LOGAN-LAZARO-RUIZ-p-2600.jpg 2600w, images/DRA-EDGAR-LOGAN-LAZARO-RUIZ-p-3200.jpg 3200w, images/DRA-EDGAR-LOGAN-LAZARO-RUIZ.jpg 6000w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Edgar Logan Lázaro Ruiz</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 4333753</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 8258986</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Radiólogo.<br></strong>Médico especialista en radiología egresado de la Universidad Veracruzana, certificado por el Consejo Mexicano de Radiología e Imagen.<br></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="uui-padding-vertical-xhuge-17">-->
<!--              <div class="uui-text-align-center-4">-->
<!--                <div class="uui-max-width-large-7 align-center">-->
<!--                  <h2 class="uui-heading-medium-9">Anestesiología</h2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="uui-team01_component">-->
<!--                <div class="uui-team01_list-wrapper">-->
<!--                  <div class="w-layout-grid uui-team01_list">-->
<!--                    <div id="w-node-_3346bd52-9b70-2de2-4d21-28838570a347-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Hermilo Martínez Mier</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Prof. 1845239</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced Esp. 3181612</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Anestesiología.</strong><br>Hospital: Lic. Adolfo López Mateos, ISSSTE. Universidad: Universidad Nacional Autónoma de México, UNAM.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_889f7a30-1fa4-3b84-9a17-aa581cc63e4d-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doca.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. Katya Marisela Reyes Guzmán</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 6024323</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Esp. 9858530</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Anestesiología.</strong><br>Universidad Justo Sierra, Medicina General, Universidad Veracruzana.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_1fcc6f90-5b69-f458-07c6-99091cbc719a-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/doc.png" loading="lazy" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dr. Pedro Eusebio Salazar Guzmán</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 6841528</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Esp. 9858530</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Anestesiología.<br></strong>Hospital Regional de Alta Especialidad de Veracruz, Universidad Veracruzana, Universidad Benito Juárez de Oaxaca.</div>-->
<!--                    </div>-->
<!--                    <div id="w-node-_85d270a7-d9bf-7c96-2a01-e3686e6ceca6-d4649e07" class="uui-team01_item">-->
<!--                      <div class="uui-team01_image-wrapper"><img src="images/DRA.-MARIA-CONCEPCION-OCAÑA-MORALES.jpeg" loading="lazy" sizes="(max-width: 479px) 64vw, (max-width: 991px) 160px, (max-width: 1279px) 16vw, 160px" srcset="images/DRA.-MARIA-CONCEPCION-OCAÑA-MORALES-p-500.jpeg 500w, images/DRA.-MARIA-CONCEPCION-OCAÑA-MORALES-p-800.jpeg 800w, images/DRA.-MARIA-CONCEPCION-OCAÑA-MORALES-p-1080.jpeg 1080w, images/DRA.-MARIA-CONCEPCION-OCAÑA-MORALES.jpeg 1600w" alt="" class="uui-team01_image"></div>-->
<!--                      <div class="uui-heading-tiny-3">Dra. María Concepción Ocaña Morales</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Prof. 10005801</div>-->
<!--                      <div class="uui-text-size-medium-13 text-color-primary700">Ced. Esp. 9858530</div>-->
<!--                      <div class="uui-space-xxsmall-7"></div>-->
<!--                      <div class="uui-text-size-medium-13"><strong>Anestesiología.<br></strong>Hospital BUAP Benemérita Universidad Autónoma de Puebla.</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<style scoped>

</style>
