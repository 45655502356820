<script >


import AddressComponent from "@/components/AddressComponent.vue";
import OurSpecialties from "@/components/OurSpecialties.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import 'animate.css';
import AppointmentComponent from "@/components/AppointmentComponent.vue";

export default {
  name: "InternalMedicinePage",
  components: { AppointmentComponent, SideContactComponent, OurSpecialties, AddressComponent },
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_8ef2365d-72cc-65cf-5d31-f3e9142860b8-a1cc7db2" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-button-row-12">
                <router-link data-w-id="8ef2365d-72cc-65cf-5d31-f3e9142860ba" to="/"
                  class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699"
                        stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div>{{$t('internal-medicine.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('internal-medicine.title')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('internal-medicine.subtitle')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_8ef2365d-72cc-65cf-5d31-f3e9142860c3-a1cc7db2" class="uui-blogpost05_image-wrapper-7"><img
                srcset="../../../public/images/QUIMICO-p-500.jpg 500w, ../../../public/images/QUIMICO-p-800.jpg 800w, ../../../public/images/QUIMICO-p-1080.jpg 1080w, ../../../public/images/QUIMICO-p-1600.jpg 1600w, ../../../public/images/QUIMICO-p-2000.jpg 2000w, ../../../public/images/QUIMICO-p-2600.jpg 2600w, ../../../public/images/QUIMICO-p-3200.jpg 3200w, ../../../public/images/QUIMICO.jpg 6000w"
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 44vw, (max-width: 1919px) 46vw, 768px"
                src="../../../public/images/QUIMICO.jpg" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">
              <our-specialties></our-specialties>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-_8ef2365d-72cc-65cf-5d31-f3e9142860c7-a1cc7db2" class="uui-blogpost05_content-7">
              <div class="uui-blogpost05_content-2">
                <h3 class="ds-md-bold">{{$t('internal-medicine.article-1')}}</h3>
                <div class="uui-text-rich-text-3 w-richtext">
                  <p>{{$t('internal-medicine.article-1-p1')}}</p>
                  <p>{{$t('internal-medicine.article-1-p2')}}</p>

                  <h5>{{$t('internal-medicine.article-2')}}</h5>
                </div>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2">{{$t('internal-medicine.article-2-li1')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2">{{$t('internal-medicine.article-2-li2')}}</div>
                </a>
                <section class="equipos-mujer">
                  <div class="rl-padding-global-20">
                    <div class="rl-container-large-15"></div>
                  </div>
                </section>
                <div class="uui-text-rich-text-3 w-richtext">
                  <h5>{{$t('internal-medicine.article-3')}}</h5>
                </div>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2">{{$t('internal-medicine.article-3-li1')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2">{{$t('internal-medicine.article-3-li2')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2">{{$t('internal-medicine.article-3-li3')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2">{{$t('internal-medicine.article-3-li4')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2">{{$t('internal-medicine.article-3-li5')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2">{{$t('internal-medicine.article-3-li6')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2">{{$t('internal-medicine.article-3-li7')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2">{{$t('internal-medicine.article-3-li8')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path
                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2">{{$t('internal-medicine.article-3-li9')}}</div>
                </a>
              </div>
              <section class="team_seccion">
                <div class="uui-page-padding-18">
                  <div class="uui-container-large-14">
                    <div class="uui-padding-vertical-xhuge-20">
                      <div class="uui-max-width-large-9">
                        <div class="uui-space-xsmall-10"></div>
                      <h2 class="uui-heading-medium-11">{{$t('internal-medicine.medical-team')}}</h2>
                    </div>
                    <div class="uui-team07_component-2">
                      <div class="uui-team07_list-wrapper-2">
                        <div class="w-layout-grid item_medico">
                          <div class="card_doctor">
                            <div class="uui-team07_image-wrapper-2"><img src="images/DR.-JESUS-BETANZOS-GOMEZ.jpg"
                                loading="lazy" sizes="84px"
                                srcset="images/DR.-JESUS-BETANZOS-GOMEZ-p-500.jpg 500w, images/DR.-JESUS-BETANZOS-GOMEZ-p-800.jpg 800w, images/DR.-JESUS-BETANZOS-GOMEZ-p-1080.jpg 1080w, images/DR.-JESUS-BETANZOS-GOMEZ.jpg 1500w"
                                alt="" class="uui-team07_image-2"></div>
                            <div class="uui-team07_item-content-2">
                              <div class="uui-heading-tiny-4">Dr. Jesús Betanzos Gómez</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('internal-medicine.professional-license')}} 5701720</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('internal-medicine.specialty-license')}} 8924726</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('internal-medicine.specialty')}} </strong>.<br>{{$t('internal-medicine.dr1-description')}}</div>
                            </div>
                          </div>
                          <div class="card_doctor">
                            <div class="uui-team07_image-wrapper-2"><img src="images/moema.png" loading="lazy"
                                sizes="84px"
                                srcset="images/moema-p-500.png 500w, images/moema-p-800.png 800w, images/moema-p-1080.png 1080w, images/moema.png 1196w"
                                alt="" class="uui-team07_image-2"></div>
                            <div class="uui-team07_item-content-2">
                              <div class="uui-heading-tiny-4">Dra. Moema Jerónimo Rodríguez</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('internal-medicine.professional-license')}} 6740669</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('internal-medicine.specialty-license')}} 09289026</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('internal-medicine.specialty')}}<br></strong>{{$t('internal-medicine.dr2-description')}}</div>
                            </div>
                          </div>
                          <div class="card_doctor">
                            <div class="uui-team07_image-wrapper-2"><img src="images/dr.yuri-alfonso-roldan-medicina.jpg" loading="lazy" alt=""
                                class="uui-team07_image-2"></div>
                            <div class="uui-team07_item-content-2">
                              <div class="uui-heading-tiny-4">Dr. Yuri Alfonso Roldan Aragón</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('internal-medicine.professional-license')}} 2391273</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('internal-medicine.specialty-license')}} 5259026</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('internal-medicine.specialty-2')}}</strong><br>
                                {{$t('internal-medicine.dr3-description')}}
                              </div>
                            </div>

                          </div>
                          <div class="card_doctor">
                            <div class="uui-team07_image-wrapper-2"><img src="images/izaidel.png" loading="lazy"
                                sizes="84px"
                                srcset="images/izaidel-p-500.png 500w, images/izaidel-p-800.png 800w, images/izaidel-p-1080.png 1080w, images/izaidel.png 1200w"
                                alt="" class="uui-team07_image-2"></div>
                            <div class="uui-team07_item-content-2">
                              <div class="uui-heading-tiny-4">Dr. Izaidel Jiménez Escobar</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('internal-medicine.professional-license')}} 10374436</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('internal-medicine.certificate')}}  07539-23</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('internal-medicine.specialty')}}<br></strong>{{$t('internal-medicine.dr4-description')}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <appointment-component></appointment-component>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</header></template>

<style scoped></style>
