<script>
export default {
  name: "AddressPediatric",
}
</script>

<template>
  <div class="uui-text-size-medium-36">{{$t('home.general-from-desk')}}</div>
  <div class="uui-space-small-23"></div>
  <a href="tel:9541042270" class="uui-text-style-link-02-5 spacing">954 104 2270 ext. 203</a>
  <div class="uui-space-small-23"></div>
</template>

<style scoped>

</style>
