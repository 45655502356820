<script >
import {app} from "@/main";
import 'animate.css';
import OurClinicsComponent from "@/components/OurClinicsComponent.vue";
import AddressComponent from "@/components/AddressComponent.vue";
import AppointmentComponent from "@/components/AppointmentComponent.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import ClinicsMision from "@/components/ClinicsMision.vue";
import ClinicsVision from "@/components/ClinicsVision.vue";

export default{
  name:"OphthalmologyClinicPage",
  components: {
    ClinicsVision,
    ClinicsMision,
    SideContactComponent,
    AppointmentComponent,
    AddressComponent,
    OurClinicsComponent
  },
  // components: {AppointmentComponent, AddressComponent, SideContactComponent, OurClinicsComponent},
  data() {
    return {
      services:[],
      showDysplasiaClinic:false,
      showBreastClinic:false,
      showMaternalFetalClinic:false,
      showReproductiveBiology:false,
      show1:false,
      show2:false,
      show3:false,
      locale:'',
      specialties:[1],
      specialists:[],
      // showAnswer: false,
    };
  },

  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });

  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods:{
    loadData(locale){
      this.specialists = require('../../data/specialists.json');
      this.specialists = this.specialists.map(specialist => {
        const translateInformation= specialist.information[locale];


        let specialties = specialist.specialties.map(specialty=>{
          const name= specialty.name[locale];
          return {
            ...specialty,
            name:name
          }
        });

        return {
          ...specialist,
          information: translateInformation,
          specialties: specialties
        };
      });
      this.specialists = this.specialists.filter(s =>
          s.specialties.some(specialty => this.specialties.includes(specialty.id))
      );
    },
    stringArray(string) {
      return string.split(',');
    },
  }
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_2e6aefb2-b0a4-f6c6-506b-ee032e38dc07-6df8db5e" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="2e6aefb2-b0a4-f6c6-506b-ee032e38dc0a" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('home.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('home.diabetic-clinic-title')}} {{$t('home.24-7')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('diabetic.diabetic-clinic-description')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_2e6aefb2-b0a4-f6c6-506b-ee032e38dc14-6df8db5e" class="uui-blogpost05_image-wrapper-7"><img src="../../../public/images/CLINICAS-03.jpg" loading="lazy" alt="" class="uui-blogpost03_image-2 diabe" style="object-fit: contain;"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">
              <our-clinics-component></our-clinics-component>
              <side-contact-component></side-contact-component>
            </div>
            
            <div id="w-node-_6cc65888-dd23-54b5-b226-93c3040c0345-6df8db5e" class="uui-blogpost05_content-6">
              <div class="uui-text-rich-text-3 w-richtext">
                <h4>{{$t('diabetic.diabetic-clinic-p1')}}</h4>
                <p>{{$t('diabetic.diabetic-clinic-p2')}} <strong>{{$t('diabetic.diabetic-clinic-p3')}}</strong> {{$t('diabetic.diabetic-clinic-p4')}} <br>{{$t('diabetic.diabetic-clinic-p5')}} <strong>{{$t('diabetic.diabetic-clinic-p6')}}</strong></p>
              </div>
<!--              <div class="uui-text-rich-text-9 w-richtext">-->
<!--                <figure style="max-width:1680px" class="w-richtext-align-fullwidth w-richtext-figure-type-image">-->
<!--                  <div><img src="../../../public/images/primer-plano-medico-guantes-goma-tomando-analisis-sangre-paciente_181624-56107.jpg" loading="lazy" alt=""></div>-->
<!--                </figure>-->
<!--              </div>-->
              <div class="f-section-large-2">
                <div class="f-container-regular-4">
                  <div class="w-layout-grid f-gallery-two-column">
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec0-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-tall"><img src="../../../public/images/clinic-diabetic-image-3.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/clinic-diabetic-image-3.jpg 500w, ../../../public/images/clinic-diabetic-image-3.jpg 800w, ../../../public/images/clinic-diabetic-image-3.jpg 1080w, ../../../public/images/clinic-diabetic-image-3.jpg 1600w, ../../../public/images/clinic-diabetic-image-3.jpg 2000w, ../../../public/images/clinic-diabetic-image-3.jpg 2600w, ../../../public/images/clinic-diabetic-image-3.jpg 3200w, ../../../public/images/clinic-diabetic-image-3.jpg 6000w" alt="" class="f-image-cover"></div>
                    </a>
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec3-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-s"><img src="../../../public/images/clinic-diabetic-image-2.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/clinic-diabetic-image-2.jpg 500w, ../../../public/images/internal-medicine-image-2.jpg 800w, ../../../public/images/clinic-diabetic-image-2.jpg 1080w, ../../../public/images/clinic-diabetic-image-2.jpg 1600w, ../../../public/images/clinic-diabetic-image-2.jpg 2000w, ../../../public/images/clinic-diabetic-image-2.jpg 2600w, ../../../public/images/clinic-diabetic-image-2.jpg 3200w, ../../../public/images/clinic-diabetic-image-2.jpg 6000w" alt="" class="f-image-cover"></div>
                    </a>
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec6-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-s"><img src="../../../public/images/clinic-diabetic-image-1.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/clinic-diabetic-image-1.jpg 500w, ../../../public/images/clinic-diabetic-image-1.jpg 800w, ../../../public/images/clinic-diabetic-image-1.jpg 1080w, ../../../public/images/clinic-diabetic-image-1.jpg 1600w, ../../../public/images/clinic-diabetic-image-1.jpg 2000w, ../../../public/images/clinic-diabetic-image-1.jpg 2600w, ../../../public/images/clinic-diabetic-image-1.jpg 3200w, ../../../public/images/clinic-diabetic-image-1.jpg 6960w" alt="" class="f-image-cover"></div>
                    </a>

                  </div>
                </div>
              </div>
                <div class="div-block-20">
                <clinics-mision mision="Proporcionar atención médica de alta calidad y centrada en el paciente tanto para personas en riesgo de desarrollar diabetes como para aquellas que ya viven con esta condición. Nos esforzamos por proporcionar una atención integral que abarque la prevención, el manejo y el tratamiento de la diabetes."></clinics-mision>
                <clinics-vision vision="Ser reconocidos como líderes en el cuidado de la diabetes, proporcionando servicios médicos excepcionales que mejoran la calidad de vida de nuestros pacientes. Nos esforzamos por ser un centro de excelencia utilizando la última tecnología y técnicas de educación para proporcionar resultados óptimos."></clinics-vision>
              </div>
              <h3>{{$t('diabetic.diabetic-clinic-p7')}} <br>{{$t('diabetic.diabetic-clinic-p8')}}</h3>
              <div class="animate__animated  animate__fadeInLeft">
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('diabetic.diabetic-clinic-p9')}}</strong> {{$t('diabetic.diabetic-clinic-p10')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('diabetic.diabetic-clinic-p11')}}</strong> {{$t('diabetic.diabetic-clinic-p12')}}<br></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('diabetic.diabetic-clinic-p13')}}</strong> {{$t('diabetic.diabetic-clinic-p14')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('diabetic.diabetic-clinic-p15')}}</strong> {{$t('diabetic.diabetic-clinic-p16')}}</div>
                </a>              
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('diabetic.diabetic-clinic-p17')}}</strong> {{$t('diabetic.diabetic-clinic-p18')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('diabetic.diabetic-clinic-p19')}}</strong> {{$t('diabetic.diabetic-clinic-p20')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('diabetic.diabetic-clinic-p21')}}</strong> {{$t('diabetic.diabetic-clinic-p22')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('diabetic.diabetic-clinic-p23')}}</strong> {{$t('diabetic.diabetic-clinic-p24')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('diabetic.diabetic-clinic-p25')}}</strong> {{$t('diabetic.diabetic-clinic-p26')}}</div>
                </a>                
                <!--<a href="#" class="rl_footer1_social-link-2 w-inline-block">                  
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>Cuidados de paciente con retinopatía diabética</strong>Ofrecemos servicios de cuidado ocular para los pacientes con retinopatía diabética, una complicación común de la diabetes que puede afectar la visión. Esto incluye exámenes oculares regulares y tratamientos para proteger la salud ocular.</div>
                </a>-->
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">
                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                      height="24" viewbox="0 0 24 24" fill="none">
                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"
                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('diabetic.diabetic-clinic-p27')}}</strong>{{$t('diabetic.diabetic-clinic-p28')}}
                    <ul role="list">
                      <li><strong>{{$t('diabetic.diabetic-clinic-p29')}}</strong> {{$t('diabetic.diabetic-clinic-p30')}}</li>
                      <li><strong>{{$t('diabetic.diabetic-clinic-p31')}}</strong> {{$t('diabetic.diabetic-clinic-p32')}}</li>
                      <li><strong>{{$t('diabetic.diabetic-clinic-p33')}}</strong> {{$t('diabetic.diabetic-clinic-p34')}}</li>
                      <li><strong>{{$t('diabetic.diabetic-clinic-p35')}}</strong> {{$t('diabetic.diabetic-clinic-p36')}}</li>
                      <li><strong>{{$t('diabetic.diabetic-clinic-p37')}}</strong> {{$t('diabetic.diabetic-clinic-p38')}}</li>
                    </ul>
                  </div>
                </a>
              
              <h4>{{$t('diabetic.diabetic-clinic-p39')}}</h4>
            </div>              




              <div class="uui-faq01_component-7">
                        <div class="uui-faq01_list-8">
                          <div class="uui-faq01_accordion-6">
                            <div @click="show1 = !show1"  class="uui-faq01_question-6">
                              <div class="uui-faq01_heading-6">{{$t('diabetic.diabetic-clinic-p40')}}<br></div>
                              <div class="uui-faq01_icon-wrapper-6">
                                <div class="accordion-icon_component-7">
                                  <div class="accordion-icon_horizontal-line-7"></div>
                                  <div v-show="!show1" class="accordion-icon_vertical-line-7"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show1" class="uui-faq01_answer-6 animate__animated animate__fadeIn">
                              <div class="uui-text-size-large-35"><strong></strong></div>
                              <p>{{$t('diabetic.diabetic-clinic-p41')}}<br>
                                {{$t('diabetic.diabetic-clinic-p42')}}
                                <ul role="list">
                                  <li><strong>{{$t('diabetic.diabetic-clinic-p43')}}</strong> {{$t('diabetic.diabetic-clinic-p44')}}</li>
                                  <li><strong>{{$t('diabetic.diabetic-clinic-p45')}}</strong> {{$t('diabetic.diabetic-clinic-p46')}}</li>
                                  <li><strong>{{$t('diabetic.diabetic-clinic-p47')}}</strong> {{$t('diabetic.diabetic-clinic-p48')}}</li>
                                </ul>
                              </p>                                                      
                            </div>
                          </div>
                          <div class="uui-faq01_accordion-6">
                            <div @click="show2 = !show2" class="uui-faq01_question-6">
                              <div class="uui-faq01_heading-6">{{$t('diabetic.diabetic-clinic-p49')}}</div>
                              <div class="uui-faq01_icon-wrapper-6">
                                <div class="accordion-icon_component-7">
                                  <div class="accordion-icon_horizontal-line-7"></div>
                                  <div   v-show="!show2" class="accordion-icon_vertical-line-7"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show2" class="uui-faq01_answer-6 animate__animated animate__fadeIn">
                              <div class="uui-text-size-medium-34">{{$t('diabetic.diabetic-clinic-p50')}}</div>
                              <ul role="list">
                                <li><strong>{{$t('diabetic.diabetic-clinic-p51')}}</strong> {{$t('diabetic.diabetic-clinic-p52')}}</li>
                                <li><strong>{{$t('diabetic.diabetic-clinic-p53')}}</strong> {{$t('diabetic.diabetic-clinic-p54')}}</li>
                                <li><strong>{{$t('diabetic.diabetic-clinic-p55')}}</strong> {{$t('diabetic.diabetic-clinic-p56')}}</li>
                                <li><strong>{{$t('diabetic.diabetic-clinic-p57')}}</strong> {{$t('diabetic.diabetic-clinic-p58')}}</li>
                              </ul>
                              <p>{{$t('diabetic.diabetic-clinic-p59')}}</p>
                              <div class="uui-space-medium-14"></div>
                            </div>
                          </div>                          
                        </div>
                      </div>

              <section class="team_seccion">
                <div class="uui-page-padding-18">
                  <div class="uui-container-large-14">
                    <div class="uui-padding-vertical-xhuge-20">
                      <div class="uui-max-width-large-9">
                        <h2 class="uui-heading-medium-11" style="margin-top: 2rem;margin-bottom: 2rem; text-align: center;color: var(--hc--colors--green--600);">{{$t('home.emergencies-aid')}}</h2>
                        <h2 class="uui-heading-medium-11">{{$t('home.our-team')}}</h2>
                        <div class="uui-space-xsmall-10"></div>
                      </div>
                      <div class="uui-team07_component-2">
                        <div class="uui-team07_list-wrapper-2">
                          <div class="w-layout-grid item_medico">
                            
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img
                                  src="../../../public/images/Captura-de-pantalla-2023-12-26-a-las-12.49.34-a.m..png" loading="lazy" sizes="84px"
                                  srcset="../../../public/images/Captura-de-pantalla-2023-12-26-a-las-12.49.34-a.m.-p-500.png 500w, ../../../public/images/Captura-de-pantalla-2023-12-26-a-las-12.49.34-a.m.-p-800.png 800w, ../../../public/images/Captura-de-pantalla-2023-12-26-a-las-12.49.34-a.m..png 966w"
                                  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Moema Jerónimo Rodriguez</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 6740669</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Esp. 09289026</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Medicina Interna | Educadora en Diabetes.<br></strong>Universidad Autónoma Benito Juárez de Oaxaca en Especialidad de Medicina Interna, Miembro del Colegio Mexicano de Medicina Interna.<br>Educadora en Diabetes por la Universidad ANAHUAC.
                                </div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR.-JESUS-BETANZOS-GOMEZ.jpg" loading="lazy"                                                                           sizes="84px"
                                srcset="../../../public/images/DR.-JESUS-BETANZOS-GOMEZ-p-500.jpg 500w, ../../../public/images/DR.-JESUS-BETANZOS-GOMEZ-p-800.jpg 800w, ../../../public/images/DR.-JESUS-BETANZOS-GOMEZ-p-1080.jpg 1080w, ../../../public/images/DR.-JESUS-BETANZOS-GOMEZ.jpg 1500w"                                alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Jesús Betanzos Gómez</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 5701720</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Esp. 8924726</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Medicina Interna.<br></strong>Universidad Autónoma Benito Juárez de Oaxaca en Especialidad de Medicina Interna, Miembro del Colegio Mexicano de Medicina Interna, Miembro del American College of Physicians,Certificación Vigente por el Consejo Mexicano de Medicina Interna.</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img
                                  src="../../../public/images/Captura-de-pantalla-2023-12-26-a-las-12.48.33-a.m..png" loading="lazy" sizes="84px"
                                  srcset="../../../public/images/Captura-de-pantalla-2023-12-26-a-las-12.48.33-a.m.-p-500.png 500w, ../../../public/images/Captura-de-pantalla-2023-12-26-a-las-12.48.33-a.m.-p-800.png 800w, ../../../public/images/Captura-de-pantalla-2023-12-26-a-las-12.48.33-a.m..png 956w"                                  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Izaidel Jiménez Escobar</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced. Prof. 10374436</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Certificación Med. Interna 07539-23</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Medicina Interna.<br></strong>Universidad Autónoma Metropolitana,
                                  Universidad Autónoma de México, Hospital Español de México.</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/dr.yuri-alfonso-roldan-medicina.jpg" loading="lazy" alt=""                                                                           class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Yuri Alfonso Roldan Aragón</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. 2391273</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. 5259026</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Endocrinología Medicina Interna /
                                  Endocrinología.</strong>‍<br>UABJO-Hospital Dr Aurelio Valdivieso, Infectología UNAM - InCMNsz,
                                  Certificación Consejo Mexicano Infectología 823.</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR.EDER-ALEXANDRO-ARANGO.jpg" loading="lazy" alt=""                        class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Eder Alexandro Arango Bravo</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. 5843377</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. 11237339</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. 11237338</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Medicina Interna<br></strong>Medico Cirujano General Universidad
                                  Autónoma Benito Juárez Oaxaca, Medico Especialista en Medicina Interna CDMX, Universidad Autónoma de México,
                                  Medico Sub Especialista en Oncología Médica Especialista en Oncología Médica</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/dra.yanei-taateni-escobedo.jpg" loading="lazy" alt=""                                                                           class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dra. Yanei Taatení Escobedo</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. 8732344</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. 11747214</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Endocrinología Medicina Interna / Endocrinología.</strong><br>Alta
                                  Especialidad, Enfermedades Tiroideas, Hospital General de México, UNAM.</div>
                              </div>
                            </div>
                            <div class="rich-text-block w-richtext">
                              <h4 class="heading-18">Nutrición</h4>
                              <p>Olvidate de las dietas estrictas y disfruta el poder tomar decisiones adecuadas en nutran tu cuerpo y logres un
                                óptimo estado de Salud, nuestros expertos en el área diseñan un menú de acuerdo a tus necesidades de una forma
                                equilibrada.</p>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img
                                  src="../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1.jpg" loading="lazy" sizes="84px"
                                  srcset="../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-500.jpg 500w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-800.jpg 800w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-1080.jpg 1080w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-1600.jpg 1600w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-2000.jpg 2000w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-2600.jpg 2600w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1-p-3200.jpg 3200w, ../../../public/images/LIC.--VALERIA-TANYBETH-VASQUEZ-PEREZ-NUTRIOLOGA-CLINICA-1.jpg 4000w"                                  alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Lic. Valeria Tanybeth Vásquez Pérez</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>Nutrióloga.<br></strong>Universidad Regional del Sureste.</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/LIC.-MA.-DE-JESUS-SALINAS-ZIGA-2-NUTRIOLOGA.jpeg" loading="lazy" sizes="84px"
                               srcset="../../../public/images/LIC.-MA.-DE-JESUS-SALINAS-ZIGA-2-NUTRIOLOGA-p-500.jpeg 500w, ../../../public/images/LIC.-MA.-DE-JESUS-SALINAS-ZIGA-2-NUTRIOLOGA-p-800.jpeg 800w, ../../../public/images/LIC.-MA.-DE-JESUS-SALINAS-ZIGA-2-NUTRIOLOGA-p-1080.jpeg 1080w, ../../../public/images/LIC.-MA.-DE-JESUS-SALINAS-ZIGA-2-NUTRIOLOGA.jpeg 1200w"                              alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Lic. Maria De Jesús Salinas Ziga</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. 13372859</div>
                                <div class="uui-text-size-medium-15"><strong>Nutrición y Educadora En Diabetes.<br>‍</strong>Licenciatura:
                                  Universidad Popular Autónoma del Estado de Puebla (UPAEP), Diplomado de Educador en Diabetes: Universidad
                                  Anáhuac,</div>
                              </div>
                            </div>
                            <div class="rich-text-block w-richtext">
                              <h4 class="heading-18">Psicología</h4>
                              <p>Te brindamos estrategias motivadoras para realizar cambios en tu estilo de vida, juntos identificamos y
                                superamos las barreras que te impiden alcanzar tus objetivos.</p>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/LIC.-JULIO-APOLINAR-BONILLA---PSICOLOGIA-INFANTIL-.jpeg" loading="lazy" alt=""                                              class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Lic. Julio Apolinar Bonilla Mota</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Prof. 4482625</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">Ced Esp. 10796988</div>
                                <div class="uui-text-size-medium-15"><strong>Psicología Infantil.<br></strong>Universidad Veracruzana.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <appointment-component></appointment-component>

                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>

</style>
