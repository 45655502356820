<script >

export default {
  name: "OurClinicsComponent"
}
</script>

<template>
  <div class="uui-blogpost05_contributers-2">
    <div class="uui-blogpost05_sidebar-heading-2">{{$t('home.meet-our-clinics')}}</div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/CLINICA-DIGESTIVA48x48.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/clinics-digestive-diseases"  :class="{ 'w--current': $route.path === '/clinics-digestive-diseases' }" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.digestive-disease-clinic-title')}} 24/7 hrs</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/Clinica-Mujer-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/clinics-womens"  :class="{ 'w--current': $route.path === '/clinics-womens' }" class="link-block-7 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.women-clinic-title')}} 24/7 hrs</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/diabetico-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/clinics-diabetic"  :class="{ 'w--current': $route.path === '/clinics-diabetic' }" class="link-block-8 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.diabetic-clinic-title')}} 24/7 hrs</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/Pediatria-icon.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/clinics-pediatric"  :class="{ 'w--current': $route.path === '/clinics-pediatric' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.pediatric-clinic-title')}} 24/7 hrs</div>
        </router-link>

      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/clinic-internal-medicine.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/clinics-internal-medicine"  :class="{ 'w--current': $route.path === '/clinics-internal-medicine' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.internal-medicine-clinic')}} 24/7 hrs</div>
        </router-link>

      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/clinic-ophthalmology.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/clinics-ophthalmology"  :class="{ 'w--current': $route.path === '/clinics-ophthalmology' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.ophthalmology-clinic')}} 24/7 hrs</div>
        </router-link>

      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div class="uui-blogpost05_author-image-wrapper-2"><img src="../../public/images/clinic-trauma.svg" loading="lazy" alt="" class="uui-blogpost05_author-image-2"></div>
      <div>
        <router-link to="/clinics-shock-and-trauma"  :class="{ 'w--current': $route.path === '/clinics-shock-and-trauma' }" class="link-block-9 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.trauma-and-shock-clinic')}} 24/7 hrs</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
