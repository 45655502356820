<script>
export default {
  name: "AddressInsurers"
}
</script>

<template>
  <div class="uui-text-size-medium-36">{{$t('home.insurers-title')}}</div>
  <div class="uui-space-small-23"></div>
  <a href="tel:9541110537" class="uui-text-style-link-02-5 spacing">954 111 0537</a>
</template>

<style scoped>

</style>
