<template>
  <div >
    <app-header></app-header>
    <router-view></router-view>
    <footer-component></footer-component>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import FooterComponent from "@/components/FooterComponent.vue";
// import './css/normalize.css'; // Importa el archivo de estilos globales
// import './css/umbelas-spectacular-site.webflow.css'; // Importa el archivo de estilos globales
// import './css/webflow.css'; // Importa el archivo de estilos globales
// import './js/webflow.js'; // Importa el archivo de estilos globales


export default {
  name: 'App',
  components: {
    FooterComponent,
    AppHeader,
  },


}
</script>

