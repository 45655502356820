<script >
import AddressComponent from "@/components/AddressComponent.vue";
import OurServices from "@/components/OurServices.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import 'animate.css';

export default{
  name:"LaboratoryPage",
  components: {SideContactComponent, OurServices, AddressComponent},
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_744c27dc-aeb3-7512-6f4e-9195ba36d79b-3b03f6e2" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="744c27dc-aeb3-7512-6f4e-9195ba36d79e" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('LaboratoryPage.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('LaboratoryPage.title')}}<br>{{$t('LaboratoryPage.title2')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('LaboratoryPage.subtitle')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_744c27dc-aeb3-7512-6f4e-9195ba36d7a8-3b03f6e2" class="uui-blogpost05_image-wrapper-7">
              <img srcset="../../../public/images/MUESTRA-LABORATORIO-p-500.jpg 500w, ../../../public/images/MUESTRA-LABORATORIO-p-800.jpg 800w, ../../../public/images/MUESTRA-LABORATORIO-p-1080.jpg 1080w, ../../../public/images/MUESTRA-LABORATORIO-p-1600.jpg 1600w, ../../../public/images/MUESTRA-LABORATORIO-p-2000.jpg 2000w, ../../../public/images/MUESTRA-LABORATORIO-p-2600.jpg 2600w, ../../../public/images/MUESTRA-LABORATORIO-p-3200.jpg 3200w, ../../../public/images/MUESTRA-LABORATORIO.jpg 6000w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 46vw, 768px" src="../../../public/images/MUESTRA-LABORATORIO.jpg" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-4">
              <div class="uui-blogpost05_content-left-2">
                <our-services></our-services>
                <side-contact-component></side-contact-component>
              </div>
            </div>
            <div id="w-node-_744c27dc-aeb3-7512-6f4e-9195ba36d7ac-3b03f6e2" class="uui-blogpost05_content-7">
              <div class="uui-blogpost05_content-2">
                <div class="uui-text-rich-text-3 w-richtext">
                  <h4>{{$t('LaboratoryPage.article-1-title')}}</h4>
                  <p>{{$t('LaboratoryPage.article-1-content')}}</p>
                  <figure style="max-width:1680pxpx" class="w-richtext-align-fullwidth w-richtext-figure-type-image"><div>
                    <img src="../../../public/images/LABORATORIO.jpg" loading="lazy" alt=""></div>
                  </figure>
                </div>
                <div>
                  <h4>{{$t('LaboratoryPage.areas-title')}}:</h4>
                  <p>{{$t('LaboratoryPage.areas-subtitle')}}</p>
                  <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">                    <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                    <div class="rl_footer1_link-text-2">{{$t('LaboratoryPage.areas-li1')}}</div>
                  </a>
                  <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">                    <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                    <div class="rl_footer1_link-text-2">{{$t('LaboratoryPage.areas-li2')}}</div>
                  </a>
                  <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">                    <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                    <div class="rl_footer1_link-text-2">{{$t('LaboratoryPage.areas-li3')}}</div>
                  </a>
                  <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">                    <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                    <div class="rl_footer1_link-text-2">{{$t('LaboratoryPage.areas-li4')}}</div>
                  </a>
                  <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">                    <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                    <div class="rl_footer1_link-text-2">{{$t('LaboratoryPage.areas-li5')}}</div>
                  </a>
                  <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">                        <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                    <div class="rl_footer1_link-text-2">{{$t('LaboratoryPage.areas-li6')}}</div>
                  </a>
                  <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft">                        <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                    <div class="rl_footer1_link-text-2">{{$t('LaboratoryPage.areas-li7')}}</div>
                  </a>
                </div>
                <div>
                  <div class="f-section-large-2">
                    <div class="f-container-regular-4">
                      <div class="w-layout-grid f-gallery-two-column">
                        <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec0-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                          <div class="f-gallery-image-tall"><img src="../../../public/images/RECEPCION-LABORATORIO.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/RECEPCION-LABORATORIO-p-500.jpg 500w, ../../../public/images/RECEPCION-LABORATORIO-p-800.jpg 800w, ../../../public/images/RECEPCION-LABORATORIO-p-1080.jpg 1080w, ../../../public/images/RECEPCION-LABORATORIO-p-1600.jpg 1600w, ../../../public/images/RECEPCION-LABORATORIO-p-2000.jpg 2000w, ../../../public/images/RECEPCION-LABORATORIO-p-2600.jpg 2600w, ../../../public/images/RECEPCION-LABORATORIO-p-3200.jpg 3200w, ../../../public/images/RECEPCION-LABORATORIO.jpg 6000w" alt="" class="f-image-cover"></div>
                        </a>
                        <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec3-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                          <div class="f-gallery-image-s"><img src="../../../public/images/QUIMICO.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/QUIMICO-p-500.jpg 500w, ../../../public/images/QUIMICO-p-800.jpg 800w, ../../../public/images/QUIMICO-p-1080.jpg 1080w, ../../../public/images/QUIMICO-p-1600.jpg 1600w, ../../../public/images/QUIMICO-p-2000.jpg 2000w, ../../../public/images/QUIMICO-p-2600.jpg 2600w, ../../../public/images/QUIMICO-p-3200.jpg 3200w, ../../../public/images/QUIMICO.jpg 6000w" alt="" class="f-image-cover"></div>
                        </a>
                        <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec6-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                          <div class="f-gallery-image-s"><img src="../../../public/images/IMG_6299.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/IMG_6299-p-500.jpg 500w, ../../../public/images/IMG_6299-p-800.jpg 800w, ../../../public/images/IMG_6299-p-1080.jpg 1080w, ../../../public/images/IMG_6299-p-1600.jpg 1600w, ../../../public/images/IMG_6299-p-2000.jpg 2000w, ../../../public/images/IMG_6299-p-2600.jpg 2600w, ../../../public/images/IMG_6299-p-3200.jpg 3200w, ../../../public/images/IMG_6299.jpg 6960w" alt="" class="f-image-cover"></div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="uui-text-rich-text-4 w-richtext">
                    <h4>{{$t('LaboratoryPage.COFEPRIS')}}</h4><p>{{$t('LaboratoryPage.COFEPRIS-content')}}</p>
                  </div>
                  <div class="uui-text-rich-text-4 w-richtext">
                    <h4>{{$t('LaboratoryPage.benefits-title')}}</h4>
                    <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft"> <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                  </svg></div>
                      <div class="rl_footer1_link-text-2">{{$t('LaboratoryPage.benefits-li1')}}</div>
                    </a>
                    <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft"> <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                  </svg></div>
                      <div class="rl_footer1_link-text-2">{{$t('LaboratoryPage.benefits-li2')}}</div>
                    </a>
                    <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft"> <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                  </svg></div>
                      <div class="rl_footer1_link-text-2">{{$t('LaboratoryPage.benefits-li3')}}</div>
                    </a>
                    <a href="#" class="rl_footer1_social-link-2 w-inline-block animate__animated  animate__fadeInLeft"> <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">                    <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01" stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                  </svg></div>
                      <div class="rl_footer1_link-text-2">{{$t('LaboratoryPage.benefits-li4')}}</div>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <h2 class="uui-heading-medium-11" style="margin-top: 2rem;margin-bottom: 2rem; text-align: center;color: var(--hc--colors--green--600);">{{$t('LaboratoryPage.service24')}}</h2>
                <p style="text-align: center"><strong>{{$t('LaboratoryPage.offer')}}</strong><br>{{$t('LaboratoryPage.content-offers')}}</p>
              </div>
              <div>
                <div class="uui-text-align-center-5">
                  <div class="uui-max-width-large-10 align-center">
                    <div class="uui-space-xsmall-11"></div>
                    <div class="uui-text-rich-text-3 w-richtext">
                      <h4>{{$t('LaboratoryPage.title-study-packages')}}</h4>
                    </div>
                  </div>
                </div>
                <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="uui-pricing08_component w-tabs">
                  <div class="uui-pricing08_tab-content w-tab-content">
                    <div data-w-tab="Tab 1" class="uui-pricing08_tab-pane w-tab-pane w--tab-active">
                      <div class="w-layout-grid uui-pricing08_components">
                        <div data-w-id="7cc4bcd4-67e6-ca4f-d404-7c4e49938cfb" class="uui-pricing08_plan">
                          <div class="uui-pricing08_content">
                            <div class="uui-pricing08_content-top">
                              <div class="uui-text-align-center-5">
                                <h2 class="uui-heading-xxsmall-9">{{$t('LaboratoryPage.title-common-profile')}}</h2>
                                <!--<div class="uui-pricing08_price">$10,300<span class="uui-pricing08_price-text">MXN</span></div>-->
                              </div>
                              <div class="uui-pricing08_feature-list">
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d06-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.common-profile-1')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d0b-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.common-profile-2')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d10-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.common-profile-3')}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uui-pricing08_plan">
                          <div class="uui-pricing08_content">
                            <div class="uui-pricing08_content-top">
                              <div class="uui-text-align-center-5">
                                <h2 class="uui-heading-xxsmall-9">{{$t('LaboratoryPage.title-basic-package')}}</h2>
                                <!--<div class="uui-pricing08_price">$14,500<span class="uui-pricing08_price-text">MXN</span></div>-->
                              </div>
                              <div class="uui-pricing08_feature-list">
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d4c-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.basic-package-1')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d51-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.basic-package-2')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d56-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.basic-package-3')}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uui-pricing08_plan">
                          <div class="uui-pricing08_content">
                            <div class="uui-pricing08_content-top">
                              <div class="uui-text-align-center-5">
                                <h2 class="uui-heading-xxsmall-9">{{$t('LaboratoryPage.title-pregnancy-package')}}</h2>
                                <!--<div class="uui-pricing08_price">$14,500<span class="uui-pricing08_price-text">MXN</span></div>-->
                              </div>
                              <div class="uui-pricing08_feature-list">
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d4c-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.pregnancy-package-1')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d51-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.pregnancy-package-2')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d56-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.pregnancy-package-3')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d5b-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.pregnancy-package-4')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d5b-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.pregnancy-package-5')}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uui-pricing08_plan">
                          <div class="uui-pricing08_content">
                            <div class="uui-pricing08_content-top">
                              <div class="uui-text-align-center-5">
                                <h2 class="uui-heading-xxsmall-9">{{$t('LaboratoryPage.title-male-profile')}}</h2>
                                <!--<div class="uui-pricing08_price">$14,500<span class="uui-pricing08_price-text">MXN</span></div>-->
                              </div>
                              <div class="uui-pricing08_feature-list">
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d4c-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.male-profile-1')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d51-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.male-profile-2')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d56-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.male-profile-3')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d5b-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.male-profile-4')}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uui-pricing08_plan">
                          <div class="uui-pricing08_content">
                            <div class="uui-pricing08_content-top">
                              <div class="uui-text-align-center-5">
                                <h2 class="uui-heading-xxsmall-9">{{$t('LaboratoryPage.title-prenuptial-package')}}</h2>
                                <!--<div class="uui-pricing08_price">$14,500<span class="uui-pricing08_price-text">MXN</span></div>-->
                              </div>
                              <div class="uui-pricing08_feature-list">
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d4c-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.prenuptial-package-1')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d51-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.prenuptial-package-2')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d56-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.prenuptial-package-3')}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uui-pricing08_plan">
                          <div class="uui-pricing08_content">
                            <div class="uui-pricing08_content-top">
                              <div class="uui-text-align-center-5">
                                <h2 class="uui-heading-xxsmall-9">{{$t('LaboratoryPage.title-ETS-package')}}</h2>
                                <!--<div class="uui-pricing08_price">$14,500<span class="uui-pricing08_price-text">MXN</span></div>-->
                              </div>
                              <div class="uui-pricing08_feature-list">
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d4c-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.packageETS-1')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d51-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.packageETS-2')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d56-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.packageETS-3')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d5b-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.packageETS-4')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d5b-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.packageETS-5')}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uui-pricing08_plan">
                          <div class="uui-pricing08_content">
                            <div class="uui-pricing08_content-top">
                              <div class="uui-text-align-center-5">
                                <h2 class="uui-heading-xxsmall-9">{{$t('LaboratoryPage.title-diabetes-package')}}</h2>
                                <!--<div class="uui-pricing08_price">$14,500<span class="uui-pricing08_price-text">MXN</span></div>-->
                              </div>
                              <div class="uui-pricing08_feature-list">
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d4c-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.diabetes-package-1')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d56-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.diabetes-package-2')}}</div>
                                </div>
                                <div id="w-node-_7cc4bcd4-67e6-ca4f-d404-7c4e49938d5b-6b5c1cae" class="uui-pricing08_feature">
                                  <div class="uui-pricing08_icon-wrapper">
                                    <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" fill="#D1FADF"></path>
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.096 7.39004L9.93602 14.3L8.03602 12.27C7.68602 11.94 7.13602 11.92 6.73602 12.2C6.34602 12.49 6.23602 13 6.47602 13.41L8.72602 17.07C8.94602 17.41 9.32601 17.62 9.75601 17.62C10.166 17.62 10.556 17.41 10.776 17.07C11.136 16.6 18.006 8.41004 18.006 8.41004C18.906 7.49004 17.816 6.68004 17.096 7.38004V7.39004Z" fill="#12B76A"></path>
                                    </svg></div>
                                  </div>
                                  <div class="uui-text-size-medium-17">{{$t('LaboratoryPage.diabetes-package-3')}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
