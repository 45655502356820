// apiService.js
import axios from 'axios';
import {API_SEARCH, LOCALE_COOKIE_NAME} from "@/config";
import {getCookie} from "@/main";

class SearchServices {
    constructor() {
        this.apiBaseUrl = API_SEARCH; //
    }

    async search(word) {
        try {
            const preferredLanguage =getCookie(LOCALE_COOKIE_NAME)||'es';// localStorage.getItem('preferredLanguage')||'es';
            const response = await axios.get(`${this.apiBaseUrl}?word=${word}&locale=${preferredLanguage}`);
            // console.log(JSON.stringify(response.data))
            return response.data;
        } catch (error) {
            console.error('Error al obtener datos de la API:', error);
            throw error;
        }
    }
}

export default new SearchServices();
