<script>
export default {
  name: "AboutPage",
  methods: {
    getImagePath(translationKey) {
      // Utiliza la función $t para obtener el valor de la clave de traducción
      return this.$t(translationKey);
    }}
}
</script>

<template>
  <header class="rl_section_header1-2">
    <div class="rl-padding-global-16">
      <div class="rl-container-large-11">
        <div class="rl-padding-section-large-16">
          <div class="rl_header1_component-3">
            <div class="rl_header1_content-2">
              <router-link  class="text-link-arrow w-inline-block" to="/">
                <div class="arrow-embed w-embed"><svg width="17" height="17" viewbox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.13 8.5L6.87296 4.24291L5.87067 5.24379L9.129 8.5L5.87067 11.7555L6.87225 12.7571L11.13 8.5Z" fill="currentColor"></path>
                </svg></div>
                <div class="text-block-26">{{$t('home.home')}}</div>
              </router-link>
              <h1 class="rl-heading-style-h1-6">{{$t('home.about-us')}}</h1>
              <div class="rl_heading1_spacing-block-1-4"></div>
              <p class="rl-text-style-medium-13">{{$t('about.ham-about-description')}}</p>
              <div class="rl_heading1_spacing-block-2-4"></div>
              <blockquote class="block-quote-2"><strong class="bold-text-16">{{$t('about.medical-address')}} - HAM</strong></blockquote>
            </div>
            <div class="rl_header1_image-wrapper-2"><img src="images/RECEPCION.jpg" loading="lazy" width="344" height="500" alt="" srcset="images/RECEPCION-p-500.jpg 500w, images/RECEPCION-p-800.jpg 800w, images/RECEPCION-p-1080.jpg 1080w, images/RECEPCION-p-1600.jpg 1600w, images/RECEPCION-p-2000.jpg 2000w, images/RECEPCION-p-2600.jpg 2600w, images/RECEPCION-p-3200.jpg 3200w, images/RECEPCION.jpg 6000w" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 39vw, (max-width: 1919px) 531.765625px, 611.765625px" class="uui-heroheader21_image-2"></div>
            <header class="uui-section_heroheader06">
              <div class="uui-page-padding-12">
                <div class="uui-container-large-9"></div>
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  </header>
  <section class="uui-section_cta08">
    <div class="uui-page-padding-39">
      <div class="uui-container-small-5">
        <div class="uui-padding-vertical-xhuge-39">
          <div class="uui-text-align-center-14">
            <h2 class="uui-heading-medium-25">Hospital Ángel del Mar</h2>
            <div class="uui-space-xsmall-20"></div>
            <div>{{$t('about.ham-about-description-2')}}
              <br><strong>
                {{$t('about.ham-about-description-3')}}
                <br></strong>
              {{$t('about.ham-about-description-4')}}</div>
            <div class="uui-space-medium-12"></div>
            <div class="uui-button-row-25 button-row-center is-reverse-mobile-landscape">
              <div class="uui-button-wrapper-13 max-width-full-mobile-landscape">
                <router-link to="/contact" class="uui-button-29 w-inline-block">
                  <div>{{$t('home.contact-us')}}</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="uui-section_layout17">
    <div class="uui-page-padding-39">
      <div class="uui-container-large-29">
        <div class="uui-padding-vertical-xhuge-39">
          <div class="w-layout-grid uui-layout17_component">
            <div class="uui-layout17_content">
              <div class="w-layout-grid uui-layout17_item-list">
                <div id="w-node-_5625c141-fb3b-7c7b-2b38-f0ab7068ab0e-73a2dd8e" class="uui-layout17_item">
                  <div class="layout17_item-icon-wrapper">
                    <div class="uui-icon-featured-outline-large-4">
                      <div class="uui-icon-1x1-xsmall-9 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewbox="0 0 21 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.2 3.6C1.2 1.61178 2.81177 0 4.8 0H16.8C18.7882 0 20.4 1.61178 20.4 3.6V20.4C20.4 22.3882 18.7882 24 16.8 24H4.8C2.81177 24 1.2 22.3882 1.2 20.4V18C0.537258 18 0 17.4627 0 16.8C0 16.1373 0.537258 15.6 1.2 15.6V13.2C0.537258 13.2 0 12.6627 0 12C0 11.3373 0.537258 10.8 1.2 10.8V8.4C0.537258 8.4 0 7.86274 0 7.2C0 6.53726 0.537258 6 1.2 6V3.6ZM3.6 8.4C4.26274 8.4 4.8 7.86274 4.8 7.2C4.8 6.53726 4.26274 6 3.6 6V3.6C3.6 2.93726 4.13726 2.4 4.8 2.4H16.8C17.4627 2.4 18 2.93726 18 3.6V20.4C18 21.0627 17.4627 21.6 16.8 21.6H4.8C4.13726 21.6 3.6 21.0627 3.6 20.4V18C4.26274 18 4.8 17.4627 4.8 16.8C4.8 16.1373 4.26274 15.6 3.6 15.6V13.2C4.26274 13.2 4.8 12.6627 4.8 12C4.8 11.3373 4.26274 10.8 3.6 10.8V8.4Z" fill="black" fill-opacity="0.8"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35 8.4V10.8H13.65V13.2H11.35V15.6H9.05V13.2H6.75V10.8H9.05L9.05 8.4H11.35Z" fill="#39D8B0"></path>
                      </svg></div>
                    </div>
                  </div>
                  <div class="uui-layout17_item-text-wrapper">
                    <h3 class="uui-heading-xsmall-3">Hospital Ángel del Mar</h3>
                    <div class="uui-space-xxsmall-15"></div>
                    <div class="uui-text-size-medium-29">{{$t('about.ham-about-description-5')}}</div>
                  </div>
                </div>
                <div id="w-node-_5625c141-fb3b-7c7b-2b38-f0ab7068ab18-73a2dd8e" class="uui-layout17_item">
                  <div class="layout17_item-icon-wrapper">
                    <div class="uui-icon-featured-outline-large-4">
                      <div class="uui-icon-1x1-xsmall-9 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                        <path d="M9 17L12 20L19.574 12.3714C21.4753 10.4563 21.4753 7.35138 19.574 5.43631C17.6726 3.52123 14.5899 3.52123 12.6885 5.43631C12.4215 5.70524 12 6.30692 12 6.30692C12 6.30692 11.5785 5.70524 11.3115 5.43631C9.4101 3.52123 6.32738 3.52123 4.42602 5.43631C3.36678 6.50318 2.89764 7.93934 3.01859 9.33333" stroke="black" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M3 13H7L8.5 10L10 13H13" stroke="#39D8B0" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg></div>
                    </div>
                  </div>
                  <div class="uui-layout17_item-text-wrapper">
                    <h3 class="uui-heading-xsmall-3">{{$t('about.ham-about-description-6')}}</h3>
                    <div class="uui-space-xxsmall-15"></div>
                    <div class="uui-text-size-medium-29">{{$t('about.ham-about-description-7')}}</div>
                  </div>
                </div>
                <div id="w-node-_5625c141-fb3b-7c7b-2b38-f0ab7068ab22-73a2dd8e" class="uui-layout17_item">
                  <div class="layout17_item-icon-wrapper">
                    <div class="uui-icon-featured-outline-large-4">
                      <div class="uui-icon-1x1-xsmall-9 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7929 11.2365C17.4416 9.58782 20.1147 9.58782 21.7635 11.2365C23.4122 12.8853 23.4122 15.5584 21.7635 17.2071L19.4853 19.4853L17.2071 21.7635C15.5584 23.4122 12.8853 23.4122 11.2365 21.7635C9.58782 20.1147 9.58782 17.4416 11.2365 15.7929L15.7929 11.2365ZM18.7782 17.364L20.3492 15.7929C21.2169 14.9252 21.2169 13.5184 20.3492 12.6508C19.4816 11.7831 18.0748 11.7831 17.2071 12.6508L15.636 14.2218L18.7782 17.364ZM14.2218 15.636L12.6508 17.2071C11.7831 18.0748 11.7831 19.4816 12.6508 20.3492C13.5184 21.2169 14.9252 21.2169 15.7929 20.3492L17.364 18.7782L14.2218 15.636Z" fill="#39D8B0"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.23632 4C5.85755 4 5.51128 4.214 5.34189 4.55279L3.5218 8.19296C3.44457 8.34744 3.41649 8.42468 3.40596 8.47587C3.26158 9.17798 3.71889 9.82152 4.26082 9.96794C4.33912 9.98909 4.41909 10 4.50025 10C5.32868 10 6.00025 9.32843 6.00025 8.5C6.00025 7.94772 6.44797 7.5 7.00025 7.5C7.55254 7.5 8.00025 7.94772 8.00025 8.5C8.00025 9.32843 8.67182 10 9.50025 10C10.3287 10 11.0003 9.32843 11.0003 8.5C11.0003 7.94772 11.448 7.5 12.0003 7.5C12.5525 7.5 13.0003 7.94772 13.0003 8.5C13.0003 10.433 11.4332 12 9.50025 12C8.52091 12 7.63551 11.5978 7.00025 10.9495C6.47505 11.4855 5.77888 11.8532 4.99999 11.9646V20H5.99999C6.55228 20 6.99999 20.4477 6.99999 21C6.99999 21.5523 6.55228 22 5.99999 22H3.99999C3.44771 22 2.99999 21.5523 2.99999 21V11.5884C1.85114 10.9166 1.15008 9.51669 1.44696 8.07301C1.51268 7.75343 1.6409 7.48263 1.73295 7.29854L3.55304 3.65836C4.06121 2.64201 5.1 2 6.23632 2H17.6814C18.8815 2 19.9662 2.71527 20.4389 3.81841L21.4192 6.10614C21.6367 6.61378 21.4015 7.20165 20.8939 7.41917C20.3862 7.63669 19.7983 7.40151 19.5808 6.89386L18.6005 4.60614L19.5088 4.21694L18.6005 4.60614C18.443 4.23842 18.0814 4 17.6814 4H6.23632Z" fill="black" fill-opacity="0.8"></path>
                      </svg></div>
                    </div>
                  </div>
                  <div class="uui-layout17_item-text-wrapper">
                    <h3 class="uui-heading-xsmall-3">{{$t('about.ham-about-description-8')}}</h3>
                    <div class="uui-space-xxsmall-15"></div>
                    <div class="uui-text-size-medium-29">{{$t('about.ham-about-description-9')}}</div>
                  </div>
                </div>
                <div id="w-node-_1821d349-a113-0786-dfbb-8b2d4ad30a36-73a2dd8e" class="uui-layout17_item">
                  <div class="layout17_item-icon-wrapper">
                    <div class="uui-icon-featured-outline-large-4">
                      <div class="uui-icon-1x1-xsmall-9 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 9V11H15V13H13V15H11V13H9V11H11V9H13Z" fill="#39D8B0"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.64806 6.03798C7.53979 5.32669 9.91556 5 12 5C14.0844 5 16.4602 5.32669 18.3519 6.03798C19.2962 6.39303 20.1806 6.86633 20.844 7.49477C21.5218 8.1369 22 8.97633 22 10C22 13.463 20.4016 16.2861 19.5351 17.3451C19.353 17.5815 19.1191 17.7476 18.9168 17.8647C18.6975 17.9916 18.4503 18.0978 18.1967 18.1882C17.6895 18.3691 17.0724 18.5163 16.4129 18.6333C15.0896 18.868 13.4838 19 12 19C10.5162 19 8.91036 18.868 7.58707 18.6333C6.92757 18.5163 6.31053 18.3691 5.80332 18.1882C5.54969 18.0978 5.30251 17.9916 5.08316 17.8647C4.88092 17.7476 4.64695 17.5815 4.46488 17.3451C3.59836 16.2861 2 13.4629 2 10C2 8.97633 2.47817 8.1369 3.15603 7.49477C3.81943 6.86633 4.70376 6.39303 5.64806 6.03798ZM4.53147 8.94673C4.14683 9.31109 4 9.65566 4 10C4 12.9184 5.38436 15.3175 6.02117 16.0888C6.02354 16.0917 6.02589 16.0946 6.02823 16.0975C6.03797 16.1046 6.056 16.1169 6.08493 16.1336C6.16615 16.1806 6.29324 16.2396 6.47499 16.3044C6.83854 16.434 7.33673 16.5577 7.93635 16.664C9.13128 16.876 10.6192 17 12 17C13.3808 17 14.8687 16.876 16.0637 16.664C16.6633 16.5577 17.1615 16.434 17.525 16.3044C17.7068 16.2396 17.8338 16.1806 17.9151 16.1336C17.944 16.1169 17.962 16.1046 17.9718 16.0975C17.9741 16.0946 17.9765 16.0917 17.9788 16.0888C18.6156 15.3175 20 12.9184 20 10C20 9.65566 19.8532 9.31109 19.4685 8.94673C19.0694 8.56866 18.4538 8.21296 17.6481 7.91002C16.0398 7.30531 13.9156 7 12 7C10.0844 7 7.96021 7.30531 6.35194 7.91002C5.54624 8.21296 4.93057 8.56866 4.53147 8.94673Z" fill="black" fill-opacity="0.8"></path>
                      </svg></div>
                    </div>
                  </div>
                  <div class="uui-layout17_item-text-wrapper">
                    <div class="uui-text-size-medium-29">{{$t('about.ham-about-description-10')}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uui-layout17_image-wrapper">
              <img :src="getImagePath('about-us-image')" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 44vw, (max-width: 1439px) 45vw, (max-width: 1919px) 608px, 688px"
                   alt="Credit card mockups" class="uui-layout17_image"></div>

<!--
            <img src="images/379637541_822945526497934_5867392141516901535_n.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 44vw, (max-width: 1439px) 45vw, (max-width: 1919px) 608px, 688px"
                 srcset="images/379637541_822945526497934_5867392141516901535_n-p-500.jpg 500w, images/379637541_822945526497934_5867392141516901535_n-p-800.jpg 800w, images/379637541_822945526497934_5867392141516901535_n-p-1080.jpg 1080w, images/379637541_822945526497934_5867392141516901535_n-p-1600.jpg 1600w, images/379637541_822945526497934_5867392141516901535_n.jpg 2048w" alt="Credit card mockups" class="uui-layout17_image"></div>-->
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="uui-section_layout01-2">
    <div class="uui-page-padding-39">
      <div class="uui-container-large-29">
        <div class="uui-padding-vertical-xhuge-39">
          <div class="w-layout-grid uui-layout01_component-2">
            <div id="w-node-_61b1ab71-7134-5796-b926-24732ed5ee60-73a2dd8e" class="uui-layout01_content-2">
              <h2 class="uui-heading-medium-25">{{$t('about.ham-we-are-title')}}</h2>
              <div class="uui-space-xsmall-20"></div>
              <div class="text-block-27">{{$t('about.ham-we-are-description')}}
                <br>{{$t('about.ham-we-are-description-2')}}
                <br>{{$t('about.ham-we-are-description-3')}}
                <br>{{$t('about.ham-we-are-description-4')}}</div>
              <div class="uui-space-medium-12"></div>
              <section class="uui-section_logos03-2">
                <div class="uui-page-padding-39">
                  <div class="uui-container-large-29">
                    <div class="uui-space-xxlarge-3"></div>
                    <div class="uui-logos03_component-2">
                      <div class="uui-logos03_logo-list-2">
                        <div id="w-node-_35eaf16e-8ae6-01af-a92e-af191f56603f-73a2dd8e" class="uui-logos03_wrapper-2"><img src="images/HAM-LOGO-256.png" loading="lazy" alt="Company logo" class="uui-logos03_logo-2"></div>
                        <div class="uui-logos03_wrapper-2"><img src="images/pas_1.png" loading="lazy" alt="Company logo" class="uui-logos03_logo-2"></div>
                        <div class="uui-logos03_wrapper-2"><img src="images/Consorcio.png" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 106.9375px, (max-width: 991px) 9vw, (max-width: 1279px) 80.203125px, (max-width: 1439px) 6vw, 133.6796875px" srcset="images/Consorcio-p-500.png 500w, images/Consorcio-p-800.png 800w, images/Consorcio-p-1080.png 1080w, images/Consorcio.png 1280w" alt="Company logo" class="uui-logos03_logo-2"></div>
                        <div class="uui-logos03_wrapper-2"></div>
                        <div class="uui-logos03_wrapper-2"></div>
                        <div id="w-node-_35eaf16e-8ae6-01af-a92e-af191f566047-73a2dd8e" class="uui-logos03_wrapper-2"></div>
                      </div>
                    </div>
                    <div class="uui-space-xxlarge-3"></div>
                  </div>
                </div>
              </section>
            </div>
            <div class="uui-layout01_image-wrapper-2"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <header class="uui-section_heroheader21-2">
    <div class="w-layout-grid uui-heroheader21_component-2">
      <div class="uui-heroheader21_image-wrapper-2"><img src="images/T1.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, 50vw" height="" alt="" srcset="images/T1-p-500.png 500w, images/T1-p-800.png 800w, images/T1.png 887w" class="uui-heroheader21_image-2"></div>
      <div id="w-node-_98cec312-97cf-99d1-5c9d-cb7470e268b0-73a2dd8e" class="uui-heroheader21_content-2">
        <div class="uui-space-small-6"></div>
        <h2 class="uui-heading-medium-25">{{$t('about.ham-our-mission-title')}}</h2>
        <div class="uui-space-small-6"></div>
        <div class="text-block-28">{{$t('about.ham-our-mission-description')}}</div>
        <div class="uui-max-width-small-2"></div>
      </div>
    </div>
  </header>
  <header class="uui-section_heroheader21-2">
    <div class="w-layout-grid uui-heroheader21_component-2">
      <div id="w-node-_1b9bc856-2fc9-2523-c08d-61943870ca93-73a2dd8e" class="uui-heroheader21_content-2">
        <div class="uui-space-small-6"></div>
        <h2 class="uui-heading-medium-25">{{$t('about.ham-our-vision-title')}}</h2>
        <div class="uui-space-small-6"></div>
        <div class="text-block-29">{{$t('about.ham-our-vision-description')}}</div>
        <div class="uui-max-width-small-2"></div>
      </div>
      <div class="uui-heroheader21_image-wrapper-2"><img src="images/T2.png" loading="lazy" srcset="images/T2-p-500.png 500w, images/T2.png 793w" alt="" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, 50vw" class="uui-heroheader21_image-2"></div>
    </div>
  </header>
  <section class="uui-section_layout75-2">
    <div class="uui-page-padding-39">
      <div class="uui-container-large-29">
        <div class="uui-padding-vertical-xhuge-40">
          <div class="uui-layout75_component-2">
            <div class="uui-text-align-center-14">
              <div class="uui-max-width-large-18">
                <div class="uui-space-xsmall-20"></div>
                <h2 class="uui-heading-medium-26">{{$t('about.ham-our-values-title')}}</h2>
                <div class="uui-space-xsmall-20"></div>
              </div>
            </div>
            <div class="w-layout-grid uui-layout75_list-2">
              <div class="uui-layout75_item-2"><img src="images/6156-1.png" loading="lazy" alt="Integration icon" class="uui-layout75_icon-2">
                <div class="uui-space-xsmall-20"></div>
                <h3 class="uui-heading-xxsmall-20">{{$t('about.ham-honesty')}}</h3>
                <div class="uui-space-xxsmall-15"></div>
                <div class="uui-text-size-medium-29">
                  {{$t('about.ham-honesty-description')}}
                  <br>{{$t('about.ham-honesty-description-2')}}</div>
              </div>
              <div class="uui-layout75_item-2"><img src="images/image-102.png" loading="lazy" alt="Integration icon" class="uui-layout75_icon-2">
                <div class="uui-space-xsmall-20"></div>
                <h3 class="uui-heading-xxsmall-20">{{$t('about.ham-commitment')}}</h3>
                <div class="uui-space-xxsmall-15"></div>
                <div class="uui-text-size-medium-29">{{$t('about.ham-commitment-description')}}</div>
              </div>
              <div class="uui-layout75_item-2"><img src="images/image-103.png" loading="lazy" alt="Integration icon" class="uui-layout75_icon-2">
                <div class="uui-space-xsmall-20"></div>
                <h3 class="uui-heading-xxsmall-20">{{$t('about.ham-service')}}</h3>
                <div class="uui-space-xxsmall-15"></div>
                <div class="uui-text-size-medium-29">{{$t('about.ham-service-description')}}</div>
              </div>
              <div class="uui-layout75_item-2"><img src="images/image-104.png" loading="lazy" alt="Integration icon" class="uui-layout75_icon-2">
                <div class="uui-space-xsmall-20"></div>
                <h3 class="uui-heading-xxsmall-20">{{$t('about.ham-professionalism')}}</h3>
                <div class="uui-space-xxsmall-15"></div>
                <div class="uui-text-size-medium-29">{{$t('about.ham-professionalism-description')}}
                  <br>{{$t('about.ham-professionalism-description-2')}} </div>
              </div>
              <div class="uui-layout75_item-2"><img src="images/image-105.png" loading="lazy" alt="Integration icon" class="uui-layout75_icon-2">
                <div class="uui-space-xsmall-20"></div>
                <h3 class="uui-heading-xxsmall-20">{{$t('about.ham-excellence')}}</h3>
                <div class="uui-space-xxsmall-15"></div>
                <div class="uui-text-size-medium-29"> {{$t('about.ham-excellence-description')}}
                  <br>{{$t('about.ham-excellence-description-2')}}</div>
              </div>
              <div class="uui-layout75_item-2"><img src="images/image-106.png" loading="lazy" alt="Integration icon" class="uui-layout75_icon-2">
                <div class="uui-space-xsmall-20"></div>
                <h3 class="uui-heading-xxsmall-20">{{$t('about.ham-innovation')}}</h3>
                <div class="uui-space-xxsmall-15"></div>
                <div class="uui-text-size-medium-29">{{$t('about.ham-innovation-description')}} <br> {{$t('about.ham-innovation-description-2')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
