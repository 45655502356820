<script >
import { app } from "@/main";
import 'animate.css';
import AddressComponent from "@/components/AddressComponent.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import OurClinicsComponent from "@/components/OurClinicsComponent.vue";
import AppointmentComponent from "@/components/AppointmentComponent.vue";

export default {
  name: "DigestiveDiseasesPage",
  components: { AppointmentComponent, OurClinicsComponent, SideContactComponent, AddressComponent },

  data() {
    return {
      locale: '',
      specialties: [7, 14, 37],
      specialists: [],
      show1:false,
      show2:false,
      show3:false,
    }
  },
  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });

  },
  created() {
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods: {
    loadData(locale) {
      this.specialists = require('../../data/specialists.json');
      this.specialists = this.specialists.filter(s =>
        s.specialties.some(specialty => this.specialties.includes(specialty.id))
      );
      this.specialists = this.specialists.map(specialist => {
        const translateInformation = specialist.information[locale];
        let specialties = specialist.specialties.map(specialty => {
          const name = specialty.name[locale];
          return {
            ...specialty,
            name: name
          }
        });

        return {
          ...specialist,
          information: translateInformation,
          specialties: specialties
        };
      });

    },
    stringArray(string) {
      return string.split(',');
    },
  }
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_79c1c23e-86db-0194-928b-b997c6cdfe28-81d240a2" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="79c1c23e-86db-0194-928b-b997c6cdfe2b" to="/"
                  class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none"                      xmlns="http://www.w3.org/2000/svg">                      <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699"                        stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div>{{$t('home.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('home.digestive-disease-clinic-title')}} 24/7</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('digestive-disease.digestive-disease-clinic-description')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_2e6aefb2-b0a4-f6c6-506b-ee032e38dc14-6df8db5e" class="uui-blogpost05_image-wrapper-7">
              <img src="../../../public/images/CLINICAS_01.jpg" loading="lazy" alt="" class="uui-blogpost03_image-2 diabe" style="object-fit: contain;">
            </div>
            <!--<div id="w-node-_79c1c23e-86db-0194-928b-b997c6cdfe35-81d240a2" class="uui-blogpost05_image-wrapper-7">
              <img srcset="../../../public/images/clinica-de-enfermedades-digestivas-p-500.jpeg 500w, ../../../public/images/clinica-de-enfermedades-digestivas-p-800.jpeg 800w, ../../../public/images/clinica-de-enfermedades-digestivas-p-1080.jpeg 1080w, ../../../public/images/clinica-de-enfermedades-digestivas.jpeg 1280w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 45vw, (max-width: 1919px) 46vw, 768px" src="../../../public/images/clinica-de-enfermedades-digestivas.jpeg" alt=""
                class="uui-blogpost03_image-2">
            </div>-->
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">
              <our-clinics-component></our-clinics-component>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-c8b101de-7fb8-1f20-e565-f6d411e6ed12-81d240a2" class="uui-blogpost05_content-2">
              <div class="uui-text-rich-text-3 w-richtext">                
                <h4>{{$t('digestive-disease.digestive-disease-clinic-p1')}}</h4><p>{{$t('digestive-disease.digestive-disease-clinic-p2')}} <strong>{{$t('digestive-disease.digestive-disease-clinic-p3')}}</strong>. {{$t('digestive-disease.digestive-disease-clinic-p4')}} <br><strong>{{$t('digestive-disease.digestive-disease-clinic-p5')}}</strong></p>
              </div>
              <div class="uui-text-rich-text-9 w-richtext">
                <figure style="max-width:1680pxpx" class="w-richtext-align-fullwidth w-richtext-figure-type-image">
                  <div><img src="../../../public/images/clinica-de-enfermedades-digestivas.jpeg" loading="lazy" alt=""></div>
                </figure>
              </div>
              <div class="div-block-20">
                <a href="#" class="sysf-card-2 sysf-card-box sysf-s-p-24 w-inline-block">
                  <div>
                    <h3 class="sysf-heading-3 sysf-xs">{{$t('digestive-disease.digestive-disease-clinic-mission')}}</h3>
                    <p class="sysf-body-text-3 sysf-m sysf-s-m-0">{{$t('digestive-disease.digestive-disease-clinic-mission-description')}}</p>
                  </div>
                </a>
                <a href="#" class="sysf-card-2 sysf-card-box sysf-s-p-24 w-inline-block">
                  <div>
                    <h3 class="sysf-heading-3 sysf-xs">{{$t('digestive-disease.digestive-disease-clinic-vision')}}</h3>
                    <p class="sysf-body-text-3 sysf-m sysf-s-m-0">{{$t('digestive-disease.digestive-disease-clinic-vision-description')}}</p>
                  </div>
                </a>
             </div>

             <h3>{{$t('home.services')}}</h3>
              <div class="animate__animated  animate__fadeInLeft">                <a href="#" class="rl_footer1_social-link-2 w-inline-block">                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('digestive-disease.digestive-disease-clinic-p8')}}</strong> {{$t('digestive-disease.digestive-disease-clinic-p9')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('digestive-disease.digestive-disease-clinic-p10')}}</strong> {{$t('digestive-disease.digestive-disease-clinic-p11')}}<br></div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('digestive-disease.digestive-disease-clinic-p12')}}</strong> {{$t('digestive-disease.digestive-disease-clinic-p13')}}</div>
                </a>
                <a href="#" class="rl_footer1_social-link-2 w-inline-block">                  <div class="rl_footer1_social-icon-2 w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24"                      height="24" viewbox="0 0 24 24" fill="none">                      <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999M22 3.99999L12 14.01L9.00001 11.01"                        stroke="#30B298" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>                    </svg></div>
                  <div class="rl_footer1_link-text-2"><strong>{{$t('digestive-disease.digestive-disease-clinic-p14')}}</strong> {{$t('digestive-disease.digestive-disease-clinic-p15')}}</div>
                </a>                
              <h4>{{$t('digestive-disease.digestive-disease-clinic-p16')}}</h4>
            </div>

              <div class="uui-faq01_component-7">
                        <div class="uui-faq01_list-8">
                          <div class="uui-faq01_accordion-6">
                            <div @click="show1 = !show1"  class="uui-faq01_question-6">
                              <div class="uui-faq01_heading-6">{{$t('digestive-disease.digestive-disease-clinic-p17')}}<br></div>
                              <div class="uui-faq01_icon-wrapper-6">
                                <div class="accordion-icon_component-7">
                                  <div class="accordion-icon_horizontal-line-7"></div>
                                  <div v-show="!show1" class="accordion-icon_vertical-line-7"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show1" class="uui-faq01_answer-6 animate__animated animate__fadeIn">
                              <div class="uui-text-size-large-35"><strong></strong></div>
                              <p>{{$t('digestive-disease.digestive-disease-clinic-p18')}}<br>{{$t('digestive-disease.digestive-disease-clinic-p19')}}<br>{{$t('digestive-disease.digestive-disease-clinic-p20')}}<br>{{$t('digestive-disease.digestive-disease-clinic-p21')}}</p>
                            </div>
                          </div>
                          <div class="uui-faq01_accordion-6">
                            <div @click="show2 = !show2" class="uui-faq01_question-6">
                              <div class="uui-faq01_heading-6">{{$t('digestive-disease.digestive-disease-clinic-p22')}}</div>
                              <div class="uui-faq01_icon-wrapper-6">
                                <div class="accordion-icon_component-7">
                                  <div class="accordion-icon_horizontal-line-7"></div>
                                  <div   v-show="!show2" class="accordion-icon_vertical-line-7"></div>
                                </div>
                              </div>
                            </div>
                            <div v-show="show2" class="uui-faq01_answer-6 animate__animated animate__fadeIn">
                              <p>{{$t('digestive-disease.digestive-disease-clinic-p23')}}</p>
                              <div class="uui-space-medium-14"></div>
                            </div>
                          </div>
                        </div>
                      </div>
              <!--<section class="uui-section_layout75">
                <div class="uui-page-padding-27">
                  <div class="uui-container-large-21">
                    <div class="uui-padding-vertical-xhuge-28">
                      <div class="uui-layout75_component">
                        <div class="uui-text-align-center-8">
                          <div class="uui-max-width-large-14">
                            <div class="uui-space-xsmall-15"></div>
                            <h2 class="uui-heading-medium-18">Seis Síntomas o Signos de Alarma</h2>
                            <div class="uui-space-xsmall-15"></div>
                          </div>
                        </div>
                        <div class="w-layout-grid uui-layout75_list">
                          <div class="uui-layout75_item"><img src="../../../public/images/advertencia.png" loading="lazy"
                              sizes="64px"
                              srcset="../../../public/images/advertencia-p-500.png 500w, ../../../public/images/advertencia.png 512w"
                              alt="Integration icon" class="uui-layout75_icon">
                            <div class="uui-space-xsmall-15"></div>
                            <h3 class="uui-heading-xxsmall-12">Inflamación</h3>
                            <div class="uui-space-xxsmall-11"></div>
                            <div class="uui-text-size-medium-21">de la zona externa <br>perianal</div>
                          </div>
                          <div class="uui-layout75_item"><img src="../../../public/images/advertencia.png" loading="lazy"
                              sizes="64px"
                              srcset="../../../public/images/advertencia-p-500.png 500w, ../../../public/images/advertencia.png 512w"
                              alt="Integration icon" class="uui-layout75_icon">
                            <div class="uui-space-xsmall-15"></div>
                            <h3 class="uui-heading-xxsmall-12">Deposiciones</h3>
                            <div class="uui-space-xxsmall-11"></div>
                            <div class="uui-text-size-medium-21">Dificultosas</div>
                          </div>
                          <div class="uui-layout75_item"><img src="../../../public/images/advertencia.png" loading="lazy"
                              sizes="64px"
                              srcset="../../../public/images/advertencia-p-500.png 500w, ../../../public/images/advertencia.png 512w"
                              alt="Integration icon" class="uui-layout75_icon">
                            <div class="uui-space-xsmall-15"></div>
                            <h3 class="uui-heading-xxsmall-12">Sangrado</h3>
                            <div class="uui-space-xxsmall-11"></div>
                            <div class="uui-text-size-medium-21">en las deposiciones</div>
                          </div>
                          <div class="uui-layout75_item"><img src="../../../public/images/advertencia.png" loading="lazy"
                              sizes="64px"
                              srcset="../../../public/images/advertencia-p-500.png 500w, ../../../public/images/advertencia.png 512w"
                              alt="Integration icon" class="uui-layout75_icon">
                            <div class="uui-space-xsmall-15"></div>
                            <h3 class="uui-heading-xxsmall-12">Supuración y Manchas</h3>
                            <div class="uui-space-xxsmall-11"></div>
                            <div class="uui-text-size-medium-21">de pus en la<br>ropa interior</div>
                          </div>
                          <div class="uui-layout75_item"><img src="../../../public/images/advertencia.png" loading="lazy"
                              sizes="64px"
                              srcset="../../../public/images/advertencia-p-500.png 500w, ../../../public/images/advertencia.png 512w"
                              alt="Integration icon" class="uui-layout75_icon">
                            <div class="uui-space-xsmall-15"></div>
                            <h3 class="uui-heading-xxsmall-12">Dolor</h3>
                            <div class="uui-space-xxsmall-11"></div>
                          </div>
                          <div class="uui-layout75_item"><img src="../../../public/images/advertencia.png" loading="lazy"
                              sizes="64px"
                              srcset="../../../public/images/advertencia-p-500.png 500w, ../../../public/images/advertencia.png 512w"
                              alt="Integration icon" class="uui-layout75_icon">
                            <div class="uui-space-xsmall-15"></div>
                            <h3 class="uui-heading-xxsmall-12">Prurito</h3>
                            <div class="uui-space-xxsmall-11"></div>
                            <div class="uui-text-size-medium-21">prurito o picor de la zona</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>-->
              <section class="team_seccion">
                <div class="uui-page-padding-18">
                  <div class="uui-container-large-14">
                    <div class="uui-padding-vertical-xhuge-20">
                      <div class="uui-max-width-large-9">
                        <h2 class="uui-heading-medium-11" style="margin-top: 2rem;margin-bottom: 2rem; text-align: center;color: var(--hc--colors--green--600);">{{$t('home.emergencies-aid')}}</h2>
                        <h2 class="uui-heading-medium-11">{{$t('home.our-team')}}</h2>
                        <div class="uui-space-xsmall-10"></div>
                      </div>
                      <div class="uui-team07_component-2">
                        <div class="uui-team07_list-wrapper-2">
                          <div class="w-layout-grid item_medico">                          
                            
                            <div class="card_doctor">
                            <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR.-RAUL-DAVID-GIJON-SORIANO---CIRUGIA-GENERAL-Y-LAPAROSCOPIA.jpg"                                loading="lazy" sizes="84px"
                                srcset="../../../public/images/DR.-RAUL-DAVID-GIJON-SORIANO---CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-500.jpg 500w, ../../../public/images/DR.-RAUL-DAVID-GIJON-SORIANO---CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-800.jpg 800w, ../../../public/images/DR.-RAUL-DAVID-GIJON-SORIANO---CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-1080.jpg 1080w, ../../../public/images/DR.-RAUL-DAVID-GIJON-SORIANO---CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-1600.jpg 1600w, ../../../public/images/DR.-RAUL-DAVID-GIJON-SORIANO---CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-2000.jpg 2000w, ../../../public/images/DR.-RAUL-DAVID-GIJON-SORIANO---CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-2600.jpg 2600w, ../../../public/images/DR.-RAUL-DAVID-GIJON-SORIANO---CIRUGIA-GENERAL-Y-LAPAROSCOPIA-p-3200.jpg 3200w, ../../../public/images/DR.-RAUL-DAVID-GIJON-SORIANO---CIRUGIA-GENERAL-Y-LAPAROSCOPIA.jpg 4000w"                                alt="" class="uui-team07_image-2"></div>
                            <div class="uui-team07_item-content-2">
                              <div class="uui-heading-tiny-4"><strong>Dr. Raúl David Gijón Soriano</strong></div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.professional-license')}} 7244681</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.specialty-license')}} 10487460</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('digestive-disease.spe1')}}<br></strong>{{$t('digestive-disease.dr1-description')}}<br></div>
                            </div>
                          </div>
                          <div class="card_doctor">
                            <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO.jpg" loading="lazy"                                sizes="84px"
                                srcset="../../../public/images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-500.jpg 500w, ../../../public/images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-800.jpg 800w, ../../../public/images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-1080.jpg 1080w, ../../../public/images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-1600.jpg 1600w, ../../../public/images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-2000.jpg 2000w, ../../../public/images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-2600.jpg 2600w, ../../../public/images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO-p-3200.jpg 3200w, ../../../public/images/DRA.-YOLANDA-CITLALI-SOLIS-SAUCEDO.jpg 4000w"                                alt="" class="uui-team07_image-2"></div>
                            <div class="uui-team07_item-content-2">
                              <div class="uui-heading-tiny-4"><strong>Dra. Yolanda Citlali Solís Saucedo</strong></div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.professional-license')}} 8667333</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.professional-license')}} 12522339</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.specialty-license')}} 13478464</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('digestive-disease.spe2')}}<br>‍</strong>{{$t('digestive-disease.dr2-description')}}<br>
                              </div>
                            </div>
                          </div>
                          <div class="card_doctor">
                            <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/Dr.Jorge-Gijon-Soriano.jpg" loading="lazy" sizes="84px"
                                srcset="../../../public/images/Dr.Jorge-Gijon-Soriano.jpg 500w, ../../../public/images/Dr.Jorge-Gijon-Soriano.jpg 704w"                                alt="" class="uui-team07_image-2"></div>                            <div class="uui-team07_item-content-2">
                              <div class="uui-heading-tiny-4"><strong>Dr. Jorge Gijón</strong></div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.professional-license')}} 2876351</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.specialty-license')}} 318042</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('digestive-disease.spe3')}}</strong><br>{{$t('digestive-disease.dr3-description')}}<br></div>
                            </div>
                          </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR.-JORGE-ARTURO-PERALES-OLIVA.jpg" loading="lazy" sizes="84px" srcset="../../../public/images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-500.jpg 500w, ../../../public/images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-800.jpg 800w, ../../../public/images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-1080.jpg 1080w, ../../../public/images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-1600.jpg 1600w, ../../../public/images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-2000.jpg 2000w, ../../../public/images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-2600.jpg 2600w, ../../../public/images/DR.-JORGE-ARTURO-PERALES-OLIVA-p-3200.jpg 3200w, ../../../public/images/DR.-JORGE-ARTURO-PERALES-OLIVA.jpg 6000w" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Jorge Arturo Perales</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.professional-license')}} 7543229</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.specialty-license')}} 11970808</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>{{$t('digestive-disease.spe4')}}<br></strong>{{$t('digestive-disease.dr4-description')}}</div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/DR.SebastianPorras.jpg"                                  loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Sebastián Porras</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.professional-license')}} 8881892</div>
                                <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.specialty-license')}} 12027028</div>
                                <div class="uui-space-xxsmall-8"></div>
                                <div class="uui-text-size-medium-15"><strong>{{$t('digestive-disease.spe5')}}<br></strong>{{$t('digestive-disease.dr5-description')}}<br></div>
                              </div>
                            </div>
                            <div class="card_doctor">
                              <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/dr.alberto-molina-peto.jpg"                                  loading="lazy" alt="" class="uui-team07_image-2"></div>
                              <div class="uui-team07_item-content-2">
                                <div class="uui-heading-tiny-4">Dr. Alberto Molina Peto</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.professional-license')}} 10002907</div>
                              <div class="uui-text-size-medium-15 text-color-primary700">{{$t('general.specialty-license')}} 10531813</div>
                              <div class="uui-space-xxsmall-8"></div>
                              <div class="uui-text-size-medium-15"><strong>{{$t('digestive-disease.spe6')}}<br></strong>{{$t('digestive-disease.dr6-description')}}.<br></div>
                            </div>                                           
                          </div>                        
                        </div>
                      </div>
                      <appointment-component></appointment-component>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</header></template>

<style scoped></style>
