<script >

export default{
  name:"MeetHamComponent",
}
</script>

<template>
  <div class="uui-blogpost05_contributers-2">
    <div class="uui-blogpost05_sidebar-heading-2">{{$t('home.meet-ham')}}</div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/directory" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.directory')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/visitors" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.visitors-regulations')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/gallery" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.photographic-gallery')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/privacy" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.privacy-terms')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="/insurers" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.insurers-conventions')}}</div>
        </router-link>
      </div>
    </div>
    <div class="uui-blogpost05_author-wrapper-2">
      <div>
        <router-link to="./faqs" class="link-block-6 w-inline-block">
          <div class="uui-blogpost05_author-heading-2">{{$t('home.faqs')}}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
