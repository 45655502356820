<script >
export default {
  name: "PrivacyPage",
}
</script>

<template>
  <header class="uui-section_header09-2">
    <div class="uui-page-padding-50">
      <div class="uui-container-small-6">
        <div class="uui-padding-vertical-xhuge-52">
          <div class="uui-text-align-center-18">
            <div class="uui-blogpost01_breadcrumb">
              <router-link to="/" class="uui-breadcrumb-link-2 w-inline-block">
                <div class="text-block-30">Hospital Ángel del Mar</div>
              </router-link>
              <div class="uui-breadcrumb-divider-2 w-embed"><svg width="16" height="16" viewbox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 3L11 8L6 13" stroke="CurrentColor" stroke-width="1.5"></path>
              </svg></div>
              <a href="#" class="uui-breadcrumb-link-2 w-inline-block">
                <div>{{$t('home.privacy-terms')}}</div>
              </a>
            </div>
            <h1 class="uui-heading-large-17">{{$t('home.privacy-terms')}}</h1>
            <div class="uui-space-small-24"></div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <header class="uui-section_blogpost01">
    <div class="uui-page-padding-50">
      <div class="uui-container-large-40">
        <div class="uui-padding-vertical-xhuge-52">
          <div class="uui-max-width-large-25 align-center">
            <div class="uui-blogpost01_content">
              <h3 class="ds-md-bold">{{$t('privacy.privacy-terms-description')}}</h3>
              <div class="uui-text-rich-text-12 w-richtext">
                <p>{{$t('privacy.privacy-terms-p1')}}</p>
                <p>{{$t('privacy.privacy-terms-p2')}}</p>
              </div>
              <ul role="list">
                <li class="list-item-16"><strong>{{$t('privacy.privacy-terms-l-1')}}</strong>: {{$t('privacy.privacy-terms-l-2')}}</li>
                <li class="list-item-15"><strong>{{$t('privacy.privacy-terms-l-3')}}</strong>: {{$t('privacy.privacy-terms-l-4')}}</li>
              </ul>
              <div class="uui-text-rich-text-12 w-richtext">
                <h4>{{$t('privacy.privacy-terms-h4')}}</h4>
                <p>{{$t('privacy.privacy-terms-p3')}}</p>
                <h4>{{$t('privacy.privacy-terms-h4-2')}}</h4>
                <p>{{$t('privacy.privacy-terms-p4')}}</p>
                <ul role="list">
                  <li>{{$t('privacy.privacy-terms-l-5')}}</li>
                  <li>{{$t('privacy.privacy-terms-l-6')}}</li>
                  <li>{{$t('privacy.privacy-terms-l-7')}}</li>
                  <li>{{$t('privacy.privacy-terms-l-8')}}</li>
                  <li>{{$t('privacy.privacy-terms-l-9')}}</li>
                  <li>{{$t('privacy.privacy-terms-l-10')}}</li>
                </ul>
                <h4>{{$t('privacy.privacy-terms-h4-3')}}</h4>
                <p>{{$t('privacy.privacy-terms-p5')}}</p>
                <h4>{{$t('privacy.privacy-terms-h4-4')}}</h4>
                <p>{{$t('privacy.privacy-terms-p6')}}</p>
                <h4>{{$t('privacy.privacy-terms-h4-5')}}</h4>
                <p>{{$t('privacy.privacy-terms-p7')}} <a href="mailto:contacto@hospitalangeldelmar.com.mx">contacto@hospitalangeldelmar.com.mx</a> {{$t('privacy.privacy-terms-p8')}}</p>
                <h4>{{$t('privacy.privacy-terms-h4-6')}}</h4>
                <p>{{$t('privacy.privacy-terms-p9')}}</p>
                <p>{{$t('privacy.privacy-terms-p10', { email: 'contacto@hospitalangeldelmar.com.mx'})}}</p>
                <ul role="list">
                  <li>{{$t('privacy.privacy-terms-l-11')}}</li>
                  <li>{{$t('privacy.privacy-terms-l-12')}}</li>
                  <li>{{$t('privacy.privacy-terms-l-13')}}</li>
                  <li>{{$t('privacy.privacy-terms-l-14')}}</li>
                  <li>{{$t('privacy.privacy-terms-l-15')}}</li>
                </ul>
                <h4>{{$t('privacy.privacy-terms-h4-7')}}</h4>
                <p>{{$t('privacy.privacy-terms-p11', {email: 'contacto@hospitalangeldelmar.com.mx'})}}</p>
                <h4>{{$t('privacy.privacy-terms-h4-8')}}</h4>
                <p>{{$t('privacy.privacy-terms-p12')}}</p>
              </div>
              <div class="uui-blogpost01_share-heading">{{$t('privacy.privacy-terms-h4-9')}}</div>
            </div>
            <div class="uui-blogpost01_divider-2"></div>
            <div class="text-block-32">CLÍNICA MÉDICA ÁNGEL DEL MAR S.A. DE C.V.</div>
            <div class="text-block-31">{{$t('privacy.privacy-terms-p13')}}</div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>

</style>
