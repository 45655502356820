<script>
import ContactUsComponent from "@/components/ContactUsComponent.vue";
import 'animate.css';

export default {
  name: "PromotionsPage",
  components: {ContactUsComponent},
  beforeRouteEnter() {

  },
  methods: {
    getImagePath(translationKey) {
      // Utiliza la función $t para obtener el valor de la clave de traducción
      return this.$t(translationKey);
    }}
}
</script>

<template>
  <header class="uui-section_heroheader16">
    <div class="uui-page-padding-39">
      <div class="uui-container-large-29">
        <div class="uui-space-xhuge-2"></div>
        <div class="uui-heroheader16_component">
          <div class="uui-text-align-center-14">
            <div class="uui-max-width-xlarge-2">
              <div class="uui-space-small-17"></div>
              <h1 class="uui-heading-xlarge-5">{{$t('promotions.medical-promotions')}}</h1>
              <div class="uui-space-small-17"></div>
              <div class="uui-max-width-large-18 align-center">
                <div class="uui-text-size-xlarge-8">{{$t('promotions.medical-promotions-description')}}<strong><br>{{$t('promotions.medical-promotions-p1')}}</strong></div>
              </div>
              <div class="uui-space-large-5"></div>
            </div>
          </div>
          <div class="uui-heroheader16_image-wrapper">
            <img :src="getImagePath('promotions-image')" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, 1216px"
           alt="MacBook mockup" class="uui-heroheader16_image" ></div>

<!--          <img src="images/370637361_811657500960070_4748418852671024123_n-1.jpg" loading="lazy" sizes="(max-width: 479px) 93vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, 1216px"-->
<!--               srcset="images/370637361_811657500960070_4748418852671024123_n-1-p-500.jpg 500w, images/370637361_811657500960070_4748418852671024123_n-1-p-800.jpg 800w, images/370637361_811657500960070_4748418852671024123_n-1-p-1080.jpg 1080w, images/370637361_811657500960070_4748418852671024123_n-1-p-1600.jpg 1600w, images/370637361_811657500960070_4748418852671024123_n-1-p-2000.jpg 2000w, images/370637361_811657500960070_4748418852671024123_n-1-p-2600.jpg 2600w, images/370637361_811657500960070_4748418852671024123_n-1-p-3200.jpg 3200w, images/370637361_811657500960070_4748418852671024123_n-1.jpg 3365w" alt="MacBook mockup" class="uui-heroheader16_image" ></div>-->


      </div>
      </div>
    </div>
  </header>
  <div class="brix---section-3">
    <div class="brix---container-default-5 w-container">
      <div>
        <h4 class="uui-heading-xlarge-5">{{$t('promotions.medical-promotions-p2')}}</h4>
        <div class="rl_layout239_spacing-block-2-4"></div>
        <p class="brix---paragraph-default-3">{{$t('promotions.medical-promotions-p3')}}</p>
        <div class="rl_layout239_spacing-block-2-4"></div>
      </div>
      <div data-w-id="fe176183-bf43-51fa-9833-93e6af28e1cb"
            class="w-layout-grid brix---grid-3-columns-gap-row-48px animate__animated animate__backInUp">
        <a data-w-id="fe176183-bf43-51fa-9833-93e6af28e1cc" href="#" class="brix---product-card-wrapper w-inline-block">
          <div class="brix---product-card-image-wrapper">
            <img  srcset="images/mastografia-p-500.jpg 500w, images/mastografia.jpg 659w" alt="mastografia" sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 991px) 44vw, (max-width: 1439px) 29vw, (max-width: 1919px) 26vw, 22vw" src="images/mastografia.jpg"
                  class="brix---product-card-image zoom">
            <div class="brix---badge-wrapper-top-left"></div>
          </div>
          <div class="brix---mg-top-24px">
            <div>
              <div>
                <div class="brix---mg-bottom-16px-3">
                  <div class="brix---color-neutral-806">
                    <h3 class="brix---heading-h4-size-2">{{$t('promotions.medical-promotions-title-1')}}</h3>
                  </div>
                </div>
                <div class="brix---mg-bottom-24px">
                  <div class="brix---color-neutral-805">
                    <p class="paragraph-11">{{$t('promotions.medical-promotions-description-1')}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
<!--        <a data-w-id="fe176183-bf43-51fa-9833-93e6af28e1e1" href="#" class="brix-&#45;&#45;product-card-wrapper w-inline-block">-->
<!--          <div class="brix-&#45;&#45;product-card-image-wrapper">-->
<!--            <img srcset="images/maternidad-p-500.jpg 500w, images/maternidad.jpg 659w" alt="maternidad" sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 991px) 44vw, (max-width: 1439px) 29vw, (max-width: 1919px) 26vw, 22vw" src="images/maternidad.jpg" class="brix-&#45;&#45;product-card-image zoom">-->
<!--            <div class="brix-&#45;&#45;badge-wrapper-top-left"></div>-->
<!--          </div>-->
<!--          <div class="brix-&#45;&#45;mg-top-24px">-->
<!--            <div>-->
<!--              <div>-->
<!--                <div class="brix-&#45;&#45;mg-bottom-16px-3">-->
<!--                  <div class="brix-&#45;&#45;color-neutral-806">-->
<!--                    <h3 class="brix-&#45;&#45;heading-h4-size-2">{{$t('promotions.medical-promotions-title-2')}}</h3>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="brix-&#45;&#45;mg-bottom-24px">-->
<!--                  <div class="brix-&#45;&#45;color-neutral-805">-->
<!--                    <p class="paragraph-10">{{$t('promotions.medical-promotions-description-2')}}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </a>-->
<!--        <a data-w-id="fe176183-bf43-51fa-9833-93e6af28e1f6" href="#" class="brix-&#45;&#45;product-card-wrapper w-inline-block">-->
<!--          <div class="brix-&#45;&#45;product-card-image-wrapper">-->
<!--             <img srcset="images/hemodialisis-p-500.jpg 500w, images/hemodialisis.jpg 659w" alt="hemodialisis" sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 991px) 44vw, (max-width: 1439px) 29vw, (max-width: 1919px) 26vw, 22vw" src="images/hemodialisis.jpg" class="brix-&#45;&#45;product-card-image zoom">-->
<!--            <div class="brix-&#45;&#45;badge-wrapper-top-left"></div>-->
<!--          </div>-->
<!--          <div class="brix-&#45;&#45;mg-top-24px">-->
<!--            <div>-->
<!--              <div>-->
<!--                <div class="brix-&#45;&#45;mg-bottom-16px-3">-->
<!--                  <div class="brix-&#45;&#45;color-neutral-806">-->
<!--                    <h3 class="brix-&#45;&#45;heading-h4-size-2">{{$t('promotions.medical-promotions-title-3')}}</h3>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="brix-&#45;&#45;mg-bottom-24px">-->
<!--                  <div class="brix-&#45;&#45;color-neutral-805">-->
<!--                    <p class="paragraph-9">{{$t('promotions.medical-promotions-description-3')}}</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </a>-->
        <a data-w-id="fe176183-bf43-51fa-9833-93e6af28e20b" href="#" class="brix---product-card-wrapper w-inline-block">
          <div class="brix---product-card-image-wrapper">
            <img srcset="images/enfermedades-digestivas-p-500.jpg 500w, images/enfermedades-digestivas.jpg 659w" alt="marzo" sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 991px) 44vw, (max-width: 1439px) 29vw, (max-width: 1919px) 26vw, 22vw" src="images/enfermedades-digestivas.jpg" class="brix---product-card-image zoom">
            <div class="brix---badge-wrapper-top-left"></div>
          </div>
          <div class="brix---mg-top-24px">
            <div>
              <div>
                <div class="brix---mg-bottom-16px-3">
                  <div class="brix---color-neutral-806">
                    <h3 class="brix---heading-h4-size-2">{{$t('promotions.medical-promotions-title-4')}}</h3>
                  </div>
                </div>
                <div class="brix---mg-bottom-24px">
                  <div class="brix---color-neutral-805">
                    <p class="paragraph-7">{{$t('promotions.medical-promotions-description-4')}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
        <a data-w-id="fe176183-bf43-51fa-9833-93e6af28e220" href="#" class="brix---product-card-wrapper w-inline-block">
          <div class="brix---product-card-image-wrapper">
            <img srcset="images/farmacia_1-p-500.jpg 500w, images/farmacia_1.jpg 659w" alt="farmacia" sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 991px) 44vw, (max-width: 1439px) 29vw, (max-width: 1919px) 26vw, 22vw" src="images/farmacia_1.jpg" class="brix---product-card-image zoom">
            <div class="brix---badge-wrapper-top-left"></div>
          </div>
          <div class="brix---mg-top-24px">
            <div>
              <div>
                <div class="brix---mg-bottom-16px-3">
                  <div class="brix---color-neutral-806">
                    <h3 class="brix---heading-h4-size-2">{{$t('promotions.medical-promotions-title-5')}}</h3>
                  </div>
                </div>
                <div class="brix---mg-bottom-24px">
                  <div class="brix---color-neutral-805">
                    <p class="paragraph-8">{{$t('promotions.medical-promotions-description-5')}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <section class="pricing-overview">
    <div class="container-5">
      <h2 class="centered-heading">{{$t('home.schedule-appointment')}}</h2>
      <p class="pricing-description">{{$t('contact.contact-description-1')}} <strong>{{$t('contact.emergencies-service')}}</strong> {{$t('contact.contact-description-2')}} <strong>{{$t('home.24-hours')}}</strong>{{$t('contact.contact-description-3')}}  <strong>{{$t('contact.contact-description-4')}}</strong> {{$t('contact.contact-description-5')}} <strong>{{$t('contact.contact-description-6')}}</strong><br></p>
      <div class="div-block-29">
        <a href="https://angeldelmar.zonasana.clinic/login?v=2.26.0" target="_blank" class="rl_navbar3_button w-button">{{$t('home.schedule-appointment')}}</a>
      </div>
      <div class="pricing-grid">
        <div id="w-node-bd80bb90-b913-2731-5697-d85d261a6413-a2d16809" class="pricing-card-three"><img src="images/email-2.png" loading="lazy" alt="" class="pricing-image">
          <h3 class="heading-12">{{$t('contact.contact-email')}}</h3>
          <p class="pricing-card-text">
            <a href="mailto:contacto@hospitalangeldelmar.com.mx" class="link-9">contacto@hospitalangeldelmar.com.mx</a>
          </p>
        </div>
        <div id="w-node-bd80bb90-b913-2731-5697-d85d261a641a-a2d16809" class="pricing-card-three"><img src="images/phone-2.png" loading="lazy" alt="" class="pricing-image">
          <div class="div-block-25">
            <div class="uui-contact12_item"><img src="images/Vector.svg" loading="lazy" alt="" class="image-23">
              <div class="uui-space-small-23"></div>
              <div class="uui-space-xxsmall-20"></div>
              <div class="uui-text-size-medium-36"><strong class="bold-text-15">{{$t('home.general-from-desk')}}</strong></div>
              <div class="uui-space-small-23"></div>
              <a href="#" class="uui-text-style-link-02-5"><strong>954 177 0692</strong></a>
            </div>
            <div class="uui-contact12_item"><img src="images/Logo-Bg-Removed-HAM.png" loading="lazy" alt="" class="image-21">
              <div class="uui-space-small-23"></div>
              <div class="uui-space-xxsmall-20"></div>
              <div class="uui-text-size-medium-36">{{$t('home.emergencies')}}</div>
              <div class="uui-space-small-23"></div>
              <a href="#" class="uui-text-style-link-02-5">954 103 4110</a>
            </div>
          </div>
        </div>
        <div id="w-node-bd80bb90-b913-2731-5697-d85d261a6433-a2d16809" class="pricing-card-three"><img src="images/location-1.png" loading="lazy" alt="" class="pricing-image">
          <h3 class="heading-14">{{$t('home.location')}}</h3>
          <p class="pricing-card-text">
            <a href="https://maps.app.goo.gl/6XsoQHej9DWHjzuR6" target="_blank" class="link-7">Sexta Norte esquina Primera Oriente S/N, Sector Reforma, Puerto Escondido, Oax, C.P. 71980</a>
          </p>
        </div>
      </div>
    </div>
    <section class="rl_section_contact7">
      <div class="rl-padding-global-12">
        <div class="rl-container-large-9">
          <div class="rl-padding-section-large-12">
            <div class="w-layout-grid rl_contact7_component">
<!--              <div class="rl_contact7_content">-->
<!--                <div class="uui-contact03_form-wrapper w-form">-->
<!--                  <div id="wf-form-Contact-03-form" name="wf-form-Contact-03-form" data-name="Contact 03 form" method="get" class="uui-contact03_form" data-wf-page-id="654bc7bf0c965f95ef48747f" data-wf-element-id="fd0e607b-10fa-460f-c8a4-f0fc1502bc00">-->
<!--                    <h3 class="heading-12">Contactanos</h3>-->
<!--                    <div class="uui-form-field-wrapper-2"><label for="Contact03-name" class="uui-field-label-2">Nombre Completo</label><input type="text" class="uui-form_input-9 w-input" maxlength="256" name="Contact-03-name" data-name="Contact 03 name" placeholder="Nombre y Apellidos" id="Contact03-name" required=""></div>-->
<!--                    <div class="uui-form-field-wrapper-2"><label for="Contact03-email" class="uui-field-label-2">Número Teléfonico</label><input type="number" class="uui-form_input-9 w-input" maxlength="256" name="Contact-03-email" data-name="Contact 03 email" placeholder="+00 000 000 0000" id="Contact03-email" required=""></div>-->
<!--                    <div class="uui-form-field-wrapper-2"><label for="Contact03-email" class="uui-field-label-2">Correo Electrónico</label><input type="email" class="uui-form_input-9 w-input" maxlength="256" name="Contact-03-email" data-name="Contact 03 email" placeholder="email@example.com" id="Contact03-email" required=""></div>-->
<!--                    <div class="uui-form-field-wrapper-2"><label for="Contact03-message" class="uui-field-label-2">Mensaje o Asunto</label><textarea id="Contact03-message" name="Contact-03-message" maxlength="5000" data-name="Contact 03 message" placeholder="Escribe tu Mensaje" required="" class="uui-form_input-9 text-area w-input"></textarea></div>-->
<!--                    <div id="w-node-fd0e607b-10fa-460f-c8a4-f0fc1502bc18-ef48747f" class="uui-form-button-wrapper-2"><input type="submit" value="Enviar " data-wait="Please wait..." id="w-node-fd0e607b-10fa-460f-c8a4-f0fc1502bc19-ef48747f" class="uui-button-16 w-button"></div>-->
<!--                  </div>-->
<!--                  <div class="success-message-3 w-form-done">-->
<!--                    <div class="success-text-2">Thank you! Your submission has been received!</div>-->
<!--                  </div>-->
<!--                  <div class="error-message-3 w-form-fail">-->
<!--                    <div class="error-text-2">Oops! Something went wrong while submitting the form.</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="rl_contact7_image-wrapper"><img src="images/oficina-de-calidad.jpeg" loading="lazy" sizes="100vw" srcset="images/OFICINA-DE-CALIDAD-p-500.jpg 500w, images/OFICINA-DE-CALIDAD-p-800.jpg 800w, images/OFICINA-DE-CALIDAD-p-1080.jpg 1080w, images/OFICINA-DE-CALIDAD-p-1600.jpg 1600w, images/OFICINA-DE-CALIDAD-p-2000.jpg 2000w, images/OFICINA-DE-CALIDAD-p-2600.jpg 2600w, images/OFICINA-DE-CALIDAD-p-3200.jpg 3200w, images/oficina-de-calidad.jpeg 6960w" alt="" class="rl_contact7_image"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="rl_section_contact6">
      <div class="rl-padding-global">
        <div class="rl-container-large">
          <div class="rl-padding-section-large">
            <div class="w-layout-grid rl_content6_component">
              <div class="rl_contact6_content">
                <div class="rl_contact6_spacing-block-1"></div>
                <h2 class="rl-heading-style-h2">{{$t('home.contact-us')}}</h2>
                <div class="rl_contact6_spacing-block-2"></div>
                <p class="rl-text-style-medium">{{$t('contact.contact-us-description')}}</p>
                <div class="rl_contact6_spacing-block-2"></div>
                <div class="rl-text-style-subheading">{{$t('contact.contact-us-question')}}</div>
                <div class="rl_contact6_spacing-block-3"></div>
                <div class="rl_contact6_contact-list">
                  <div id="w-node-_9a760025-93a6-1598-0cf3-6a8af1266403-a2d16809" class="rl_contact6_item">
                    <div class="rl_contact6_icon-wrapper">
                      <div class="rl_contact6_icon w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                        <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6M22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6M22 6L12 13L2 6" stroke="#20816F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg></div>
                    </div>
                    <div class="rl-text-style-regular">contacto@hospitalangeldelmar.com.mx</div>
                  </div>
                  <div id="w-node-_9a760025-93a6-1598-0cf3-6a8af1266408-a2d16809" class="rl_contact6_item">
                    <div class="rl_contact6_icon-wrapper">
                      <div class="rl_contact6_icon w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                        <path d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z" stroke="#20816F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="#20816F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg></div>
                    </div>
                    <div class="rl-text-style-regular">Sexta Norte esquina Primera Oriente S/N, Sector Reforma, Puerto Escondido, Oax, C.P. 71980</div>
                  </div>
                  <div id="w-node-_9a760025-93a6-1598-0cf3-6a8af126640d-a2d16809" class="rl_contact6_item">
                    <div class="rl_contact6_icon-wrapper">
                      <div class="rl_contact6_icon w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24" fill="none">
                        <path d="M22 16.92V19.92C22.0011 20.1985 21.9441 20.4741 21.8325 20.7293C21.7209 20.9845 21.5573 21.2136 21.3521 21.4018C21.1468 21.5901 20.9046 21.7335 20.6407 21.8227C20.3769 21.9119 20.0974 21.945 19.82 21.92C16.7428 21.5856 13.787 20.5341 11.19 18.85C8.77382 17.3146 6.72533 15.2661 5.18999 12.85C3.49997 10.2412 2.44824 7.27097 2.11999 4.17997C2.095 3.90344 2.12787 3.62474 2.21649 3.3616C2.30512 3.09846 2.44756 2.85666 2.63476 2.6516C2.82196 2.44653 3.0498 2.28268 3.30379 2.1705C3.55777 2.05831 3.83233 2.00024 4.10999 1.99997H7.10999C7.5953 1.9952 8.06579 2.16705 8.43376 2.48351C8.80173 2.79996 9.04207 3.23942 9.10999 3.71997C9.23662 4.68004 9.47144 5.6227 9.80999 6.52997C9.94454 6.8879 9.97366 7.27689 9.8939 7.65086C9.81415 8.02482 9.62886 8.36809 9.35999 8.63998L8.08999 9.90997C9.51355 12.4135 11.5864 14.4864 14.09 15.91L15.36 14.64C15.6319 14.3711 15.9751 14.1858 16.3491 14.1061C16.7231 14.0263 17.1121 14.0554 17.47 14.19C18.3773 14.5285 19.3199 14.7634 20.28 14.89C20.7658 14.9585 21.2094 15.2032 21.5265 15.5775C21.8437 15.9518 22.0122 16.4296 22 16.92Z" stroke="#20816F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg></div>
                    </div>
                    <div class="rl-text-style-regular">954 104 2270</div>
                  </div>
                </div>
              </div>
              <contact-us-component></contact-us-component>
<!--              <div class="rl_contact6_form-block w-form">-->
<!--                <div id="wf-form-Contact-6-Form" name="wf-form-Contact-6-Form" data-name="Contact 6 Form" method="get" class="rl_contact6_form" data-wf-page-id="654bc7bf0c965f95ef48747f" data-wf-element-id="23abbfb8-8caf-c23a-6fa2-c99896743ea4">-->
<!--                  <div class="rl_contact6_form-field-wrapper">-->
<!--                    <div class="rl_contact6_form-field-wrapper"><label for="Contact-6-First-Name" class="rl-field-label">Nombre Completo</label><input type="text" class="rl-form-input-2 w-input" maxlength="256" name="Contact-6-First-Name-3" data-name="Contact 6 First Name 3" placeholder="Nombre y Apellidos" id="Contact-6-First-Name-3" required=""></div>-->
<!--                  </div>-->
<!--                  <div class="rl_contact6_form-field-2col">-->
<!--                    <div class="rl_contact6_form-field-wrapper"><label for="Contact-6-Email" class="rl-field-label">Correo Electrónico</label><input type="email" class="rl-form-input-2 w-input" maxlength="256" name="Contact-6-Email" data-name="Contact 6 Email" placeholder="email@example.com" id="Contact-6-Email" required=""></div>-->
<!--                    <div class="rl_contact6_form-field-wrapper"><label for="Contact-6-Phone" class="rl-field-label">Número Teléfonico</label><input type="tel" class="rl-form-input-2 w-input" maxlength="256" name="Contact-6-Phone" data-name="Contact 6 Phone" placeholder="+00 (000) 000-0000" id="Contact-6-Phone" required=""></div>-->
<!--                  </div>-->
<!--                  <div class="rl_contact6_form-field-wrapper"><label for="Contact-6-Message" class="rl-field-label">Mensaje</label><textarea placeholder="Escribe tu mensaje..." maxlength="5000" id="Contact-6-Message" name="Contact-6-Message" data-name="Contact 6 Message" required="" class="rl-form-text-area w-input"></textarea></div>-->
<!--                  <div id="w-node-_23abbfb8-8caf-c23a-6fa2-c99896743eb7-ef48747f" class="rl_contact6_button-wrapper">-->
<!--                    <div class="rl_contact6_spacing-block-7"></div><input type="submit" value="Enviar" data-wait="Please wait..." id="w-node-_23abbfb8-8caf-c23a-6fa2-c99896743eb9-ef48747f" class="rl-button w-button">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="rl-success-message-2 w-form-done">-->
<!--                  <div class="rl-success-text">Thank you! Your submission has been received!</div>-->
<!--                </div>-->
<!--                <div class="rl-error-message-2 w-form-fail">-->
<!--                  <div class="rl-error-text">Oops! Something went wrong while submitting the form.</div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<style scoped>
.zoom {
  transition: transform .1s; /* Animation */

}

.zoom:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
</style>
