<script >

// import OurServices from "@/components/OurServices.vue";
// import AddressComponent from "@/components/AddressComponent.vue";

import AddressComponent from "@/components/AddressComponent.vue";
import OurServices from "@/components/OurServices.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";

export default{
  name:"HospitalizationPage",
  components: {SideContactComponent, OurServices, AddressComponent},
  // components: {AddressComponent, OurServices}
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-_7f19c4e1-aa81-6684-c06b-a51ca3d37c15-48ca69a3" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-space-large-7"></div>
              <div class="uui-button-row-12">
                <router-link data-w-id="7f19c4e1-aa81-6684-c06b-a51ca3d37c18" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('HospitalizationPage.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('HospitalizationPage.title')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('HospitalizationPage.subtitle')}}</div>
              <address-component></address-component>
            </div>
            <div id="w-node-_7f19c4e1-aa81-6684-c06b-a51ca3d37c22-48ca69a3" class="uui-blogpost05_image-wrapper-7"><img srcset="../../../public/images/CENTRAL-DE-ENFERMERIA-HOSPITALIZACION-p-500.jpg 500w, ../../../public/images/CENTRAL-DE-ENFERMERIA-HOSPITALIZACION-p-800.jpg 800w, ../../../public/images/CENTRAL-DE-ENFERMERIA-HOSPITALIZACION-p-1080.jpg 1080w, ../../../public/images/CENTRAL-DE-ENFERMERIA-HOSPITALIZACION-p-1600.jpg 1600w, ../../../public/images/CENTRAL-DE-ENFERMERIA-HOSPITALIZACION-p-2000.jpg 2000w, ../../../public/images/CENTRAL-DE-ENFERMERIA-HOSPITALIZACION-p-2600.jpg 2600w, ../../../public/images/CENTRAL-DE-ENFERMERIA-HOSPITALIZACION-p-3200.jpg 3200w, ../../../public/images/CENTRAL-DE-ENFERMERIA-HOSPITALIZACION.jpg 6960w" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 44vw, (max-width: 1919px) 46vw, 768px" src="../../../public/images/CENTRAL-DE-ENFERMERIA-HOSPITALIZACION.jpg" alt="" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-4">
              <div class="uui-blogpost05_content-left-2">
                <our-services></our-services>
                <side-contact-component></side-contact-component>
              </div>
            </div>
            <div id="w-node-_9b50c314-882a-eb87-c1d3-0f6530882b2d-48ca69a3" class="uui-blogpost05_content-2">
              <div class="uui-text-rich-text-3 w-richtext">
                <h4>{{$t('HospitalizationPage.article-1')}}</h4>
                <p>{{$t('HospitalizationPage.article-1-content1')}}<br></p>
                <p>{{$t('HospitalizationPage.article-1-content2')}}<br></p>
                <p>{{$t('HospitalizationPage.article-1-content3')}}<br></p>
              </div>
              <div class="uui-text-rich-text-3 w-richtext">
                <figure style="max-width:1680px" class="w-richtext-align-fullwidth w-richtext-figure-type-image">
                  <div><img src="../../../public/images/HOSPI-VI.jpg" loading="lazy" alt=""></div>
                </figure>
              </div>
              <h4 class="heading-22">{{$t('HospitalizationPage.hospitalization-area')}}</h4>
              <ul role="list">
                <li>{{$t('HospitalizationPage.hospitalization-area-li1')}}</li>
                <li>{{$t('HospitalizationPage.hospitalization-area-li2')}}</li>
                <li>{{$t('HospitalizationPage.hospitalization-area-li3')}}</li>
                <li>{{$t('HospitalizationPage.hospitalization-area-li4')}}</li>
              </ul>
              <div class="f-section-large-2">
                <div class="f-container-regular-4">
                  <div class="w-layout-grid f-gallery-two-column">
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec0-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-tall"><img src="../../../public/images/hospitalizacion-ham.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/hospitalizacion-ham-500.jpg 500w, ../../../public/images/hospitalizacion-ham-800.jpg 800w, ../../../public/images/hospitalizacion-ham-1080.jpg 1080w, ../../../public/images/hospitalizacion-ham-1600.jpg 1600w, ../../../public/images/hospitalizacion-ham-2000.jpg 2000w, ../../../public/images/hospitalizacion-ham-2600.jpg 2600w, ../../../public/images/hospitalizacion-ham-3200.jpg 3200w" alt="" class="f-image-cover"></div>
                    </a>
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec3-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-s"><img src="../../../public/images/hospitalizacion-p.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/hospitalizacion-p-500.jpg 500w, ../../../public/images/hospitalizacion-p-800.jpg 800w, ../../../public/images/hospitalizacion-p-1080.jpg 1080w, ../../../public/images/hospitalizacion-p-1600.jpg 1600w, ../../../public/images/hospitalizacion-p-2000.jpg 2000w, ../../../public/images/hospitalizacion-p-2600.jpg 2600w, ../../../public/images/hospitalizacion-p-3200.jpg 3200w" alt="" class="f-image-cover"></div>
                    </a>
                    <a href="#" id="w-node-ac3a02c4-9775-7341-3714-6898920b9ec6-4ba0942a" class="f-gallery-lightbox w-inline-block w-lightbox">
                      <div class="f-gallery-image-s"><img src="../../../public/images/hospitalizacion.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 60vw, (max-width: 1919px) 61vw, 1003.53125px" srcset="../../../public/images/hospitalizacion-500.jpg 500w, ../../../public/images/hospitalizacion-800.jpg 800w, ../../../public/images/hospitalizacion-1080.jpg 1080w, ../../../public/images/hospitalizacion-1600.jpg 1600w, ../../../public/images/hospitalizacion-2000.jpg 2000w, ../../../public/images/hospitalizacion-2600.jpg 2600w, ../../../public/images/hospitalizacion-3200.jpg 3200w" alt="" class="f-image-cover"></div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="uui-text-rich-text-7 w-richtext">
                <h4>{{$t('HospitalizationPage.additional-services')}}</h4>
                <p>{{$t('HospitalizationPage.article-2-content1')}}</p>
                <p>{{$t('HospitalizationPage.article-2-content2')}}</p>
                <p>{{$t('HospitalizationPage.article-2-content3')}}</p>
              </div>
              <div class="uui-page-padding-18">
                <div class="uui-container-large-14">
                  <div class="uui-padding-vertical-xhuge-20">
                    <div class="uui-max-width-large-9">
                      <h2 class="uui-heading-medium-11" style="margin-top: 2rem;margin-bottom: 2rem; text-align: center;color: var(--hc--colors--green--600);">{{$t('HospitalizationPage.Visiting-hours')}}</h2>
                      <p>{{$t('HospitalizationPage.Visiting-hours-content1')}} <br> {{$t('HospitalizationPage.Visiting-hours-content2')}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-layout-grid uui-cta03_component">
                <div class="uui-cta03_content">
                  <h2 class="uui-heading-large-3">{{$t('HospitalizationPage.complementary-services')}}</h2>
                  <div class="uui-space-small-8"></div>
                  <p><strong> {{$t('HospitalizationPage.complementary-service-li1')}} </strong><br> {{$t('HospitalizationPage.complementary-service-content1')}} </p>
                  <p><strong> {{$t('HospitalizationPage.complementary-service-li2')}} </strong> {{$t('HospitalizationPage.complementary-service-content2')}} </p>
                  <p><strong> {{$t('HospitalizationPage.complementary-service-li3')}} </strong> {{$t('HospitalizationPage.complementary-service-content3')}} </p>
                  <p><strong> {{$t('HospitalizationPage.complementary-service-li4')}} </strong> {{$t('HospitalizationPage.complementary-service-content4')}} </p>
                  <p><strong> {{$t('HospitalizationPage.complementary-service-li5')}} </strong><br> {{$t('HospitalizationPage.complementary-service-content5')}} </p>
                </div><img src="../../../public/images/frame-hospitalizacion.png" loading="lazy" id="w-node-_9a312395-9a72-5927-77f9-6de52c4ebc85-24e86033" alt="" class="image-8">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<style scoped>
</style>
