<script >

// import OurSpecialties from "@/components/OurSpecialties.vue";
// import AddressComponent from "@/components/AddressComponent.vue";
// import AppointmentComponent from "@/components/AppointmentComponent.vue";

import AddressComponent from "@/components/AddressComponent.vue";
import OurSpecialties from "@/components/OurSpecialties.vue";
import SideContactComponent from "@/components/SideContactComponent.vue";
import AppointmentComponent from "@/components/AppointmentComponent.vue";
import {app} from "@/main";

export default{
  name:"DermatologyPage",
  components: {AppointmentComponent, SideContactComponent, OurSpecialties, AddressComponent},
  data() {
    return {
      locale:'',
      specialties:[12],
      specialists:[],
    };
  },

  mounted() {
    app.config.globalProperties.$emitter.on('languageChanged', (locale) => {
      this.loadData(locale);
    });

  },
  created(){
    let locale = this.$i18n.locale;
    this.locale = locale;
    this.loadData(locale);
  },
  methods:{
    loadData(locale){
      this.specialists = require('../../data/specialists.json');
      this.specialists = this.specialists.filter(s =>
          s.specialties.some(specialty => this.specialties.includes(specialty.id))
      );
      this.specialists = this.specialists.map(specialist => {
        const translateInformation= specialist.information[locale];
        let specialties = specialist.specialties.map(specialty=>{
          const name= specialty.name[locale];
          return {
            ...specialty,
            name:name
          }
        });

        return {
          ...specialist,
          information: translateInformation,
          specialties:specialties
        };
      });

    },
    stringArray(string) {
      return string.split(',');
    },
  }
}
</script>

<template>
  <header class="uui-section_blogpost05-7">
    <div class="uui-page-padding-49">
      <div class="uui-container-large-39">
        <div class="uui-padding-vertical-xhuge-51">
          <div class="w-layout-grid uui-blogpost05_header-7">
            <div id="w-node-c84db9d6-587f-f145-8c90-6dcfdba5207d-86e516d0" class="uui-blogpost05_title-wrapper-7">
              <div class="uui-button-row-12">
                <router-link data-w-id="c84db9d6-587f-f145-8c90-6dcfdba5207f" to="/" class="uui-button-link-10 w-inline-block">
                  <div class="uui-button-icon-11 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 10.0003H4.1665M4.1665 10.0003L9.99984 15.8337M4.1665 10.0003L9.99984 4.16699" stroke="CurrentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg></div>
                  <div>{{$t('dermatology.home')}}</div>
                </router-link>
              </div>
              <h1 class="uui-heading-large-15">{{$t('dermatology.title')}}</h1>
              <div class="uui-space-large-7"></div>
              <div class="text-block-13">{{$t('dermatology.subtitle')}}</div>
             <address-component></address-component>
            </div>
            <div id="w-node-c84db9d6-587f-f145-8c90-6dcfdba52089-86e516d0" class="uui-blogpost05_image-wrapper-7"><img srcset="../../../public/images/Dermatologia-p-500.jpeg 500w, ../../../public/images/Dermatologia-p-800.jpeg 800w, ../../../public/images/Dermatologia-puerto-escondido.jpg 1045w" loading="lazy" src="../../../public/images/Dermatologia-puerto-escondido.jpg" alt="" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 94vw, (max-width: 1439px) 44vw, (max-width: 1919px) 46vw, 768px" class="uui-blogpost03_image-2"></div>
          </div>
          <div class="uui-blogpost05_content-wrapper-8">
            <div class="uui-blogpost05_content-left-2">
              <our-specialties></our-specialties>
              <side-contact-component></side-contact-component>
            </div>
            <div id="w-node-c84db9d6-587f-f145-8c90-6dcfdba5208d-86e516d0" class="uui-blogpost05_content-7">
              <div class="uui-blogpost05_content-2">
                <h3 class="ds-md-bold">{{$t('dermatology.specialty')}}</h3>
                <div class="uui-text-rich-text-3 w-richtext">
                  <p>{{$t('dermatology.p1')}}</p>
                </div>
                <ul role="list">
                  <li class="list-item-7">
                    <strong>{{$t('dermatology.li1-name')}}</strong>
                    {{$t('dermatology.li1-text')}}</li>
                  <li>
                    <strong>{{$t('dermatology.li2-name')}}</strong>
                    {{$t('dermatology.li2-text')}}</li>
                  <li class="list-item-8">
                    <strong>{{$t('dermatology.li3-name')}}</strong>
                    {{$t('dermatology.li3-text')}}</li>
                  <li class="list-item-9">
                    <strong>{{$t('dermatology.li4-name')}}</strong>
                    {{$t('dermatology.li4-text')}}</li>
                </ul>
                <section class="equipos-mujer">
                  <div class="rl-padding-global-20">
                    <div class="rl-container-large-15"></div>
                  </div>
                </section>
                <div class="uui-padding-vertical-xhuge-20">
                  <div class="uui-max-width-large-9">
                    <h2 class="ds-md-bold">{{$t('dermatology.medical-team')}}</h2>
                    <div class="uui-space-xsmall-10"></div>
                  </div>
                  <div class="uui-team07_component-2">
                    <div class="uui-team07_list-wrapper-2">
                      <div class="w-layout-grid item_medico">

<!--                        <div  v-for="(item, index) in specialists" :key="index" class="card_doctor">-->
<!--                          <div class="uui-team07_image-wrapper-2"><img v-bind:src="item.profile_picture" loading="lazy" sizes="84px"  alt="" class="uui-team07_image-2"></div>-->
<!--                          <div class="uui-team07_item-content-2">-->
<!--                            <div class="uui-heading-tiny-4">{{item.name}}</div>-->
<!--                            <div v-if="item.professional_license.length"  class="uui-text-size-medium-15 text-color-primary700">{{$t('dermatology.professional-license')}} {{item.professional_license}}</div>-->
<!--                            <div v-if="item.specialty_license.length">-->
<!--                              <div  v-for="(spe, i) in stringArray(item.specialty_license)" :key="i"  class="uui-text-size-medium-15 text-color-primary700">Ced. Esp. {{spe}} </div>-->
<!--                            </div>-->
<!--                            <div class="uui-space-xxsmall-8"></div>-->
<!--                            <div class="uui-text-size-medium-15">-->
<!--                              <strong v-for="(specialty, index) in item.specialties" :key="index">{{specialty.name}} <br></strong>-->
<!--                              {{item.information}}-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
                        <div class="card_doctor">
                          <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/doca.png" loading="lazy" alt="" class="uui-team07_image-2"></div>
                          <div class="uui-team07_item-content-2">
                            <div class="uui-heading-tiny-4">Dra. Ana Karla Díaz</div>
                            <div class="uui-text-size-medium-15 text-color-primary700">{{$t('dermatology.professional-license')}} 8761927</div>
                            <div class="uui-text-size-medium-15 text-color-primary700">{{$t('dermatology.specialty-license')}} 11718999</div>
                            <div class="uui-space-xxsmall-8"></div>
                            <div class="uui-text-size-medium-15"><strong>{{$t('dermatology.specialty')}}<br>‍</strong>{{$t('dermatology.dr1-description')}}</div>
                          </div>
                        </div>
                        <div class="card_doctor">
                          <div class="uui-team07_image-wrapper-2"><img src="../../../public/images/doca.png" loading="lazy" alt="" class="uui-team07_image-2"></div>
                          <div class="uui-team07_item-content-2">
                            <div class="uui-heading-tiny-4">Dra. Dayra Rebeca Jiménez Mendoza</div>
                            <div class="uui-text-size-medium-15 text-color-primary700">C{{$t('dermatology.professional-license')}} 8320656</div>
                            <div class="uui-text-size-medium-15 text-color-primary700">{{$t('dermatology.specialty-license')}} 11543103</div>
                            <div class="uui-space-xxsmall-8"></div>
                            <div class="uui-text-size-medium-15"><strong>{{$t('dermatology.specialty')}}<br></strong>{{$t('dermatology.dr2-description')}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <appointment-component></appointment-component>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>

</style>
