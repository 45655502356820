<script>
export default {
  name: "AddressLaboratory"
}
</script>

<template>
  <div class="uui-text-size-medium-36">{{$t('home.laboratory')}}</div>
  <div class="uui-space-small-23"></div>
  <a href="tel:9541111702" class="uui-text-style-link-02-5 spacing">954 111 1702</a>
</template>

<style scoped>

</style>
